import React, { useState, useEffect } from "react";
import TodoDataService from "../../services/todos";
import {
  Modal,
  Form,
  Button,
  OverlayTrigger,
  Tooltip,
  Card,
  Row,
  Col,
  Popover,
  Image,
  Alert,
} from "react-bootstrap";
import { AiOutlinePlus, AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { BsFillChatFill, BsTrash } from "react-icons/bs";
import { MdMessage } from "react-icons/md";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../components/navigation/Footer";
import publicidad1 from "../../assets/logo-infocoop-50anos.png";
import publicidad2 from "../../assets/redes_sociales.png";
import publicidad3 from "../../assets/visibilidad.png";
import './manage_blog.css';
import { useMediaQuery } from "react-responsive";

const ManageBlog = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [image, setImage] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [selectedBlogId, setSelectedBlogId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [showComments, setShowComments] = useState({});
  const [likedPosts, setLikedPosts] = useState([]);
  const [showDeletePopover, setShowDeletePopover] = useState(false);
  const [deletePopoverTarget, setDeletePopoverTarget] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const token = useSelector((state) => state.authentication.token);
  const user = useSelector((state) => state.authentication.user);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  
  useEffect(() => {
    fetchBlogPosts();
  }, []);

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    const likedBlogPosts = blogPosts.filter((blogPost) =>
      blogPost.likes.some((like) => like.user === userId)
    );
    setLikedPosts(likedBlogPosts);
  }, [blogPosts]);

  const fetchBlogPosts = () => {
    TodoDataService.getAllBlogPosts()
      .then((response) => {
        setBlogPosts(response.data);
      })
      .catch((error) => {
        console.error("Error fetching blog posts:", error);
      });
  };
  const createBlogPost = () => {
    const data = new FormData();
    data.append("title", title);
    data.append("content", content);
    data.append("image", image);

    const token = localStorage.getItem("token");
    TodoDataService.createBlogPost(data, token)
      .then(() => {
        fetchBlogPosts(token);
        setTitle("");
        setContent("");
        setImage(null);
      })
      .catch((error) => {
        console.error("Error creating blog post:", error);
      });
  };

  const openEditModal = (id) => {
    const selectedBlog = blogPosts.find((blogPost) => blogPost.id === id);
    if (selectedBlog) {
      setSelectedBlogId(id);
      setTitle(selectedBlog.title);
      setContent(selectedBlog.content);
      setImage(null);
      setIsModalOpen(true);
    }
  };

  const closeEditModal = () => {
    setSelectedBlogId(null);
    setTitle("");
    setContent("");
    setIsModalOpen(false);
  };

  const updateBlogPost = () => {
    if (selectedBlogId) {
      const data = new FormData();

      data.append("title", title);
      data.append("content", content);

      if (image && typeof image === "object") {
        data.append("image", image);
      }

      const token = localStorage.getItem("token");
      console.log("Data to be sent:", Object.fromEntries(data.entries()));

      TodoDataService.updateBlogPost(selectedBlogId, data, token)
        .then(() => {
          fetchBlogPosts(token);
          closeEditModal();
        })
        .catch((error) => {
          console.error("Error updating blog post:", error);
        });
    }
  };

  const deleteBlogPost = (id) => {
    const token = localStorage.getItem("token");
    TodoDataService.deleteBlogPost(id, token)
      .then(() => {
        fetchBlogPosts(token);
        setShowDeletePopover(false);
      })
      .catch((error) => {
        console.error("Error deleting blog post:", error);
      });
  };

  const handleCommentChange = (event, blogPostId) => {
    const { value } = event.target;
    const modifiedBlogPosts = blogPosts.map((blogPost) => {
      if (blogPost.id === blogPostId) {
        return {
          ...blogPost,
          comment: value,
        };
      }
      return blogPost;
    });
    setBlogPosts(modifiedBlogPosts);
  };

  const createComment = (blogPostId, comment) => {
    const token = localStorage.getItem("token");
  
    // Verifica si el token existe
    if (!token) {
      alert("Necesitas autenticarte para poder comentar en nuestros blogs.");
      return; // Sal de la función si no hay token
    }
  
    const data = {
      blog_post: blogPostId,
      user: localStorage.getItem("userId"),
      content: comment,
    };
  
    TodoDataService.createComment(data, token)
      .then(() => {
        fetchBlogPosts(token);
        const modifiedBlogPosts = blogPosts.map((blogPost) => {
          if (blogPost.id === blogPostId) {
            return {
              ...blogPost,
              comment: "",
            };
          }
          return blogPost;
        });
        setBlogPosts(modifiedBlogPosts);
      })
      .catch((error) => {
        console.error("Error creating comment:", error);
      });
  };
  

  const deleteComment = (blogPostId, commentId) => {
    const token = localStorage.getItem("token");
    TodoDataService.deleteComment(blogPostId, commentId, token)
      .then(() => {
        fetchBlogPosts(token);
      })
      .catch((error) => {
        console.error("Error deleting comment:", error);
      });
  };

  const toggleLike = (blogPostId) => {
    const token = localStorage.getItem("token");
  
    // Verifica si el token existe
    if (!token) {
      alert("Necesitas autenticarte para interactuar en nuestros blogs.");
      return; // Sal de la función si no hay token
    }
  
    const userId = localStorage.getItem("userId");
    const likedBlogPost = blogPosts.find(
      (blogPost) => blogPost.id === blogPostId
    );

    if (likedBlogPost.likes.some((like) => like.user === userId)) {
      TodoDataService.deleteLike(blogPostId, userId, token)
        .then(() => {
          fetchBlogPosts(token);
        })
        .catch((error) => {
          console.error("Error deleting like:", error);
        });
    } else {
      TodoDataService.createLike(blogPostId, userId, token)
        .then(() => {
          fetchBlogPosts(token);
        })
        .catch((error) => {
          console.error("Error creating like:", error);
        });
    }
  };

  const openImageModal = (blogPost) => {
    setSelectedImage(blogPost);
    setIsImageModalOpen(true);
  };

  const closeImageModal = () => {
    setSelectedImage(null);
    setIsImageModalOpen(false);
  };

  const toggleComments = (blogPostId) => {
    setShowComments((prevState) => ({
      ...prevState,
      [blogPostId]: !prevState[blogPostId],
    }));
  };

  const renderLikesTooltip = (likes) => (
    <Tooltip
      id="likes-tooltip"
      style={{
        fontSize: "12px",
        background: "rgba(255, 255, 255, 1)",
        color: "#000",
        borderRadius: "8px",
      }}
    >
      {likes.map((like) => (
        <div key={like.user}>{like.user}</div>
      ))}
    </Tooltip>
  );

  const handleDeleteBlogPost = async (blogPostId) => {
    try {
      const token = localStorage.getItem("token");
      await TodoDataService.deleteBlogPost(blogPostId, token);
      // Actualizar la lista de publicaciones después de la eliminación exitosa
      fetchBlogPosts();
    } catch (error) {
      console.error("Error deleting blog post:", error);
    }
  };
  const [isFullScreen, setIsFullScreen] = useState(false); // Estado para pantalla completa

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen); // Cambiar entre pantalla completa y normal
  };
  
  const renderBlogPostModal = (blogPost) => (
    <Modal
      show={isImageModalOpen}
      onHide={closeImageModal}
      centered
      style={{
        backgroundColor: 'rgba(0, 0, 0, 0.8)', // Sombreado de fondo
        width:"100%"
      }}
    >
      {!isFullScreen && <Modal.Title style={{ textAlign: 'center', color: 'white' }}>{blogPost.title}</Modal.Title>}
      <Modal.Body
        style={{
          maxHeight: isFullScreen ? "100vh" : "90vh",
          overflowY: "auto",
          top: "10%",
          backgroundColor: 'white', // Fondo blanco del modal
          border: '2px solid red', // Borde rojo
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.5)', // Sombreado negro
          borderRadius: '10px',
          padding: isMobile ? '10px' : '20px', // Ajuste de padding según el dispositivo
          
        }}
      >
        <div style={{ textAlign: "left", width: "100%", position: "relative" }}>
          {isFullScreen && (
            <button
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                background: "transparent",
                border: "none",
                fontSize: "24px",
                color: "red",
                cursor: "pointer",
                zIndex: 1000,
              }}
              onClick={toggleFullScreen}
            >
              X
            </button>
          )}
          <Image
            src={blogPost.image}
            alt="Blog Post Image"
            fluid
            style={{
              marginBottom: "20px",
              maxHeight: isFullScreen ? "100vh" : "300px",
              width: isFullScreen ? "100%" : "auto",
              cursor: "pointer",
            }}
            onClick={toggleFullScreen} // Al hacer clic, activa la pantalla completa
          />
          {!isFullScreen && (
            <>
              <pre style={{ whiteSpace: "pre-wrap", color: 'black' }}>{blogPost.content}</pre>
              <p style={{ marginTop: "10px", color: 'gray' }}>Author: {blogPost.author}</p>
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
  

  const [index, setIndex] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 10000); // Cambia el valor para ajustar la velocidad del carrusel

    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      clearInterval(intervalId);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const images = [publicidad1, publicidad2, publicidad3];

  return (
    <div
      style={{
        width: "100%",
        minHeight: "100vh",
        overflow: "auto",
        padding: "20px",
      }}
    >
      {/* <h1 style={{ fontWeight: 'bold', textAlign: 'left' }}>Manage Blog</h1> */}
      <br />
      <br />
      <br />

      {user !== null && (
        <div
          style={{
            position: "fixed",
            bottom: "10px",
            right: "10px",
            zIndex: "1",
          }}
        >
          <Button variant="primary" onClick={() => setIsModalOpen(true)}>
            <AiOutlinePlus size={24} />
          </Button>
        </div>
      )}
      <div style={{ display: "flex" }}>
        {/* Columna de Blog Posts (50%) */}
        <div style={{ width: "100%", marginTop: '5%' }}>
          <br />
          <h2
            style={{
              color: "red",
              fontWeight: "bold",
              textShadow: "2px 2px 4px #000", // Sombra roja
              textTransform: "none",
            }}
          >
            Publicaciones del Blog ABCupon
          </h2>
          <Row>
            {blogPosts.map((blogPost) => (
              <Col key={blogPost.id} md={12} style={{ marginBottom: "20px" }}>
             <Card style={{ borderRadius: '10px', overflow: 'hidden', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)', marginBottom: '20px' }}>
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Card.Img
      variant="top"
      as={Image}
      src={blogPost.image}
      alt="Blog Post Image"
      fluid
      style={{
        objectFit: 'cover',
        cursor: 'pointer',
        maxHeight: '200px', // Ajusta la altura máxima según tus necesidades
        width: '100%',
      }}
      onClick={() => openImageModal(blogPost)}
    />
  </div>
  <Card.Body>
    <Card.Title
      style={{
        fontWeight: 'bold',
        textAlign: 'center', // Cambiado a center
        color: 'red',
        fontSize: '1.8rem',
        textShadow: '2px 2px 4px #000',
      }}
    >
      {blogPost.title}
    </Card.Title>
    <div style={{ marginBottom: '10px', textAlign: 'center', fontFamily: 'Arial, sans-serif' }}>
      <p style={{ fontSize: '16px', color: 'black', whiteSpace: 'pre-wrap', marginBottom: '5px' }}>
        {blogPost.content}
      </p>
      <p style={{ fontSize: '10px', color: 'black', fontWeight: 'bold', marginBottom: '5px' }}>
        Autor: {blogPost.author}
      </p>
    </div>

    {user !== null && (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button variant="primary" onClick={() => openEditModal(blogPost.id)}>
          Editar
        </Button>
        <Button
          variant="danger"
          onClick={() => handleDeleteBlogPost(blogPost.id)}
          style={{ fontSize: '10px', transform: 'scale(0.5)' }}
        >
          <BsTrash size={14} style={{ verticalAlign: 'middle' }} />
        </Button>
      </div>
    )}
  </Card.Body>
  <Card.Footer style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <OverlayTrigger placement="top" overlay={renderLikesTooltip(blogPost.likes)}>
        <span style={{ cursor: 'pointer' }}>
          {likedPosts.some((post) => post.id === blogPost.id) ? (
            <AiFillHeart size={20} onClick={() => toggleLike(blogPost.id)} />
          ) : (
            <AiOutlineHeart size={20} onClick={() => toggleLike(blogPost.id)} />
          )}
        </span>
      </OverlayTrigger>
      
      {/* Contenedor para el icono de comentarios y el textarea */}
      <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
        <OverlayTrigger placement="left" overlay={<Tooltip id="comment-tooltip">Comentarios</Tooltip>}>
          <Button variant="link" onClick={() => toggleComments(blogPost.id)} style={{ fontSize: '10px', padding: '4px' }}>
            <BsFillChatFill size={40} style={{ verticalAlign: 'middle' }} />
          </Button>
        </OverlayTrigger>
        
        {showComments[blogPost.id] && (
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px', flexGrow: 1 }}>
            <Form.Control
              as="textarea"
              value={blogPost.comment}
              onChange={(event) => handleCommentChange(event, blogPost.id)}
              style={{ width: "100%", marginRight: '10px' }} // Añadir margen a la derecha
              placeholder="Escribe un comentario..."
            />
            <Button variant="primary" onClick={() => createComment(blogPost.id, blogPost.comment)}>
              Comentar
            </Button>
          </div>
        )}
      </div>
    </div>
    <small className="text-muted">Autor: {blogPost.author}</small>
  </Card.Footer>
</Card>



              </Col>
            ))}
          </Row>
        </div>
        {/* Columna de Cooperativas (50%) */}

        <div
          style={{
            width: "50%",
            marginLeft: "3%",
            overflow: "hidden",
            whiteSpace: "nowrap",
            display: "flex",
            flexDirection: "column",
            transition: "transform 6s ease", // Ajusta la duración y la función de temporización según sea necesario
            transform: `translateY(-${
              index * 100
            }%) translateY(${scrollPosition}px)`,
          }}
        >
          <br />
          <br />
          <br />
          <br />
          <br />
          {/* <h1>Aqui se puede agregar la publicidad!!</h1> */}
          {images.map((image, i) => (
            <Image
              key={i}
              src={image}
              alt={`Cooperative Image ${i + 1}`}
              style={{
                maxHeight: "30%",
                maxWidth: "100%",
                marginBottom: "10px",
              }}
            />
          ))}
        </div>
      </div>
      <Modal show={isModalOpen} onHide={closeEditModal} centered>
        {/* <Modal.Header closeButton> */}
          <Modal.Title>
            {selectedBlogId
              ? "Editar publicacion del publicacion"
              : "Crear publicacion del blog"}
          </Modal.Title>
        {/* </Modal.Header> */}
        <Modal.Body style={{ maxHeight: "60vh", overflowY: "auto" }}>
          <Form>
            <Form.Group controlId="formTitle">
              <Form.Label>Titulo</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formContent">
              <Form.Label>Contenido</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter content"
                value={content}
                onChange={(e) => setContent(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formImage">
              <Form.Label>Imagen</Form.Label>
              <Form.Control
                type="file"
                onChange={(e) => setImage(e.target.files[0])}
              />
              {image && (
                <div>
                  <h4>Preview:</h4>
                  <img
                    src={URL.createObjectURL(image)}
                    alt="Preview"
                    style={{ width: "100%", marginBottom: "10px" }}
                  />
                </div>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {selectedBlogId ? (
            <Button variant="primary" onClick={updateBlogPost}>
              Actualizar
            </Button>
          ) : (
            <Button variant="primary" onClick={createBlogPost}>
              Crear
            </Button>
          )}
          <Button variant="secondary" onClick={closeEditModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      <Footer />
      {selectedImage && renderBlogPostModal(selectedImage)}
    </div>
  );
};

export default ManageBlog;
