import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap'

const SkillModal = ({ show, handleClose, skill, handleSave }) => {
    const [name, setName] = useState(skill ? skill.name : '');
    const [description, setDescription] = useState(skill ? skill.description : '');

    const handleSubmit = (e) => {
        e.preventDefault();
        handleSave({ id: skill ? skill.id : null, name, description });
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleClose}>
                <Modal.Title>{skill ? 'Editar Habilidad' : 'Crear Habilidad'}</Modal.Title>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formSkillName">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control 
                            type="text" 
                            value={name} 
                            onChange={(e) => setName(e.target.value)} 
                            required 
                        />
                    </Form.Group>
                    <Form.Group controlId="formSkillDescription">
                        <Form.Label>Descripcion</Form.Label>
                        <Form.Control 
                            type="text" 
                            value={description} 
                            onChange={(e) => setDescription(e.target.value)} 
                            required 
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit">Guardar</Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default SkillModal;
