import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const JobAlertModal = ({ 
    show, 
    handleClose, 
    jobAlert,
    jobCategories, 
    experienceLevels, 
    skills,  
    handleSave 
}) => {
    const [alertText, setAlertText] = useState(jobAlert ? jobAlert.alertText : '');
    const [category, setCategory] = useState(jobAlert ? jobAlert.category : '');
    const [location, setLocation] = useState(jobAlert ? jobAlert.location : '');
    const [experienceLevel, setExperienceLevel] = useState(jobAlert ? jobAlert.experienceLevel : '');
    const [selectedSkills, setSelectedSkills] = useState(jobAlert ? jobAlert.skills_required : []);

    const handleMultiSelectChange = (e, setter) => {
        const options = e.target.options;
        const selectedValues = [];
        for (let i = 0, len = options.length; i < len; i++) {
            if (options[i].selected) {
                selectedValues.push(options[i].value);
            }
        }
        setter(selectedValues);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        handleSave({
            // id: jobAlert ? jobAlert.id : null,
            keywords: alertText,
            category,
            location,
            experienceLevel,
            skills: selectedSkills,
        });
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{jobAlert ? 'Editar Alerta de Trabajo' : 'Crear Alerta de Trabajo'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formJobAlertText">
                        <Form.Label>Texto de la Alerta</Form.Label>
                        <Form.Control 
                            type="text" 
                            value={alertText} 
                            onChange={(e) => setAlertText(e.target.value)} 
                            required 
                        />
                    </Form.Group>

                    <Form.Group controlId="formJobCategory">
                        <Form.Label>Categoría</Form.Label>
                        <Form.Control 
                            as="select" 
                            value={category} 
                            onChange={(e) => setCategory(e.target.value)} 
                            required
                        >
                            <option value="">Seleccionar...</option>
                            {jobCategories.map((cat) => (
                                <option key={cat.id} value={cat.id}>{cat.name}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>

                    <Form.Group controlId="formJobLocation">
                        <Form.Label>Ubicación</Form.Label>
                        <Form.Control 
                            type="text" 
                            value={location} 
                            onChange={(e) => setLocation(e.target.value)} 
                            required 
                        />
                    </Form.Group>

                    <Form.Group controlId="formJobExperienceLevel">
                    <Form.Label>Nivel de Experiencia</Form.Label>
                        <Form.Control 
                            as="select" 
                            value={experienceLevel} 
                            onChange={(e) => setExperienceLevel(e.target.value)} 
                            required
                        >
                            <option value="">Seleccionar...</option>
                            {experienceLevels.map((level) => (
                                <option key={level.id} value={level.id}>{level.level}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>

                    <Form.Group controlId="formJobSkills">
                    <Form.Label>Habilidades Requeridas</Form.Label>
                        <Form.Control 
                            as="select" 
                            multiple 
                            value={selectedSkills} 
                            onChange={(e) => handleMultiSelectChange(e, setSelectedSkills)}
                        >
                            {skills.map(skill => (
                                <option key={skill.id} value={skill.id}>{skill.name}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>

                    <Button variant="primary" type="submit">Guardar</Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default JobAlertModal;
