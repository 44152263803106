import React, { useState, useEffect } from "react";
import { Table, Button } from "react-bootstrap";
import JobDataService from "../../services/employee";
import JobPostingModal from "./job_modal/jobposting_modal";
import JobPostingDetails from "./job_modal/jobposting_details";

const JobPostings = ({ token, currentUser }) => {
  const [jobPostings, setJobPostings] = useState([]);
  const [currentJobPosting, setCurrentJobPosting] = useState(null);
  const [showJobPostingModal, setShowJobPostingModal] = useState(false);
  const [showJobPostingDetails, setShowJobPostingDetails] = useState(false);
  const [jobCategories, setJobCategories] = useState([]);
  const [experienceLevels, setExperienceLevels] = useState([]);
  const [jobTags, setJobTags] = useState([]);
  const [benefits, setBenefits] = useState([]);
  const [skills, setSkills] = useState([]);
  const [companies, setCompanies] = useState([]);
  useEffect(() => {
    loadJobPostings();
    loadJobCategories();
    loadExperienceLevels();
    loadJobTags();
    loadSkills();
    loadBenefits();
    loadCompanies();
  }, []);
  useEffect(() => {
    loadJobPostings();
    loadJobCategories();
    loadExperienceLevels();
    loadJobTags();
    loadSkills();
    loadBenefits();
    loadCompanies();
  }, [currentUser]);

  const loadJobPostings = () => {
    JobDataService.getAllJobPostings(token)
      .then((response) => {
        const postingsWithCompanyNames = response.data.map(posting => {
          // Buscar el nombre de la empresa basado en el ID
          const company = companies.find(company => company.id === posting.company);
          return { ...posting, companyName: company ? company.name : "Desconocido" }; // Cambia 'name' si tu propiedad se llama diferente
        });
        setJobPostings(postingsWithCompanyNames);
      })
      .catch((error) => console.error(error));
  };
 const loadJobCategories = () => {
    JobDataService.getAllJobCategories(token)
      .then((response) => {
        console.log("jobcategory", response.data);
        setJobCategories(response.data);
      })
      .catch((error) => console.error(error));
  };
  const loadExperienceLevels = () => {
    JobDataService.getAllExperienceLevels(token)
      .then((response) => {
        setExperienceLevels(response.data);
      })
      .catch((error) => console.error(error));
  };
  const loadJobTags = () => {
    JobDataService.getAllJobTags(token)
      .then((response) => {
        setJobTags(response.data);
      })
      .catch((error) => console.error(error));
  };
  const loadBenefits = () => {
    JobDataService.getAllBenefits(token)
      .then((response) => {
        setBenefits(response.data);
      })
      .catch((error) => console.error(error));
  };
  const loadSkills = () => {
    JobDataService.getAllSkills(token)
      .then((response) => {
        setSkills(response.data);
      })
      .catch((error) => console.error(error));
  };
  const loadCompanies = () => {
    JobDataService.getAllCompanies(token)
      .then((response) => {
        const filteredCompanies = response.data.filter(
          (company) => company.user === currentUser.id
        );
        setCompanies(filteredCompanies);
        console.log("empresas necesarias", companies)
      })
      .catch((error) => console.error(error));
  };

  const handleSaveJobPosting = (jobPosting) => {
    console.log("jobposting", jobPosting);
    const formData = new FormData();
    
    formData.append("company", jobPosting.company);
    formData.append("title", jobPosting.title);
    formData.append("description", jobPosting.description);
    formData.append("category", jobPosting.category);
    formData.append("city", jobPosting.city);
    formData.append("country", jobPosting.country);
    formData.append("region", jobPosting.region);
    formData.append("modality", jobPosting.modality);
    formData.append("employment_type", jobPosting.employment_type);
    formData.append("experience_level", jobPosting.experience_level);
    formData.append("salary_range", jobPosting.salary_range);

    // Manejo de múltiples skills
    if (Array.isArray(jobPosting.skills_required)) {
        jobPosting.skills_required.forEach(skillId => {
            formData.append("skills_required", skillId); // Añade cada skill individualmente
        });
    } else {
        formData.append("skills_required", jobPosting.skills_required); // Para un solo skill
    }

    // Manejo de múltiples tags
    if (Array.isArray(jobPosting.tags)) {
        jobPosting.tags.forEach(tagId => {
            formData.append("tags", tagId); // Añade cada tag individualmente
        });
    } else {
        formData.append("tags", jobPosting.tags); // Para un solo tag
    }

    formData.append("benefits", jobPosting.benefits);
    formData.append("application_deadline", jobPosting.application_deadline);
    formData.append("how_to_apply", jobPosting.how_to_apply);

    // Debugging: Imprimir todos los datos
    for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
    }

    JobDataService.createJobPosting(formData, token)
    .catch((error) => console.error("Error al crear empresa", error));

    loadJobPostings();
};


  const deleteJobPosting = (postingId) => {
    JobDataService.deleteJobPosting(postingId, token)
      .then((response) => {
        loadJobPostings(); // Recargar datos de publicaciones de trabajo después de la eliminación
      })
      .catch((error) => console.error(error));
  };

  const handleShowDetails = (posting) => {
    setCurrentJobPosting(posting);
    setShowJobPostingDetails(true);
  };

  

  return (
    <div style={{overflowY:"auto"}}>
      <h2>Job Postings</h2>
            <Button variant="primary" onClick={() => setShowJobPostingModal(true)}>Crear Publicación</Button>
            <Table striped bordered hover>
                <thead>
                    <tr>
                    <th style={{ color: 'blue' }}>Empresa</th>
                        <th style={{ color: 'blue' }}>Título</th>
                        <th style={{ color: 'blue' }}>Descripción</th>
                        <th style={{ color: 'blue' }}>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {jobPostings.map((posting) => (
                        <tr key={posting.id}>
                          <td>{posting.companyName}</td>
                            <td><span 
                  style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }} 
                  onClick={() => handleShowDetails(posting)} // Abre el modal de detalles
                >
                  {posting.title}
                </span></td>
                            <td>{posting.description}</td>
                            <td>
                                <Button variant="danger" onClick={() => deleteJobPosting(posting.id)}>Eliminar</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

    
            <JobPostingModal
                show={showJobPostingModal} 
                handleClose={() => setShowJobPostingModal(false)} 
                jobPosting={currentJobPosting} 
                handleSave={handleSaveJobPosting} 
                jobCategories={jobCategories}  
                experienceLevels={experienceLevels}
                tags ={jobTags} 
                benefits={benefits}
                skills={skills}
                companies={companies}
            />

        <JobPostingDetails 
        token={token}
        currentUser={currentUser}
        show={showJobPostingDetails} 
        handleClose={() => setShowJobPostingDetails(false)} 
        jobPosting={currentJobPosting} // Enviamos el jobPosting actual
        experienceLevels={experienceLevels} // Agregamos los niveles de experiencia
        benefits={benefits} // Agregamos beneficios
        skills={skills} // Agregamos habilidades
        jobTags={jobTags} // Agregamos etiquetas
      />
    </div>
  );
};

export default JobPostings;
