import React, { useState } from 'react';
import Provincias from '../../components/json/provincias.json';

const Visualizacion = () => {
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [activeProvince, setActiveProvince] = useState(null); // Estado para la provincia activa
  const [activeCanton, setActiveCanton] = useState(null); // Estado para el canton activo

  const handleProvinceSelect = (province) => {
    setSelectedProvince(province);
    setActiveProvince(province); // Establece la provincia activa
    setActiveCanton(null); // Reinicia el canton activo
  };

  const handleCantonSelect = (canton) => {
    setActiveCanton(canton.nombre); // Establece el canton activo
    window.open(canton.facebook, '_blank'); // Abre el enlace del canton
  };

  // Estilos para el contenedor de botones
  const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'flex-start',
    margin: '10px 0',
    flexWrap: 'wrap',
  };

  // Estilos para los botones
  const buttonStyle = {
    margin: '5px',
    padding: '10px',
    cursor: 'pointer',
  };

  // Estilos para los títulos
  const titleStyle = {
    fontSize: '18px',
    margin: '10px 0',
    textAlign: 'left',
    display: 'inline-block',
    width: '100%',
  };

  return (
    <div>
      <h1 style={titleStyle}>Visualización de Facebook de Municipalidades de Cantones</h1>
      
      {/* Botones para las provincias */}
      <div style={buttonContainerStyle}>
        {Object.keys(Provincias.provincias).map((province) => (
          <button
            key={province}
            style={{
              backgroundColor: activeProvince === province ? 'green' : 'white', // Cambia el color según la provincia activa
              color: activeProvince === province ? 'white' : 'black',
              fontWeight: 'bold',
              border: '1px solid black',
              padding: '10px 20px',
              cursor: 'pointer',
              width: 'auto',
              transition: 'background-color 0.3s, color 0.3s',
              borderRadius: '10px',
              marginRight: '10px',
            }}
            onClick={() => handleProvinceSelect(province)}
          >
            {province}
          </button>
        ))}
      </div>

      {/* Si hay una provincia seleccionada, mostramos sus cantones */}
      {selectedProvince && (
        <div>
          <h2 style={titleStyle}>Cantones en {selectedProvince}</h2>
          <div style={buttonContainerStyle}>
            {Provincias.provincias[selectedProvince].cantones.map((canton) => (
              <button
                key={canton.nombre}
                style={{
                  backgroundColor: activeCanton === canton.nombre ? 'green' : 'white', // Cambia el color según el canton activo
                  color: activeCanton === canton.nombre ? 'white' : 'black',
                  fontWeight: 'bold',
                  border: '1px solid black',
                  padding: '10px 20px',
                  cursor: 'pointer',
                  width: 'auto',
                  transition: 'background-color 0.3s, color 0.3s',
                  borderRadius: '10px',
                  marginRight: '10px',
                }}
                onClick={() => handleCantonSelect(canton)} // Cambia el color al hacer clic
              >
                {canton.nombre}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Visualizacion;
