import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap'

const BenefitModal = ({ show, handleClose, benefit, handleSave }) => {
    const [description, setDescription] = useState(benefit ? benefit.description : '');
    const [name, setName] = useState(benefit ? benefit.name : '');

    const handleSubmit = (e) => {
        e.preventDefault();
        handleSave({ id: benefit ? benefit.id : null, description, name });
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{benefit ? 'Editar Beneficio' : 'Crear Beneficio'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formBenefitName">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control 
                            type="text" 
                            value={name} 
                            onChange={(e) => setName(e.target.value)} 
                            required 
                        />
                    </Form.Group>
                    <Form.Group controlId="formBenefitDescription">
                        <Form.Label>Descripción</Form.Label>
                        <Form.Control 
                            type="text" 
                            value={description} 
                            onChange={(e) => setDescription(e.target.value)} 
                            required 
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit">Guardar</Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default BenefitModal;
