// src/components/modals/ModalCrearForm.js
import React, { useState } from 'react';
import ShippingDataService from '../../../services/shipping';
import { useSelector } from 'react-redux';

const ModalCrearForm = ({ onClose, updateFields, users }) => {
  const [formName, setFormName] = useState('');
  const user = useSelector((state) => state.authentication.user);
  const token = useSelector((state) => state.authentication.token);

  const getUserNameById = (user1) => {
    console.log("todos los usuarios",users)
    const user = users.find((user) => user.email === user1);
    return user ? `${user.id} ` : "Desconocido";
  };

  const handleCreateForm = () => {
    // console.log("usuarios totales", users)
    const user_id = getUserNameById(user)
    const formData = {
      user: user_id,  // ID del usuario
      name: formName,
    };
    console.log("Datos de formulario a enviar:", formData);
    ShippingDataService.createForm(formData, token)
      .then(() => {
        alert('Formulario creado con éxito!');
        updateFields();  // Actualiza la lista de formularios
        onClose();  // Cierra el modal
      })
      .catch((error) => {
        console.error(error);
        alert('Error al crear el formulario');
      });
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Crear Formulario</h2>
        <input
          type="text"
          placeholder="Nombre del formulario"
          value={formName}
          onChange={(e) => setFormName(e.target.value)}
        />
        <button onClick={handleCreateForm}>Crear</button>
        <button onClick={onClose}>Cerrar</button>
      </div>
    </div>
  );
};

export default ModalCrearForm;
