import React, { useEffect, useState } from "react";
import { Table, Form, Button, Pagination } from "react-bootstrap";
import EmployeeDataService from "../../services/employee";
import { useSelector } from "react-redux";
import image1 from "../../assets/categorias/2.jpg";
import image2 from "../../assets/categorias/ABCupon.jpg";
import { Modal } from "react-bootstrap";
import JobApplicationModal from "../../components/manager/job_modal/jobapplication_modal_customer";
import { useMediaQuery } from "react-responsive";

const JobListing = () => {
  const [jobPostings, setJobPostings] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const jobsPerPage = 5; // Mostrar 5 empleos por página
  const [companies, setCompanies] = useState([]);
  const [jobCategories, setJobCategories] = useState([]);
  const [experienceLevels, setExperienceLevels] = useState([]);
  const [skills, setSkills] = useState([]);
  const [benefits, setBenefits] = useState([]);
  const [jobTags, setJobTags] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const token = useSelector((state) => state.authentication.token);
  const user = useSelector((state) => state.authentication.user);
  const [showJobApplicationModal, setShowJobApplicationModal] = useState(false);
  const [currentJobApplication, setCurrentJobApplication] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const handleSaveJobApplication = (jobApplication) => {
    console.log("datos job application", jobApplication.formData);
    const id = jobApplication.formData.get("id");

    console.log("user", user);
    jobApplication.formData.append("applicant", user);
    for (let [key, value] of jobApplication.formData.entries()) {
      console.log(`${key}: ${value}`);
    }
    EmployeeDataService.createJobApplication(jobApplication.formData, token, id)
      .then(() => {
        alert("Su aplicación fue enviada con éxito.");
      })
      .catch((error) => {
        console.error("Error al crear empresa", error);
        alert(
          "Hubo un error al enviar su aplicación. Por favor, ingrese con su usuario e intente de nuevo."
        );
      });
  };

  const deleteJobApplication = (applicationjob, applicationid) => {
    console.log("id de eliminar", applicationid);
    EmployeeDataService.deleteJobApplication(
      applicationjob,
      token,
      applicationid
    ).catch((error) => console.error(error));
  };
  console.log(token);

  const fetchJobPostings = async (token) => {
    try {
      const jobResponse = await EmployeeDataService.getAllJobPostings(token);
      setJobPostings(jobResponse.data);
      console.log("jobspostings", jobResponse)
      setFilteredJobs(jobResponse.data);
    } catch (error) {
      console.error("Error al obtener los Job Postings:", error);
    }
  };

  const fetchCompanies = async (token) => {
    try {
      const companyResponse = await EmployeeDataService.getAllCompanies(token);
      setCompanies(companyResponse.data);
      console.log("companies", companyResponse.data);
    } catch (error) {
      console.error("Error al obtener las Companies:", error);
    }
  };

  const fetchJobCategories = async (token) => {
    try {
      const categoryResponse = await EmployeeDataService.getAllJobCategories(
        token
      );
      setJobCategories(categoryResponse.data);
    } catch (error) {
      console.error("Error al obtener las Job Categories:", error);
    }
  };

  const fetchExperienceLevels = async (token) => {
    try {
      const experienceResponse =
        await EmployeeDataService.getAllExperienceLevels(token);
      setExperienceLevels(experienceResponse.data);
    } catch (error) {
      console.error("Error al obtener las Experience Levels:", error);
    }
  };

  const fetchSkills = async (token) => {
    try {
      const skillsResponse = await EmployeeDataService.getAllSkills(token);
      setSkills(skillsResponse.data);
    } catch (error) {
      console.error("Error al obtener las Skills:", error);
    }
  };

  const fetchBenefits = async (token) => {
    try {
      const benefitsResponse = await EmployeeDataService.getAllBenefits(token);
      setBenefits(benefitsResponse.data);
    } catch (error) {
      console.error("Error al obtener los Benefits:", error);
    }
  };

  const fetchJobTags = async (token) => {
    try {
      const tagsResponse = await EmployeeDataService.getAllJobTags(token);
      setJobTags(tagsResponse.data);
    } catch (error) {
      console.error("Error al obtener los Job Tags:", error);
    }
  };

  // Función principal que llama a las funciones individuales
  const fetchData = async () => {
    // if (!token) return; // Verifica si el token está disponible

    setLoading(true); // Empieza la carga

    try {
      await fetchJobPostings(token);
      await fetchJobCategories(token);
      await fetchExperienceLevels(token);
      await fetchSkills(token);
      await fetchCompanies(token);
      await fetchBenefits(token);
      await fetchJobTags(token);
    } catch (error) {
      console.error("Error al obtener datos:", error);
    } finally {
      setLoading(false); // Asegúrate de marcar el final de la carga
    }
  };
  useEffect(() => {
    if (token) {
      console.log("tenemos token", token);
      fetchData();
    }
  }, [token]);
  useEffect(() => {
      fetchData();
  }, []);

  useEffect(() => {
    const filtered = jobPostings.filter((job) =>
      job.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredJobs(filtered);
    setCurrentPage(1); // Resetear la página al buscar
  }, [searchTerm, jobPostings]);

  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = filteredJobs.slice(indexOfFirstJob, indexOfLastJob);
  console.log("currentJobs", currentJobs);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Función para obtener el nombre de la empresa
  const getCompanyNameById = (id) => {
    const company = companies.find((company) => company.id === id);
    return company ? company.name : "Desconocida";
  };

  // Función para obtener el nombre de la categoría
  const getCategoryNameById = (id) => {
    const category = jobCategories.find((category) => category.id === id);
    return category ? category.name : "Desconocida";
  };

  // Función para obtener el nombre del nivel de experiencia
  const getExperienceLevelNameById = (id) => {
    console.log("id de experience", id);
    const level = experienceLevels.find((level) => level.id === id);
    console.log("level de experience", level);
    return level ? level.level : "Desconocido";
  };

  // Función para obtener los nombres de habilidades
  // Función para obtener los nombres de habilidades
  const getSkillsByIds = (ids) => {
    if (!Array.isArray(ids)) return "Ninguna"; // Verificar que ids sea un arreglo
    return (
      skills
        .filter((skill) => ids.includes(skill.id))
        .map((skill) => skill.name)
        .join(", ") || "Ninguna"
    );
  };

  // Función para obtener los nombres de beneficios
  const getBenefitsByIds = (ids) => {
    return (
      benefits
        .filter((benefit) => ids.includes(benefit.id))
        .map((benefit) => benefit.name)
        .join(", ") || "Ninguno"
    );
  };

  // Función para obtener los nombres de etiquetas
  const getTagsByIds = (ids) => {
    return (
      jobTags
        .filter((tag) => ids.includes(tag.id))
        .map((tag) => tag.name)
        .join(", ") || "Ninguna"
    );
  };

  const handleShowModal = (job) => {
    const company = companies.find((company) => company.id === job.company);
    setSelectedCompany(company); // Guarda la información de la empresa seleccionada
    setSelectedJob(job); // Guarda la información del trabajo
    setShowModal(true); // Abre el modal
  };

  useEffect(() => {
    let filtered = jobPostings;

    // Filtra por título si hay un término de búsqueda
    if (searchTerm) {
      filtered = filtered.filter((job) =>
        job.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Filtra por categoría si hay una categoría seleccionada
    if (selectedCategory) {
      filtered = filtered.filter((job) => job.category === selectedCategory);
    }

    setFilteredJobs(filtered);
    setCurrentPage(1); // Resetear la página al buscar o filtrar
  }, [searchTerm, jobPostings, selectedCategory]);

  const handleCategorySelect = (categoryId) => {
    if (selectedCategory === categoryId) {
      setSelectedCategory(null); // Si ya está seleccionada, desmarca
    } else {
      setSelectedCategory(categoryId); // Selecciona la nueva categoría
    }
  };

  return (
    <div className="job-listing-container" style={{ marginTop: isMobile ? "20%": "8%" }}>
       <div
  style={{
    display: "flex",
    marginTop:isMobile ? "8%":"6%",
    flexDirection: isMobile ? "row" : "row", // Cambia a columna si es móvil
    justifyContent: "left",
    height: isMobile ? "auto" : "10vh", // Ajusta la altura para móviles
    marginBottom: "1.2%",
    marginLeft: "5.3%",
  }}
>
  <img
    src={image1}
    alt="Imagen 1"
    style={{
      maxWidth: isMobile ? "30%" : "50%", // Ocupa 100% en móvil y 50% en escritorio
      maxHeight: "100%",
      objectFit: "cover",
      borderRadius: "15px",
      boxShadow: "0 4px 8px rgba(255, 255, 255, 0.5)",
    }}
    loading="lazy"
  />
  <img
    src={image2}
    alt="Imagen 2"
    style={{
      maxWidth: isMobile ? "30%" : "50%", // Ocupa 100% en móvil y 50% en escritorio
      maxHeight: "100%",
      objectFit: "cover",
      borderRadius: "15px",
      boxShadow: "0 4px 8px rgba(255, 255, 255, 0.5)",
    }}
    loading="lazy"
  />
</div>
      <div
        style={{
          display: "grid",
          alignItems: "center",
          marginBottom: "20px",
          marginLeft: "5.3%",
          marginTop: "20px",
        }}
      >
        <Form.Group controlId="search" style={{ marginRight: "10px" }} />
        <Form.Control
          type="text"
          placeholder="Buscar por título, empresa..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            width: "50vh",
            border: "2px solid red",
          }}
        />
      </div>
      <div style={{ marginLeft: "5.3%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <h4
            style={{
              color: "black",
              fontWeight: "bold",
              fontSize: "2.0em",
              textTransform: "none",
            }}
          >
            Seleccione la categoría deseada:
          </h4>
        </div>

        <div
  style={{
    display: "flex", // Organiza los botones en una fila
    flexWrap: "wrap", // Permite que los botones se muevan a una nueva línea si es necesario
    justifyContent: "flex-start", // Alinea los botones al inicio (izquierda)
    alignItems: "flex-start", // Alinea los botones en la parte superior
    marginBottom: "20px",
    marginRight:"10px"
  }}
>
  <div>
  <Button
      variant="outline-secondary"
      onClick={() => {
        setSelectedCategory(null); // Desmarcar cualquier categoría seleccionada
      }}
      style={{
        marginLeft: "10px", // Espaciado entre botones
        backgroundColor: selectedCategory === null ? "rgb(0, 255, 0)" : "white", // Cambia el color de fondo si "Ver Todos" está seleccionado
        fontSize: isMobile ?"1.2em": "1.8em",
        color: "black",
        borderColor: "black",
        borderWidth: "0.4px",
        borderStyle: "solid",
      }}
    >
      2.0 Ver Todos
    </Button>
   {jobCategories.map((category) => (
  <Button
    key={category.id}
    variant="outline-primary"
    onClick={() => setSelectedCategory(category.id)}
    style={{
      marginLeft: "10px", // Espaciado entre botones
      // marginBottom: "10px", // Espaciado entre líneas (si los botones saltan a la siguiente fila)
      backgroundColor:
        selectedCategory === category.id ? "rgb(0, 255, 0)" : "white", // Cambia el color de fondo según la selección
      fontSize: isMobile ? "1.2em" : "1.8em",
      color: "black",
      borderColor: "black", // Color del borde
      borderWidth: "0.4px", // Grosor del borde en píxeles
      borderStyle: "solid", // Estilo del borde
      whiteSpace: "normal", // Permite que el texto se ajuste a múltiples líneas
      lineHeight: "1.5", // Establece el interlineado
      width: "auto", // Ajusta el ancho del botón automáticamente
      minWidth: "120px", // Establece un ancho mínimo para el botón
    }}
    onMouseEnter={(e) => {
      e.currentTarget.style.backgroundColor = "rgb(0, 255, 0)";
      e.currentTarget.style.color = "black";
    }}
    onMouseLeave={(e) => {
      e.currentTarget.style.backgroundColor =
        selectedCategory === category.id ? "rgb(0, 255, 0)" : "white"; // Mantiene el color de fondo si está seleccionado
      e.currentTarget.style.color = "black";
    }}
  >
    {category.name}
  </Button>
))}

    
  </div>
</div>
      </div>

<table
  style={{
    margin: "10 auto",
    border: "2px solid black",
    borderRadius: "10px",
    overflow: "hidden",
    backgroundColor: "white",
    boxShadow: "0 0 10px rgba(255, 0, 0, 0.3)",
    width: "90%",
  }}
>
  <thead>
    <tr style={{ backgroundColor: "black", color: "white" }}>
      <th
        style={{
          padding: "10px",
          backgroundColor: "black",
          textAlign: "center",
          fontWeight: "bold",
         fontSize: isMobile ?"1.2em":"2.0em",
        }}
      >
        Empresa
      </th>
      <th
        style={{
          padding: "10px",
          backgroundColor: "black",
          textAlign: "center",
          fontWeight: "bold",
          fontSize: isMobile ?"1.2em": "2.0em",
        }}
      >
        Título
      </th>
      <th
        style={{
          padding: "10px",
          backgroundColor: "black",
          textAlign: "center",
          fontWeight: "bold",
          fontSize: isMobile ?"1.2em": "2.0em",
        }}
      >
        Ubicación
      </th>
      {!isMobile && (
        <>
          <th
            style={{
              padding: "10px",
              backgroundColor: "black",
              textAlign: "center",
              fontWeight: "bold",
              fontSize: isMobile ?"1.2em": "2.0em",
            }}
          >
            Rango Salario
          </th>
          <th
            style={{
              padding: "10px",
              backgroundColor: "black",
              textAlign: "center",
              fontWeight: "bold",
              fontSize: isMobile ?"1.2em": "2.0em",
            }}
          >
            Modalidad
          </th>
          <th
            style={{
              padding: "10px",
              backgroundColor: "black",
              textAlign: "center",
              fontWeight: "bold",
              fontSize: isMobile ?"1.2em": "2.0em",
            }}
          >
            Tipo de empleo
          </th>
          <th
            style={{
              padding: "10px",
              backgroundColor: "black",
              textAlign: "center",
              fontWeight: "bold",
              fontSize: isMobile ?"1.2em": "2.0em",
            }}
          >
            Nivel de experiencia
          </th>
          <th
            style={{
              padding: "10px",
              backgroundColor: "black",
              textAlign: "center",
              fontWeight: "bold",
              fontSize: isMobile ?"1.2em": "2.0em",
            }}
          >
            Habilidades
          </th>
          <th
            style={{
              padding: "10px",
              backgroundColor: "black",
              textAlign: "center",
              fontWeight: "bold",
              fontSize: isMobile ?"1.2em": "2.0em",
            }}
          >
            Beneficios
          </th>
          <th
            style={{
              padding: "10px",
              backgroundColor: "black",
              textAlign: "center",
              fontWeight: "bold",
              fontSize: isMobile ?"1.2em": "2.0em",
            }}
          >
            Etiquetas
          </th>
        </>
      )}
      <th
        style={{
          padding: "10px",
          backgroundColor: "black",
          textAlign: "center",
          fontWeight: "bold",
          fontSize: isMobile ?"1.2em": "2.0em",
        }}
      >
        Fecha de publicación
      </th>
      <th
        style={{
          padding: "10px",
          backgroundColor: "black",
          textAlign: "center",
          fontWeight: "bold",
          fontSize: isMobile ?"1.2em": "2.0em",
        }}
      >
        Acciones
      </th>
    </tr>
  </thead>
  <tbody>
    {currentJobs.length > 0 ? (
      currentJobs.map((job) => (
        <tr
          key={job.id}
          style={{
            color: "black",
            fontWeight: "bold",
            fontSize: isMobile ?"1.0em": "1.4em",
            textTransform: "none",
          }}
        >
          <td
            style={{
              padding: "10px",
              fontSize: "1em",
              maxWidth: "150px",
              whiteSpace: "normal",
              overflowWrap: "break-word",
            }}
          >
            {getCompanyNameById(job.company)}
          </td>
          <td
            style={{
              padding: "10px",
              fontSize: isMobile ?"0.8em":  "1em",
              maxWidth: "150px",
              whiteSpace: "normal",
              overflowWrap: "break-word",
            }}
          >
            {job.title}
          </td>
          <td
            style={{
              padding: "10px",
              fontSize: isMobile ?"0.8em":  "1em",
              maxWidth: "150px",
              whiteSpace: "normal",
              overflowWrap: "break-word",
            }}
          >
            {`${job.city}, ${job.country}`}
          </td>
          {!isMobile && (
            <>
              <td
                style={{
                  padding: "10px",
                  fontSize: isMobile ?"0.8em":  "1em",
                  maxWidth: "150px",
                  whiteSpace: "normal",
                  overflowWrap: "break-word",
                }}
              >
                ₡{`${job.salary_range}`}
              </td>
              <td
                style={{
                  padding: "10px",
                  fontSize: isMobile ?"0.8em":  "1em",
                  maxWidth: "150px",
                  whiteSpace: "normal",
                  overflowWrap: "break-word",
                }}
              >
                {job.modality}
              </td>
              <td
                style={{
                  padding: "10px",
                  fontSize: isMobile ?"0.8em": "1em",
                  maxWidth: "150px",
                  whiteSpace: "normal",
                  overflowWrap: "break-word",
                }}
              >
                {job.employment_type}
              </td>
              <td
                style={{
                  padding: "10px",
                  fontSize: isMobile ?"0.8em": "1em",
                  maxWidth: "150px",
                  whiteSpace: "normal",
                  overflowWrap: "break-word",
                }}
              >
                {getExperienceLevelNameById(job.experience_level)}
              </td>
              <td
                style={{
                  padding: "10px",
                  fontSize: isMobile ?"0.8em": "1em",
                  maxWidth: "150px",
                  whiteSpace: "normal",
                  overflowWrap: "break-word",
                }}
              >
                {getSkillsByIds(job.skills_required || [])}
              </td>
              <td
                style={{
                  padding: "10px",
                  fontSize: isMobile ?"0.8em": "1em",
                  maxWidth: "150px",
                  whiteSpace: "normal",
                  overflowWrap: "break-word",
                }}
              >
                {getBenefitsByIds(job.benefits)}
              </td>
              <td
                style={{
                  padding: "10px",
                  fontSize: isMobile ?"0.8em": "1em",
                  maxWidth: "150px",
                  whiteSpace: "normal",
                  overflowWrap: "break-word",
                }}
              >
                {getTagsByIds(job.tags)}
              </td>
            </>
          )}
          <td
            style={{
              padding: "10px",
              fontSize: isMobile ?"0.8em": "1em",
              maxWidth: "150px",
              whiteSpace: "normal",
              overflowWrap: "break-word",
            }}
          >
            {new Date(job.posted_date).toLocaleDateString()}
          </td>
          <td>
            <button
              
              onClick={() => handleShowModal(job)}
              style={{
                backgroundColor: 'white',
                color: 'black',
                fontWeight:"bold",
                border: '1px solid black',
                padding: '10px 20px',
                cursor: 'pointer',
                // width: '50vh',
                transition: 'background-color 0.3s, color 0.3s',
                borderRadius: '10px',
              }} 
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = 'green';
                e.target.style.color = 'white';
              }} 
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = 'transparent';
                e.target.style.color = 'black';
              }}
            >
              Aplicar
            </button>
          </td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan="12">Necesitas autenticarte para poder visualizar los empleos disponibles.</td>
      </tr>
    )}
  </tbody>
</table>
{filteredJobs.length > jobsPerPage && (
        <Pagination>
          {[...Array(Math.ceil(filteredJobs.length / jobsPerPage)).keys()].map(
            (num) => (
              <Pagination.Item
                key={num + 1}
                active={num + 1 === currentPage}
                onClick={() => paginate(num + 1)}
              >
                {num + 1}
              </Pagination.Item>
            )
          )}
        </Pagination>
      )}
       <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        style={{ overflowY: "auto" }}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Title
          style={{
            color: "red",
            fontWeight: "bold",
            fontSize: isMobile ?"1.6em":"1.8em",
            textShadow: "2px 2px 4px #000",
            textTransform: "none",
            overflow: "hidden",
            whiteSpace: "nowrap",
            marginBottom: "2%",
          }}
        >
          Aplicar a {selectedJob?.title}
        </Modal.Title>
        {/* </Modal.Header> */}
        <Modal.Body>
          <h5
            style={{
              color: "red",
              fontWeight: "bold",
              fontSize: isMobile ?"1.4em":  "1.8em",
              textShadow: "2px 2px 4px #000",
              textTransform: "none",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            Detalles de la Empresa
          </h5>
          <table className="table">
            <tbody>
              <tr>
                <td style={{ fontSize: isMobile ?"1.2em":   "1.6em", fontWeight:"bold"}}>Logo</td>
                <td>
                  {selectedCompany && selectedCompany.logo ? (
                    <img
                      src={selectedCompany.logo}
                      alt="Company Logo"
                      style={{ width: "400px" }}
                      loading="lazy"
                    />
                  ) : (
                    <p>No logo available</p>
                  )}
                </td>
              </tr>
              <tr>
                <td style={{ fontSize: isMobile ?"1.2em":  "1.6em", fontWeight:"bold"}}>Nombre</td>
                <td style={{ fontSize: isMobile ?"1.2em":  "1.6em"}}>{selectedCompany?.name}</td>
              </tr>

              <tr>
                <td style={{fontSize: isMobile ?"1.2em":   "1.6em", fontWeight:"bold"}}>Correo Electronico</td>
                <td style={{ fontSize: isMobile ?"1.2em":   "1.6em"}}>{selectedCompany?.contact_email}</td>
              </tr>
              <tr>
                <td style={{ fontSize: isMobile ?"1.2em":   "1.6em", fontWeight:"bold"}}>Descripción</td>
                <td style={{ fontSize: isMobile ?"1.2em":   "1.6em"}}>{selectedCompany?.description}</td>
              </tr>
              <tr>
                <td style={{ fontSize: isMobile ?"1.2em":   "1.6em", fontWeight:"bold"}}>Dirección</td>
                <td style={{ fontSize: isMobile ?"1.2em":   "1.6em"}}>{selectedCompany?.address}</td>
              </tr>
              <tr>
                <td style={{ fontSize: isMobile ?"1.2em":   "1.6em", fontWeight:"bold"}}>Correo de contacto</td>
                <td style={{ fontSize: isMobile ?"1.0em":   "1.4em"}}>{selectedCompany?.phone_number}</td>
              </tr>
              <tr>
                <td style={{ fontSize: isMobile ?"1.2em":  "1.6em", fontWeight:"bold"}}>Pagina Web</td>
                <td style={{ fontSize: isMobile ?"1.2em":  "1.6em"}}>{selectedCompany?.website}</td>
              </tr>
              {/* Agrega más campos según sea necesario */}
            </tbody>
          </table>

          <h5>Detalles de la Publicación de Trabajo</h5>
          <table className="table">
            <tbody>
              <tr>
                <td style={{ fontSize: isMobile ?"1.2em":   "1.6em", fontWeight:"bold"}}>Título</td>
                <td style={{ fontSize: isMobile ?"1.2em":   "1.6em"}}>{selectedJob?.title}</td>
              </tr>
              <tr>
                <td style={{ fontSize: isMobile ?"1.2em":  "1.6em", fontWeight:"bold"}}>Ubicación</td>
                <td style={{ fontSize: "1.6em"}}>{`${selectedJob?.city}, ${selectedJob?.country}`}</td>
              </tr>
              <tr>
                <td style={{ fontSize: isMobile ?"1.2em":  "1.6em", fontWeight:"bold"}}>Rango Salario</td>
                <td style={{ fontSize: isMobile ?"1.2em":   "1.6em"}}>₡{`${selectedJob?.salary_range}`}</td>
              </tr>
              <tr>
                <td style={{ fontSize: isMobile ?"1.2em":   "1.6em", fontWeight:"bold"}}>Modalidad</td>
                <td style={{ fontSize: isMobile ?"1.2em":  "1.6em"}}>{selectedJob?.modality}</td>
              </tr>
              <tr>
                <td style={{ fontSize: isMobile ?"1.2em":  "1.6em", fontWeight:"bold"}}>Tipo de empleo</td>
                <td style={{ fontSize: isMobile ?"1.2em":  "1.6em"}}>{selectedJob?.employment_type}</td>
              </tr>
              <tr>
                <td style={{ fontSize: isMobile ?"1.2em":  "1.6em", fontWeight:"bold"}}>Nivel de experiencia</td>
                <td style={{ fontSize: isMobile ?"1.2em":  "1.6em"}}>
                  {getExperienceLevelNameById(selectedJob?.experience_level)}
                </td>
              </tr>
              {/* Agrega más campos según sea necesario */}
            </tbody>
          </table>
          <Button
          
          style={{
            backgroundColor: 'white',
            color: 'black',
            fontWeight:"bold",
            border: '1px solid black',
            padding: '10px 20px',
            cursor: 'pointer',
            // width: '50vh',
            transition: 'background-color 0.3s, color 0.3s',
            borderRadius: '10px',
          }} 
          onMouseEnter={(e) => {
            e.target.style.backgroundColor = 'green';
            e.target.style.color = 'white';
          }} 
          onMouseLeave={(e) => {
            e.target.style.backgroundColor = 'transparent';
            e.target.style.color = 'black';
          }}
            className="mt-3"
            onClick={() => setShowJobApplicationModal(true)}
          >
            Crear Aplicación
          </Button>
        </Modal.Body>
      </Modal>
      <JobApplicationModal
        show={showJobApplicationModal}
        handleClose={() => setShowJobApplicationModal(false)}
        jobApplication={currentJobApplication}
        jobPostings={jobPostings}
        handleSave={handleSaveJobApplication}
      />

    </div>
  );
};

export default JobListing;
