import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.css";
import Header from "../../components/home/Header";
import Footer from "../../components/navigation/Footer";
import Navbar from "../../components/navigation/Navbar";
import { useMediaQuery } from "react-responsive";
import Distributors from './directorio_comercial/directorio_comercial';
import DistributorDataService from "../../services/products";
import ProductDataService from "../../services/products";
import "./directorio.css";

function Home() {
  
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchAllSubProducts ();
    fetchProducts();
  }, []);

  const fetchProducts = () => {
    // Verifica si los productos ya están en localStorage
    const cachedProducts = localStorage.getItem("products1");
  
    if (cachedProducts) {
      // Si existen, parsea y establece los productos desde localStorage
      const products = JSON.parse(cachedProducts);
    } else {
      // Si no existen, obtiene los datos del servidor
      ProductDataService.getAll()
        .then((response) => {
          const products = response.data; // Productos obtenidos del servidor
          localStorage.setItem("products1", JSON.stringify(products)); // Guarda en localStorage
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };
  

  const fetchAllSubProducts = async () => {
    try {
      const allSubProductsResponse =
        await DistributorDataService.getAllSubProduct();
      const allSubProducts = allSubProductsResponse.data;
      console.log("allsubproducts", allSubProducts);

      // Verifica si ya existe "subproducts" en localStorage
      if (localStorage.getItem("subproducts")) {
        localStorage.removeItem("subproducts"); // Elimina el item existente
      }

      // Guarda los nuevos subproductos en localStorage
      localStorage.setItem("subproducts", JSON.stringify(allSubProducts));


    } catch (error) {
      console.error("Error al obtener los subproductos y servicios:", error);
    }
  };
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <div>
      <Helmet>
        <title>Directorio Comercial de ABCupon</title>
        <meta
          name="description"
          content="Somos una plataforma virtual que reúne a numerosos proveedores cuidadosamente seleccionados, ofreciendo servicios con altos estándares de excelencia. En ABcupon.com, nos esforzamos por hacer que su experiencia de compra sea cómoda, segura y satisfactoria."
        />
      </Helmet>
      <div style={{ marginTop: '8%' }}>
        <h1 className="text-4xl sm:text-6xl lg:text-6xl xl:text-6xl text-black font-bold tracking-tight pb-0 text-center"
          style={{ textShadow: '0 0 2px white', color: 'black' }}>
        </h1>
        <Distributors />
      </div>

      
    </div>
  );
}

export default Home;
