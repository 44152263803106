import React, { useEffect, useState } from "react";
import apiService from "../../services/Tourism";
import { useSelector } from "react-redux";
import CostaRica from "../../components/json/costarica.json";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "./VisualizacionTurismo.css";
import { MdCastle, MdApartment, MdFreeBreakfast } from "react-icons/md";
import { FaMapMarkerAlt,FaHome,FaTrailer,FaSyncAlt,FaTree,FaCampground,FaWindmill,FaHotel, FaLeaf, FaEye } from "react-icons/fa";
// import { PiBarnDuotone } from "react-icons/pi";
import { FaSailboat } from "react-icons/fa6";
import { GiHut, GiMountainCave, GiFarmTractor, GiHouseBoat,GiCastle, GiWindmill, } from "react-icons/gi";
import { TbCamper, TbTent } from "react-icons/tb";
import {MdOutlineHouseboat, MdOutlineHotel,MdOutlineCottage,} from "react-icons/md";
import { AiFillBank } from "react-icons/ai";
import { BsHouseDoor } from "react-icons/bs";
import PropertyModal from "./22modal";

const CustomIcon = L.divIcon({
  className: "custom-icon",
  html: `<div style="color: red; font-size: 24px;"><i class="fa fa-map-marker"></i></div>`,
  iconSize: [24, 36],
  iconAnchor: [12, 36],
});

const VisualizacionTurismo = () => {
  const token = useSelector((state) => state.authentication.token);

  const [properties, setProperties] = useState([]);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [country, setCountry] = useState("Costa Rica");
  const [province, setProvince] = useState("");
  const [canton, setCanton] = useState("");

  const [provinces, setProvinces] = useState(CostaRica.Costa_Rica.Provinces);
  const [cantons, setCantons] = useState([]);

  const [position, setPosition] = useState([9.9281, -84.0907]);
  const [visibleIframes, setVisibleIframes] = useState({});
  const [propertyTypeSelected, setPropertyTypeSelected] = useState("");
  // Estado para manejar el índice de la imagen por propiedad
  const [currentImageIndices, setCurrentImageIndices] = useState({});
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Paginación
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const propertyTypes = [
    { type: "Casa", icon: <FaHome /> },
    { type: "Apartamento", icon: <MdApartment /> },
    // { type: "Granero", icon: < PiBarnDuotone  /> },
    { type: "Bed & breakfast", icon: <MdFreeBreakfast /> },
    { type: "Barco", icon: <FaSailboat /> },
    { type: "Cabañas", icon: <GiHut /> },
    { type: "Casa rodante", icon: <TbCamper /> },
    { type: "Castillo", icon: <MdCastle /> },
    { type: "Cueva", icon: <GiMountainCave /> },
    { type: "Contenedores", icon: <FaTrailer /> },
    { type: "Casa cíclada", icon: <FaSyncAlt /> }, 
    { type: "Dammuso", icon: <AiFillBank /> }, 
    { type: "Domo", icon: <AiFillBank /> }, 
    { type: "Casa ecológica", icon: <FaLeaf /> }, 
    { type: "Granja", icon: <GiFarmTractor /> },
    { type: "Casa de huéspedes", icon: <FaHotel /> }, 
    { type: "Hotel", icon: <MdOutlineHotel /> }, 
    { type: "Casa flotante", icon: <MdOutlineHouseboat /> }, 
    { type: "Kezhan", icon: <MdOutlineCottage /> }, 
    { type: "Minsu", icon: <FaHome /> }, 
    { type: "Riad", icon: <FaHome /> }, 
    { type: "Ryokan", icon: <FaHome /> }, 
    { type: "Casa de pastor", icon: <MdOutlineCottage /> }, 
    { type: "Tienda de campo", icon: <TbTent /> }, 
    { type: "Minicasa", icon: <FaHome /> }, 
    { type: "Torre", icon: <GiCastle /> }, 
    { type: "Casa del árbol", icon: <FaTree /> }, 
    { type: "Trullo", icon: <FaHome /> }, 
    { type: "Molino de viento", icon: <GiWindmill /> }, 
    { type: "Yurta", icon: <FaCampground /> },
  ];

  useEffect(() => {
    fetchProperties();
  }, []);

  useEffect(() => {
    filterProperties(); // Filtrar propiedades cada vez que cambien el país, provincia, cantón o tipo de propiedad
  }, [country, province, canton, propertyTypeSelected]);

  const fetchProperties = async () => {
    try {
      const response = await apiService.getAllProperties(token);
      setProperties(response.data);
      console.log("Propiedades:", response.data); // Verifica las propiedades obtenidas
      setFilteredProperties(response.data);

      if (response.data.length > 0) {
        const coords = getCoordinatesFromLocation(response.data[0].location);
        console.log("Coordenadas de la primera propiedad:", coords); // Verifica las coordenadas
        if (coords) {
          setPosition(coords);
        }
      }
    } catch (error) {
      console.error("Error fetching properties:", error);
    }
  };

  const getCoordinatesFromLocation = (location) => {
    const regex =
      /!1m12!1m3!1d[^!]*!2d([-+]?[0-9]*\.?[0-9]+)!3d([-+]?[0-9]*\.?[0-9]+)/;
    const match = location.match(regex);
    if (match) {
      const latitude = parseFloat(match[2]);
      const longitude = parseFloat(match[1]);
      return [latitude, longitude];
    }
    return null;
  };

  const filterProperties = () => {
    let filtered = properties;

    if (country) {
      filtered = filtered.filter((property) => property.country === country);
    }

    if (province) {
      filtered = filtered.filter((property) => property.province === province);
    }

    if (canton) {
      filtered = filtered.filter((property) => property.canton === canton);
    }

    if (propertyTypeSelected) {
      filtered = filtered.filter(
        (property) => property.property_type === propertyTypeSelected
      );
    }

    setFilteredProperties(filtered);
  };

  const handlePropertyTypeClick = (type) => {
    // Si ya está seleccionado el tipo, al hacer clic nuevamente, mostrar todos
    if (propertyTypeSelected === type) {
      setPropertyTypeSelected("");
    } else {
      setPropertyTypeSelected(type);
    }
  };
  const handleViewAllClick = () => {
    // Restablecer los filtros al hacer clic en "Ver Todos"
    setCountry("Costa Rica");
    setProvince("");
    setCanton("");
    setPropertyTypeSelected("");
  };

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    setCountry(selectedCountry);

    if (selectedCountry === "Costa Rica") {
      setProvinces(CostaRica.Costa_Rica.Provinces);
    } else {
      setProvinces([]);
      setProvince("");
      setCantons([]);
    }
  };

  const handleProvinceChange = (e) => {
    const selectedProvince = e.target.value;
    setProvince(selectedProvince);

    const provinceData = provinces.find((p) => p.name === selectedProvince);
    if (provinceData) {
      setCantons(provinceData.cantons);
    } else {
      setCantons([]);
    }
  };

  const handleCantonChange = (e) => {
    const selectedCanton = e.target.value;
    setCanton(selectedCanton);
  };

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };

  const toggleIframeVisibility = (propertyId) => {
    setVisibleIframes((prev) => ({
      ...prev,
      [propertyId]: !prev[propertyId],
    }));
  };

  // Paginación
  const indexOfLastProperty = currentPage * itemsPerPage;
  const indexOfFirstProperty = indexOfLastProperty - itemsPerPage;
  const currentProperties = filteredProperties.slice(
    indexOfFirstProperty,
    indexOfLastProperty
  );
  const totalPages = Math.ceil(filteredProperties.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Manejar el índice de la imagen para una propiedad específica
  const handleImageChange = (propertyId, change) => {
    setCurrentImageIndices((prev) => {
      const currentIndex = prev[propertyId] || 0; // Obtener el índice actual, o 0 si no existe
      const newIndex = Math.max(
        0,
        Math.min(
          currentIndex + change,
          properties.find((p) => p.id === propertyId)?.images.length - 1
        )
      );
      return { ...prev, [propertyId]: newIndex };
    });
  };

  const handlePropertyClick = (propertyId) => {
    const property = properties.find((p) => p.id === propertyId);
    
    setSelectedProperty(property);
    setIsModalOpen(true);
  };

  return (
    <div className="visualizacion-turismo" style={{zoom:"0.8"}}>
      <h1>Alojamientos Disponibles</h1>
       <div className="property-types-container">
       <div
          className={'property-type-item'}
          onClick={handleViewAllClick} // Añadir el manejador de clics aquí
        >
       <div className="icon-container">
          <FaEye />
          </div>
          <div className="type-name">Ver Todos</div>
          </div>
        {propertyTypes.map((propertyType, index) => (
          <div
            key={index}
            className={`property-type-item ${
              propertyTypeSelected === propertyType.type ? "selected" : ""
            }`}
            onClick={() => handlePropertyTypeClick(propertyType.type)}
          >
            <div className="icon-container">{propertyType.icon}</div>
            <div className="type-name">{propertyType.type}</div>
          </div>
        ))}
      </div>

      <div className="content-container">
        <div className="map-container">
          <div className="filters">
            <div className="filter">
              <label>País:</label>
              <select
                value={country}
                onChange={handleCountryChange}
                className="filter-input"
              >
                <option value="">Seleccionar país</option>
                <option value="Costa Rica">Costa Rica</option>
              </select>
            </div>

            <div className="filter">
              <label>Provincia:</label>
              <select
                value={province}
                onChange={handleProvinceChange}
                className="filter-input"
              >
                <option value="">Seleccionar provincia</option>
                {provinces.map((prov, index) => (
                  <option key={index} value={prov.name}>
                    {prov.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="filter">
              <label>Cantón:</label>
              <select
                value={canton}
                onChange={handleCantonChange}
                className="filter-input"
              >
                <option value="">Seleccionar cantón</option>
                {cantons.length > 0
                  ? cantons.map((cantonName, index) => (
                      <option key={index} value={cantonName}>
                        {cantonName}
                      </option>
                    ))
                  : properties.map((property, index) => (
                      <option key={index} value={property.canton}>
                        {property.canton}
                      </option>
                    ))}
              </select>
            </div>
          </div>
          <MapContainer
            center={position}
            zoom={7}
            style={{ height: "100%", width: "100%" }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {filteredProperties.map((property) => {
              const coords = getCoordinatesFromLocation(property.location);
              return coords ? (
                <Marker key={property.id} position={coords} icon={CustomIcon}>
                  <Popup>
                    <strong>{property.title}</strong>
                    <br />
                    Precio por noche: ₡{property.price_per_night}
                  </Popup>
                </Marker>
              ) : null;
            })}
          </MapContainer>
        </div>

        <div className="properties-list">
          {currentProperties.map((property) => {
            const currentIndex = currentImageIndices[property.id] || 0; // Obtener el índice actual para la propiedad
            return (
              <div className="property-card" key={property.id} onClick={() => handlePropertyClick(property.id)}>
                <div className={"image-carousel single-image"}>
                  {/* Mostrar solo la imagen actual */}
                  {property.images &&
                    property.images.length > 0 &&
                    currentIndex < property.images.length && (
                      <img
                        src={property.images[currentIndex].image}
                        alt={`Imagen de ${property.title}`}
                      />
                    )}
                  <button
                    className="carousel-button prev"
                    onClick={() => handleImageChange(property.id, -1)}
                    disabled={currentIndex === 0} // Deshabilitar si es la primera imagen
                  >
                    ❮
                  </button>
                  <button
                    className="carousel-button next"
                    onClick={() => handleImageChange(property.id, 1)}
                    disabled={currentIndex === property.images.length - 1} // Deshabilitar si es la última imagen
                  >
                    ❯
                  </button>
                </div>
                <div className="property-details">
                  <div className="title-rating-container">
                    <h4>
                      <strong>{truncateText(property.title, 30)}</strong>
                    </h4>
                    <p className="rating">★{property.rating}</p>
                  </div>
                  <div className="description-slider">
                    <p>{truncateText(property.description, 100)}</p>
                  </div>
                  <div className="property-info">
                    <p>
                      <strong> {property.property_type}</strong>
                    </p>
                    <p>
                      <strong>Provincia:</strong> {property.province}
                    </p>
                    <p>
                      <strong>Cantón:</strong> {property.canton}
                    </p>

                    <p>
                      <strong>{property.bedrooms} camas:</strong> |{" "}
                      <strong>{property.bathrooms} Baños:</strong>
                    </p>
                    <p className="price">
                      <strong>Precio:</strong> ₡{property.price_per_night}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* Paginación */}
      <div className="pagination">
        <button onClick={handlePrevPage} disabled={currentPage === 1}>
          Anterior
        </button>
        <span>
          Página {currentPage} de {totalPages}
        </span>
        <button onClick={handleNextPage} disabled={currentPage === totalPages}>
          Siguiente
        </button>
      </div>
      {isModalOpen && (
      <PropertyModal 
        property={selectedProperty} 
        onClose={() => setIsModalOpen(false)} 
      />
    )}
    </div>
  );
};

export default VisualizacionTurismo;
