import React from 'react'

const Modal_Ecommerce = () => {
  return (
    <div style={{marginTop:"8%"}}><div className="bg-background text-foreground p-4 rounded-lg shadow-md">
    <div className="flex justify-between items-center mb-4">
      <h2 className="text-xl font-bold">Vendedor</h2>
      <div className="flex space-x-2">
        <button className="bg-secondary text-secondary-foreground p-2 rounded-lg hover:bg-secondary/80">⭐</button>
        <button className="bg-secondary text-secondary-foreground p-2 rounded-lg hover:bg-secondary/80">❤️</button>
        <button className="bg-secondary text-secondary-foreground p-2 rounded-lg hover:bg-secondary/80">🛠️</button>
        <button className="bg-secondary text-secondary-foreground p-2 rounded-lg hover:bg-secondary/80">📞</button>
      </div>
    </div>
    <img src="https://placehold.co/600x400" alt="Car Image" className="w-full h-auto rounded-lg mb-4" />

    <div className="grid grid-cols-3 gap-2 mb-4">
      <img src="https://placehold.co/200x150" alt="Thumbnail 1" className="rounded-lg" />
      <img src="https://placehold.co/200x150" alt="Thumbnail 2" className="rounded-lg" />
      <img src="https://placehold.co/200x150" alt="Thumbnail 3" className="rounded-lg" />
    </div>

    <div className="bg-card p-4 rounded-lg">
      <p className="text-lg font-semibold">Nombre: JOSÉ PABLO VEGA</p>
      <p>Teléfono: 8839-3080 / 7109-3070</p>
      <p>Whatsapp: 8839-3080</p>
      <p>Dirección: San Pablo de Heredia costado oeste plaza de deportes Heredia</p>
      <button className="bg-primary text-primary-foreground p-2 rounded-lg mt-4 hover:bg-primary/80">Enviar un Email al Vendedor</button>
    </div>
  </div></div>
  )
}

export default Modal_Ecommerce