import React, { useState, useEffect } from 'react';

import FileDataService from '../../services/files';

import moment from 'moment';

import FileViewer from 'react-file-viewer';

import { useDispatch, useSelector } from 'react-redux';

import { Table, Button, Form, Container } from 'react-bootstrap';

import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import './Files.css';

import Footer from '../../components/navigation/Footer';


const Files = () => {

  const [files, setFiles] = useState([]);

  const [selectedFile, setSelectedFile] = useState(null);

  const [fileName, setFileName] = useState('');

  const [selectedFileName, setSelectedFileName] = useState('');

  const [selectedFiles, setSelectedFiles] = useState([]);

  const [selectAll, setSelectAll] = useState(false);

  const [previewFileUrls, setPreviewFileUrls] = useState({});

  const [searchTerm, setSearchTerm] = useState('');

  const [filesPerPage, setFilesPerPage] = useState(3);

  const [currentPage, setCurrentPage] = useState(1);

  const token = useSelector(state => state.authentication.token);

  const user = useSelector(state => state.authentication.user);

  const [designs, setDesigns] = useState([]);

  const [selectedDesigns, setSelectedDesigns] = useState([]);

  const [previewDesignUrls, setPreviewDesignUrls] = useState({});

  const [designName, setDesignName] = useState('');

  const [designUrl, setDesignUrl] = useState('');

  const [designCustomer, setDesignCustomer] = useState('');

  const [designContext, setDesignContext] = useState('');

  const [showFullContext, setShowFullContext] = useState(false);

  const [selectedDesignFile, setSelectedDesignFile] = useState(null);

  const [designsPerPage, setDesignsPerPage] = useState(5);

  const [currentDesignsPage, setCurrentDesignsPage] = useState(1);

  const [designNameSearch, setDesignNameSearch] = useState('');

  const [creatorSearch, setCreatorSearch] = useState('');

  const [startDate, setStartDate] = useState(null);

  const [endDate, setEndDate] = useState(null);

  const dispatch = useDispatch();


  const [currentUser, setCurrentUser] = useState(null);

  const [showCurrentUserModal, setShowCurrentUserModal] = useState(false);


  const fetchCurrentUserData = () => {

    const currentUser = localStorage.getItem('currentUser');

    setCurrentUser(JSON.parse(currentUser));

  };


  useEffect(() => {

    fetchCurrentUserData();

  }, []);


  useEffect(() => {

    fetchFiles();

  }, []);


  // const allowedFileNames = ['COMPACT 2', 'COMPACT 3', 'COMPACT 3XL', 'TABLE TOP', 'RT 3D SENSOR'];

  const NotallowedFileNames = ['First', 'SERVICES_3', 'SERVICES_1', 'SERVICES_2', 'Third', 'Second', 'Background Header', 'Video Summary', 'CPT3', 'CPT3XL', 'Compact', 'Tabletop', 'PLD_3D', '3Dpads', 'PCB', 'MultiScale', 'Sensor', 'Fringes', 'Chips'];


  const fetchFiles = () => {

    const token = localStorage.getItem('token');

    FileDataService.getAll(token)

      .then(response => {

        setFiles(response.data);

        const urls = {};

        response.data.forEach(file => {

          urls[file.id] = file.file;

        });

        setPreviewFileUrls(urls);

      })

      .catch(error => {

        console.error(error);

      });

  };


  const handleFileChange = (event) => {

    const file = event.target.files[0];

    setSelectedFile(file);

    setFileName(file.name);

  };


  const handleFileNameChange = (event) => {

    setSelectedFileName(event.target.value);

  };


  const handleUpload = () => {

    const token = localStorage.getItem('token');


    // Verificar si se ha seleccionado un archivo

    if (!selectedFile) {

      console.log('Ningún archivo seleccionado');

      return;

    }


    const existingFile = files.find(file => file.name === selectedFileName);

    if (existingFile) {

      const confirmOverwrite = window.confirm('El archivo ya existe. ¿Quieres sobreescribirlo?');

      if (!confirmOverwrite) {

        return;

      }

      // Eliminar el archivo existente antes de subir el nuevo

      handleDelete(existingFile.id);

    }


    const formData = new FormData();

    formData.append('file', selectedFile);

    formData.append('name', selectedFileName);


    FileDataService.uploadFile(formData, token)

      .then(response => {

        console.log('Documento cargado exitosamente:', response.data);

        fetchFiles(); // Actualizar la lista de archivos después de la subida exitosa

        setSelectedFile(null); // Limpiar el archivo seleccionado

        setFileName(''); // Limpiar el nombre del archivo

        setSelectedFileName(''); // Limpiar el nombre de archivo seleccionado

      })

      .catch(error => {

        console.error('Error al cargar el archivo:', error);

      });

  };


  const handleDelete = (id, isDesign = false) => {

    const token = localStorage.getItem('token');

  

    if (isDesign) {

      FileDataService.deleteDesign(id, token)

        .then(response => {

          console.log('Diseño eliminado exitosamente:', response.data);

          fetchDesigns();

        })

        .catch(error => {

          console.error('Error al eliminar el diseño:', error);

        });

    } else {

      FileDataService.deleteFile(id, token)

        .then(response => {

          console.log('Archivo eliminado exitosamente:', response.data);

          fetchFiles();

        })

        .catch(error => {

          console.error('Error al eliminar el archivo:', error);

        });

    }

  };

  


  const handleCheckboxChange = (event, fileId, isDesign = false) => {

    const checked = event.target.checked;

  

    if (isDesign) {

      if (checked) {

        setSelectedDesigns(prevSelectedDesigns => [...prevSelectedDesigns, fileId]);

      } else {

        setSelectedDesigns(prevSelectedDesigns => prevSelectedDesigns.filter(design => design !== fileId));

      }

    } else {

      if (checked) {

        setSelectedFiles(prevSelectedFiles => [...prevSelectedFiles, fileId]);

      } else {

        setSelectedFiles(prevSelectedFiles => prevSelectedFiles.filter(file => file !== fileId));

      }

    }

  };


  const handleSelectAll = (event) => {

    const checked = event.target.checked;

    setSelectAll(checked);

    if (checked) {

      const allFileIds = files.map(file => file.id);

      setSelectedFiles(allFileIds);

    } else {

      setSelectedFiles([]);

    }

  };


  const formatDate = (date) => {

    return moment(date).format('YYYY-MM-DD HH:mm:ss');

  };


  const getFileExtension = (url) => {

    const extension = url.split('.').pop().toLowerCase();

    return extension;

  };


  const handleDeleteSelected = () => {

    // Delete selected regular files

    selectedFiles.forEach(fileId => {

      handleDelete(fileId);

    });

    

    // Delete selected design files

    selectedDesigns.forEach(designId => {

      handleDelete(designId, true);

    });

  

    setSelectedFiles([]);

    setSelectedDesigns([]);

    setSelectAll(false);

  };

  

  const handleDownloadSelected = () => {

    selectedFiles.forEach(fileId => {

      const file = files.find(file => file.id === fileId);

      if (file) {

        downloadFile(file.file, file.name);

      }

    });

  };


  const downloadFile = (url, fileName) => {

    console.log('la url es la siguiente', url);

    window.open(url, '_blank');

    fetch(url)

      .then(response => response.blob())

      .then(blob => {

        const blobURL = URL.createObjectURL(blob);

        const link = document.createElement('a');

        link.href = blobURL;

        link.download = fileName;

        link.click();

        URL.revokeObjectURL(blobURL);

        link.remove();

      })

      .catch(error => {

        console.error('Error al descargar el archivo:', error);

      });

  };


  const handleDownload = (fileId, isDesign = false) => {

    let file;

    if (isDesign) {

      file = designs.find(design => design.id === fileId);

    } else {

      file = files.find(file => file.id === fileId);

    }


    if (file) {

      if (isDesign) {

        // Abrir el diseño en una nueva página

        window.open(file.url, '_blank');

      } else {

        // Descargar el archivo utilizando Fetch

        fetch(file.file)

          .then(response => response.blob())

          .then(blob => {

            const blobURL = URL.createObjectURL(blob);

            const link = document.createElement('a');

            link.href = blobURL;

            link.download = file.name;

            link.click();

            URL.revokeObjectURL(blobURL);

            link.remove();

          })

          .catch(error => {

            console.error('Error al descargar el archivo:', error);

          });

      }

    }

  };



  const handleSearch = (event) => {

    setSearchTerm(event.target.value);

    setCurrentPage(1);

    setCurrentDesignsPage(1); // Reset the current page for design files as well

  };


  const handleFilesPerPageChange = (event) => {

    const value = parseInt(event.target.value, 10);

    setFilesPerPage(value);

    setCurrentPage(1);

  };


  const indexOfLastFile = currentPage * filesPerPage;

  const indexOfFirstFile = indexOfLastFile - filesPerPage;

  const filteredFiles = files.filter(file =>

    file.name.toLowerCase().includes(searchTerm.toLowerCase()) &&

    (!startDate || moment(file.created_at).isSameOrAfter(startDate)) &&

    (!endDate || moment(file.created_at).isSameOrBefore(endDate)) &&

    !NotallowedFileNames.includes(file.name)

  );

  const currentFiles = filteredFiles.slice(indexOfFirstFile, indexOfLastFile);

  const totalPages = Math.ceil(filteredFiles.length / filesPerPage);


  const handlePageChange = (pageNumber) => {

    setCurrentPage(pageNumber);

  };


  const getPreviewComponent = (fileUrl) => {

    const fileExtension = getFileExtension(fileUrl);


    if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)) {

      return <img src={fileUrl} alt="Preview" className="preview-image" style={{ width: '200px', height: 'auto' , float: 'right' }} />;

    } else if (fileExtension === 'pdf') {

      return (

        <p>

          Este archivo PDF no se puede previsualizar aquí.{' '}

          <a href={fileUrl} target="_blank" rel="noopener noreferrer">

            Abrir en nueva pestaña

          </a>

        </p>

      );

    } else if (fileExtension === 'mp4') {

      return (

        <video controls className="preview-video" style={{ width: '100%' }}>

          <source src={fileUrl} type="video/mp4" />

        </video>

      );

    } else {

      return <p>No hay vista previa disponible</p>;

    }

  };

  const handleAdditionalFileChange = (event) => {

    const file = event.target.files[0];

    setSelectedFile(file);

    setFileName(file.name);

    setSelectedFileName('');

  };


  const handleUploadAdditionalFile = () => {

    const token = localStorage.getItem('token');


    if (!selectedFile) {

      console.log('Ningún archivo seleccionado');

      return;

    }


    if (!selectedFileName) {

      console.log('Por favor ingrese un nombre de archivo');

      return;

    }


    const existingFile = files.find(file => file.name === selectedFileName);

    if (existingFile) {

      const confirmOverwrite = window.confirm('El archivo ya existe. ¿Quieres sobreescribirlo?');

      if (!confirmOverwrite) {

        return;

      }

      handleDelete(existingFile.id);

    }


    const formData = new FormData();

    formData.append('file', selectedFile);

    formData.append('name', selectedFileName);


    FileDataService.uploadFile(formData, token)

      .then(response => {

        console.log('Documento cargado exitosamente:', response.data);

        fetchFiles();

        setSelectedFile(null);

        setFileName('');

        setSelectedFileName('');

      })

      .catch(error => {

        console.error('Error al cargar el archivo:', error);

      });

  };

  useEffect(() => {

    fetchDesigns();

  }, []);


  const fetchDesigns = () => {

    const token = localStorage.getItem('token');

    FileDataService.getAllDesigns(token)

      .then(response => {

        setDesigns(response.data);

        const urls = {};

        response.data.forEach(design => {

          urls[design.id] = design.file;

        });

        setPreviewDesignUrls(urls);

      })

      .catch(error => {

        console.error(error);

      });

  };


  // ... (existing code)


  const handleDownloadDesignSelected = () => {

    selectedDesigns.forEach(designId => {

      const design = designs.find(design => design.id === designId);

      if (design) {

        downloadFile(design.file, design.name);

      }

    });

  };


  const handleDesignFileChange = (event) => {

    const file = event.target.files[0];

    setSelectedDesignFile(file);

  };


  const handleDesignNameChange = (event) => {

    setDesignName(event.target.value);

  };


  const handleDesignUrlChange = (event) => {

    setDesignUrl(event.target.value);

  };

  const handleDesignCustomerChange = (event) => {

    setDesignCustomer(event.target.value);

  };

  

  const handleDesignContextChange = (event) => {

    setDesignContext(event.target.value);

  };

  


  const handleUploadDesign = () => {

    const token = localStorage.getItem('token');


    if (!selectedDesignFile) {

      console.log('Ningún archivo de diseño seleccionado');

      return;

    }


    if (!designName || !designUrl || !designCustomer || !designContext) {

      console.log('Por favor, complete todos los campos para el diseño');

      return;

    }


    const formData = new FormData();

    // formData.append('file', selectedDesignFile);

    formData.append('name', designName);

    formData.append('url', designUrl);

    formData.append('image', selectedDesignFile);

    formData.append('customer', designCustomer); 

    formData.append('context', designContext); 


    FileDataService.createDesign(formData, token)

      .then(response => {

        console.log('Diseño cargado exitosamente:', response.data);

        fetchDesigns();

        setSelectedDesignFile(null);

        setDesignName('');

        setDesignUrl('');

        setDesignCustomer('');

        setDesignContext('');

        setSelectedDesigns([...selectedDesigns, response.data.id]); // Seleccionar automáticamente el diseño recién creado

      })

      .catch(error => {

        console.error('Error al cargar el diseño:', error);

      });

  };


  const handleDesignsPerPageChange = (event) => {

    const value = parseInt(event.target.value, 10);

    setDesignsPerPage(value);

    setCurrentDesignsPage(1);

  };

  const indexOfLastDesign = currentDesignsPage * designsPerPage;

  const indexOfFirstDesign = indexOfLastDesign - designsPerPage;

  const filteredDesigns = designs.filter(design =>

    design.name.toLowerCase().includes(designNameSearch.toLowerCase()) &&

    design.created_by.toLowerCase().includes(creatorSearch.toLowerCase()) &&

    (!startDate || moment(design.created_at).isSameOrAfter(startDate)) &&

    (!endDate || moment(design.created_at).isSameOrBefore(endDate)) &&

    !NotallowedFileNames.includes(design.name)

  );

  const currentDesigns = filteredDesigns.slice(indexOfFirstDesign, indexOfLastDesign);

  const totalDesignPages = Math.ceil(filteredDesigns.length / designsPerPage);


  const handleDesignsPageChange = (pageNumber) => {

    setCurrentDesignsPage(pageNumber);

  };

  const handleDesignNameSearch = (event) => {

    setDesignNameSearch(event.target.value);

    setCurrentDesignsPage(1); // Reset the current page when changing the search term

  };


  const handleCreatorSearch = (event) => {

    setCreatorSearch(event.target.value);

    setCurrentDesignsPage(1); // Reset the current page when changing the search term

  };


  const handleStartDateChange = (date) => {

    setStartDate(date);

    setCurrentPage(1);

    setCurrentDesignsPage(1);

  };


  const handleEndDateChange = (date) => {

    setEndDate(date);

    setCurrentPage(1);

    setCurrentDesignsPage(1);

  };


  return (

    <Container style={{ width: "100%" }}>

      <div style={{ display: 'flex', width: '100%', marginTop: '13%' }}>

      <h1>Archivos de ABCupon</h1>

      </div>

      {currentUser && currentUser.staff_status === 'administrator' && (

        <>

          <div className="upload-container">


            <input type="file" onChange={handleAdditionalFileChange} />

            <input

              type="text"

              placeholder="Ingrese el nombre del archivo"

              value={selectedFileName}

              onChange={handleFileNameChange}

            />

            <button className="upload-button" onClick={handleUploadAdditionalFile}>

              Cargar archivo

            </button>

          </div>


        </>

      )}


<div style={{ width: '100%', textAlign: 'left' }}>

      <div className="search-container">

        

        <Form.Group controlId="searchTerm" style={{ marginBottom: '10px' }}>

          <Form.Control

            type="text"

            placeholder="Buscar por nombre de archivo"

            value={searchTerm}

            onChange={handleSearch}

            style={{

              borderRadius: '5px',

              boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',

              transition: 'box-shadow 0.3s',

              outline: 'none',

              padding: '6px',

              width: '30%',

            }}

            onFocus={(e) => {

              e.target.style.boxShadow = '0 0 6px rgba(0, 0, 255, 0.5)';

            }}

            onBlur={(e) => {

              e.target.style.boxShadow = '2px 2px 4px rgba(0, 0, 0, 0.5)';

            }}

          />

        </Form.Group>

        <Form.Group controlId="startDate" style={{ marginBottom: '10px', display: 'inline-block', marginRight: '20px' ,borderRadius: '5px',

    boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',

    border: '1px solid #000' }}>

  <Form.Label>Buscar por fecha desde:</Form.Label>

  <br />

  <DatePicker

    selected={startDate}

    onChange={handleStartDateChange}

    dateFormat="yyyy-MM-dd"

  />

</Form.Group>


<Form.Group controlId="endDate" style={{ marginBottom: '10px', display: 'inline-block' ,borderRadius: '5px',

    boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',

    border: '1px solid #000'}}>

  <Form.Label>Buscar por fecha hasta:</Form.Label>

  <br />

  <DatePicker

    selected={endDate}

    onChange={handleEndDateChange}

    dateFormat="yyyy-MM-dd"

    

  />

</Form.Group>


      </div>

      </div>

      <div className="files-per-page-container" >

        <Form.Group controlId="filesPerPage" style={{ marginBottom: '10px' }}>

          <Form.Label>Archivos por pagina:</Form.Label>

          <div style={{ display: 'flex', alignItems: 'center' }}>

            <Form.Control

              type="number"

              min="1"

              value={filesPerPage}

              onChange={handleFilesPerPageChange}

              style={{

                borderRadius: '5px',

                boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',

                transition: 'box-shadow 0.3s',

                outline: 'none',

                padding: '6px',

                marginLeft: '5px',

                marginRight: '5px',

                width: '50px',

                textAlign: 'center',

              }}

              onFocus={(e) => {

                e.target.style.boxShadow = '0 0 6px rgba(0, 0, 255, 0.5)';

              }}

              onBlur={(e) => {

                e.target.style.boxShadow = '2px 2px 4px rgba(0, 0, 0, 0.5)';

              }}

            />

          </div>

        </Form.Group>

        <input

          type="checkbox"

          checked={selectAll}

          onChange={handleSelectAll}

        />

      </div>


      {filteredFiles.length > 0 ? (

        <>

          <div

            className="header-buttons"

            style={{

              display: 'flex',

              justifyContent: 'space-between',

              alignItems: 'center',

              width: '50%',

              marginLeft: '50%',

              marginTop: '-3%'

            }}

          >

            <Button variant="primary" onClick={handleDownloadSelected} style={{

              color: 'blue',

              backgroundColor: 'white',

              border: '2px solid black',

              boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',

              transition: 'background-color 0.3s, border-color 0.3s',

            }}>Descargar seleccionado</Button>

            {currentUser && currentUser.staff_status === 'administrator' && (

              <Button variant="primary" onClick={handleDeleteSelected} style={{

                color: 'blue',

                backgroundColor: 'white',

                border: '2px solid black',

                boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',

                transition: 'background-color 0.3s, border-color 0.3s',

              }}>Eliminar seleccionado</Button>

            )}

          </div>

          <div style={{ overflowX: 'auto' }}>

            <Table striped bordered hover className="files-table">

              <thead>

                <tr>

                  <th style={{ color: 'blue' }}>Seleccionar</th>

                  <th style={{ color: 'blue' }}>Nombre del archivo</th>

                  <th style={{ color: 'blue' }}>Creado por:</th>

                  <th style={{ color: 'blue' }}>Fecha de creacion</th>

                  <th style={{ color: 'blue' }}>Descargar</th>

                  <th style={{ textAlign: 'center', color: 'blue' }}>Previsualizacion</th>

                </tr>

              </thead>

              <tbody>

                {currentFiles.map(file => (

                  <tr key={file.id}>

                    <td>

                      <input

                        type="checkbox"

                        checked={selectedFiles.includes(file.id)}

                        onChange={event => handleCheckboxChange(event, file.id)}

                      />

                    </td>

                    <td>

                      <a

                        href={file.file}

                        target="_blank"

                        rel="noopener noreferrer"

                        style={{ color: 'black' }}

                      >

                        {file.name}

                      </a>

                    </td>

                    <td>{file.user}</td>

                    <td>{formatDate(file.created_at)}</td>

                    <td>

                      <button className="download-button" onClick={() => handleDownload(file.id)}>

                        Descargar

                      </button>

                    </td>

                    <td style={{ width: '50%', textAlign: 'right' }}>

                      {previewFileUrls[file.id] && (

                        <div className="file-preview">

                          <div style={{ textAlign: 'center' }}></div>

                          {getPreviewComponent(previewFileUrls[file.id])}

                        </div>

                      )}

                    </td>

                  </tr>

                ))}

              </tbody>

            </Table>


          </div>

          {totalPages > 1 && (

            <div className="pagination-container">

              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                <Button

                  variant="primary"

                  disabled={currentPage === 1}

                  onClick={() => handlePageChange(currentPage - 1)}

                >

                  Anterior

                </Button>

                <div className="page-info" style={{ margin: '0 5px' }}>Page {currentPage}</div>

                <Button

                  variant="primary"

                  disabled={currentPage === totalPages}

                  onClick={() => handlePageChange(currentPage + 1)}

                >

                  Siguiente

                </Button>

              </div>

            </div>

          )}



        </>

      ) : (

        <p>No hay archivos disponibles.</p>

      )}



      <br /><br />


      {/* New section for design files */}

      {currentUser && currentUser.staff_status === 'administrator' && (

        <>

       <div style={{ display: 'flex', width: '100%' }}>

      <h2>Archivos de Diseño</h2>

      </div>

          {/* Nueva sección para cargar diseño */}

          <div className="upload-container">

            <input type="file" onChange={handleDesignFileChange} />

            <br/>

            

            <input

              type="text"

              placeholder="Ingrese el nombre del diseño"

              value={designName}

              onChange={handleDesignNameChange}

              style={{

                borderRadius: '5px',

                boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',

                transition: 'box-shadow 0.3s',

                outline: 'none',

                padding: '6px',

                width: '30%',

              }}

            />

            <br/>

            {/* Campo para "Customer" */}

  <input

    type="text"

    placeholder="Ingrese el cliente"

    value={designCustomer}

    onChange={handleDesignCustomerChange}

    style={{

      borderRadius: '5px',

      boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',

      transition: 'box-shadow 0.3s',

      outline: 'none',

      padding: '6px',

      width: '30%',

    }}

  />

  <br />


  {/* Campo para "Context" */}

  <textarea

    placeholder="Ingrese el contexto del diseño"

    value={designContext}

    onChange={handleDesignContextChange}

    style={{

      borderRadius: '5px',

      boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',

      transition: 'box-shadow 0.3s',

      outline: 'none',

      padding: '6px',

      width: '30%',

      height: '80px',

      resize: 'none',

    }}

  />

  <br />

            <input

              type="text"

              placeholder="Ingrese la URL del diseño"

              value={designUrl}

              onChange={handleDesignUrlChange}

              style={{

                borderRadius: '5px',

                boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',

                transition: 'box-shadow 0.3s',

                outline: 'none',

                padding: '6px',

                width: '30%',

              }}

            />

            <br/>

            <button className="upload-button" onClick={handleUploadDesign} style={{

              borderRadius: '5px',

              boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',

              transition: 'box-shadow 0.3s',

              outline: 'none',

              padding: '6px',

              width: '30%',

            }}>

              Cargar diseño

            </button>

            <br/>

           

          </div>

          

          <div className="search-container" style={{ width: '100%', textAlign: 'left' }}>

        <Form.Group controlId="designNameSearch" style={{ marginBottom: '10px' }}>

          <Form.Control

            type="text"

            placeholder="Buscar por nombre del diseño"

            value={designNameSearch}

            onChange={handleDesignNameSearch}

            style={{

              borderRadius: '5px',

              boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',

              transition: 'box-shadow 0.3s',

              outline: 'none',

              padding: '6px',

              width: '30%',

            }}

            onFocus={(e) => {

              e.target.style.boxShadow = '0 0 6px rgba(0, 0, 255, 0.5)';

            }}

            onBlur={(e) => {

              e.target.style.boxShadow = '2px 2px 4px rgba(0, 0, 0, 0.5)';

            }}

          />

        </Form.Group>

        <Form.Group controlId="creatorSearch" style={{ marginBottom: '10px' }}>

          <Form.Control

            type="text"

            placeholder="Buscar por creador del diseño"

            value={creatorSearch}

            onChange={handleCreatorSearch}

            style={{

              borderRadius: '5px',

              boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',

              transition: 'box-shadow 0.3s',

              outline: 'none',

              padding: '6px',

              width: '30%',

            }}

            onFocus={(e) => {

              e.target.style.boxShadow = '0 0 6px rgba(0, 0, 255, 0.5)';

            }}

            onBlur={(e) => {

              e.target.style.boxShadow = '2px 2px 4px rgba(0, 0, 0, 0.5)';

            }}

          />

        </Form.Group>

        <Form.Group controlId="startDate" style={{ marginBottom: '10px', display: 'inline-block', marginRight: '20px' ,borderRadius: '5px',

    boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',

    border: '1px solid #000' }}>

  <Form.Label>Buscar por fecha desde:</Form.Label>

  <br />

  <DatePicker

    selected={startDate}

    onChange={handleStartDateChange}

    dateFormat="yyyy-MM-dd"

  />

</Form.Group>


<Form.Group controlId="endDate" style={{ marginBottom: '10px', display: 'inline-block' ,borderRadius: '5px',

    boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',

    border: '1px solid #000'}}>

  <Form.Label>Buscar por fecha hasta:</Form.Label>

  <br />

  <DatePicker

    selected={endDate}

    onChange={handleEndDateChange}

    dateFormat="yyyy-MM-dd"

    

  />

</Form.Group>

        

      </div>

          <div className="designs-per-page-container">

        <Form.Group controlId="designsPerPage" style={{ marginBottom: '10px'}}>

          <br/>

          <Form.Label>Diseños por página:</Form.Label>

          <div style={{ display: 'flex', alignItems: 'center' }}>

            <Form.Control

              type="number"

              min="1"

              value={designsPerPage}

              onChange={handleDesignsPerPageChange}

              style={{

                borderRadius: '5px',

                boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',

                transition: 'box-shadow 0.3s',

                outline: 'none',

                padding: '6px',

                marginLeft: '5px',

                marginRight: '5px',

                width: '50px',

                textAlign: 'center',

              }}

              onFocus={(e) => {

                e.target.style.boxShadow = '0 0 6px rgba(0, 0, 255, 0.5)';

              }}

              onBlur={(e) => {

                e.target.style.boxShadow = '2px 2px 4px rgba(0, 0, 0, 0.5)';

              }}

            />

          </div>

        </Form.Group>

        <div style={{ display: 'flex', gap: '10px' }}>

  <Button

    variant="primary"

    onClick={handleDownloadDesignSelected}

    style={{

      color: 'blue',

      backgroundColor: 'white',

      border: '2px solid black',

      boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',

      transition: 'background-color 0.3s, border-color 0.3s',

    }}

  >

    Descargar diseños

  </Button>


  {currentUser && currentUser.staff_status === 'administrator' && (

    <Button

      variant="primary"

      onClick={handleDeleteSelected}

      style={{

        color: 'blue',

        backgroundColor: 'white',

        border: '2px solid black',

        boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',

        transition: 'background-color 0.3s, border-color 0.3s',

      }}

    >

      Eliminar diseño seleccionado

    </Button>

  )}

</div>


      </div>

      {filteredDesigns.length > 0 && (

        <>

         <div style={{ overflowX: 'auto' }}>

          <Table striped bordered hover className="designs-table">

            <thead>

              <tr>

                <th  style={{ color: 'blue' }}>Seleccionar</th>

                <th  style={{ color: 'blue' }}>Nombre del cliente</th>

                <th  style={{ color: 'blue' }}>Nombre del diseño</th>

                <th  style={{ color: 'blue' }}>Contexto</th>

                <th  style={{ color: 'blue' }}>URL</th>

                <th  style={{ color: 'blue' }}>Creado por:</th>

                <th  style={{ color: 'blue' }}>Fecha de creacion</th>

                <th  style={{ color: 'blue' }}>Descargar</th>

                <th style={{ textAlign: 'center',color: 'blue' }}>Previsualizacion1234</th>

              </tr>

            </thead>

            <tbody>

              {currentDesigns.map(design => (

                <tr key={design.id}>

                  <td>

                  <input

                    type="checkbox"

                    checked={selectedDesigns.includes(design.id)}

                    onChange={event => handleCheckboxChange(event, design.id, true)}

                  />

                  </td>

                  <td>

                      {design.customer}

                  </td>

                  <td>

                    <a

                      href={design.file}

                      target="_blank"

                      rel="noopener noreferrer"

                      style={{ color: 'black' }}

                    >

                      {design.name}

                    </a>

                  </td>

                  <td>

                    {/* Mostrar el contexto con viñetas y limitado a 55 caracteres */}

                    <div className="context-container">

                      <ul style={{ paddingLeft: '20px' }}>

                        {design.context.length > 50 ? (

                          <>

                            {/* Mostrar solo los primeros 55 caracteres con viñetas */}

                            <li>{design.context.slice(0, 50)}...</li>

                            {/* Botón para ver el contexto completo */}

                            <button

                              style={{

                                borderRadius: '5px',

                                padding: '4px 10px',

                                backgroundColor: '#4CAF50',

                                color: 'white',

                                border: 'none',

                                cursor: 'pointer',

                                marginTop: '10px',

                                display: 'inline-block',

                              }}

                              onClick={() => setShowFullContext(true)}

                            >

                              Ver más

                            </button>

                          </>

                        ) : (

                          <li>{design.context}</li>

                        )}

                      </ul>


                      {/* Si se presiona "Ver más", mostrar el texto completo */}

                      {showFullContext && (

                        <div>

                          <ul style={{ paddingLeft: '20px' }}>

                            <li>{design.context}</li>

                          </ul>

                          <button

                            style={{

                              borderRadius: '5px',

                              padding: '4px 10px',

                              backgroundColor: '#f44336',

                              color: 'white',

                              border: 'none',

                              cursor: 'pointer',

                              marginTop: '10px',

                            }}

                            onClick={() => setShowFullContext(false)}

                          >

                            Ver menos

                          </button>

                        </div>

                      )}

                    </div>

                  </td>


                  <td>{design.url}</td>

                  <td>{design.created_by}</td>

                  <td>{formatDate(design.created_at)}</td>

                  <td>

                    <button className="download-button" onClick={() => handleDownload(design.id, true)}>

                      Descargar

                    </button>

                  </td>

                  <td style={{ width: '50%', textAlign: 'right' }}>

                    {design.image && (

                      <div className="design-preview">

                        <img src={design.image} alt="Preview"  style={{ width: '200px', height: 'auto' , float: 'right' }}  />

                      </div>

                    )}

                  </td>

                </tr>

              ))}

            </tbody>

          </Table>

          </div>

          {totalDesignPages > 1 && (

            <div className="pagination-container">

              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                <Button

                  variant="primary"

                  disabled={currentDesignsPage === 1}

                  onClick={() => handleDesignsPageChange(currentDesignsPage - 1)}

                >

                  Anterior

                </Button>

                <div className="page-info" style={{ margin: '0 5px' }}>Page {currentDesignsPage}</div>

                <Button

                  variant="primary"

                  disabled={currentDesignsPage === totalDesignPages}

                  onClick={() => handleDesignsPageChange(currentDesignsPage + 1)}

                >

                  Siguiente

                </Button>

              </div>

            </div>

          )}

        </>

      )}

       </>

      )}


{currentUser && currentUser.staff_status === 'design' && (

        <>

       

      <h2>Archivos de Diseño</h2>

          {/* Nueva sección para cargar diseño */}

          <div className="upload-container">

            <input type="file" onChange={handleDesignFileChange} />

            <br/>

            <input

              type="text"

              placeholder="Ingrese el nombre del diseño"

              value={designName}

              onChange={handleDesignNameChange}

              style={{

                borderRadius: '5px',

                boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',

                transition: 'box-shadow 0.3s',

                outline: 'none',

                padding: '6px',

                width: '30%',

              }}

            />

            <br/>

            <input

              type="text"

              placeholder="Ingrese la URL del diseño"

              value={designUrl}

              onChange={handleDesignUrlChange}

              style={{

                borderRadius: '5px',

                boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',

                transition: 'box-shadow 0.3s',

                outline: 'none',

                padding: '6px',

                width: '30%',

              }}

            />

            <br/>

            <button className="upload-button" onClick={handleUploadDesign} style={{

              borderRadius: '5px',

              boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',

              transition: 'box-shadow 0.3s',

              outline: 'none',

              padding: '6px',

              width: '30%',

            }}>

              Cargar diseño

            </button>

            <br/>

           

          </div>

          

          <div className="search-container">

        <Form.Group controlId="designNameSearch" style={{ marginBottom: '10px' }}>

          <Form.Control

            type="text"

            placeholder="Buscar por nombre del diseño"

            value={designNameSearch}

            onChange={handleDesignNameSearch}

            style={{

              borderRadius: '5px',

              boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',

              transition: 'box-shadow 0.3s',

              outline: 'none',

              padding: '6px',

              width: '30%',

            }}

            onFocus={(e) => {

              e.target.style.boxShadow = '0 0 6px rgba(0, 0, 255, 0.5)';

            }}

            onBlur={(e) => {

              e.target.style.boxShadow = '2px 2px 4px rgba(0, 0, 0, 0.5)';

            }}

          />

        </Form.Group>

        <Form.Group controlId="creatorSearch" style={{ marginBottom: '10px' }}>

          <Form.Control

            type="text"

            placeholder="Buscar por creador del diseño"

            value={creatorSearch}

            onChange={handleCreatorSearch}

            style={{

              borderRadius: '5px',

              boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',

              transition: 'box-shadow 0.3s',

              outline: 'none',

              padding: '6px',

              width: '30%',

            }}

            onFocus={(e) => {

              e.target.style.boxShadow = '0 0 6px rgba(0, 0, 255, 0.5)';

            }}

            onBlur={(e) => {

              e.target.style.boxShadow = '2px 2px 4px rgba(0, 0, 0, 0.5)';

            }}

          />

        </Form.Group>

        

      </div>

          <div className="designs-per-page-container">

        <Form.Group controlId="designsPerPage" style={{ marginBottom: '10px' }}>

          <Form.Label>Diseños por página:</Form.Label>

          <div style={{ display: 'flex', alignItems: 'center' }}>

            <Form.Control

              type="number"

              min="1"

              value={designsPerPage}

              onChange={handleDesignsPerPageChange}

              style={{

                borderRadius: '5px',

                boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',

                transition: 'box-shadow 0.3s',

                outline: 'none',

                padding: '6px',

                marginLeft: '5px',

                marginRight: '5px',

                width: '50px',

                textAlign: 'center',

              }}

              onFocus={(e) => {

                e.target.style.boxShadow = '0 0 6px rgba(0, 0, 255, 0.5)';

              }}

              onBlur={(e) => {

                e.target.style.boxShadow = '2px 2px 4px rgba(0, 0, 0, 0.5)';

              }}

            />

          </div>

        </Form.Group>

        <div style={{ display: 'flex', gap: '10px' }}>

  <Button

    variant="primary"

    onClick={handleDownloadDesignSelected}

    style={{

      color: 'blue',

      backgroundColor: 'white',

      border: '2px solid black',

      boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',

      transition: 'background-color 0.3s, border-color 0.3s',

    }}

  >

    Descargar diseños

  </Button>


</div>


      </div>

      {filteredDesigns.length > 0 && (

        <>

         

          <Table striped bordered hover className="designs-table">

            <thead>

              <tr>

                <th>Seleccionar</th>

                <th>Nombre del diseño</th>

                <th>URL</th>

                <th>Creado por:</th>

                <th>Fecha de creacion</th>

                <th>Descargar</th>

                <th style={{ textAlign: 'center' }}>Previsualizacion</th>

              </tr>

            </thead>

            <tbody>

              {currentDesigns.map(design => (

                <tr key={design.id}>

                  <td>

                  <input

  type="checkbox"

  checked={selectedDesigns.includes(design.id)}

  onChange={event => handleCheckboxChange(event, design.id, true)}

/>

                  </td>

                  <td>

                    <a

                      href={design.file}

                      target="_blank"

                      rel="noopener noreferrer"

                      style={{ color: 'black' }}

                    >

                      {design.name}

                    </a>

                  </td>

                  <td>{design.url}</td>

                  <td>{design.created_by}</td>

                  <td>{formatDate(design.created_at)}</td>

                  <td>

                    <button className="download-button" onClick={() => handleDownload(design.id, true)}>

                      Descargar

                    </button>

                  </td>

                  <td style={{ width: '50%', textAlign: 'right' }}>

                    {design.image && (

                      <div className="design-preview">

                        <img src={design.image} alt="Preview" style={{ width: '100%', height: 'auto' }} />

                      </div>

                    )}

                  </td>

                </tr>

              ))}

            </tbody>

          </Table>

          {totalDesignPages > 1 && (

            <div className="pagination-container">

              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                <Button

                  variant="primary"

                  disabled={currentDesignsPage === 1}

                  onClick={() => handleDesignsPageChange(currentDesignsPage - 1)}

                >

                  Anterior

                </Button>

                <div className="page-info" style={{ margin: '0 5px' }}>Page {currentDesignsPage}</div>

                <Button

                  variant="primary"

                  disabled={currentDesignsPage === totalDesignPages}

                  onClick={() => handleDesignsPageChange(currentDesignsPage + 1)}

                >

                  Siguiente

                </Button>

              </div>

            </div>

          )}

        </>

      )}

       </>

      )}


      <Footer />

    </Container>

  );

};


export default Files;


