import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import TourismDataService from "../../services/Tourism";
import PropertyModal from './tourism_modal/create/PropertyModal';
import BookingModal from './tourism_modal/create/BookingModal'; // Importa el modal de reserva
import PaymentModal from './tourism_modal/create/PaymentModal'; // Importa el modal de pago
import ReviewModal from './tourism_modal/create/ReviewModal'; // Importa el modal de reseña

const Tourism = () => {
  const token = useSelector((state) => state.authentication.token);
  const [properties, setProperties] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [payments, setPayments] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [amenities, setAmenities] = useState([]); 
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentModalType, setCurrentModalType] = useState(null);
  const [currentEntity, setCurrentEntity] = useState(null); // Para editar
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const storedUser = localStorage.getItem("currentUser");
    // console.log("currentUSer", currentUser)
    if (storedUser) {
      const user = JSON.parse(storedUser);
      setCurrentUser(user);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch properties
        const propertiesResponse = await TourismDataService.getAllProperties(token);
        setProperties(propertiesResponse.data);
        console.log("datos de propiedades", propertiesResponse.data)

        // Fetch amanities
        const amenitiesResponse = await TourismDataService.getAllAmenities(token);
        setAmenities(amenitiesResponse.data);
        console.log("amanidades servidor",amenities)

        // Fetch bookings
        const bookingsResponse = await TourismDataService.getAllBookings(token);
        setBookings(bookingsResponse.data);

        // Fetch payments
        const paymentsResponse = await TourismDataService.getAllPayments(token);
        setPayments(paymentsResponse.data);

        // Fetch reviews
        const reviewsResponse = await TourismDataService.getAllReviews(token);
        setReviews(reviewsResponse.data);
      } catch (err) {
        setError("Error fetching data: " + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [token]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch properties
        const propertiesResponse = await TourismDataService.getAllProperties(token);
        setProperties(propertiesResponse.data);

        // Fetch bookings
        const bookingsResponse = await TourismDataService.getAllBookings(token);
        setBookings(bookingsResponse.data);

        // Fetch payments
        const paymentsResponse = await TourismDataService.getAllPayments(token);
        setPayments(paymentsResponse.data);

        // Fetch reviews
        const reviewsResponse = await TourismDataService.getAllReviews(token);
        setReviews(reviewsResponse.data);
      } catch (err) {
        setError("Error fetching data: " + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [token]);

  // Handler functions
  const handleEdit = (id, type) => {
    const entity = type === "property" ? properties.find(prop => prop.id === id) :
                  type === "booking" ? bookings.find(book => book.id === id) :
                  type === "payment" ? payments.find(pay => pay.id === id) :
                  reviews.find(review => review.id === id);
    setCurrentEntity(entity);
    setCurrentModalType(type);
    setIsModalOpen(true);
  };

  const handleCreate = (type) => {
    setCurrentEntity(null);
    setCurrentModalType(type);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCurrentEntity(null);
    setCurrentModalType(null);
  };
  const handleDelete = (id, type) => {
    console.log(`Delete ID: ${id} from ${type}`);
    // Add your delete logic here
  };
  
  // Render loading state or error message
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div style={{ marginTop: "8%" }}>
      <h1>Tourism Data</h1>

      {/* Properties Table */}
      <h2>Properties</h2>
      <table>
        <thead>
          <tr>
            <th style={{color:"black"}}>ID</th>
            <th style={{color:"black"}}>Title</th>
            <th style={{color:"black"}}>Description</th>
            <th style={{color:"black"}}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {properties.map((property) => (
            <tr key={property.id}>
              <td >{property.id}</td>
              <td>{property.title}</td>
              <td>{property.description}</td>
              <td>
                <button onClick={() => handleEdit(property.id, 'property')}>Edit</button>
                <button onClick={() => handleDelete(property.id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={() => handleCreate("property")}>Create Property</button>

      {/* Bookings Table */}
      <h2>Bookings</h2>
      <table>
        <thead>
          <tr>
            <th style={{color:"black"}}>ID</th>
            <th style={{color:"black"}}>User</th>
            <th style={{color:"black"}}>Property</th>
            <th style={{color:"black"}}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {bookings.map((booking) => (
            <tr key={booking.id}>
              <td>{booking.id}</td>
              <td>{booking.user}</td>
              <td>{booking.property}</td>
              <td>
                <button onClick={() => handleEdit(booking.id, 'booking')}>Edit</button>
                <button onClick={() => handleDelete(booking.id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={() => handleCreate("booking")}>Create Booking</button>

      {/* Payments Table */}
      <h2>Payments</h2>
      <table>
        <thead>
          <tr>
            <th style={{color:"black"}}>ID</th>
            <th style={{color:"black"}}>Amount</th>
            <th style={{color:"black"}}>Status</th>
            <th style={{color:"black"}}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {payments.map((payment) => (
            <tr key={payment.id}>
              <td>{payment.id}</td>
              <td>{payment.amount}</td>
              <td>{payment.status}</td>
              <td>
                <button onClick={() => handleEdit(payment.id, 'payment')}>Edit</button>
                <button onClick={() => handleDelete(payment.id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={() => handleCreate("payment")}>Create Payment</button>

      {/* Reviews Table */}
      <h2>Reviews</h2>
      <table>
        <thead>
          <tr>
            <th style={{color:"black"}}>ID</th>
            <th style={{color:"black"}}>User</th>
            <th style={{color:"black"}}>Comment</th>
            <th style={{color:"black"}}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {reviews.map((review) => (
            <tr key={review.id}>
              <td>{review.id}</td>
              <td>{review.user}</td>
              <td>{review.comment}</td>
              <td>
                <button onClick={() => handleEdit(review.id, 'review')}>Edit</button>
                <button onClick={() => handleDelete(review.id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={() => handleCreate("review")}>Create Review</button>

      {/* Modals */}
      {currentModalType === 'property' && (
        <PropertyModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          property={currentEntity}
          owner={currentUser} 
          token={token}
          amenities={amenities} 
        />
      )}
      {currentModalType === 'booking' && (
        <BookingModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          booking={currentEntity}
        />
      )}
      {currentModalType === 'payment' && (
        <PaymentModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          payment={currentEntity}
        />
      )}
      {currentModalType === 'review' && (
        <ReviewModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          review={currentEntity}
        />
      )}
    </div>
  );
};

export default Tourism;
