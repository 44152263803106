import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import JobDataService from "../../services/employee";
import Usuarios_search_job from "../backend/usuarios_search_job";
import CompanyModal from "./job_modal/company_modal";
import CompanydetailModal from "./job_modal/company_detail";
import SkillModal from "./job_modal/skill_model";
import JobPostingModal from "./job_modal/jobposting_modal";
import JobApplicationModal from "./job_modal/jobapplication_modal";

import Companies from "./Companies";
import Skills from "./Skills";
import JobPostings from "./JobPostings";
import JobApplications from "./JobApplications";

const RH = () => {
  const token = useSelector((state) => state.authentication.token);
  const user = useSelector((state) => state.authentication.user);
  const [currentUser, setCurrentUser] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [jobCategories, setJobCategories] = useState([]);
  const [jobPostings, setJobPostings] = useState([]);
  const [jobApplications, setJobApplications] = useState([]);

  const [experienceLevels, setExperienceLevels] = useState([]);
  const [skills, setSkills] = useState([]);
  const [benefits, setBenefits] = useState([]);
  const [jobTags, setJobTags] = useState([]);
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const [showCompanyModal1, setShowCompanyModal1] = useState(false);
  const [currentCompany, setCurrentCompany] = useState(null);
  const [showSkillModal, setShowSkillModal] = useState(false);
  const [currentSkill, setCurrentSkill] = useState(null);
  const [showJobPostingModal, setShowJobPostingModal] = useState(false);
  const [currentJobPosting, setCurrentJobPosting] = useState(null);
  const [showJobApplicationModal, setShowJobApplicationModal] = useState(false);
  const [currentJobApplication, setCurrentJobApplication] = useState(null);
  const [newCompany, setNewCompany] = useState({
    name: "",
    logo: null,
    website: "",
    description: "",
    contact_email: "",
    phone_number: "",
    address: "",
    industry: "",
    established_date: null,
  });
  useEffect(() => {
    const storedUser = localStorage.getItem("currentUser");
    // console.log("currentUSer", currentUser)
    if (storedUser) {
      const user = JSON.parse(storedUser);
      setCurrentUser(user);
    }
  }, []);
  // Cargar datos al inicio
  useEffect(() => {
    loadJobPostings();
    loadJobApplications();
    loadSkills();
    loadBenefits();
    loadJobTags();
    loadJobCategories();
    loadExperienceLevels();
  }, []);


 

  const loadJobCategories = () => {
    JobDataService.getAllJobCategories(token)
      .then((response) => {
        console.log("jobcategory", response.data);
        setJobCategories(response.data);
      })
      .catch((error) => console.error(error));
  };

  const loadJobPostings = () => {
    JobDataService.getAllJobPostings(token)
      .then((response) => {
        setJobPostings(response.data);
        console.log("jobposting", response.data)
      })
      .catch((error) => console.error(error));
  };

  const loadJobApplications = () => {
    JobDataService.getAllJobApplications(token)
      .then((response) => {
        console.log("jobaplicaciones", response.data);
        setJobApplications(response.data);
      })
      .catch((error) => console.error(error));
  };

  const loadExperienceLevels = () => {
    JobDataService.getAllExperienceLevels(token)
      .then((response) => {
        setExperienceLevels(response.data);
      })
      .catch((error) => console.error(error));
  };

  const loadSkills = () => {
    JobDataService.getAllSkills(token)
      .then((response) => {
        setSkills(response.data);
      })
      .catch((error) => console.error(error));
  };

  const loadBenefits = () => {
    JobDataService.getAllBenefits(token)
      .then((response) => {
        setBenefits(response.data);
      })
      .catch((error) => console.error(error));
  };

  const loadJobTags = () => {
    JobDataService.getAllJobTags(token)
      .then((response) => {
        setJobTags(response.data);
      })
      .catch((error) => console.error(error));
  };


  const deleteJobPosting = (postingId) => {
    JobDataService.deleteJobPosting(postingId, token)
      .then((response) => {
        loadJobPostings(); // Recargar datos de publicaciones de trabajo después de la eliminación
      })
      .catch((error) => console.error(error));
  };




  const handleSaveJobPosting = (jobPosting) => {
    console.log("jobposting", jobPosting)
    const formData = new FormData();
    formData.append("company", jobPosting.company);
    formData.append("title", jobPosting.title);
    formData.append("description", jobPosting.description);
    formData.append("category", jobPosting.category);
    formData.append("city", jobPosting.city);
    formData.append("country", jobPosting.country);
    formData.append("region", jobPosting.region);
    formData.append("modality", jobPosting.modality);
    formData.append("employment_type", jobPosting.employment_type);
    formData.append("experience_level", jobPosting.experience_level);
    formData.append("salary_range", jobPosting.salary_range);
    formData.append("skills_required", jobPosting.skills_required);
    formData.append("benefits", jobPosting.benefits);
    formData.append("tags", jobPosting.tags);
    formData.append("application_deadline", jobPosting.application_deadline);
    formData.append("how_to_apply", jobPosting.how_to_apply);

    for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
        }
    JobDataService.createJobPosting(formData, token)
    .catch((error) => console.error("Error al crear empresa", error));
};

const deleteJobApplication = (applicationjob, applicationid) => {
  console.log("id de eliminar", applicationid)
  JobDataService.deleteJobApplication(applicationjob, token, applicationid)
    .then((response) => {
      loadJobApplications(); // Recargar datos de aplicaciones después de la eliminación
    })
    .catch((error) => console.error(error));
};
const handleSaveJobApplication = (jobApplication) => {
  console.log("datos job application", jobApplication.formData)
  const id = jobApplication.formData.get("id");

  console.log("user", user);
  jobApplication.formData.append("applicant", user)
  for (let [key, value] of jobApplication.formData.entries()) {
    console.log(`${key}: ${value}`);
    }
  JobDataService.createJobApplication(jobApplication.formData, token, id)
  .catch((error) => console.error("Error al crear empresa", error));
};


  return (
    <div style={{ marginTop: "8%" }}>

      <h2 style={{marginTop:"3%"}}>Gestión de Datos</h2>

      <Companies token={token} currentUser={currentUser} />
      <Skills token={token} currentUser={currentUser} />
      <JobPostings token={token} currentUser={currentUser} />

      <JobApplications token={token} currentUser={currentUser}/>
            <div>
            {/* <h2>Job Applications</h2>
            <Button variant="primary" onClick={() => setShowJobApplicationModal(true)}>Crear Aplicación</Button>
            <Table striped bordered hover>
                <thead>
                    <tr>
                    <th style={{ color: 'blue' }}>Puesto</th>
                        <th style={{ color: 'blue' }}>Nombre del Candidato</th>
                        <th style={{ color: 'blue' }}>Presentacion</th>
                        <th style={{ color: 'blue' }}>Estado de Aplicacion</th>
                        <th style={{ color: 'blue' }}>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {jobApplications.map((application) => (
                        <tr key={application.id}>
                          <td>{application.job}</td>
                            <td>{application.applicant}</td>
                            <td>{application.cover_letter }</td>
                            <td>{application.status}</td>
                            <td>
                                <Button variant="warning" onClick={() => {}}>Editar</Button>
                                <Button variant="danger" onClick={() => deleteJobApplication(application.job, application.id)}>Eliminar</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table> */}
            </div>

            <Usuarios_search_job />
            
            <JobPostingModal
                show={showJobPostingModal} 
                handleClose={() => setShowJobPostingModal(false)} 
                jobPosting={currentJobPosting} 
                handleSave={handleSaveJobPosting} 
                jobCategories={jobCategories}  // Pasa las categorías de trabajo
                experienceLevels={experienceLevels}  // Pasa los niveles de experiencia
                tags ={jobTags}  // Pasa los tags cargados
                benefits={benefits}
                skills={skills}
                companies={companies}
            />
            <JobApplicationModal 
                show={showJobApplicationModal} 
                handleClose={() => setShowJobApplicationModal(false)} 
                jobApplication={currentJobApplication} 
                jobPostings={jobPostings}
                handleSave={handleSaveJobApplication} 
            />
    </div>
  );
};

export default RH;
