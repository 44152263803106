import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Form } from "react-bootstrap";
import JobDataService from "../../services/employee";
import SkillModal from "./job_modal/skill_model";

const Skills = ({ token, currentUser }) => {

    const [skills, setSkills] = useState([]);
    const [currentSkill, setCurrentSkill] = useState(null);
    const [showSkillModal, setShowSkillModal] = useState(false);

    useEffect(() => {
        loadSkills()
      }, [currentUser]);

      const loadSkills = () => {
        JobDataService.getAllSkills(token)
          .then((response) => {
            setSkills(response.data);
          })
          .catch((error) => console.error(error));
      };
      const deleteSkill = (skillId) => {
        JobDataService.deleteSkill(skillId, token)
          .then((response) => {
            loadSkills(); // Recargar habilidades después de la eliminación
          })
          .catch((error) => console.error(error));
      };
      const handleSaveSkill = (skill) => {
        loadSkills()
        console.log("skill",skill)
        const formData = new FormData();
        formData.append("name", skill.name);
        formData.append("description", skill.description);
        for (let [key, value] of formData.entries()) {
            console.log(`${key}: ${value}`);
            }
        JobDataService.createSkill(formData, token)
        .catch((error) => console.error("Error al crear empresa", error));
        loadSkills()
    };
  return (
    <div>
        Skills
        <div> 
        <h2>Habilidades</h2>
            <Button variant="primary" onClick={() => setShowSkillModal(true)}>Crear Habilidad</Button>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th style={{ color: 'blue' }}>Nombre</th>
                        <th style={{ color: 'blue' }}>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {skills.map((skill) => (
                        <tr key={skill.id}>
                            <td>{skill.name}</td>
                            <td>
                                <Button variant="danger" onClick={() => {deleteSkill(skill.id)}}>Eliminar</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            </div>
            <SkillModal 
                show={showSkillModal} 
                handleClose={() => setShowSkillModal(false)} 
                skill={currentSkill} 
                handleSave={handleSaveSkill} 
            />
    </div>
  )
}

export default Skills