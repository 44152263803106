import React, { useState, useEffect } from 'react';
import './style.css'

const ReviewModal = ({ isOpen, onClose, review }) => {
  const [user, setUser] = useState('');
  const [comment, setComment] = useState('');

  useEffect(() => {
    if (review) {
      setUser(review.user);
      setComment(review.comment);
    } else {
      setUser('');
      setComment('');
    }
  }, [review]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Aquí va la lógica para enviar los datos al servidor
    console.log({ user, comment });
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="modal">
      <h2>{review ? "Edit Review" : "Create Review"}</h2>
      <form onSubmit={handleSubmit}>
        <label>
          User:
          <input type="text" value={user} onChange={(e) => setUser(e.target.value)} />
        </label>
        <label>
          Comment:
          <textarea value={comment} onChange={(e) => setComment(e.target.value)} />
        </label>
        <button type="submit">{review ? "Update" : "Create"}</button>
        <button type="button" onClick={onClose}>Close</button>
      </form>
    </div>
  );
};

export default ReviewModal;
