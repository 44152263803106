import axios from 'axios';

class ProductDataService {
 // Obtener todos los productos
 getAll() {
  return axios.get('https://abcupon-backend.com/products/'); // URL para obtener productos
}

// Obtener todos los subproductos
getAllSubProduct() {
  return axios.get('https://abcupon-backend.com/products/subproducts/'); // URL para obtener subproductos
}
  createSubProduct( data, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.post(`https://abcupon-backend.com/products/subproducts/`, data );
  }

  updateSubProduct(subProductId, data, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.put(`https://abcupon-backend.com/products/subproducts/${subProductId}/`, data);
  }

  deleteSubProduct(subProductId, token) {
    // axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.delete(`https://abcupon-backend.com/products/subproducts/${subProductId}/delete/`);
  }

  createProduct( data, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.post("https://abcupon-backend.com/products/", data);  // Cambiar la URL
  }
updateProduct(id, data, token) {
  axios.defaults.headers.common["Authorization"] = "Token " + token;
  return axios.put(`https://abcupon-backend.com/products/${id}/update/`, data);  // Use the correct URL for updating a product
}

deleteProduct(id, token) {
  axios.defaults.headers.common["Authorization"] = "Token " + token;
  return axios.delete(`https://abcupon-backend.com/products/product/${id}/delete/`);  // Cambiar la URL
}

  getAllCharacteristics(token) {
    // axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.get('https://abcupon-backend.com/products/characteristics/');  // URL para obtener características
  }

  createCharacteristic(data, token) {
    // axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.post("https://abcupon-backend.com/products/characteristics/", data);  // URL para crear característica
  }

  updateCharacteristic(id, data, token) {
    // axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.put(`https://abcupon-backend.com/products/characteristics/${id}/`, data);  // URL para actualizar característica
  }

  deleteCharacteristic(id, token) {
    // axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.delete(`https://abcupon-backend.com/products/characteristics/${id}/`);  // URL para eliminar característica
  }
  
  getAllServices(token) {
    return axios.get('https://abcupon-backend.com/products/services/');
  }


  getAllServicesForSubProduct(subProductId, token) {
    // axios.defaults.headers.common['Authorization'] = 'Token ' + token;
    return axios.get(`https://abcupon-backend.com/products/subproducts/${subProductId}/services/`);
  }

  createServiceForSubProduct(subProductId, data, token) {
    // axios.defaults.headers.common['Authorization'] = 'Token ' + token;
    return axios.post(`https://abcupon-backend.com/products/subproducts/${subProductId}/services/`, data);
  }

  updateServiceForSubProduct(subProductId, serviceId, data, token) {
    axios.defaults.headers.common['Authorization'] = 'Token ' + token;
    return axios.put(`https://abcupon-backend.com/products/subproducts/${subProductId}/services/${serviceId}/`, data);
  }

  deleteServiceForSubProduct(subProductId, serviceId, token) {
    // axios.defaults.headers.common['Authorization'] = 'Token ' + token;
    return axios.delete(`https://abcupon-backend.com/products/subproducts/${subProductId}/services/${serviceId}/`);
  }
  getAllCombos(token) {
    // axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.get('https://abcupon-backend.com/products/combos/');  // URL for getting combos
  }

  createCombo(data, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.post("https://abcupon-backend.com/products/combos/", data);  // URL for creating combo
  }

  updateCombo(comboId, data, token) {
    axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.put(`https://abcupon-backend.com/products/combos/${comboId}/`, data);  // URL for updating combo
  }

  deleteCombo(comboId, token) {
    // axios.defaults.headers.common["Authorization"] = "Token " + token;
    return axios.delete(`https://abcupon-backend.com/products/combos/${comboId}/`);
  }
}

export default new ProductDataService();
