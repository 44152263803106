import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

const CompanyModal = ({ show, handleClose, company, handleSave }) => {
    const [name, setName] = useState(company ? company.name : '');
    const [description, setDescription] = useState(company ? company.description : '');
    const [website, setWebsite] = useState(company ? company.website : '');
    const [contactEmail, setContactEmail] = useState(company ? company.contact_email : '');
    const [phoneNumber, setPhoneNumber] = useState(company ? company.phone_number : '');
    const [address, setAddress] = useState(company ? company.address : '');
    const [industry, setIndustry] = useState(company ? company.industry : '');
    const [establishedDate, setEstablishedDate] = useState(company ? new Date(company.established_date) : null);
    const [logo, setLogo] = useState(null);



    useEffect(() => {
        if (company) {
            setName(company.name);
            setDescription(company.description);
            setWebsite(company.website);
            setContactEmail(company.contact_email);
            setPhoneNumber(company.phone_number);
            setAddress(company.address);
            setIndustry(company.industry);
            setEstablishedDate(company.established_date ? new Date(company.established_date) : null);
        } else {
            setName('');
            setDescription('');
            setWebsite('');
            setContactEmail('');
            setPhoneNumber('');
            setAddress('');
            setIndustry('');
            setEstablishedDate(null);
        }
    }, [company]);
    

    const handleSubmit = (e) => {
        e.preventDefault();
        
        // Formatear established_date
        let formattedEstablishedDate = null;
        if (establishedDate) {
            formattedEstablishedDate = moment(establishedDate).format("YYYY-MM-DD");
        }
        
        handleSave({
            id: company ? company.id : null,
            name,
            description,
            website,
            contact_email: contactEmail,
            phone_number: phoneNumber,
            address,
            industry,
            established_date: formattedEstablishedDate,
            logo,
        });
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleClose}>
                <Modal.Title>{company ? 'Editar Empresa' : 'Crear Empresa'}</Modal.Title>
            <Modal.Body style={{ marginTop: "4%" }}>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="companyName">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="companyLogo">
                        <Form.Label>Logo</Form.Label>
                        <Form.Control
                            type="file"
                            onChange={(e) => setLogo(e.target.files[0])}
                        />
                    </Form.Group>
                    <Form.Group controlId="companyWebsite">
                        <Form.Label>Sitio Web</Form.Label>
                        <Form.Control
                            type="text"
                            value={website}
                            onChange={(e) => setWebsite(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="companyDescription">
                        <Form.Label>Descripción</Form.Label>
                        <Form.Control
                            as="textarea"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="companyContactEmail">
                        <Form.Label>Email de Contacto</Form.Label>
                        <Form.Control
                            type="email"
                            value={contactEmail}
                            onChange={(e) => setContactEmail(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="companyPhoneNumber">
                        <Form.Label>Número de Teléfono</Form.Label>
                        <Form.Control
                            type="text"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="companyAddress">
                        <Form.Label>Dirección</Form.Label>
                        <Form.Control
                            type="text"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="companyIndustry">
                        <Form.Label>Industria</Form.Label>
                        <Form.Control
                            type="text"
                            value={industry}
                            onChange={(e) => setIndustry(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="companyEstablishedDate">
                        <Form.Label>Fecha de Creación</Form.Label>
                        <DatePicker
                            selected={establishedDate}
                            onChange={(date) => setEstablishedDate(date)}
                            dateFormat="yyyy-MM-dd"
                            className="form-control"
                            placeholderText="Selecciona una fecha"
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Guardar
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default CompanyModal;
