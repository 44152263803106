import React, { useState, useEffect } from 'react';
import './style.css'

const BookingModal = ({ isOpen, onClose, booking }) => {
  const [user, setUser] = useState('');
  const [property, setProperty] = useState('');

  useEffect(() => {
    if (booking) {
      setUser(booking.user);
      setProperty(booking.property);
    } else {
      setUser('');
      setProperty('');
    }
  }, [booking]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Aquí va la lógica para enviar los datos al servidor
    console.log({ user, property });
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="modal">
      <h2>{booking ? "Edit Booking" : "Create Booking"}</h2>
      <form onSubmit={handleSubmit}>
        <label>
          User:
          <input type="text" value={user} onChange={(e) => setUser(e.target.value)} />
        </label>
        <label>
          Property:
          <input type="text" value={property} onChange={(e) => setProperty(e.target.value)} />
        </label>
        <button type="submit">{booking ? "Update" : "Create"}</button>
        <button type="button" onClick={onClose}>Close</button>
      </form>
    </div>
  );
};

export default BookingModal;
