import React, { useEffect, useState } from "react";
import './FormDetailsModal.css'; // Importamos los estilos desde el archivo CSS
import ShippingDataService from "../../../services/shipping";
import TodoDataService from "../../../services/todos";
import { useSelector } from 'react-redux';

const FORM_FIELD_TYPES = [
  { value: 'text', label: 'Text' },
  { value: 'textarea', label: 'Textarea' },
  { value: 'select', label: 'Select' },
  { value: 'checkbox', label: 'Checkbox' },
  { value: 'radio', label: 'Radio' },
];

const FormDetailsModal = ({ formProduct, getResponseForField, onClose, updateFields }) => {
  const [selectedField, setSelectedField] = useState(null);
  const [showAddField, setShowAddField] = useState(false);
  const [newFieldLabel, setNewFieldLabel] = useState("");
  const [newFieldType, setNewFieldType] = useState("");
  const token = useSelector(state => state.authentication.token);
  const formId = formProduct._id;
  const [users, setUsers] = useState([])
  const [products, setProducts] = useState([]);
  const handleCheckboxChange = (field) => {
    setSelectedField(selectedField === field._id ? null : field._id);
  };

  const handleAddField = () => {
    setShowAddField(true);
  };

  const handleSaveField = async () => {
    if (!newFieldLabel || !newFieldType) {
      console.error('El campo de etiqueta o tipo está vacío');
      return;
    }

    const newFieldData = {
      label: newFieldLabel,
      field_type: newFieldType,
      form: formId
    };

    try {
      await ShippingDataService.createFormField(formId, newFieldData, token);
      console.log('Campo creado:', newFieldLabel);
      setNewFieldLabel("");
      setNewFieldType("");
      setShowAddField(false);
      updateFields(); // Llama a la función de actualización aquí
    } catch (error) {
      console.error('Error creando el campo:', error);
    }
  };
  const fetchUsers = () => {
    TodoDataService.getUserList(token)
      .then((response) => {
        setUsers(response.data);
        console.log("users",response.data)
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const fetchProducts = () => {
    ShippingDataService.getAllProducts()
      .then((response) => {
        setProducts(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

 
  useEffect(() => {
    fetchUsers();
    fetchProducts();
  }, [token]);

  const getUserNameById = (userId) => {
    const user = users.find((user) => user.id === userId);
    return user ? `${user.first_name} ${user.last_name}` : "Desconocido";
  };

  const getProductNameById = (productId) => {
    const product = products.find((product) => product._id === productId);
    return product ? product.name : "Producto no disponible";
  };
  const handleDeleteSelected = async (selectedField) => {
    if (!selectedField) return;
    try {
      await ShippingDataService.deleteFormField(formId, selectedField, token);
      console.log(`Campo con ID: ${selectedField} eliminado`);
      setSelectedField(null);
      updateFields(); // Llama a la función de actualización aquí
    } catch (error) {
      console.error('Error eliminando el campo del formulario:', error);
    }
  };
  console.log("formProduct.user",getUserNameById(formProduct.user))
  return (
    <div className="modal">
      <div className="modal-content">
        <button onClick={onClose} className="close-button">X</button>
        <h2>{formProduct.name}</h2>
        <div className="modal-scroll">
          <table className="details-table">
            <tbody>
              <tr>
                <td>Usuario:</td>
                <td>{getUserNameById(formProduct.user)}</td>
              </tr>
              <tr>
                <td>Producto:</td>
                <td>{formProduct.product.length > 0 ? getProductNameById(formProduct.product[0]) : "Sin producto"}</td>
              </tr>
              <tr>
                <td>Fecha de Creación:</td>
                <td>{new Date(formProduct.created_at).toLocaleDateString()}</td>
              </tr>
            </tbody>
          </table>

          <h3>Campos y Respuestas</h3>
          <div className="fields-container">
            {formProduct.fields.map((field) => (
              <div key={field._id} className="field-column">
                <div className="field-label">{field.label}</div>
                <div className="field-response">{getResponseForField(formProduct.responses, field._id)}</div>
                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    checked={selectedField === field._id}
                    onChange={() => handleCheckboxChange(field)}
                  />
                  <label>Seleccionar</label>
                </div>
                {/* <div>
                  <input
                    type={field.field_type}
                    value={getResponseForField(formProduct.responses, field._id)}
                    readOnly
                  />
                </div> */}
              </div>
            ))}
          </div>

          <button onClick={handleAddField}>Agregar Campo</button>
          {showAddField && (
            <div className="add-field">
              <input
                type="text"
                placeholder="Etiqueta del Campo"
                value={newFieldLabel}
                onChange={(e) => setNewFieldLabel(e.target.value)}
              />
              <select onChange={(e) => setNewFieldType(e.target.value)} value={newFieldType}>
                <option value="">Seleccionar Tipo de Campo</option>
                {FORM_FIELD_TYPES.map((type) => (
                  <option key={type.value} value={type.value}>{type.label}</option>
                ))}
              </select>
              <button onClick={handleSaveField}>Guardar Campo</button>
            </div>
          )}

          <button onClick={() => handleDeleteSelected(selectedField)}>Eliminar Campo Seleccionado</button>
        </div>
      </div>
      
    </div>
  );
};

export default FormDetailsModal;
