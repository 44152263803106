import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const JobPostingModal = ({
  show,
  handleClose,
  jobPosting,
  handleSave,
  jobCategories,
  experienceLevels,
  skills,
  benefits,
  companies,
  tags,
}) => {
  // Estados para los campos del formulario
  const [title, setTitle] = useState(jobPosting ? jobPosting.title : "");
  const [description, setDescription] = useState(
    jobPosting ? jobPosting.description : ""
  );
  const [category, setCategory] = useState(
    jobPosting ? jobPosting.category : ""
  );
  const [city, setCity] = useState(jobPosting ? jobPosting.city : "");
  const [country, setCountry] = useState(jobPosting ? jobPosting.country : "");
  const [region, setRegion] = useState(jobPosting ? jobPosting.region : "");
  const [modality, setModality] = useState(
    jobPosting ? jobPosting.modality : "Presencial"
  );
  const [employmentType, setEmploymentType] = useState(
    jobPosting ? jobPosting.employment_type : "Full-time"
  );
  const [experienceLevel, setExperienceLevel] = useState(
    jobPosting ? jobPosting.experienceLevel : ""
  );
  const [salaryRange, setSalaryRange] = useState(
    jobPosting ? jobPosting.salary_range : ""
  );
  const [selectedSkills, setSelectedSkills] = useState(
    jobPosting ? jobPosting.skills_required : []
  );
  const [selectedBenefits, setSelectedBenefits] = useState(
    jobPosting ? jobPosting.benefits : []
  );
  const [selectedCompany, setSelectedCompany] = useState(
    jobPosting ? jobPosting.companies : []
  );
  const [selectedTags, setSelectedTags] = useState(
    jobPosting ? jobPosting.tags : []
  );
  const [applicationDeadline, setApplicationDeadline] = useState(
    jobPosting ? jobPosting.application_deadline : ""
  );
  const [howToApply, setHowToApply] = useState(
    jobPosting ? jobPosting.how_to_apply : ""
  );

  // Manejo de selección para campos ManyToMany (skills, benefits, tags)
  const handleMultiSelectChange = (e, setter) => {
    const options = e.target.options;
    const selectedValues = [];
    for (let i = 0, len = options.length; i < len; i++) {
      if (options[i].selected) {
        selectedValues.push(options[i].value);
      }
    }
    setter(selectedValues);
  };

  // Función para manejar el envío del formulario
  const handleSubmit = (e) => {
    e.preventDefault();
    let formattedApplicationDeadline = null;
    if (applicationDeadline) {
      formattedApplicationDeadline =
        moment(applicationDeadline).format("YYYY-MM-DD");
    }
    console.log("formattedApplicationDeadline", formattedApplicationDeadline);

    handleSave({
      id: jobPosting ? jobPosting.id : null,
      title,
      description,
      category,
      city,
      country,
      region,
      modality,
      employment_type: employmentType,
      experience_level: experienceLevel,
      salary_range: salaryRange,
      skills_required: selectedSkills,
      benefits: selectedBenefits,
      tags: selectedTags,
      application_deadline: formattedApplicationDeadline,
      how_to_apply: howToApply,
      company: selectedCompany,
    });
    handleClose();
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      style={{ overflowY: "auto", width: "100%" }}
    >
      <Modal.Title>
        {jobPosting
          ? "Editar Publicación de Trabajo"
          : "Crear Publicación de Trabajo"}
      </Modal.Title>
      <Modal.Body style={{ maxHeight: "80vh", overflowY: "auto" }}>
        <Form onSubmit={handleSubmit}>
          {/* Selección de Empresa */}
          <Form.Group controlId="formJobCompany">
            <Form.Label>Empresa</Form.Label>
            <Form.Control
              as="select"
              value={selectedCompany}
              onChange={(e) => setSelectedCompany(e.target.value)}
              required
            >
              <option value="">Seleccionar Empresa...</option>
              {companies.map((company) => (
                <option key={company.id} value={company.id}>
                  {company.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          {/* Título */}
          <Form.Group controlId="formJobTitle">
            <Form.Label>Título</Form.Label>
            <Form.Control
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </Form.Group>

          {/* Descripción */}
          <Form.Group controlId="formJobDescription">
            <Form.Label>Descripción</Form.Label>
            <Form.Control
              as="textarea"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </Form.Group>

          {/* Categoría */}
          <Form.Group controlId="formJobCategory">
            <Form.Label>Categoría</Form.Label>
            <Form.Control
              as="select"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              required
            >
              <option value="">Seleccionar...</option>
              {jobCategories.map((cat) => (
                <option key={cat.id} value={cat.id}>
                  {cat.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          {/* Ciudad, País y Región */}
          <Form.Group controlId="formJobCity">
            <Form.Label>Ciudad</Form.Label>
            <Form.Control
              type="text"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="formJobCountry">
            <Form.Label>País</Form.Label>
            <Form.Control
              type="text"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="formJobRegion">
            <Form.Label>Región</Form.Label>
            <Form.Control
              type="text"
              value={region}
              onChange={(e) => setRegion(e.target.value)}
            />
          </Form.Group>

          {/* Modalidad */}
          <Form.Group controlId="formJobModality">
            <Form.Label>Modalidad</Form.Label>
            <Form.Control
              as="select"
              value={modality}
              onChange={(e) => setModality(e.target.value)}
              required
            >
              <option value="Presencial">Presencial</option>
              <option value="Remoto">Remoto</option>
              <option value="Híbrido">Híbrido</option>
            </Form.Control>
          </Form.Group>

          {/* Tipo de Empleo */}
          <Form.Group controlId="formJobEmploymentType">
            <Form.Label>Tipo de Empleo</Form.Label>
            <Form.Control
              as="select"
              value={employmentType}
              onChange={(e) => setEmploymentType(e.target.value)}
              required
            >
              <option value="Full-time">Tiempo Completo</option>
              <option value="Part-time">Medio tiempo</option>
              <option value="Contract">Contratista</option>
              <option value="Temporary">Temporal</option>
              <option value="Freelance">Freelance</option>
            </Form.Control>
          </Form.Group>

          {/* Nivel de Experiencia */}
          <Form.Group controlId="formJobExperienceLevel">
            <Form.Label>Nivel de Experiencia</Form.Label>
            <Form.Control
              as="select"
              value={experienceLevel}
              onChange={(e) => setExperienceLevel(e.target.value)}
              required
            >
              <option value="">Seleccionar...</option>
              {experienceLevels.map((level) => {
                // Traducción de los niveles de experiencia
                let translatedLevel;
                switch (level.level) {
                  case "Entry-level":
                    translatedLevel = "Principiante (menos de 1 año)";
                    break;
                  case "Mid-level":
                    translatedLevel = "Nivel medio (menos de 5 años)";
                    break;
                  case "Senior-level":
                    translatedLevel = "Nivel senior (más de 5 años)";
                    break;
                  case "Director":
                    translatedLevel = "Director";
                    break;
                  case "Executive":
                    translatedLevel = "Ejecutivo";
                    break;
                  default:
                    translatedLevel = level.level; // Por si acaso no está en la lista
                    break;
                }

                return (
                  <option key={level.id} value={level.id}>
                    {translatedLevel}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>

          {/* Rango Salarial */}
          <Form.Group controlId="formJobSalaryRange">
            <Form.Label>Rango Salarial</Form.Label>
            <Form.Control
              type="text"
              value={salaryRange}
              onChange={(e) => setSalaryRange(e.target.value)}
            />
          </Form.Group>

          {/* Skills */}
          <Form.Group controlId="formJobSkills">
            <Form.Label>Habilidades Requeridas</Form.Label>
            <Form.Control
              as="select"
              multiple
              value={selectedSkills}
              onChange={(e) => handleMultiSelectChange(e, setSelectedSkills)}
            >
              {skills.map((skill) => (
                <option key={skill.id} value={skill.id}>
                  {skill.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          {/* Beneficios */}
          <Form.Group controlId="formJobBenefits">
            <Form.Label>Beneficios</Form.Label>
            <Form.Control
              as="select"
              multiple
              value={selectedBenefits}
              onChange={(e) => handleMultiSelectChange(e, setSelectedBenefits)}
            >
              {benefits.map((benefit) => (
                <option key={benefit.id} value={benefit.id}>
                  {benefit.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          {/* Tags */}
          <Form.Group controlId="formJobTags">
            <Form.Label>Etiquetas</Form.Label>
            <Form.Control
              as="select"
              multiple
              value={selectedTags}
              onChange={(e) => handleMultiSelectChange(e, setSelectedTags)}
            >
              {tags.map((tag) => (
                <option key={tag.id} value={tag.id}>
                  {tag.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          {/* Fecha de Cierre */}
          <Form.Group controlId="formJobApplicationDeadline">
            <Form.Label>Fecha Límite de Aplicación</Form.Label>
            <DatePicker
              selected={applicationDeadline}
              onChange={(date) => setApplicationDeadline(date)}
              dateFormat="yyyy-MM-dd"
              className="form-control"
              placeholderText="Selecciona una fecha"
            />
          </Form.Group>
          {/* Cómo aplicar */}
          <Form.Group controlId="formJobHowToApply">
            <Form.Label>Cómo aplicar</Form.Label>
            <Form.Control
              as="textarea"
              value={howToApply}
              onChange={(e) => setHowToApply(e.target.value)}
              required
            />
          </Form.Group>

          <Button variant="primary" type="submit">
            {jobPosting ? "Actualizar" : "Crear"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default JobPostingModal;
