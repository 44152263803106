import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom"; // Importa Link
import "./Redireccion.css"; // Asegúrate de que la ruta del archivo CSS sea correcta

const Redireccion = () => {
    const [vibrateButton, setVibrateButton] = useState(false);
    const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
  const isTablet = useMediaQuery({ query: '(min-width: 481px) and (max-width: 768px)' });
  const isSmallDesktop = useMediaQuery({ query: '(min-width: 769px) and (max-width: 1024px)' });
  const isLargeDesktop = useMediaQuery({ query: '(min-width: 1025px)' });
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setVibrateButton(null);
        }, 200);
        return () => clearTimeout(timeoutId);
    }, [vibrateButton]);

    return (
        <div>
           <div className="buttonContainerStyle" style={{ width: "75%", marginLeft: "15%", marginTop: "2%" }}>

                        <div className="buttonGroup">
                    <Link to="/avisos_economicos">
                        <button className={`buttonStyle mobileButton ${vibrateButton === "allProducts" ? 'vibrating' : ''}`}  
                        onMouseEnter={(e) => {
                e.target.style.backgroundColor = 'green';
                e.target.style.color = 'white';
              }} 
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = 'red';
                e.target.style.color = 'white';
              }}>
                            16-AVISOS ECONÓMICOS
                        </button>
                    </Link>

                    <Link to="/directorio_comercial">
                        <button className={`buttonStyle mobileButton ${vibrateButton === "clasificados" ? 'vibrating' : ''}`}onMouseEnter={(e) => {
                e.target.style.backgroundColor = 'green';
                e.target.style.color = 'white';
              }} 
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = 'red';
                e.target.style.color = 'white';
              }}>
                            24-DIRECTORIO_COMERCIAL
                        </button>
                    </Link>

                    <Link to="/tienda">
                        <button className={`buttonStyle mobileButton ${vibrateButton === "products" ? 'vibrating' : ''}`}onMouseEnter={(e) => {
                e.target.style.backgroundColor = 'green';
                e.target.style.color = 'white';
              }} 
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = 'red';
                e.target.style.color = 'white';
              }}>
                            11-TIENDA EN LÍNEA
                        </button>
                    </Link>

                    <Link to="/bolsadeempleo">
                        <button className={`buttonStyle mobileButton ${vibrateButton === "bolsaEmpleo" ? 'vibrating' : ''}`}onMouseEnter={(e) => {
                e.target.style.backgroundColor = 'green';
                e.target.style.color = 'white';
              }} 
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = 'red';
                e.target.style.color = 'white';
              }}>
                            02-BOLSA DE EMPLEO
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Redireccion;
