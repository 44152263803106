import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table, Modal, Form } from "react-bootstrap";
import ProductDataService from "../../services/products";
import TodoDataService from "../../services/todos";
import moment from "moment";
import Papa from "papaparse";
import { Newspaper } from "@mui/icons-material";
import "./file_products.css";

const Directory = (props) => {
  const [products, setProducts] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [description, setDescription] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [userList, setUserList] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const token = useSelector((state) => state.authentication.token);
  const [characteristicsList, setCharacteristicsList] = useState([]);
  const [subproductList, setSubproductList] = useState([]);
  const [showCreateModalM, setShowCreateModalM] = useState(false);
  const [showCreateModalS, setShowCreateModalS] = useState(false);
  const [showEditModalM, setShowEditModalM] = useState(false);
  const [selectedCharacteristicM, setSelectedCharacteristicM] = useState(null);
  const [nameM, setNameM] = useState("");
  const [descriptionM, setDescriptionM] = useState("");
  const [nameS, setNameS] = useState("");
  const [phoneS, setPhoneS] = useState("");
  const [subproductModalShow, setSubproductModalShow] = useState(false);
  const [showCreateSubproductModal, setShowCreateSubproductModal] =
    useState(false);
  const [selectedSubproducts, setSelectedSubproducts] = useState([]);
  const [selectedSubproduct, setSelectedSubproduct] = useState(null);
  const [editSubproductName, setEditSubproductName] = useState("");
  const [editSubproductPhone, setEditSubproductPhone] = useState("");
  const [currentProductsPage, setCurrentProductsPage] = useState(1);
  const [currentCharacteristicsPage, setCurrentCharacteristicsPage] =
    useState(1);
  const [currentSubproductsPage, setCurrentSubproductsPage] = useState(1);
  const [isEditing, setIsEditing] = useState(false);
  const itemsPerPage = 4;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentProducts = products.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(products.length / itemsPerPage);
  const [csvFile, setCsvFile] = useState(null);
  const [showServicesModal, setShowServicesModal] = useState(false);
  const [selectedSubproductServices, setSelectedSubproductServices] = useState(
    []
  );
  const [showModal, setShowModal] = useState(false);
  const moment = require("moment");
  const [isFullScreen, setIsFullScreen] = useState(false); // Estado para pantalla completa

  const renderPaginationButtons = (currentPage, totalPages, setCurrentPage) => {
    const buttons = [];
    for (let i = 1; i <= totalPages; i++) {
      buttons.push(
        <Button
          key={i}
          variant={i === currentPage ? "primary" : "secondary"}
          onClick={() => setCurrentPage(i)}
        >
          {i}
        </Button>
      );
    }
    return buttons;
  };

  const renderSubproductsPaginationButtons = () => {
    const buttons = [];
    for (let i = 1; i <= Math.ceil(subproducts.length / itemsPerPage); i++) {
      buttons.push(
        <Button
          key={i}
          variant={i === currentPage ? "primary" : "secondary"}
          onClick={() => setCurrentPage(i)}
        >
          {i}
        </Button>
      );
    }
    return buttons;
  };

  const fetchCharacteristicsM = async () => {
    try {
      const response = await ProductDataService.getAllCharacteristics();
      setCharacteristicsList(response.data);
    } catch (error) {
      console.error("Error al recuperar características:", error);
    }
  };

  const fetchSubProduct = async () => {
    try {
      const response = await ProductDataService.getAllSubProduct();
      setSubproductList(response.data);
    } catch (error) {
      console.error("Error al recuperar subproductos:", error);
    }
  };

  const handleCloseEditModalM = () => {
    setShowEditModalM(false);
    setSelectedCharacteristicM(null);
    setNameM("");
    setDescriptionM("");
  };

  const handleCreateM = () => {
    setShowCreateModalM(true);
  };
  const handleCreateS = () => {
    setShowCreateModalS(true);
  };

  const handleCloseCreateModalM = () => {
    setShowCreateModalM(false);
    setNameM("");
    setDescriptionM("");
  };

  const handleCloseCreateModalS = () => {
    setShowCreateModalS(false);
    setSubproductName("");
    setSubproductPhone("");
    setSubproductEmail("");
    setSubproductAddress("");
    setSubproductAddressmap("");
    setSubproductUrl("");
    setSubproductServices("");
  };
  const handleEditM = (characteristic) => {
    setSelectedCharacteristicM(characteristic);
    setNameM(characteristic.name);
    setDescriptionM(characteristic.description);
    setShowEditModalM(true);
  };

  const handleDeleteM = async (id) => {
    try {
      await ProductDataService.deleteCharacteristic(id);
      fetchCharacteristicsM();
    } catch (error) {
      console.error("Error al eliminar característica:", error);
    }
  };

  const handleSubmitCreateM = async () => {
    const newCharacteristic = { name: nameM, description: descriptionM };
    try {
      await ProductDataService.createCharacteristic(newCharacteristic, token); // Pasa el token a la función
      setShowCreateModalM(false);
      fetchCharacteristicsM();
    } catch (error) {
      console.error("Error al crear característica:", error);
    }
  };

  const handleSubmitEditM = async () => {
    if (!selectedCharacteristicM) {
      console.error("Selected characteristic is null or undefined");
      return;
    }

    const updatedCharacteristic = { name: nameM, description: descriptionM };
    try {
      await ProductDataService.updateCharacteristic(
        selectedCharacteristicM.id,
        updatedCharacteristic
      );
      setShowEditModalM(false);
      fetchCharacteristicsM();
    } catch (error) {
      console.error("Error al actualizar la característica:", error);
    }
  };

  useEffect(() => {
    fetchCharacteristicsM();
  }, []);

  useEffect(() => {
    fetchSubProduct();
  }, []);

  const [editData, setEditData] = useState({
    id: null,
    name: "",
    description: "",
    name_url: "",
    characteristics: [],
    subproducts: [],
  });
  // const [showEditModal, setShowEditModal] = useState(false);
  const handleEditNameUrlChange = (e) => {
    const newNameUrl = e.target.value;
    setEditData({ ...editData, name_url: newNameUrl });
  };

  const getUserList = () => {
    if (props.token) {
      TodoDataService.getUserList(props.token)
        .then((response) => {
          setUserList(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  useEffect(() => {
    getUserList();
  }, [props.token]);

  useEffect(() => {
    if (userList.length > 0 && props.user) {
      const currentUser = userList.find((user) => user.email === props.user);
      setCurrentUser(currentUser);
      localStorage.setItem("currentUser", JSON.stringify(currentUser));
    }
  }, [userList, props.user]);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await ProductDataService.getAll();
      setProducts(response.data);

      // Update the subproducts state with all subproducts
      const allSubproducts = response.data.reduce((acc, product) => {
        if (product.subproducts && product.subproducts.length > 0) {
          acc.push(...product.subproducts);
        }
        return acc;
      }, []);

      setSubproducts(allSubproducts);
      console.log("All Subproducts:", allSubproducts);
    } catch (error) {
      console.error("Error al recuperar productos:", error);
    }
  };

  const [file, setFile] = useState(null);
  const [file1, setFile1] = useState(null);
  const [subproducts, setSubproducts] = useState([]);
  const [subproductName, setSubproductName] = useState("");
  const [subproductPhone, setSubproductPhone] = useState("");
  const [subproductEmail, setSubproductEmail] = useState("");
  const [subproductAddress, setSubproductAddress] = useState("");
  const [subproductAddressmap, setSubproductAddressmap] = useState("");

  const [subproductUrl, setSubproductUrl] = useState("");
  const [subproductServices, setSubproductServices] = useState("");
  const [subproductImage, setSubproductImage] = useState("");
  const [nameUrl, setNameUrl] = useState("");

  const [subproductDescription, setSubproductDescription] = useState("");
  const [subproductCountry, setSubproductCountry] = useState("");
  const [subproductProvince, setSubproductProvince] = useState("");
  const [subproductCanton, setSubproductCanton] = useState("");
  const [subproductDistrito, setSubproductDistrito] = useState("");
  const [subproductContactName, setSubproductContactName] = useState("");
  const [subproductPhoneNumber, setSubproductPhoneNumber] = useState("");
  const [subproductComercialActivity, setSubproductComercialActivity] =
    useState("");
  const [subproductPayMethod, setSubproductPayMethod] = useState("");
  const [subproductConstitucion, setSubproductConstitucion] = useState("");
  const [subproductLogo, setSubproductLogo] = useState(null);
  const [subproductFile, setSubproductFile] = useState(null);
  const [combos, setCombos] = useState([]);
  const [services, setServices] = useState([]);
  const [selectedComboIds, setSelectedComboIds] = useState([]);
  const [newComboData, setNewComboData] = useState({
    name: "",
    description: "",
    price: "",
    selectedServiceIds: [],
  });
  useEffect(() => {
    fetchCombos();
    fetchServices();
  }, []);

  const fetchCombos = async () => {
    try {
      const response = await ProductDataService.getAllCombos();
      console.log("Combos iniciales", response.data);
      setCombos(response.data);
    } catch (error) {
      console.error("Error al recuperar características:", error);
    }
  };
  const fetchServices = async () => {
    try {
      const response = await ProductDataService.getAllServices();
      console.log("Servicios iniciales", response.data);
      setServices(response.data);
    } catch (error) {
      console.error("Error al recuperar servicios:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await ProductDataService.deleteProduct(id, token);
      // Fetch updated products list
      fetchProducts();
    } catch (error) {
      console.error("Error al eliminar el producto:", error);
    }
  };

  const handleDeleteS = async (id) => {
    try {
      await ProductDataService.deleteSubProduct(id, token);
      // Fetch updated products list
      fetchProducts();
    } catch (error) {
      console.error("Error al eliminar el producto:", error);
    }
  };

  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
  };

  const handleSaveSubproducts = async () => {
    const newSubProduct = new FormData(); // Crea un objeto FormData

    const constitucion = subproductConstitucion
      ? moment(subproductConstitucion).format("YYYY-MM-DD")
      : "";

    console.log("constitucion:", constitucion);

    // Agrega los campos de texto al formulario
    newSubProduct.append("name", subproductName);
    newSubProduct.append("phone", subproductPhone);
    newSubProduct.append("email", subproductEmail);
    newSubProduct.append("address", subproductAddress);
    newSubProduct.append("addressmap", subproductAddressmap);
    newSubProduct.append("url", subproductUrl);
    newSubProduct.append("description", subproductDescription);
    newSubProduct.append("country", subproductCountry);
    newSubProduct.append("province", subproductProvince);
    newSubProduct.append("contact_name", subproductContactName);
    newSubProduct.append("phone_number", subproductPhoneNumber);
    newSubProduct.append("constitucion", constitucion);
    newSubProduct.append("comercial_activity", subproductComercialActivity);
    newSubProduct.append("pay_method", subproductPayMethod);

    // Agrega el archivo de imagen (logo) si existe
    if (subproductLogo) {
      newSubProduct.append("logo", subproductLogo);
    }

    // Agrega el archivo (file) si existe
    if (subproductFile) {
      newSubProduct.append("file", subproductFile);
    }

    // Agrega los productos seleccionados
    selectedProducts.forEach((product) => {
      newSubProduct.append("products", product.id);
    });

    try {
      console.log("Nuevo producto");
      newSubProduct.forEach((value, key) => {
        console.log(`${key}: ${value}`);
      });
      await ProductDataService.createSubProduct(newSubProduct, token);
      setShowCreateModalS(false);
      fetchSubProduct();
    } catch (error) {
      console.error("Error creating subproducto:", error);
    }

    setSubproductModalShow(false);
    setEditingSubproduct(false);
    setEditSubproductData({
      id: null,
      name: "",
      phone: "",
      email: "",
      address: "",
      addressmap: "",
      url: "",
      services: "",
      image: "",
    });
  };

  const handleCsvUpload = async () => {
    if (!csvFile) {
      console.error("No se ha seleccionado ningún archivo CSV.");
      return;
    }

    try {
      Papa.parse(csvFile, {
        header: true,
        delimiter: ";",
        encoding: "UTF-8",
        complete: async function (result) {
          const subproductsData = result.data;

          // Mostrar nombres de las columnas
          console.log(
            "Nombres de las columnas:",
            Object.keys(subproductsData[0])
          );

          for (const [index, subproduct] of subproductsData.entries()) {
            // Limpiar campos que puedan tener saltos de línea
            const cleanedTelefono = subproduct.Telefono
              ? subproduct.Telefono.replace(/[\r\n]/g, "")
              : "";
            const cleanedDireccion = subproduct.Direccion
              ? subproduct.Direccion.replace(/[\r\n]/g, "")
              : "";

            // Mostrar contenido de cada columna
            console.log(`Fila ${index + 1}:`);
            Object.entries(subproduct).forEach(([columnName, columnValue]) => {
              console.log(`${columnName}: ${columnValue}`);
            });

            // Validar que los campos requeridos estén presentes en cada fila del CSV

            try {
              // Fetch all products and handle any errors
              const constitucion = subproduct.Constitucion
                ? moment(subproduct.Constitucion, "DD/MM/YYYY").format(
                    "YYYY-MM-DD"
                  )
                : "";
              console.log("Fecha de constitucion servidor:", constitucion);

              const allProductsResponse = await ProductDataService.getAll();
              const allProducts = allProductsResponse.data;
              console.log("Todos los productos", allProducts);

              // Check if allProducts is an array before using find
              if (Array.isArray(allProducts)) {
                const existingProduct = allProducts.find(
                  (product) => product.name === subproduct.Producto
                );

                console.log("Productos de existingProduct ", existingProduct);
                // Validar que se encuentre el producto asociado en la base de datos
                if (!existingProduct) {
                  console.error(
                    "El producto asociado no se encuentra en la base de datos."
                  );
                  continue; // Pasar a la siguiente fila si no se encuentra el producto
                }

                const formattedData = new FormData();

                // Agregar los campos de texto al formulario
                formattedData.append("name", subproduct.Nombre || "");
                formattedData.append("phone", subproduct.Telefono || "");
                formattedData.append("email", subproduct.Correo || "");
                formattedData.append("address", subproduct.Direccion || "");
                formattedData.append("url", subproduct.Url || "");
                formattedData.append(
                  "products",
                  existingProduct ? [existingProduct.id] : ""
                );

                // Agregar campos adicionales
                formattedData.append("country", subproduct.Pais || "");
                formattedData.append("province", subproduct.Provincia || "");
                formattedData.append("canton", subproduct.canton || "");
                formattedData.append("distrito", subproduct.distrito || "");
                formattedData.append("contact_name", subproduct.Contacto || "");
                formattedData.append(
                  "phone_number",
                  subproduct.TelefonoNumero || ""
                );
                formattedData.append(
                  "comercial_activity",
                  subproduct.ActividadComercial || ""
                );
                formattedData.append("pay_method", subproduct.MetodoPago || "");
                formattedData.append("constitucion", constitucion);
                formattedData.append("constitucion", constitucion);

                // Agregar el archivo de imagen (logo) si existe
                if (subproduct.Logo) {
                  formattedData.append("logo", subproduct.Logo);
                }

                // Agregar el archivo (file) si existe
                if (subproduct.File) {
                  formattedData.append("file", subproduct.File);
                }

                console.log("Archivos para servidor", formattedData);

                try {
                  await ProductDataService.createSubProduct(
                    formattedData,
                    token
                  );
                } catch (error) {
                  console.error(
                    "Error al enviar los datos formateados al servidor:",
                    error
                  );
                }
              } else {
                console.error("Error: Product data is not an array.");
              }
            } catch (error) {
              console.error("Error al obtener datos de productos:", error);
            }
          }

          fetchSubProduct();
        },
      });
    } catch (error) {
      console.error("Error al cargar el archivo CSV:", error);
    }
  };

  const handleSubproductsSelection = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions, (option) =>
      JSON.parse(option.value)
    );

    setSelectedSubproducts(selectedOptions);
  };

  const handleDoubleClick = () => {
    setSelectedSubproducts([]);
  };

  const [selectedProducts, setSelectedProducts] = useState([]);

  const handleProductsSelection = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions, (option) =>
      JSON.parse(option.value)
    );
    setSelectedProducts(selectedOptions);
  };

  const handleProductsDoubleClick = () => {
    setSelectedProducts([]);
  };

  const handleEditSubproduct = (subproduct) => {
    setEditingSubproduct(true);
    setIsEditing(true);
    setEditSubproductData({
      id: subproduct.id,
      name: subproduct.name,
      phone: subproduct.phone,
      email: subproduct.email,
      address: subproduct.address,
      url: subproduct.url,
      services: subproduct.services,
      addressmap: subproduct.addressmap,
      image: subproduct.image,
    });
    setShowEditModalS(true);
  };

  const handleCloseEditModalS = () => {
    setEditingSubproduct(false);
    setEditSubproductData({
      id: null,
      name: "",
      phone: "",
      email: "",
      address: "",
      url: "",
      services: "",
      image: "",
    });
    setShowEditModalS(false);
  };

  const [editSubproductData, setEditSubproductData] = useState({
    id: null,
    name: "",
    phone: "",
    email: "",
    address: "",
    url: "",
    services: "",
    image: "",
  });

  const handleEditStart = (subproduct) => {
    setIsEditing(true);
    setEditSubproductData({
      name: subproduct.name,
      phone: subproduct.phone,
      email: subproduct.email,
      address: subproduct.address,
      addressmap: subproduct.addressmap,
      url: subproduct.url,
      products: selectedProducts.map((product) => product.id),
    });
  };

  const [editingSubproduct, setEditingSubproduct] = useState(false);
  const [showEditModalS, setShowEditModalS] = useState(false);

  const handleEditSubproducts = async () => {
    // Crear un objeto FormData para la edición
    const editedSubProduct = new FormData();

    // Agregar los campos de texto al formulario
    editedSubProduct.append("name", editSubproductData.name);
    editedSubProduct.append("phone", editSubproductData.phone);
    editedSubProduct.append("email", editSubproductData.email);
    editedSubProduct.append("address", editSubproductData.address);
    editedSubProduct.append("url", editSubproductData.url);
    editedSubProduct.append("country", editSubproductData.country);
    editedSubProduct.append("province", editSubproductData.province);
    editedSubProduct.append("canton", editSubproductData.canton);
    editedSubProduct.append("canton", editSubproductData.distrito);
    editedSubProduct.append("contact_name", editSubproductData.contact_name);
    editedSubProduct.append("phone_number", editSubproductData.phone_number);
    editedSubProduct.append(
      "comercial_activity",
      editSubproductData.comercial_activity
    );
    editedSubProduct.append("pay_method", editSubproductData.pay_method);

    // Agregar los productos seleccionados
    selectedProducts.forEach((product) => {
      editedSubProduct.append("products", product.id);
    });

    try {
      // Agregar el archivo de imagen (logo) si existe
      if (editSubproductData.logo) {
        editedSubProduct.append("logo", editSubproductData.logo);
      }

      // Agregar el archivo (file) si existe
      if (editSubproductData.file) {
        editedSubProduct.append("file", editSubproductData.file);
      }
      console.log("datos editados antes de enviar:", editSubproductData);
      // Realizar la solicitud de actualización con el objeto FormData
      await ProductDataService.updateSubProduct(
        editSubproductData.id,
        editedSubProduct,
        token
      );
      fetchProducts();
      // Actualizar la lista de subproductos
      fetchSubProduct();
    } catch (error) {
      console.error("Error updating subproduct:", error);
    }

    // Restablecer los estados
    setShowEditModalS(false);
    setSubproductModalShow(false);
    setEditingSubproduct(false);
    setEditSubproductData({
      id: null,
      name: "",
      phone: "",
      email: "",
      address: "",
      url: "",
      country: "",
      province: "",
      canton: "",
      distrito: "",
      contact_name: "",
      phone_number: "",
      comercial_activity: "",
      pay_method: "",
    });
  };

  const [selectedSubproductData, setSelectedSubproductData] = useState({
    id: null,
    name: "",
    phone: "",
    email: "",
    address: "",
    url: "",
    services: "",
  });

  const handleShowServicesModal = async (subproductId) => {
    setSelectedSubproduct(subproductId);
    setShowServicesModal(true);

    try {
      // Recuperar los servicios solo para el subproducto específico
      const responseServices =
        await ProductDataService.getAllServicesForSubProduct(subproductId.id);
      setSelectedSubproductServices(responseServices.data);

      // Recuperar los combos solo para el subproducto específico (si es necesario)
      const responseCombos = await ProductDataService.getAllCombosForSubProduct(
        subproductId.id
      );
      setSelectedComboIds(responseCombos.data.map((combo) => combo.id));

      setShowServicesModal(true);
    } catch (error) {
      console.error("Error fetching services or combos:", error);
    }
  };

  const handleShowCombosModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleServiceCheckboxChange = (serviceId) => {
    const selectedServiceIds = [...newComboData.selectedServiceIds];

    if (selectedServiceIds.includes(serviceId)) {
      // Si el servicio ya está seleccionado, quítalo
      const index = selectedServiceIds.indexOf(serviceId);
      selectedServiceIds.splice(index, 1);
    } else {
      // Si el servicio no está seleccionado, agrégalo
      selectedServiceIds.push(serviceId);
    }

    setNewComboData({ ...newComboData, selectedServiceIds });
  };

  const handleCheckboxChange = (comboId) => {
    // Toggle selected state for the combo
    setSelectedComboIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(comboId)) {
        return prevSelectedIds.filter((id) => id !== comboId);
      } else {
        return [...prevSelectedIds, comboId];
      }
    });
  };

  const handleDeleteSelectedCombos = async () => {
    try {
      // Iterar sobre los combos seleccionados y eliminarlos
      for (const comboId of selectedComboIds) {
        await ProductDataService.deleteCombo(comboId, token);
      }
      // Limpiar los combos seleccionados después de eliminar
      setSelectedComboIds([]);
      fetchCombos();
    } catch (error) {
      console.error("Error al eliminar combos:", error);
    }
  };
  // Filtrar servicios y combos según el subproducto seleccionado
  const filteredServices = selectedSubproductServices.filter(
    (service) => service.subproduct === selectedSubproduct.id // Asegúrate de tener esta propiedad
  );

  const filteredCombos = combos.filter((combo) =>
    combo.services.some((service) =>
      filteredServices.map((s) => s.id).includes(service.id)
    )
  );

  const handleCreateCombo = async () => {
    console.log("Selected Service IDs:", newComboData.selectedServiceIds); // Agregar este log
    console.log("combo enviado", newComboData);

    try {
      const response = await ProductDataService.createCombo(
        {
          ...newComboData,
          selectedServices: newComboData.selectedServiceIds.map(
            (serviceId) => ({ id: serviceId })
          ),
        },
        token
      );

      console.log("Create Combo Response:", response.data);

      fetchCombos();
      handleCloseModal();
    } catch (error) {
      console.error("Error creating combo:", error);
    }
  };

  const resetModalData = () => {
    setSelectedSubproductServices([]);
    setSelectedSubproduct(null);
  };

  const [newServiceName, setNewServiceName] = useState(""); // Estado para el nombre del nuevo servicio
  const [newServiceDescription, setNewServiceDescription] = useState(""); // Estado para la descripción del nuevo servicio
  const [newServicePrice, setNewServicePrice] = useState(""); // Estado para el precio del nuevo servicio

  const handleAddService = async () => {
    try {
      // Verificar si se ha seleccionado un subproducto
      if (!selectedSubproduct) {
        console.error(
          "No se ha seleccionado un subproducto para agregar el servicio."
        );
        return;
      }

      // Validar que los campos requeridos no estén vacíos
      if (
        !newServiceName.trim() ||
        !newServiceDescription.trim() ||
        !newServicePrice.trim()
      ) {
        console.error("Nombre, descripción y precio son campos obligatorios.");
        return;
      }

      // Crear un nuevo servicio con los datos proporcionados
      const newService = {
        name: newServiceName,
        description: newServiceDescription,
        price: parseFloat(newServicePrice), // Convertir el precio a número decimal
        subproduct: selectedSubproduct.id,
      };
      console.log("servicios", newService);
      // Enviar la solicitud para agregar el nuevo servicio al subproducto seleccionado
      const response = await ProductDataService.createServiceForSubProduct(
        selectedSubproduct.id,
        newService
      );

      // Actualizar la lista de servicios del subproducto
      setSelectedSubproductServices([
        ...selectedSubproductServices,
        response.data,
      ]);

      // Limpiar los campos de entrada
      setNewServiceName("");
      setNewServiceDescription("");
      setNewServicePrice("");
    } catch (error) {
      console.error("Error al agregar el servicio:", error);
    }
  };

  const handleDeleteService = async (serviceId) => {
    try {
      // Verificar si se ha seleccionado un subproducto
      if (!selectedSubproduct) {
        console.error(
          "No se ha seleccionado un subproducto para eliminar el servicio."
        );
        return;
      }

      // Enviar la solicitud para eliminar el servicio con el ID proporcionado
      await ProductDataService.deleteServiceForSubProduct(
        selectedSubproduct.id,
        serviceId
      );

      // Actualizar la lista de servicios del subproducto eliminando el servicio correspondiente
      const updatedServices = selectedSubproductServices.filter(
        (service) => service.id !== serviceId
      );
      setSelectedSubproductServices(updatedServices);
    } catch (error) {
      console.error("Error al eliminar el servicio:", error);
    }
  };

  const handleSaveSubproductData = async () => {
    try {
      const updatedSubproductData = {
        ...selectedSubproductData,
        services: selectedSubproductServices,
        combos: selectedComboIds, // Agregamos los combos seleccionados
      };

      await ProductDataService.updateSubproduct(
        selectedSubproductData.id,
        updatedSubproductData
      );
      console.log("Subproducto actualizado con servicios y combos.");
    } catch (error) {
      console.error("Error al actualizar el subproducto:", error);
    }
  };

  const [searchTerm, setSearchTerm] = useState(""); // Estado para el término de búsqueda
  const [filteredSubproducts, setFilteredSubproducts] = useState([]); // Estado para los subproductos filtrados

  useEffect(() => {
    // Filtrar subproductos cuando cambia el término de búsqueda o la lista de subproductos
    const results = subproducts.filter((subproduct) =>
      subproduct.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredSubproducts(results);
  }, [searchTerm, subproducts]); // Dependencias: se ejecuta cuando cambia searchTerm o subproducts

  return (
    <div>
      <br />
      <br />
      <br />
      <br />
      <br />

      <br />
      <br />
      <Button variant="primary" onClick={handleCreateS}>
        Crear subproducto
      </Button>
      <Form.Group controlId="csvFile">
        <Form.Label>Subir archivo CSV para subproductos</Form.Label>
        <Form.Control
          type="file"
          accept=".csv"
          onChange={(e) => setCsvFile(e.target.files[0])}
        />
      </Form.Group>
      <Button onClick={handleCsvUpload}>Subir CSV</Button>

      <div style={{ display: "flex", justifyContent: "flex-start" }}>
        <input
          type="text"
          placeholder="Buscar cliente en Directorio..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)} // Actualizar el estado del término de búsqueda
          style={{
            border: "2px solid red", // Contorno rojo
            borderRadius: "8px", // Orillas redondeadas
            boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.5)", // Sombreado negro
            padding: "10px", // Espaciado interno
            width: "240px", // Ajusta el tamaño según sea necesario
            fontSize: "16px", // Tamaño de fuente
            outline: "none", // Quitar el contorno por defecto al enfocar
          }}
        />
      </div>
      <div style={{ overflowX: "auto" }}>
        <Table striped bordered hover style={{ width: "100%" }}>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Telefono</th>
              <th>Email</th>
              <th>Direccion</th>
              <th>Pagina web</th>
              <th>Direccion Maps</th>
            </tr>
          </thead>
          <tbody>
            {filteredSubproducts
              .filter((subproduct, index, self) => {
                // Filtrar para que solo se muestren elementos con nombres y correos únicos
                return (
                  self.findIndex(
                    (s) =>
                      s.name === subproduct.name && s.email === subproduct.email
                  ) === index
                );
              })
              .slice(
                (currentSubproductsPage - 1) * itemsPerPage,
                currentSubproductsPage * itemsPerPage
              )
              .map((subproduct) => (
                <tr key={subproduct.id}>
                  <td>
                    <button onClick={() => handleShowServicesModal(subproduct)}>
                      {subproduct.name}
                    </button>
                  </td>
                  <td>{subproduct.phone}</td>
                  <td>{subproduct.email}</td>
                  <td>{subproduct.address}</td>
                  <td>{subproduct.url}</td>
                  <td>{subproduct.addressmap}</td>
                  <td>
                    <Button
                      variant="info"
                      onClick={() => handleEditSubproduct(subproduct)}
                    >
                      Editar
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => handleDeleteS(subproduct.id)}
                    >
                      Eliminar
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          variant="secondary"
          onClick={() => setCurrentSubproductsPage(currentSubproductsPage - 1)}
          disabled={currentSubproductsPage === 1}
        >
          Página Anterior
        </Button>{" "}
        {/* {renderPaginationButtons(currentSubproductsPage, Math.ceil(subproducts.length / itemsPerPage), setCurrentSubproductsPage)} */}
        <Button
          variant="secondary"
          onClick={() => setCurrentSubproductsPage(currentSubproductsPage + 1)}
          disabled={
            currentSubproductsPage ===
            Math.ceil(subproducts.length / itemsPerPage)
          }
        >
          Página Siguiente
        </Button>
      </div>
      {/* Modal para mostrar los servicios */}
      <Modal
        show={showServicesModal}
        onHide={() => {
          setShowServicesModal(false);
          resetModalData(); // Reiniciar los datos del modal al cerrarlo
        }}
        dialogClassName="modal-lg"
        style={{ overflowY: "auto" }}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Title>
          Servicios de {selectedSubproduct && selectedSubproduct.name}
        </Modal.Title>
        {/* </Modal.Header> */}
        <Modal.Body style={{ maxHeight: "70vh", overflowY: "auto" }}>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Codigo</th>
                <th>Nombre</th>
                <th>Descripción</th>
                <th>Precio</th>
                <th>Duración</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {selectedSubproductServices.map((service) => (
                <tr key={service.id}>
                  <td>{service.code}</td>
                  <td>{service.name}</td>
                  <td>{service.description}</td>
                  <td>{`$${Number(service.price).toFixed(2)}`}</td>

                  <td>{service.duration}</td>
                  <td>
                    <button
                      onClick={() => handleDeleteService(service.id)}
                      className="btn btn-danger"
                    >
                      Eliminar
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <h4 className="mt-4">Agregar Nuevo Servicio</h4>
          <div className="mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Nombre del servicio"
              value={newServiceName}
              onChange={(e) => setNewServiceName(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Descripción del servicio"
              value={newServiceDescription}
              onChange={(e) => setNewServiceDescription(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <input
              type="number"
              className="form-control"
              placeholder="Precio del servicio"
              value={newServicePrice}
              onChange={(e) => setNewServicePrice(e.target.value)}
            />
          </div>
          <button onClick={handleAddService} className="btn btn-primary">
            Agregar Servicio
          </button>

          <h4 className="mt-4">Combos Disponibles</h4>
          <ul className="list-unstyled">
            {filteredCombos.map((combo) => (
              <li key={combo.id} className="mb-2">
                <label>
                  <input
                    type="checkbox"
                    checked={selectedComboIds.includes(combo.id)}
                    onChange={() => handleCheckboxChange(combo.id)}
                  />
                  {combo.name}
                </label>
              </li>
            ))}
          </ul>
          <button
            onClick={handleDeleteSelectedCombos}
            disabled={selectedComboIds.length === 0}
            className="btn btn-danger mb-3"
          >
            Eliminar Combos Seleccionados
          </button>

          <h4 className="mt-4">Agregar Nuevo Combo</h4>
          <input
            type="text"
            className="form-control mb-2"
            placeholder="Nombre del combo"
            value={newComboData.name}
            onChange={(e) =>
              setNewComboData({ ...newComboData, name: e.target.value })
            }
          />
          <input
            type="text"
            className="form-control mb-2"
            placeholder="Descripción"
            value={newComboData.description}
            onChange={(e) =>
              setNewComboData({ ...newComboData, description: e.target.value })
            }
          />
          <input
            type="number"
            className="form-control mb-2"
            placeholder="Precio"
            value={newComboData.price}
            onChange={(e) =>
              setNewComboData({ ...newComboData, price: e.target.value })
            }
          />

          <h5 className="mt-4">Seleccionar Servicios para el Combo</h5>
          {selectedSubproductServices.map((service) => (
            <div key={service.id} className="mb-2">
              <input
                type="checkbox"
                checked={newComboData.selectedServiceIds.includes(service.id)}
                onChange={() => handleServiceCheckboxChange(service.id)}
              />
              <label>{service.name}</label>
            </div>
          ))}
          <button onClick={handleCreateCombo} className="btn btn-success">
            Crear Combo
          </button>
        </Modal.Body>
      </Modal>

      <Modal
        show={showEditModalS}
        onHide={handleCloseEditModalS}
        style={{ overflowY: "auto" }}
      >
        <Modal.Title>Editar Subproducto</Modal.Title>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formSubProductName">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                value={editSubproductData.name || ""}
                onChange={(e) =>
                  setEditSubproductData({
                    ...editSubproductData,
                    name: e.target.value,
                  })
                }
              />
            </Form.Group>

            <Form.Group controlId="formSubProductPhone">
              <Form.Label>Teléfono</Form.Label>
              <Form.Control
                type="text"
                value={editSubproductData.phone || ""}
                onChange={(e) =>
                  setEditSubproductData({
                    ...editSubproductData,
                    phone: e.target.value,
                  })
                }
              />
            </Form.Group>

            <Form.Group controlId="formSubProductEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={editSubproductData.email || ""}
                onChange={(e) =>
                  setEditSubproductData({
                    ...editSubproductData,
                    email: e.target.value,
                  })
                }
              />
            </Form.Group>

            <Form.Group controlId="formSubProductAddress">
              <Form.Label>Dirección</Form.Label>
              <Form.Control
                type="text"
                value={editSubproductData.address || ""}
                onChange={(e) =>
                  setEditSubproductData({
                    ...editSubproductData,
                    address: e.target.value,
                  })
                }
              />
            </Form.Group>

            <Form.Group controlId="formSubProductUrl">
              <Form.Label>Página web</Form.Label>
              <Form.Control
                type="text"
                value={editSubproductData.url || ""}
                onChange={(e) =>
                  setEditSubproductData({
                    ...editSubproductData,
                    url: e.target.value,
                  })
                }
              />
            </Form.Group>

            <Form.Group controlId="formSubProductAddressmap">
              <Form.Label>Dirección Maps</Form.Label>
              <Form.Control
                type="text"
                value={editSubproductData.addressmap || ""}
                onChange={(e) =>
                  setEditSubproductData({
                    ...editSubproductData,
                    addressmap: e.target.value,
                  })
                }
              />
            </Form.Group>

            {/* Continuar con el resto de los campos... */}
            <Form.Group>
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                value={editSubproductData.description || ""}
                onChange={(e) =>
                  setEditSubproductData({
                    ...editSubproductData,
                    description: e.target.value,
                  })
                }
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>País</Form.Label>
              <Form.Control
                type="text"
                value={editSubproductData.country || ""}
                onChange={(e) =>
                  setEditSubproductData({
                    ...editSubproductData,
                    country: e.target.value,
                  })
                }
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Provincia</Form.Label>
              <Form.Control
                type="text"
                value={editSubproductData.province || ""}
                onChange={(e) =>
                  setEditSubproductData({
                    ...editSubproductData,
                    province: e.target.value,
                  })
                }
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Canton</Form.Label>
              <Form.Control
                type="text"
                value={editSubproductData.canton || ""}
                onChange={(e) =>
                  setEditSubproductData({
                    ...editSubproductData,
                    canton: e.target.value,
                  })
                }
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Distrito</Form.Label>
              <Form.Control
                type="text"
                value={editSubproductData.distrito || ""}
                onChange={(e) =>
                  setEditSubproductData({
                    ...editSubproductData,
                    distrito: e.target.value,
                  })
                }
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Nombre de Contacto</Form.Label>
              <Form.Control
                type="text"
                value={editSubproductData.contact_name || ""}
                onChange={(e) =>
                  setEditSubproductData({
                    ...editSubproductData,
                    contact_name: e.target.value,
                  })
                }
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Número de Teléfono</Form.Label>
              <Form.Control
                type="text"
                value={editSubproductData.phone_number || ""}
                onChange={(e) =>
                  setEditSubproductData({
                    ...editSubproductData,
                    phone_number: e.target.value,
                  })
                }
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Actividad Comercial</Form.Label>
              <Form.Control
                type="text"
                value={editSubproductData.comercial_activity || ""}
                onChange={(e) =>
                  setEditSubproductData({
                    ...editSubproductData,
                    comercial_activity: e.target.value,
                  })
                }
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Método de Pago</Form.Label>
              <Form.Control
                type="text"
                value={editSubproductData.pay_method || ""}
                onChange={(e) =>
                  setEditSubproductData({
                    ...editSubproductData,
                    pay_method: e.target.value,
                  })
                }
              />
            </Form.Group>

            {/* Agregar archivos */}
            <Form.Group>
              <Form.Label>Logo</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={(e) =>
                  setEditSubproductData({
                    ...editSubproductData,
                    logo: e.target.files[0],
                  })
                }
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Archivo</Form.Label>
              <Form.Control
                type="file"
                accept=".pdf, .jpg, .jpeg, .png, .gif, .mp4, .avi, .mkv, .doc, .docx, .xls, .xlsx"
                onChange={(e) =>
                  setEditSubproductData({
                    ...editSubproductData,
                    file: e.target.files[0],
                  })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEditModalS}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleEditSubproducts}>
            Guardar Cambios
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showCreateModalS}
        onHide={handleCloseCreateModalS}
        style={{ overflowY: "auto" }}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Title>
          {editingSubproduct
            ? "Editar Cliente en directorio"
            : "Crear Cliente en directorio"}
        </Modal.Title>
        {/* </Modal.Header> */}
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                value={subproductName}
                onChange={(e) => setSubproductName(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Telefono</Form.Label>
              <Form.Control
                type="text"
                value={subproductPhone}
                onChange={(e) => setSubproductPhone(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={subproductEmail}
                onChange={(e) => setSubproductEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Direccion</Form.Label>
              <Form.Control
                type="text"
                value={subproductAddress}
                onChange={(e) => setSubproductAddress(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Pagina web</Form.Label>
              <Form.Control
                type="text"
                value={subproductUrl}
                onChange={(e) => setSubproductUrl(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Direccion Maps</Form.Label>
              <Form.Control
                type="text"
                value={subproductAddressmap}
                onChange={(e) => setSubproductAddressmap(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formProducts">
              <Form.Label>Seleccionar Productos</Form.Label>
              <Form.Control
                as="select"
                multiple
                value={selectedProducts.map((product) =>
                  JSON.stringify(product)
                )}
                onChange={(e) => handleProductsSelection(e)}
                onDoubleClick={handleProductsDoubleClick}
                style={{ height: "40vh" }}
              >
                {products.map((product) => (
                  <option
                    key={product.id}
                    value={JSON.stringify(product)}
                    disabled={selectedProducts.some(
                      (selectedProduct) => selectedProduct.id === product.id
                    )}
                  >
                    {product.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                value={subproductDescription}
                onChange={(e) => setSubproductDescription(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>País</Form.Label>
              <Form.Control
                type="text"
                value={subproductCountry}
                onChange={(e) => setSubproductCountry(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Provincia</Form.Label>
              <Form.Control
                type="text"
                value={subproductProvince}
                onChange={(e) => setSubproductProvince(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Canton</Form.Label>
              <Form.Control
                type="text"
                value={subproductCanton}
                onChange={(e) => setSubproductCanton(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Distrito</Form.Label>
              <Form.Control
                type="text"
                value={subproductDistrito}
                onChange={(e) => setSubproductDistrito(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Nombre de Contacto</Form.Label>
              <Form.Control
                type="text"
                value={subproductContactName}
                onChange={(e) => setSubproductContactName(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Número de Teléfono</Form.Label>
              <Form.Control
                type="text"
                value={subproductPhoneNumber}
                onChange={(e) => setSubproductPhoneNumber(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Actividad Comercial</Form.Label>
              <Form.Control
                type="text"
                value={subproductComercialActivity}
                onChange={(e) => setSubproductComercialActivity(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Fecha de Constitucion</Form.Label>
              <Form.Control
                type="date"
                value={subproductConstitucion}
                onChange={(e) => setSubproductConstitucion(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Método de Pago</Form.Label>
              <Form.Control
                type="text"
                value={subproductPayMethod}
                onChange={(e) => setSubproductPayMethod(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Logo</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={(e) => setSubproductLogo(e.target.files[0])}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Archivo</Form.Label>
              <Form.Control
                type="file"
                accept=".pdf, .jpg, .jpeg, .png, .gif, .mp4, .avi, .mkv, .doc, .docx, .xls, .xlsx"
                onChange={(e) => setSubproductFile(e.target.files[0])}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCreateModalS}>
            Cancelar
          </Button>
          <Button
            variant="primary"
            onClick={
              editingSubproduct ? handleEditSubproducts : handleSaveSubproducts
            }
          >
            {editingSubproduct ? "Guardar Cambios" : "Crear"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Directory;
