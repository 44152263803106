import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

// Componente Modal
const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div style={modalOverlayStyle}>
      <div style={modalContentStyle}>
        <button onClick={onClose} style={closeButtonStyle}>X</button>
        {children}
      </div>
    </div>
  );
};

const Cartelera = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCinema, setSelectedCinema] = useState(null);

  const handleCinemarkClick = () => {
    setSelectedCinema("cinemark");
    setIsModalOpen(true);
  };

  const handleCinepolisClick = () => {
    setSelectedCinema("cinepolis");
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedCinema(null);
  };

  const handleOpenLink = () => {
    if (selectedCinema === "cinemark") {
      window.open("https://www.cinemarkca.com", "_blank");
    } else if (selectedCinema === "cinepolis") {
      window.open("https://cinepolis.co.cr/", "_blank");
    }
    handleCloseModal(); // Cerrar el modal después de abrir el enlace
  };

  useEffect(() => {
    // Definir las cookies que deseas establecer
    const cookies = {
      APISID: 'XvNjSeCY8gHLkT9f/Ar9ay_68mxvN9MPN6',
      CLID: '4592a41b96c24f4d87db87e93ec7ded0.20240730.20251021',
      HSID: 'AI7OXO_k0kKaUJqAp',
      LOGIN_INFO: 'AFmmF2swRQIhANxuRgVdU_AkOsuJjep8-FdoWWcBJhyn7j4YIh_74MjrAiAmdaYLkYq8zyZ8r73BOSCM_7C8KMumqH7tiqRymCsCnA:QUQ3MjNmeWFibkVSbkZOc1E1TWJiVWF1MmNjY2NqbnhjdGtSd1pMSTk5UEpJMVcyZnAzMUd0XzNlaGJQUURXZXM1MGhJTFl0VGNmTjlPSzktb1hTQzZyUDc0N3ZRWDRIdUI1SmxoZVZvbndPcjFldW94Vmp6SmFoSm5vbkJHMEVrdU9oOF8zNFBlVjQtQTFDUEJoVFo2X0pCS3JLTjdfNUpB',
      GMAIL_LOGIN: 'GMAIL_LOGIN_VALUE', // Reemplaza con el valor real
      GMAIL_PASSWORD: 'GMAIL_PASSWORD_VALUE', // Reemplaza con el valor real
      // Añade las otras cookies que necesites
    };

    // Establecer cookies si no existen
    Object.entries(cookies).forEach(([key, value]) => {
      if (!Cookies.get(key)) { // Verifica si la cookie ya existe
        Cookies.set(key, value, { expires: 30 }); // Las cookies expiran en 30 días
      }
    });
  }, []);

  return (
    <div style={{ textAlign: 'center' }}>
      <h2>Cartelera</h2>
      <div style={{ marginBottom: '20px' }}>
        <button onClick={handleCinemarkClick} style={buttonStyle}>
          Cinemark
        </button>
        <button onClick={handleCinepolisClick} style={buttonStyle}>
          Cinepolis
        </button>
      </div>

      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <div>
          <p>¿Quieres abrir el sitio de {selectedCinema === "cinemark" ? "Cinemark" : "Cinepolis"}?</p>
          <button onClick={handleOpenLink}>Abrir</button>
        </div>
      </Modal>
    </div>
  );
};

const buttonStyle = {
  margin: '0 10px',
  padding: '10px 20px',
  fontSize: '16px',
  cursor: 'pointer',
  backgroundColor: 'green',
  color: 'white',
  border: 'none',
  borderRadius: '5px',
  transition: 'background-color 0.3s',
};

const modalOverlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
};

const modalContentStyle = {
  backgroundColor: 'white',
  padding: '20px',
  borderRadius: '10px',
  boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
};

const closeButtonStyle = {
  backgroundColor: 'red',
  color: 'white',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  float: 'right',
};

export default Cartelera;
