import axios from 'axios';

class TourismDataService {
  // Property Actions
  getAllProperties(token) {
    // axios.defaults.headers.common['Authorization'] = 'Token ' + token;
    return axios.get(`https://abcupon-backend.com/tourism/properties/`);
  }

  createProperty(data, token) {
    axios.defaults.headers.common['Authorization'] = 'Token ' + token;
    return axios.post(`https://abcupon-backend.com/tourism/properties/`, data);
  }

  updateProperty(id, data, token) {
    axios.defaults.headers.common['Authorization'] = 'Token ' + token;
    return axios.put(`https://abcupon-backend.com/tourism/properties/${id}/`, data);
  }

  deleteProperty(id, token) {
    axios.defaults.headers.common['Authorization'] = 'Token ' + token;
    return axios.delete(`https://abcupon-backend.com/tourism/properties/${id}/`);
  }

  // Booking Actions
  getAllBookings(token) {
    // axios.defaults.headers.common['Authorization'] = 'Token ' + token;
    return axios.get(`https://abcupon-backend.com/tourism/bookings/`);
  }

  createBooking(data, token) {
    axios.defaults.headers.common['Authorization'] = 'Token ' + token;
    return axios.post(`https://abcupon-backend.com/tourism/bookings/`, data);
  }

  updateBooking(id, data, token) {
    axios.defaults.headers.common['Authorization'] = 'Token ' + token;
    return axios.put(`https://abcupon-backend.com/tourism/bookings/${id}/`, data);
  }

  deleteBooking(id, token) {
    axios.defaults.headers.common['Authorization'] = 'Token ' + token;
    return axios.delete(`https://abcupon-backend.com/tourism/bookings/${id}/`);
  }

  // Payment Actions
  getAllPayments(token) {
    axios.defaults.headers.common['Authorization'] = 'Token ' + token;
    return axios.get(`https://abcupon-backend.com/tourism/payments/`);
  }

  createPayment(data, token) {
    axios.defaults.headers.common['Authorization'] = 'Token ' + token;
    return axios.post(`https://abcupon-backend.com/tourism/payments/`, data);
  }

  updatePayment(id, data, token) {
    axios.defaults.headers.common['Authorization'] = 'Token ' + token;
    return axios.put(`https://abcupon-backend.com/tourism/payments/${id}/`, data);
  }

  deletePayment(id, token) {
    axios.defaults.headers.common['Authorization'] = 'Token ' + token;
    return axios.delete(`https://abcupon-backend.com/tourism/payments/${id}/`);
  }

  // Review Actions
  getAllReviews(token) {
    // axios.defaults.headers.common['Authorization'] = 'Token ' + token;
    return axios.get(`https://abcupon-backend.com/tourism/reviews/`);
  }

  createReview(data, token) {
    // axios.defaults.headers.common['Authorization'] = 'Token ' + token;
    return axios.post(`https://abcupon-backend.com/tourism/reviews/`, data);
  }

  updateReview(id, data, token) {
    axios.defaults.headers.common['Authorization'] = 'Token ' + token;
    return axios.put(`https://abcupon-backend.com/tourism/reviews/${id}/`, data);
  }

  deleteReview(id, token) {
    axios.defaults.headers.common['Authorization'] = 'Token ' + token;
    return axios.delete(`https://abcupon-backend.com/tourism/reviews/${id}/`);
  }

   // Amenities Actions
   getAllAmenities(token) {
    // axios.defaults.headers.common['Authorization'] = 'Token ' + token;
    return axios.get(`https://abcupon-backend.com/tourism/amenities/`);
  }

  createAmenity(data, token) {
    axios.defaults.headers.common['Authorization'] = 'Token ' + token;
    return axios.post(`https://abcupon-backend.com/tourism/amenities/`, data);
  }

  updateAmenity(id, data, token) {
    axios.defaults.headers.common['Authorization'] = 'Token ' + token;
    return axios.put(`https://abcupon-backend.com/tourism/amenities/${id}/`, data);
  }

  deleteAmenity(id, token) {
    axios.defaults.headers.common['Authorization'] = 'Token ' + token;
    return axios.delete(`https://abcupon-backend.com/tourism/amenities/${id}/`);
  }
}


export default new TourismDataService();
