import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FaBriefcase, FaMapMarkerAlt, FaDollarSign, FaCalendarAlt, FaRegClock } from 'react-icons/fa';

const JobPostingDetails = ({ show, handleClose, jobPosting, experienceLevels, benefits, skills, jobTags }) => {
  if (!jobPosting) return null; // Evitar renderizar si no hay jobPosting

  return (
    <Modal show={show} onHide={handleClose} centered style={{overflowY:"auto"}}>
      <Modal.Title><h2>{jobPosting.title}</h2></Modal.Title>
      <Modal.Body>
        <p><strong>Descripción:</strong> {jobPosting.description}</p>
        
        <table className="table">
          <tbody>
            <tr>
              <td><strong>Empresa:</strong></td>
              <td>{jobPosting.company}</td>
            </tr>
            <tr>
              <td><FaMapMarkerAlt /> <strong>Ciudad:</strong></td>
              <td>{jobPosting.city}, {jobPosting.country}</td>
            </tr>
            <tr>
              <td><strong>Tipo de empleo:</strong></td>
              <td>{jobPosting.employment_type}</td>
            </tr>
            <tr>
              <td><strong>Nivel de experiencia:</strong></td>
              <td>{experienceLevels.find(level => level.id === jobPosting.experience_level)?.name || jobPosting.experience_level}</td>
            </tr>
            <tr>
              <td><FaDollarSign /> <strong>Rango salarial:</strong></td>
              <td>{jobPosting.salary_range}</td>
            </tr>
            <tr>
              <td><strong>Modalidad:</strong></td>
              <td>{jobPosting.modality}</td>
            </tr>
            <tr>
              <td><FaCalendarAlt /> <strong>Fecha de publicación:</strong></td>
              <td>{jobPosting.posted_date}</td>
            </tr>
            <tr>
              <td><FaRegClock /> <strong>Fecha límite de aplicación:</strong></td>
              <td>{jobPosting.application_deadline}</td>
            </tr>
            <tr>
              <td><strong>Cómo aplicar:</strong></td>
              <td>{jobPosting.how_to_apply}</td>
            </tr>
            <tr>
              <td><strong>Beneficios:</strong></td>
              <td>{jobPosting.benefits.map(benefitId => benefits.find(benefit => benefit.id === benefitId)?.name).join(', ')}</td>
            </tr>
            <tr>
              <td><strong>Habilidades requeridas:</strong></td>
              <td>{jobPosting.skills_required.map(skillId => skills.find(skill => skill.id === skillId)?.name).join(', ')}</td>
            </tr>
            <tr>
              <td><strong>Etiquetas:</strong></td>
              <td>{jobPosting.tags.map(tagId => jobTags.find(tag => tag.id === tagId)?.name).join(', ')}</td>
            </tr>
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default JobPostingDetails;
