import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.css';
import { useMediaQuery } from 'react-responsive';
import slider1 from '../../../assets/slider1.jpg';
import slider2 from '../../../assets/slider2.jpg';
import slider3 from '../../../assets/slider3.jpg';
import slider4 from '../../../assets/slider4.jpg';
import slider5 from '../../../assets/slider5.jpg';

const Carousel1 = () => {
  // Definir diferentes breakpoints
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
  const isTablet = useMediaQuery({ query: '(min-width: 481px) and (max-width: 768px)' });
  const isSmallDesktop = useMediaQuery({ query: '(min-width: 769px) and (max-width: 1024px)' });
  const isLargeDesktop = useMediaQuery({ query: '(min-width: 1025px)' });

  // Definir tamaños para el carrusel según el tamaño de la pantalla
  let carouselSettings;
  
  if (isMobile) {
    carouselSettings = {
      centerMode: false,
      emulateTouch: true,
      infiniteLoop: true,
      showIndicators: false,
      showStatus: false,
      showThumbs: false,
      autoPlay: true,
      interval: 4000,
      stopOnHover: false,
    };
  } else if (isTablet) {
    carouselSettings = {
      centerMode: true,
      centerSlidePercentage: 50, // Mostrar 2 slides en tablets
      emulateTouch: true,
      infiniteLoop: true,
      showIndicators: false,
      showStatus: false,
      showThumbs: false,
      autoPlay: true,
      interval: 4000,
      stopOnHover: false,
    };
  } else if (isSmallDesktop) {
    carouselSettings = {
      centerMode: true,
      centerSlidePercentage: 33.33, // Mostrar 3 slides en pantallas pequeñas de escritorio
      emulateTouch: true,
      infiniteLoop: true,
      showIndicators: false,
      showStatus: false,
      showThumbs: false,
      autoPlay: true,
      interval: 4000,
      stopOnHover: false,
    };
  } else if (isLargeDesktop) {
    carouselSettings = {
      centerMode: true,
      centerSlidePercentage: 25, // Mostrar 4 slides en pantallas grandes
      emulateTouch: true,
      infiniteLoop: true,
      showIndicators: false,
      showStatus: false,
      showThumbs: false,
      autoPlay: true,
      interval: 4000,
      stopOnHover: false,
    };
  }

  return (
    <div className="imageContainerStyle" style={{marginTop:isTablet ? "18vh": isMobile ?"20vh":""}}>
      <Carousel {...carouselSettings}>
        {[...Array(10)].map((_, repeatIndex) =>
          [slider1, slider2, slider3, slider4, slider5].map((image, index) => (
            <div key={index}>
              <img src={image} alt={`Slide ${index + 1}`} loading="lazy" />
            </div>
          ))
        )}
      </Carousel>
    </div>
  );
};

export default Carousel1;
