import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FaEnvelope, FaPhone, FaMapMarkerAlt, FaIndustry, FaGlobe } from 'react-icons/fa';

const CompanyDetailModal = ({ show, handleClose, company }) => {
  if (!company) return null; // Asegurarte de que company tenga datos

  return (
    <Modal show={show} onHide={handleClose} centered style={{ overflowY:"auto" }}>

        <Modal.Title className="text-center" style={{fontSize:"2.3em", color:"red", fontWeight:"bold", marginBottom:"2%"}}>{company.name}</Modal.Title>
      <Modal.Body style={{ color: "black", overflowY:"auto" }}>
        <img src={company.logo} alt={`${company.name} logo`} style={{ width: '100%', height: 'auto', borderRadius: '8px' }} />
        <h3 className="mt-3">Descripción</h3>
        <p>{company.description}</p>
        
        <h5 className="mt-4">Detalles de Contacto</h5>
        <div className="mb-2">
          <FaEnvelope /> <strong>Email:</strong> {company.contact_email}
        </div>
        <div className="mb-2">
          <FaPhone /> <strong>Teléfono:</strong> {company.phone_number}
        </div>
        <div className="mb-2">
          <FaMapMarkerAlt /> <strong>Dirección:</strong> {company.address}
        </div>
        <div className="mb-2">
          <FaIndustry /> <strong>Industria:</strong> {company.industry}
        </div>
        <div className="mb-2">
          <strong>Fecha de Establecimiento:</strong> {company.established_date}
        </div>
        <div className="mb-2">
          <FaGlobe /> <strong>Sitio Web:</strong> <a href={company.website} target="_blank" rel="noopener noreferrer" style={{ color: "blue" }}>{company.website}</a>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>Cerrar</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CompanyDetailModal;
