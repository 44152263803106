import React, { useState, useEffect } from 'react';
import './style.css'

const PaymentModal = ({ isOpen, onClose, payment }) => {
  const [amount, setAmount] = useState('');
  const [status, setStatus] = useState('');

  useEffect(() => {
    if (payment) {
      setAmount(payment.amount);
      setStatus(payment.status);
    } else {
      setAmount('');
      setStatus('');
    }
  }, [payment]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Aquí va la lógica para enviar los datos al servidor
    console.log({ amount, status });
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="modal">
      <h2>{payment ? "Edit Payment" : "Create Payment"}</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Amount:
          <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} />
        </label>
        <label>
          Status:
          <input type="text" value={status} onChange={(e) => setStatus(e.target.value)} />
        </label>
        <button type="submit">{payment ? "Update" : "Create"}</button>
        <button type="button" onClick={onClose}>Close</button>
      </form>
    </div>
  );
};

export default PaymentModal;
