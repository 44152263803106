import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";

const JobApplicationModal = ({
  show,
  handleClose,
  jobApplication,
  jobPostings = [],
  handleSave,
}) => {
  const [applicantName, setApplicantName] = useState(
    jobApplication ? jobApplication.applicantName : ""
  );
  const [jobPostingId, setJobPostingId] = useState(
    jobApplication ? jobApplication.jobPostingId : ""
  );
  const [resume, setResume] = useState(null);
  const [coverLetter, setCoverLetter] = useState(
    jobApplication ? jobApplication.coverLetter : ""
  );
  const [notes, setNotes] = useState(
    jobApplication ? jobApplication.notes : ""
  );
  const [status, setStatus] = useState(
    jobApplication ? jobApplication.status : "Pending"
  );
  // console.log("datos recibidos", jobPostings)
  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    // formData.append('applicant', applicantName);
    formData.append("job", jobPostingId);
    formData.append("id", jobPostingId);
    formData.append("resume", resume);
    formData.append("cover_letter", coverLetter);
    formData.append("notes", notes);
    formData.append("status", status);
    // for (let [key, value] of formData.entries()) {
    //     console.log(`${key}: ${value}`);
    //     }
    handleSave({ id: jobApplication ? jobApplication.id : null, formData });
    handleClose();
  };

  useEffect(() => {
    if (jobApplication) {
      // setApplicantName(jobApplication.applicantName);
      setJobPostingId(jobApplication.jobPostingId);
      setCoverLetter(jobApplication.coverLetter);
      setNotes(jobApplication.notes);
      setStatus(jobApplication.status);
    }
  }, [jobApplication]);

  return (
    <Modal show={show} onHide={handleClose} style={{ overflowY: "auto" }}>
      <Modal.Title
        style={{
          color: "red",
          fontWeight: "bold",
          fontSize: "2.0em",
          lineHeight: "1.2em",
          textShadow: "2px 2px 4px #000",
          textTransform: "none",
          overflow: "hidden",
          whiteSpace: "nowrap",
          marginBottom: "4%",
        }}
      >
        {jobApplication
          ? "Editar Aplicación de Trabajo"
          : "Crear Aplicación de Trabajo"}
      </Modal.Title>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group
            controlId="formJobApplicationJobPostingId"
            style={{ marginBottom: "20px" }}
          >
            <Form.Label style={{ fontSize: "1.6em", fontWeight: "bold" }}>
              Seleccionar Publicación de Trabajo
            </Form.Label>
            <Form.Control
              as="select"
              value={jobPostingId}
              onChange={(e) => setJobPostingId(e.target.value)}
              required
            >
              <option value="" style={{ fontSize: "1.6em" }}>
                Seleccionar un trabajo...
              </option>
              {jobPostings.length > 0 ? (
                jobPostings.map((job) => (
                  <option key={job.id} value={job.id}>
                    {job.title}
                  </option>
                ))
              ) : (
                <option value="" style={{ fontSize: "1.6em" }}>
                  No hay trabajos disponibles
                </option>
              )}
            </Form.Control>
          </Form.Group>

          <Form.Group
            controlId="formJobApplicationResume"
            style={{ marginBottom: "20px" }}
          >
            <Form.Label style={{ fontSize: "1.6em", fontWeight: "bold" }}>
              Currículum
            </Form.Label>
            <Form.Control
              type="file"
              accept=".pdf,.doc,.docx"
              onChange={(e) => setResume(e.target.files[0])}
              required
              style={{ fontSize: "1.6em" }}
            />
          </Form.Group>

          <Form.Group
            controlId="formJobApplicationCoverLetter"
            style={{ marginBottom: "20px" }}
          >
            <Form.Label style={{ fontSize: "1.6em", fontWeight: "bold" }}>
              Carta de Presentación
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={coverLetter}
              onChange={(e) => setCoverLetter(e.target.value)}
              style={{ fontSize: "1.6em" }}
            />
          </Form.Group>

          <Form.Group
            controlId="formJobApplicationNotes"
            style={{ marginBottom: "20px" }}
          >
            <Form.Label style={{ fontSize: "1.6em", fontWeight: "bold" }}>
              Notas
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              style={{ fontSize: "1.6em" }}
            />
          </Form.Group>

          {/* <Form.Group controlId="formJobApplicationStatus">
                        <Form.Label>Estado</Form.Label>
                        <Form.Control 
                            as="select" 
                            value={status} 
                            onChange={(e) => setStatus(e.target.value)} 
                        >
                            <option value="Pending">Pendiente</option>
                            <option value="Reviewed">Revisado</option>
                            <option value="Interview">Entrevista</option>
                            <option value="Offer">Oferta</option>
                            <option value="Hired">Contratado</option>
                            <option value="Rejected">Rechazado</option>
                        </Form.Control>
                    </Form.Group> */}
          <Button variant="primary" type="submit">
            Guardar
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default JobApplicationModal;
