import React, { useState, useEffect } from 'react';
import Boton from './iframe';
import HomeScreen from '../ecommerce/Homescreen';
import image1 from './imagenes/22.jpg';
import image2 from '../../assets/categorias/ABCupon.jpg';
import Table1 from '../pages/abcupon/Table1';
import { useMediaQuery } from "react-responsive";
import VisualizacionTurismo from './22visualizacion';

const Hoteles_turismo = () => {
  const allowedCategories = [
    '22. Esencial Costa Rica: Hoteles y Turismo'  
  ];
  const tablacategory = "22. Escencial Costa Rica hoteles y turismo";
  const [inputValue, setInputValue] = useState('');
  const [botones, setBotones] = useState([]);
  const [isHomeScreenOpen, setIsHomeScreenOpen] = useState(true); // Cambiado a true
  const [isTableOpen, setIsTableOpen] = useState(false); // Cambiado a false
  const [activeButton, setActiveButton] = useState('home'); // Cambiado el valor inicial a 'home'
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
  const isTablet = useMediaQuery({ query: '(min-width: 481px) and (max-width: 768px)' });

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const filteredBotones = botones.filter((boton) =>
    boton.label.toLowerCase().includes(inputValue.toLowerCase())
  );

  const toggleHomeScreen = () => {
    setIsHomeScreenOpen((prev) => {
      const newState = !prev;
      if (newState) {
        setIsTableOpen(false); // Cerrar tabla al abrir HomeScreen
        setActiveButton('home'); // Establecer el botón "Productos de Tienda" como activo
      }
      return newState;
    });
  };

  const toggleTable = () => {
    setIsTableOpen((prev) => {
      const newState = !prev;
      if (newState) {
        setIsHomeScreenOpen(false); // Cerrar HomeScreen solo si se abre la tabla
        setActiveButton('table'); // Establecer el botón "DIRECTORIO DE COMERCIOS AFILIADOS" como activo
      }
      return newState;
    });
  };

  // Para depuración
  useEffect(() => {
    console.log('isHomeScreenOpen:', isHomeScreenOpen);
    console.log('isTableOpen:', isTableOpen);
  }, [isHomeScreenOpen, isTableOpen]);

  return (
    <div style={{ marginTop: 'var(--marginTop)' }}>
      {filteredBotones.map((boton) => (
        <Boton key={boton.label} url={boton.url} label={boton.label} />
      ))}

      <div>
        {/* Imágenes en la parte superior ocupando la mitad de la pantalla */}
        <div
          style={{
            display: "flex",
            marginTop: isMobile ? "8%" : "6%",
            flexDirection: isMobile ? "row" : "row", // Cambia a columna si es móvil
            justifyContent: "left",
            height: isMobile ? "auto" : "10vh", // Ajusta la altura para móviles
            marginBottom: "1.2%",
            marginLeft: "5.3%",
          }}
        >
          <img
            src={image1}
            alt="Imagen 1"
            style={{
              maxWidth: isMobile ? "30%" : "50%", // Ocupa 100% en móvil y 50% en escritorio
              maxHeight: "100%",
              objectFit: "cover",
              borderRadius: "15px",
              boxShadow: "0 4px 8px rgba(255, 255, 255, 0.5)",
            }}
            loading="lazy"
          />
          <img
            src={image2}
            alt="Imagen 2"
            style={{
              maxWidth: isMobile ? "30%" : "50%", // Ocupa 100% en móvil y 50% en escritorio
              maxHeight: "100%",
              objectFit: "cover",
              borderRadius: "15px",
              boxShadow: "0 4px 8px rgba(255, 255, 255, 0.5)",
            }}
            loading="lazy"
          />
        </div>

        {/* Sección de productos de tienda */}
        <div style={{ marginLeft: "5.3%" }}>
          <nav style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '10px' }}>
            <h3
              onClick={toggleHomeScreen}
              style={{
                backgroundColor: activeButton === 'home' ? 'green' : 'white', // Cambia el color según el botón activo
                color: activeButton === 'home' ? 'white' : 'black', // Cambia el color del texto
                fontWeight: "bold",
                border: '1px solid black',
                padding: '10px 20px',
                cursor: 'pointer',
                width: 'auto',
                transition: 'background-color 0.3s, color 0.3s',
                borderRadius: '10px',
                marginRight: '10px'
              }}
              onMouseEnter={(e) => {
                if (activeButton !== 'home') {
                  e.target.style.backgroundColor = 'green';
                  e.target.style.color = 'white';
                }
              }}
              onMouseLeave={(e) => {
                if (activeButton !== 'home') {
                  e.target.style.backgroundColor = 'transparent';
                  e.target.style.color = 'black';
                }
              }}
            >
              Productos de Tienda
            </h3>

            <h3
              onClick={toggleTable}
              style={{
                backgroundColor: activeButton === 'table' ? 'green' : 'white', // Cambia el color según el botón activo
                color: activeButton === 'table' ? 'white' : 'black', // Cambia el color del texto
                fontWeight: "bold",
                border: '1px solid black',
                padding: '10px 20px',
                cursor: 'pointer',
                width: 'auto',
                transition: 'background-color 0.3s, color 0.3s',
                borderRadius: '10px',
              }}
              onMouseEnter={(e) => {
                if (activeButton !== 'table') {
                  e.target.style.backgroundColor = 'green';
                  e.target.style.color = 'white';
                }
              }}
              onMouseLeave={(e) => {
                if (activeButton !== 'table') {
                  e.target.style.backgroundColor = 'transparent';
                  e.target.style.color = 'black';
                }
              }}
            >
              DIRECTORIO DE COMERCIOS AFILIADOS
            </h3>
          </nav>
        </div>
        
        <div style={{ marginTop: "2%", marginRight: "auto" }}>
          {isHomeScreenOpen && <VisualizacionTurismo />}
          {isTableOpen && <Table1 tablacategory={tablacategory} />}
        </div>
      </div>
    </div>
  );
};
export default Hoteles_turismo;
