import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const AdminRoute = () => {
  // Obtener el usuario actual de localStorage y parsearlo
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  
  // Verificar si el usuario existe y si su estado de staff es "administrator"
  const isAdmin = currentUser && currentUser.staff_status === "administrator";

  return isAdmin ? <Outlet /> : <Navigate to="/" />;
};

export default AdminRoute;
