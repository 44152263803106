import React, { useState, useEffect } from "react";
import { Table, Button, Modal } from "react-bootstrap";
import JobDataService from "../../services/employee";
import JobApplicationModal from "./job_modal/jobapplication_modal";
import TodoDataService from "../../services/todos";
import PDFViewer from "pdf-viewer-reactjs";
import PdfViewer1 from "./PdfViewer";
import moment from "moment";

const JobApplications = ({ token, currentUser }) => {
  const [jobApplications, setJobApplications] = useState([]);
  const [currentJobApplication, setCurrentJobApplication] = useState(null);
  const [showJobApplicationModal, setShowJobApplicationModal] = useState(false); // Modal para crear
  const [showJobDetailsModal, setShowJobDetailsModal] = useState(false); // Modal para detalles
  const [jobPostings, setJobPostings] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showPdf, setShowPdf] = useState(false);

  useEffect(() => {
    loadJobApplications();
    loadJobPostings();
    loadCompanies();
    getUserList();
  }, [token]);

  const getUserList = () => {
    if (token) {
      TodoDataService.getUserList(token)
        .then((response) => {
          setUserList(response.data);
          console.log("usuarios12", response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const loadJobApplications = () => {
    JobDataService.getAllJobApplications(token)
      .then((response) => {
        setJobApplications(response.data);
        console.log("jobApplications12", jobApplications);
      })
      .catch((error) => console.error(error));
  };

  const loadJobPostings = () => {
    JobDataService.getAllJobPostings(token)
      .then((response) => {
        const postingsWithCompanyNames = response.data.map((posting) => {
          const company = companies.find(
            (company) => company.id === posting.company
          );
          return {
            ...posting,
            companyName: company ? company.name : "Desconocido",
          };
        });
        setJobPostings(postingsWithCompanyNames);
        console.log("jobPostings12", jobPostings);
      })
      .catch((error) => console.error(error));
  };

  const loadCompanies = () => {
    JobDataService.getAllCompanies(token)
      .then((response) => {
        const filteredCompanies = response.data.filter(
          (company) => company.user === currentUser.id
        );
        setCompanies(filteredCompanies);
        console.log("empresas necesarias", companies);
      })
      .catch((error) => console.error(error));
  };

  const handleSaveJobApplication = (applicationData) => {
    if (currentJobApplication) {
      JobDataService.updateJobApplication(
        currentJobApplication.id,
        applicationData,
        token
      )
        .then(() => loadJobApplications())
        .catch((error) => console.error(error));
    }
    setShowJobApplicationModal(false);
  };

  const deleteJobApplication = (applicationjob, applicationid) => {
    console.log("id de eliminar", applicationid);
    JobDataService.deleteJobApplication(applicationjob, token, applicationid)
      .then((response) => {
        loadJobApplications();
      })
      .catch((error) => console.error(error));
  };

  const handleViewJobApplication = (application) => {
    const matchingUser = userList.find(
      (user) => user.email === application.applicant
    );
    setSelectedUser(matchingUser || null);
    setCurrentJobApplication(application);
    console.log("currentjobapplication", application);
    setShowJobDetailsModal(true); // Usar el estado del modal de detalles
  };

  const handleCloseJobDetailsModal = () => {
    setShowJobDetailsModal(false);
    setCurrentJobApplication(null);
    setSelectedUser(null);
  };

  return (
    <div>
      <h2>Job Applications</h2>
      <Button
        variant="primary"
        onClick={() => setShowJobApplicationModal(true)}
      >
        Crear Aplicación
      </Button>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th style={{ color: "blue" }}>Puesto</th>
            <th style={{ color: "blue" }}>Nombre del Candidato</th>
            <th style={{ color: "blue" }}>Presentacion</th>
            <th style={{ color: "blue" }}>Estado de Aplicacion</th>
            <th style={{ color: "blue" }}>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {jobApplications.map((application) => (
            <tr key={application.id}>
              <td> {application.job} </td>
              <td> <span 
                  style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }} 
                   onClick={() => handleViewJobApplication(application)} >
                    {application.applicant} 
                    </span></td>
              <td>{application.cover_letter}</td>
              <td>{application.status}</td>
              <td>
                {/* <Button
                  variant="primary"
                  onClick={() => handleViewJobApplication(application)}
                >
                  Ver Detalles
                </Button> */}
                <Button
                  variant="danger"
                  onClick={() =>
                    deleteJobApplication(application.job, application.id)
                  }
                >
                  Eliminar
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal para Detalles de la Aplicación */}
      {currentJobApplication && (
        <Modal
          show={showJobDetailsModal}
          onHide={handleCloseJobDetailsModal}
          style={{ overflowY: "auto" }}
        >
          <Modal.Title>Detalles de la Aplicación</Modal.Title>
          <Modal.Body>
            <h5>Datos de la Aplicación</h5>
            <Table striped bordered>
              <tbody>
                <tr>
                  <td>
                    <strong>Email del Candidato:</strong>
                  </td>
                  <td>{currentJobApplication.applicant}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Presentación:</strong>
                  </td>
                  <td>{currentJobApplication.cover_letter}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Notas:</strong>
                  </td>
                  <td>{currentJobApplication.notes}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Estado:</strong>
                  </td>
                  <td>{currentJobApplication.status}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Currículum:</strong>
                  </td>
                  <td>
                    <div
                      style={{
                        maxHeight: "70vh",
                        maxWidth: "100%",
                        overflowY: "auto",
                        overflowX: "auto",
                      }}
                    >
                      <PdfViewer1
                        pdf={currentJobApplication.resume}
                        onCancel={() => setShowPdf(false)}
                        visible={showPdf}
                      />
                      <Button onClick={() => setShowPdf(!showPdf)}>
                        Visualizar PDF
                      </Button>
                    </div>
                    <Button
                      variant="link"
                      onClick={() => {
                        // Mostrar alerta de confirmación
                        if (window.confirm("¿Quieres descargar el archivo?")) {
                          // Obtener el PDF desde la URL
                          fetch(currentJobApplication.resume)
                            .then((response) => {
                              if (response.ok) {
                                return response.blob(); // Convertir la respuesta a un Blob
                              }
                              throw new Error(
                                "Error en la descarga del archivo."
                              );
                            })
                            .then((blob) => {
                              // Crear un enlace temporal para descargar el archivo
                              const link = document.createElement("a");
                              link.href = URL.createObjectURL(blob); // Crear una URL para el Blob
                              link.download = `${currentJobApplication.applicant}.pdf`; // Usa el nombre del solicitante
                              document.body.appendChild(link);
                              link.click(); // Simular un clic para descargar
                              document.body.removeChild(link); // Remover el enlace del DOM
                            })
                            .catch((error) => {
                              console.error("Error al descargar el CV:", error);
                              alert(
                                "Hubo un problema al descargar el archivo."
                              ); // Mostrar alerta si hay error
                            });
                        }
                      }}
                    >
                      Descargar CV
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
            {/* Información del Usuario */}
            <h4
              style={{
                marginBottom: "15px",
                color: "#333",
                borderBottom: "2px solid #007bff",
                paddingBottom: "5px",
              }}
            >
              Información del Usuario
            </h4>
            {selectedUser && (
              <>
                <div style={{ textAlign: "center", marginBottom: "20px" }}>
                  <img
                    src={selectedUser.profile_picture} // Cambia esto según el campo correspondiente
                    alt="Profile"
                    style={{
                      width: "150px",
                      borderRadius: "75px",
                      boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                    }}
                  />
                  <h2 style={{ margin: "10px 0", color: "#007bff" }}>
                    {selectedUser.first_name} {selectedUser.last_name}
                  </h2>
                  <p style={{ fontStyle: "italic", color: "#555" }}>
                    {selectedUser.email}
                  </p>
                </div>

                <Table
                  striped
                  bordered
                  hover
                  responsive
                  style={{ marginBottom: "20px", width: "100%" }}
                >
                  <tbody>
                    <tr>
                      <td>
                        <strong>Teléfono</strong>
                      </td>
                      <td>{selectedUser.phone_number}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Biografía</strong>
                      </td>
                      <td>{selectedUser.bio}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Ubicación</strong>
                      </td>
                      <td>
                        {selectedUser.address}, {selectedUser.country}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Fecha de Nacimiento</strong>
                      </td>
                      <td>
                        {moment(selectedUser.date_of_birth).format(
                          "DD/MM/YYYY"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Estado de Empleo</strong>
                      </td>
                      <td>
                        <strong
                          style={{
                            color: selectedUser.openwork ? "green" : "red",
                          }}
                        >
                          {selectedUser.openwork
                            ? "Disponible"
                            : "No disponible"}
                        </strong>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseJobDetailsModal}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {/* Modal para Crear Aplicación */}
      <JobApplicationModal
        show={showJobApplicationModal}
        handleClose={() => setShowJobApplicationModal(false)}
        jobApplication={currentJobApplication}
        jobPostings={jobPostings}
        handleSave={handleSaveJobApplication}
      />
    </div>
  );
};

export default JobApplications;
