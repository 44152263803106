import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Table, Modal, Form } from 'react-bootstrap';
import ProductDataService from '../../services/products';
import TodoDataService from '../../services/todos';
import moment from 'moment';
import Papa from 'papaparse';
import { Newspaper } from '@mui/icons-material';
import './file_products.css'

const CharacteristicsTable = (props) => {
  const [products, setProducts] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [name, setName] = useState('');
  const [url, setUrl] = useState('');
  const [description, setDescription] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [userList, setUserList] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const token = useSelector(state => state.authentication.token);
  const [characteristicsList, setCharacteristicsList] = useState([]);
  // const [subproductList, setSubproductList] = useState([]);
  const [showCreateModalM, setShowCreateModalM] = useState(false);
  const [showCreateModalS, setShowCreateModalS] = useState(false);
  const [showEditModalM, setShowEditModalM] = useState(false);
  const [selectedCharacteristicM, setSelectedCharacteristicM] = useState(null);
  const [nameM, setNameM] = useState('');
  const [descriptionM, setDescriptionM] = useState('');
  // const [nameS, setNameS] = useState('');
  // const [phoneS, setPhoneS] = useState('');
  // const [subproductModalShow, setSubproductModalShow] = useState(false);
  // const [showCreateSubproductModal, setShowCreateSubproductModal] = useState(false);
  // const [selectedSubproducts, setSelectedSubproducts] = useState([]);
  const [selectedSubproduct, setSelectedSubproduct] = useState(null);
  // const [editSubproductName, setEditSubproductName] = useState('');
  // const [editSubproductPhone, setEditSubproductPhone] = useState('');
  // const [currentProductsPage, setCurrentProductsPage] = useState(1);
  const [currentCharacteristicsPage, setCurrentCharacteristicsPage] = useState(1);
  // const [currentSubproductsPage, setCurrentSubproductsPage] = useState(1);
  const [isEditing, setIsEditing] = useState(false);
  const itemsPerPage = 4;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentProducts = products.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(products.length / itemsPerPage);
  // const [csvFile, setCsvFile] = useState(null);
  // const [showServicesModal, setShowServicesModal] = useState(false);
  const [selectedSubproductServices, setSelectedSubproductServices] = useState([]);
  // const [showModal, setShowModal] = useState(false);
  const moment = require('moment');

  const renderPaginationButtons = (currentPage, totalPages, setCurrentPage) => {
    const buttons = [];
    for (let i = 1; i <= totalPages; i++) {
      buttons.push(
        <Button
          key={i}
          variant={i === currentPage ? 'primary' : 'secondary'}
          onClick={() => setCurrentPage(i)}
        >
          {i}
        </Button>
      );
    }
    return buttons;
  };
  const renderCharacteristicsPaginationButtons = () => {
    const buttons = [];
    for (let i = 1; i <= Math.ceil(characteristicsList.length / itemsPerPage); i++) {
      buttons.push(
        <Button
          key={i}
          variant={i === currentPage ? 'primary' : 'secondary'}
          onClick={() => setCurrentPage(i)}
        >
          {i}
        </Button>
      );
    }
    return buttons;
  };
  // const renderSubproductsPaginationButtons = () => {
  //   const buttons = [];
  //   for (let i = 1; i <= Math.ceil(subproducts.length / itemsPerPage); i++) {
  //     buttons.push(
  //       <Button
  //         key={i}
  //         variant={i === currentPage ? 'primary' : 'secondary'}
  //         onClick={() => setCurrentPage(i)}
  //       >
  //         {i}
  //       </Button>
  //     );
  //   }
  //   return buttons;
  // };

  const fetchCharacteristicsM = async () => {
    try {
      const response = await ProductDataService.getAllCharacteristics();
      setCharacteristicsList(response.data);
    } catch (error) {
      console.error('Error al recuperar características:', error);
    }
  };

  // const fetchSubProduct = async () => {
  //   try {
  //     const response = await ProductDataService.getAllSubProduct();
  //     setSubproductList(response.data);
  //   } catch (error) {
  //     console.error('Error al recuperar subproductos:', error);
  //   }
  // };




  const handleCloseEditModalM = () => {
    setShowEditModalM(false);
    setSelectedCharacteristicM(null);
    setNameM('');
    setDescriptionM('');
  };

  const handleCreateM = () => {
    setShowCreateModalM(true);
  };
  const handleCreateS = () => {
    setShowCreateModalS(true);
  };

  const handleCloseCreateModalM = () => {
    setShowCreateModalM(false);
    setNameM('');
    setDescriptionM('');
  };


  // const handleCloseCreateModalS = () => {
  //   setShowCreateModalS(false);
  //   setSubproductName('');
  //   setSubproductPhone('');
  //   setSubproductEmail('');
  //   setSubproductAddress('');
  //   setSubproductAddressmap('');
  //   setSubproductUrl('');
  //   setSubproductServices('');
  //   setFile3(null); // Asegúrate de reiniciar el estado del archivo relacionado con el subproducto
  // };
  const handleEditM = (characteristic) => {
    setSelectedCharacteristicM(characteristic);
    setNameM(characteristic.name);
    setDescriptionM(characteristic.description);
    setShowEditModalM(true);
  };

  const handleDeleteM = async (id) => {
    try {
      await ProductDataService.deleteCharacteristic(id);
      fetchCharacteristicsM();
    } catch (error) {
      console.error('Error al eliminar característica:', error);
    }
  };

  const handleSubmitCreateM = async () => {
    const newCharacteristic = { name: nameM, description: descriptionM };
    try {
      await ProductDataService.createCharacteristic(newCharacteristic, token); // Pasa el token a la función
      setShowCreateModalM(false);
      fetchCharacteristicsM();
    } catch (error) {
      console.error('Error al crear característica:', error);
    }
  };

  const handleSubmitEditM = async () => {
    if (!selectedCharacteristicM) {
      console.error('Selected characteristic is null or undefined');
      return;
    }

    const updatedCharacteristic = { name: nameM, description: descriptionM };
    try {
      await ProductDataService.updateCharacteristic(selectedCharacteristicM.id, updatedCharacteristic);
      setShowEditModalM(false);
      fetchCharacteristicsM();
    } catch (error) {
      console.error('Error al actualizar la característica:', error);
    }
  };

  useEffect(() => {
    fetchCharacteristicsM();
  }, []);

  // useEffect(() => {
  //   fetchSubProduct();
  // }, []);


  const [editData, setEditData] = useState({
    id: null,
    name: '',
    description: '',
    name_url: '',
    characteristics: [],
    subproducts: [],
  });
  // const [showEditModal, setShowEditModal] = useState(false);
  const handleEditNameUrlChange = (e) => {
    const newNameUrl = e.target.value;
    setEditData({ ...editData, name_url: newNameUrl });
  };

  const getUserList = () => {
    if (props.token) {
      TodoDataService.getUserList(props.token)
        .then((response) => {
          setUserList(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  useEffect(() => {
    getUserList();
  }, [props.token]);

  useEffect(() => {
    if (userList.length > 0 && props.user) {
      const currentUser = userList.find((user) => user.email === props.user);
      setCurrentUser(currentUser);
      localStorage.setItem('currentUser', JSON.stringify(currentUser));
    }
  }, [userList, props.user]);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await ProductDataService.getAll();
      setProducts(response.data);
      console.log('products:', products);
      // Update the subproducts state with all subproducts
      

      // console.log('All Subproducts:', allSubproducts);
    } catch (error) {
      console.error('Error al recuperar productos:', error);
    }
  };
  const handleCreate = () => {
    setSelectedCharacteristics([]); // Reset selected characteristics when opening the create modal
    setShowCreateModal(true);
  };

  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
    setName('');
    setDescription('');
    setFile(null);
    setFile1(null);
    setSelectedCharacteristics([]);
  };

  const handleEdit = (product) => {
    setSelectedProduct(product);
    setEditData({
      id: product.id,
      name: product.name,
      name_url: product.name_url,
      description: product.description,
      characteristics: product.characteristics,
      subproducts: product.subproducts,
    });

    setShowEditModal(true);
  };


  const handleSaveEdit = async () => {
    if (!selectedProduct) {
        console.error('Selected product is null or undefined');
        return;
    }

    const formData = new FormData();
    formData.append('id', selectedProduct.id); // ID del producto a editar
    formData.append('name', editData.name);
    formData.append('name_url', editData.name_url);
    formData.append('description', editData.description);

    // Agregar características seleccionadas en el formato correcto
    if (editData.characteristics && Array.isArray(editData.characteristics)) {
        const characteristicsToSend = editData.characteristics.map(char => ({
            id: char.id,
            name: char.name, // Asegúrate de que 'name' esté disponible en editData
            description: char.description // Asegúrate de que 'description' esté disponible en editData
        }));
        formData.append('characteristics', JSON.stringify(characteristicsToSend));
    }

    // Agregar archivos si existen
    if (file) {
        formData.append('file', file);
    }
    if (file1) {
        formData.append('file1', file1);
    }

    // Verificar datos antes de enviar
    console.log('Datos a enviar:', {
        id: selectedProduct.id,
        name: editData.name,
        name_url: editData.name_url,
        description: editData.description,
        characteristics: editData.characteristics.map(char => ({
            id: char.id,
            name: char.name,
            description: char.description
        })), // Mostrar objetos completos
    });

    try {
        const response = await ProductDataService.updateProduct(selectedProduct.id, formData, token);
        setShowEditModal(false);
        fetchProducts();
    } catch (error) {
        console.error('Error updating product:', error);
    }
};



  const handleEditNameChange = (e) => {
    const newName = e.target.value;
    setEditData({ ...editData, name: newName });
    setName(newName); // Update the name state as well
  };

  const handleEditDescriptionChange = (e) => {
    const newDescription = e.target.value;
    setEditData({ ...editData, description: newDescription });
    setDescription(newDescription); // Update the description state as well
  };
  const handleCloseEditModal = () => {
    setSelectedProduct(null);
    setShowEditModal(false);
    setName('');
    setDescription('');
    setFile(null);
    setFile1(null);
    setSelectedCharacteristics([]);
  };

  const [file, setFile] = useState(null);
  const [file1, setFile1] = useState(null);
  const [characteristicOptions, setCharacteristicOptions] = useState([]);
  const [selectedCharacteristics, setSelectedCharacteristics] = useState([]);
  const [nameUrl, setNameUrl] = useState('');
  const [services, setServices] = useState([]);

  useEffect(() => {
    fetchCharacteristicsOptions();
    fetchServices();
  }, []);

  const fetchCharacteristicsOptions = async () => {
    try {
      const response = await ProductDataService.getAllCharacteristics();
      setCharacteristicOptions(response.data);
    } catch (error) {
      console.error('Error al recuperar características:', error);
    }
  };

  const fetchServices = async () => {
    try {
      const response = await ProductDataService.getAllServices();
      console.log("Servicios iniciales", response.data);    
      setServices(response.data);
    } catch (error) {
      console.error('Error al recuperar servicios:', error);
    }
  };
  
  const handleSave = async () => {
    try {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('name_url', nameUrl);
      formData.append('description', description);
      const selectedCharacteristicIds = selectedCharacteristics.map(char => char.id);
      selectedCharacteristicIds.forEach(id => formData.append('characteristics', id));

      if (file) {
        formData.append('file', file);
      }
      if (file1) {
        formData.append('file1', file1);
      }
      const response = await ProductDataService.createProduct(formData, token);

      // Log the server response
      console.log('Server response:', response);
      handleCloseCreateModal();
      fetchProducts();
    } catch (error) {
      console.error('Error creating product:', error);
    }
  };



  const handleEditCharacteristicsChange = (e) => {
    const selectedCharacteristicStrings = Array.from(e.target.selectedOptions, option => option.value);
    const selectedCharacteristics = characteristicOptions.filter(char =>
      selectedCharacteristicStrings.includes(JSON.stringify(char))
    );
    setEditData((prevEditData) => ({ ...prevEditData, characteristics: selectedCharacteristics }));
  };

  const handleDelete = async (id) => {
    try {
      await ProductDataService.deleteProduct(id, token);
      // Fetch updated products list
      fetchProducts();
    } catch (error) {
      console.error('Error al eliminar el producto:', error);
    }
  };

  const handleDeleteS = async (id) => {
    try {
      await ProductDataService.deleteSubProduct(id, token);
      // Fetch updated products list
      fetchProducts();
    } catch (error) {
      console.error('Error al eliminar el producto:', error);
    }
  };


  const formatDate = (date) => {
    return moment(date).format('YYYY-MM-DD HH:mm:ss');
  };


  const handleCharacteristicsSelection = (e) => {
  const selectedOptions = Array.from(e.target.selectedOptions, option =>
    JSON.parse(option.value)
  );

  // Actualiza las características seleccionadas
  setSelectedCharacteristics(selectedOptions);

  // Si estás en modo edición, actualiza editData.characteristics
  if (showEditModal) {
    setEditData(prevEditData => ({
      ...prevEditData,
      characteristics: selectedOptions
    }));
  }
};

const handleCharacteristicsDoubleClick = () => {
  setSelectedCharacteristics([]);

  // Si estás en modo edición, también resetea las características en editData
  if (showEditModal) {
    setEditData(prevEditData => ({
      ...prevEditData,
      characteristics: []
    }));
  }
};


  const [selectedProducts, setSelectedProducts] = useState([]);

  const handleProductsSelection = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions, option =>
      JSON.parse(option.value)
    );
    setSelectedProducts(selectedOptions);
  };

  const handleProductsDoubleClick = () => {
    setSelectedProducts([]);
  };
;

  const [newServiceName, setNewServiceName] = useState(''); // Estado para el nombre del nuevo servicio
  const [newServiceDescription, setNewServiceDescription] = useState(''); // Estado para la descripción del nuevo servicio
  const [newServicePrice, setNewServicePrice] = useState(''); // Estado para el precio del nuevo servicio
  const [isFullScreen, setIsFullScreen] = useState(false); // Estado para pantalla completa

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen); // Cambiar entre pantalla completa y normal
  };
  
  const handleAddService = async () => {
    try {
      // Verificar si se ha seleccionado un subproducto
      if (!selectedSubproduct) {
        console.error('No se ha seleccionado un subproducto para agregar el servicio.');
        return;
      }

      // Validar que los campos requeridos no estén vacíos
      if (!newServiceName.trim() || !newServiceDescription.trim() || !newServicePrice.trim()) {
        console.error('Nombre, descripción y precio son campos obligatorios.');
        return;
      }

      // Crear un nuevo servicio con los datos proporcionados
      const newService = {
        name: newServiceName,
        description: newServiceDescription,
        price: parseFloat(newServicePrice), // Convertir el precio a número decimal
        subproduct: selectedSubproduct.id,
      };
      console.log("servicios", newService)
      // Enviar la solicitud para agregar el nuevo servicio al subproducto seleccionado
      const response = await ProductDataService.createServiceForSubProduct(selectedSubproduct.id, newService);

      // Actualizar la lista de servicios del subproducto
      setSelectedSubproductServices([...selectedSubproductServices, response.data]);

      // Limpiar los campos de entrada
      setNewServiceName('');
      setNewServiceDescription('');
      setNewServicePrice('');
    } catch (error) {
      console.error('Error al agregar el servicio:', error);
    }
  };

  const handleDeleteService = async (serviceId) => {
    try {
      // Verificar si se ha seleccionado un subproducto
      if (!selectedSubproduct) {
        console.error('No se ha seleccionado un subproducto para eliminar el servicio.');
        return;
      }

      // Enviar la solicitud para eliminar el servicio con el ID proporcionado
      await ProductDataService.deleteServiceForSubProduct(selectedSubproduct.id, serviceId);

      // Actualizar la lista de servicios del subproducto eliminando el servicio correspondiente
      const updatedServices = selectedSubproductServices.filter((service) => service.id !== serviceId);
      setSelectedSubproductServices(updatedServices);
    } catch (error) {
      console.error('Error al eliminar el servicio:', error);
    }
  };

  return (
    <div style={{marginTop:"10%"}}>
      {/* <br /><br /><br /><br /><br /> */}
      <h1>Archivos de pagina Servicios</h1>
      <Button variant="primary" onClick={handleCreate}>Crear un Servicio</Button>
      <br />
      <div style={{ overflowX: 'auto' }}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Nombre del producto</th>
              <th>Visualizacion</th>
              <th>Caracteristicas</th>
              <th>Drescripcion</th>
              <th>Creado por:</th>
              <th>Creado en:</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {currentProducts.map(product => (
              <tr key={product.id}>
                <td>{product.name}</td>
                <td>
                  {product.file && (
                    <div>

                      <br />
                      <img
                        src={product.file}
                        alt="Vista previa"
                        style={{ maxWidth: '100px', maxHeight: '100px' }}
                      />
                    </div>
                  )}
                  {product.file1 && (
                    <div>
                      <br />
                      <img
                        src={product.file1}
                        alt="Vista previa"
                        style={{ maxWidth: '100px', maxHeight: '100px' }}
                      />
                    </div>
                  )}
                </td>
                <td>
                  {product.characteristics.map(characteristic => (
                    <div key={characteristic.id}>
                      <strong>{characteristic.name}</strong>: {characteristic.description}
                    </div>
                  ))}
                </td>
                <td>{product.description}</td>
                <td>{product.user}</td>
                <td>{formatDate(product.created_at)}</td>
                <td>
                  <Button variant="info" onClick={() => handleEdit(product)}>Editar</Button>{' '}
                  <Button variant="danger" onClick={() => handleDelete(product.id)}>Eliminar</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {/* Paginación para la tabla de productos */}
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Button
          variant="secondary"
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Página Anterior
        </Button>{' '}
        {/* {renderPaginationButtons()} */}
        <Button
          variant="secondary"
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Página Siguiente
        </Button>
      </div>

      <Button variant="primary" onClick={handleCreateM}>Crear Caracteristica</Button>
      <div style={{ overflowX: 'auto' }}>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Nombre</th>
              <th>Descripcion</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {characteristicsList
              .slice((currentCharacteristicsPage - 1) * itemsPerPage, currentCharacteristicsPage * itemsPerPage)
              .map(characteristic => (
                <tr key={characteristic.id}>
                  <td>{characteristic.id}</td>
                  <td>{characteristic.name}</td>
                  <td>{characteristic.description}</td>
                  <td>
                    <Button variant="info" onClick={() => handleEditM(characteristic)}>
                      Editar
                    </Button>
                    <Button variant="danger" onClick={() => handleDeleteM(characteristic.id)}>
                      Eliminar
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button
            variant="secondary"
            onClick={() => setCurrentCharacteristicsPage(currentCharacteristicsPage - 1)}
            disabled={currentCharacteristicsPage === 1}
          >
            Página Anterior
          </Button>{' '}
          {/* {renderPaginationButtons(currentCharacteristicsPage, Math.ceil(characteristicsList.length / itemsPerPage), setCurrentCharacteristicsPage)} */}
          <Button
            variant="secondary"
            onClick={() => setCurrentCharacteristicsPage(currentCharacteristicsPage + 1)}
            disabled={currentCharacteristicsPage === Math.ceil(characteristicsList.length / itemsPerPage)}
          >
            Página Siguiente
          </Button>
        </div>
      </div>
      <Modal show={showCreateModalM} onHide={handleCloseCreateModalM} style={{overflowY: "auto"}}>
        {/* <Modal.Header closeButton> */}
          <Modal.Title>Crear característica</Modal.Title>
        {/* </Modal.Header> */}
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Nombre</Form.Label>
              <Form.Control type="text" value={nameM} onChange={(e) => setNameM(e.target.value)} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Descripción</Form.Label>
              <Form.Control as="textarea" rows={3} value={descriptionM} onChange={(e) => setDescriptionM(e.target.value)} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCreateModalM}>Cancelar</Button>
          <Button variant="primary" onClick={handleSubmitCreateM}>Crear</Button>
        </Modal.Footer>
      </Modal>

     
      {selectedCharacteristicM && (
        <Modal show={showEditModalM} onHide={handleCloseEditModalM} style={{overflowY: "auto"}}>
          {/* <Modal.Header closeButton> */}
            <Modal.Title>Editar característica</Modal.Title>
          {/* </Modal.Header> */}
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Nombre</Form.Label>
                <Form.Control type="text" value={nameM} onChange={(e) => setNameM(e.target.value)} />
              </Form.Group>
              <Form.Group>
                <Form.Label>Descripción</Form.Label>
                <Form.Control as="textarea" rows={3} value={descriptionM} onChange={(e) => setDescriptionM(e.target.value)} />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseEditModalM}>Cancelar</Button>
            <Button variant="primary" onClick={handleSubmitEditM}>Guardar los cambios</Button>
          </Modal.Footer>
        </Modal>
      )}

      <Modal show={showCreateModal} onHide={handleCloseCreateModal} 
      style={{overflowY: "auto"}}
      >
        {/* <Modal.Header closeButton> */}
          <Modal.Title>Crear Servicios</Modal.Title>
        {/* </Modal.Header> */}
        <Modal.Body>
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Nombre</Form.Label>
              <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)} />
            </Form.Group>
            <Form.Group controlId="formUrl">
              <Form.Label>URL</Form.Label>
              <Form.Control type="text" value={nameUrl} onChange={(e) => setNameUrl(e.target.value)} />
            </Form.Group>
            <Form.Group controlId="formDescription">
              <Form.Label>Descripción</Form.Label>
              <Form.Control as="textarea" rows={3} value={description} onChange={(e) => setDescription(e.target.value)} />
            </Form.Group>
            <Form.Group controlId="formFile">
              <Form.Label>Archivo</Form.Label>
              <Form.Control type="file" onChange={(e) => setFile(e.target.files[0])} />
            </Form.Group>
            <Form.Group controlId="formFile1">
              <Form.Label>Archivo característica</Form.Label>
              <Form.Control type="file" onChange={(e) => setFile1(e.target.files[0])} />
            </Form.Group>
            <Form.Group controlId="formCharacteristics">
              <Form.Label>Características</Form.Label>
              <Form.Control
                as="select"
                multiple
                value={selectedCharacteristics.map(char => JSON.stringify(char))}
                onChange={(e) => handleCharacteristicsSelection(e)}
                onDoubleClick={handleCharacteristicsDoubleClick}
                style={{ height: '40vh' }}  // Establece la altura a 80vh
              >
                {characteristicOptions.map((char, index) => (
                  <option
                    key={char.id}
                    value={JSON.stringify(char)}
                    disabled={selectedCharacteristics.some(
                      selectedChar => selectedChar.id === char.id
                    )}
                  >
                    {index + 1}. {char.name} {/* Muestra el índice empezando desde 1 */}
                    {/* <br /><br /><br /><br /><br />
                    {char.description} */}
                  </option>
                ))}
              </Form.Control>

            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCreateModal}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Crear
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showEditModal} onHide={handleCloseEditModal} style={{overflowY: "auto"}}
     >
        {/* <Modal.Header closeButton> */}
          <Modal.Title>Editar Servicio</Modal.Title>
        {/* </Modal.Header> */}
        <Modal.Body>
          <Form>
            <Form.Group controlId="editFormName">
              <Form.Label>Nombre</Form.Label>
              <Form.Control type="text" value={editData.name} onChange={handleEditNameChange} />
            </Form.Group>

            <Form.Group controlId="editFormDescription">
              <Form.Label>Descripcion</Form.Label>
              <Form.Control as="textarea" rows={3} value={editData.description} onChange={handleEditDescriptionChange} />
            </Form.Group>
            <Form.Group controlId="editFormNameUrl">
              <Form.Label>Nombre URL</Form.Label>
              <Form.Control type="text" value={editData.name_url} onChange={handleEditNameUrlChange} />
            </Form.Group>

            <Form.Group controlId="formFile">
              <Form.Label>Archivo</Form.Label>
              <Form.Control type="file" onChange={(e) => setFile(e.target.files[0])} />
            </Form.Group>
            <Form.Group controlId="formFile1">
              <Form.Label>Archivo caracteristica</Form.Label>
              <Form.Control type="file" onChange={(e) => setFile1(e.target.files[0])} />
            </Form.Group>
            <Form.Group controlId="formCharacteristics">
              <Form.Label>Características</Form.Label>
              <Form.Control
                as="select"
                multiple
                value={selectedCharacteristics.map(char => JSON.stringify(char))}
                onChange={(e) => handleCharacteristicsSelection(e)}
                onDoubleClick={handleCharacteristicsDoubleClick}
                style={{ height: '40vh' }}  // Establece la altura a 80vh
              >
                {characteristicOptions.map((char, index) => (
                  <option
                    key={char.id}
                    value={JSON.stringify(char)}
                    disabled={selectedCharacteristics.some(
                      selectedChar => selectedChar.id === char.id
                    )}
                  >
                    {index + 1}. {char.name} {/* Muestra el índice empezando desde 1 */}
                    {/* <br /><br /><br /><br /><br />
                    {char.description} */}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEditModal}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSaveEdit}>
            Guardar Cambios
          </Button>
        </Modal.Footer>

      </Modal>

    </div>


  );
};

export default CharacteristicsTable;