import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const ExperienceLevelModal = ({ show, handleClose, experienceLevel, handleSave }) => {
    const [level, setLevel] = useState(experienceLevel ? experienceLevel.level : '');

    const handleSubmit = (e) => {
        e.preventDefault();
        handleSave({ id: experienceLevel ? experienceLevel.id : null, level });
        handleClose();
    };

    // Lista de niveles de experiencia
    const experienceLevels = [
        'Entry-level',
        'Mid-level',
        'Senior-level',
        'Director',
        'Executive'
    ];

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{experienceLevel ? 'Editar Nivel de Experiencia' : 'Crear Nivel de Experiencia'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formExperienceLevel">
                        <Form.Label>Nivel</Form.Label>
                        <Form.Control 
                            as="select" 
                            value={level} 
                            onChange={(e) => setLevel(e.target.value)} 
                            required
                        >
                            <option value="">Seleccionar nivel</option>
                            {experienceLevels.map((expLevel) => (
                                <option key={expLevel} value={expLevel}>
                                    {expLevel}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Button variant="primary" type="submit">Guardar</Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default ExperienceLevelModal;
