import React, { useEffect, useState, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import ProductDataService from "../../../services/products";
import Publicidad from "../../../assets/visibilidad.png";
import { useMediaQuery } from "react-responsive";
import Modal from "react-modal";
import "./SubproductDetails.css";
import Avisoseconomicos from "./Avisos_clasificados_individual";
import HomeScreen from "../../ecommerce/Homescreenbyuser";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Portada from "../../../assets/catalogo/portada.png"
import Contraportada from "../../../assets/catalogo/contraportada.png"
import Quienessomos from "../../../assets/catalogo/quienessomos.png"

Modal.setAppElement("#root");

const SubproductDetails = () => {
  const location = useLocation();
  const { subproductId } = useParams();
  const [subproductData, setSubproductData] = useState(null);
  const [subProducts, setSubProducts] = useState([]);
  const [subCombos, setCombos] = useState([]);
  const [showWhatsAppModal, setShowWhatsAppModal] = useState(false);
  const [whatsAppMessage, setWhatsAppMessage] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [consulta, setConsulta] = useState("");
  const [services, setServices] = useState([]);
  const [quantity, setQuantity] = useState(0);
  const [quantities, setQuantities] = useState({});
  const [comboQuantities, setComboQuantities] = useState({});
  const [comboSubtotals, setComboSubtotals] = useState({});
  const [comboServicesTotals, setComboServicesTotals] = useState({});
  const [comboServices, setComboServices] = useState({});
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [allowedProduct, setAllowedProduct] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [query, setQuery] = useState("");
  const componentRef = useRef();
  const componentRef1 = useRef();
  // Función para manejar el cambio en el input
  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Función para ejecutar la búsqueda al presionar el botón
  const handleSearch = () => {
    setQuery(searchTerm); // Actualiza el query con lo que esté en el input
  };

  // Filtrado de los servicios por nombre o descripción
  const filteredServices = services.filter(
    (service) =>
      service.name.toLowerCase().includes(query.toLowerCase()) ||
      service.description.toLowerCase().includes(query.toLowerCase())
  );

  useEffect(() => {
    const fetchSubProducts = async () => {
      try {
        const response = await ProductDataService.getAllSubProduct();
        setSubProducts(response.data);
        // console.log(response.data);
      } catch (error) {
        console.error("Error fetching subproducts:", error);
      }
    };
    const fetchCombos = async () => {
      try {
        const response = await ProductDataService.getAllCombos();
        setCombos(response.data);
        console.log("Combos", response.data);
        const comboServicesData = await Promise.all(
          response.data.map(async (combo) => {
            const comboServicesResponse =
              await ProductDataService.getAllServicesForCombo(combo.id);
            return {
              comboId: combo.id,
              services: comboServicesResponse.data,
            };
          })
        );

        // Almacenar los servicios asociados a cada combo en el estado
        const comboServicesMap = comboServicesData.reduce((acc, comboData) => {
          acc[comboData.comboId] = comboData.services;
          return acc;
        }, {});
        setComboServices(comboServicesMap);
      } catch (error) {
        console.error("Error fetching subproducts:", error);
      }
    };
    fetchCombos();
    fetchSubProducts();
  }, []);

  useEffect(() => {
    const fetchCombosForSubproduct = async () => {
      if (!subproductId) return; // Asegúrate de que haya un subproducto seleccionado

      try {
        const response = await ProductDataService.getAllCombos();
        const allCombos = response.data;

        // Filtrar combos relacionados con el subproducto
        const filteredCombos = allCombos.filter((combo) =>
          combo.services.some((service) =>
            services.map((s) => s.id).includes(service.id)
          )
        );

        setCombos(filteredCombos); // Establecer solo los combos filtrados
        console.log("Combos filtrados:", filteredCombos);
      } catch (error) {
        console.error("Error fetching combos:", error);
      }
    };

    fetchCombosForSubproduct();
  }, [subproductId, services]); // Añadir 'services' como dependencia

  useEffect(() => {
    const selectedSubproduct = subProducts.find(
      (subProduct) => subProduct.email.toString() === subproductId.toString()
    );

    if (selectedSubproduct) {
      setSubproductData(selectedSubproduct);

      // Guardar el subproduct_id en una variable
      const subproduct_id = selectedSubproduct.id;

      // Utilizar subproduct_id en la solicitud de servicios
      const fetchServicesForSubProduct = async () => {
        try {
          const response = await ProductDataService.getAllServicesForSubProduct(
            subproduct_id
          );
          setServices(response.data);
          console.log("Services:", services);
        } catch (error) {
          console.error("Error fetching services:", error);
        }
      };

      fetchServicesForSubProduct();
    }
  }, [subProducts, subproductId]);

  useEffect(() => {
    if (subproductData) {
      document.title = subproductData.name || "Directorio de Comercios ABCupon";
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', subproductData.description || "Descripción del subproducto no disponible");
      }
    }
  }, [subproductData]);


  const openWhatsAppModal = () => {
    setShowWhatsAppModal(true);
  };

  const closeWhatsAppModal = () => {
    setShowWhatsAppModal(false);
  };

  const sendWhatsAppMessage = () => {
    if (subproductData && subproductData.phone) {
      const selectedServices = services.filter(
        (service) => quantities[service.id] && quantities[service.id] > 0
      );

      const selectedCombos = subCombos.filter(
        (combo) => comboQuantities[combo.id] && comboQuantities[combo.id] > 0
      );

      if (selectedServices.length > 0 || selectedCombos.length > 0) {
        const message = `Nombre: ${name}\nApellido: ${lastName}\nCorreo: ${email}\nConsulta: ${consulta}\n\n`;

        const servicesMessage = selectedServices.map((service) => {
          const quantity = quantities[service.id];
          const subtotal = calculateSubtotal(service.price, service.id);
          return `${quantity}x ${service.name} - ₡${service.price} c/u = ₡${subtotal}`;
        });

        const combosMessage = selectedCombos.map((combo) => {
          const quantity = comboQuantities[combo.id];
          const subtotal = calculateComboSubtotal(combo);
          return `${quantity}x ${combo.name} - ₡${combo.price} c/u = ₡${subtotal}`;
        });

        const totalPrice = calculateTotal() + calculateTotalCombos();
        const totalMessage = `Total: ₡${totalPrice.toLocaleString("es-CR")}`;

        const finalMessage = `${message}${servicesMessage.join(
          "\n"
        )}\n${combosMessage.join("\n")}\n${totalMessage}`;

        const phoneNumber = subproductData.phone_number;
        const whatsappLink = `https://api.whatsapp.com/send?phone=506${phoneNumber}&text=${encodeURIComponent(
          finalMessage
        )}`;
        window.open(whatsappLink, "_blank");
        closeWhatsAppModal();
      } else {
        console.error(
          "No hay servicios o combos seleccionados para enviar el mensaje."
        );
        alert(
          "No hay servicios o combos seleccionados para enviar el mensaje."
        );
      }
    } else {
      console.error("Número de teléfono no disponible.");
    }
  };

  const openURL = () => {
    if (subproductData && subproductData.url) {
      window.open(subproductData.url, "_blank");
    } else {
      console.error("URL no disponible.");
    }
  };
  const calculateSubtotal = (price, serviceId) => {
    const quantity = quantities[serviceId] || 0;
    return quantity * price;
  };

  const calculateTotal = () => {
    return services.reduce(
      (total, service) => total + calculateSubtotal(service.price, service.id),
      0
    );
  };
  const calculateComboTotal = (services) => {
    return services.reduce(
      (total, service) => total + parseFloat(service.price),
      0
    );
  };
  const calculateComboSubtotal = (combo) => {
    const quantity = comboQuantities[combo.id] || 0;
    return quantity * combo.price;
  };

  const calculateTotalCombos = () => {
    return subCombos.reduce(
      (total, combo) => total + calculateComboSubtotal(combo),
      0
    );
  };

  // // Función para determinar el tipo de imagen
  // const getImageType = (image) => {
  //   // Si es una URL, podemos hacer una solicitud para obtener el tipo
  //   // Para este ejemplo, asumimos que se recibe el tipo desde el backend
  //   return image.substring(0, 10).includes("data:image/")
  //     ? image.split(";")[0].split(":")[1] // Obtenemos el tipo del formato base64
  //     : "image/jpeg"; // Retorna un valor por defecto si es una URL
  // };
// Función para obtener el tipo de imagen
const getImageType = (image) => {
  // Verifica si es una imagen en base64
  if (image.startsWith("data:image/")) {
      return image.split(";")[0].split(":")[1]; // Obtener tipo del formato base64
  } else {
      console.warn("No se detecta un formato base64. Usando 'image/jpeg' como predeterminado.");
      return "image/jpeg"; // Retorna un valor por defecto si es una URL
  }
};
  const generatePDF = () => {
    const doc = new jsPDF();
    console.log("se presionó el botón");

    // Agregar datos de la empresa
    doc.setFontSize(18);
    doc.text("Cotización", 10, 10);

    // Datos de la empresa
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 255); // Color azul
    doc.text(`Nombre: ${subproductData.name}`, 10, 20);
    doc.text(`Teléfono: ${subproductData.phone}`, 10, 30);
    doc.text(`Correo: ${subproductData.email}`, 10, 40);

    // Agregar logo solo si está disponible y es válido
    const logo = subproductData.logo; // URL o base64
    const logoWidth = 50; // Ajusta el ancho del logo
    const logoHeight = 20; // Ajusta la altura del logo

    // Verificar si hay una imagen y que no esté corrupta
    if (logo) {
      const validImageFormats = [
        "image/png",
        "image/jpeg",
        "image/jpg",
        "image/gif",
      ]; // Formatos válidos
      const imageType = getImageType(logo); // Función para obtener el tipo de imagen

      if (validImageFormats.includes(imageType)) {
        // Probar añadir la imagen; si falla, la captura del error evitará que se rompa el PDF
        try {
          doc.addImage(
            logo,
            imageType.split("/")[1].toUpperCase(),
            150,
            10,
            logoWidth,
            logoHeight
          ); // Posición del logo en la página
        } catch (error) {
          console.error("Error al agregar la imagen:", error);
        }
      } else {
        console.warn("Formato de imagen no compatible:", imageType);
      }
    }

    // Agregar título de servicios
    doc.setFontSize(14);
    doc.setTextColor(255, 0, 0); // Color rojo para el título
    doc.text("Servicios", 10, 50);

    // Restablecer color del texto
    doc.setTextColor(0, 0, 0); // Color negro para los contenidos

    // Establecer la posición inicial de la tabla
    let yPosition = 60;

    // Títulos de las columnas
    doc.text("Descripción", 10, yPosition);
    doc.text("Precio", 100, yPosition);
    doc.text("Cantidad", 140, yPosition);
    doc.text("Subtotal", 180, yPosition);
    yPosition += 10; // Espaciado después de los títulos

    // Añadir servicios a la tabla
    services.forEach((service, index) => {
      const subtotal = calculateSubtotal(service.price, service.id);
      doc.setFontSize(12);

      // Ajustar el texto del nombre del servicio para evitar que se superponga
      const serviceName = `${index + 1}. ${service.name}`;
      const maxWidth = 90; // Ancho máximo para el nombre del servicio
      const nameLines = doc.splitTextToSize(serviceName, maxWidth); // Dividir el texto si es necesario

      // Dibujar el nombre del servicio en múltiples líneas si es necesario
      nameLines.forEach((line, lineIndex) => {
        doc.text(line, 10, yPosition + lineIndex * 10);
      });

      // Asegurar que la posición yHeight se actualice correctamente
      yPosition += nameLines.length * 5;

      // Agregar precios, cantidades y subtotales sin el símbolo "₡"
      doc.text(`${service.price.toLocaleString("es-CR")}`, 100, yPosition);
      doc.text(`${quantities[service.id] || 0}`, 140, yPosition);
      doc.text(`${subtotal.toLocaleString("es-CR")}`, 180, yPosition);

      yPosition += 10; // Mover hacia abajo para la siguiente línea
    });

    // Calcular el total
    const totalPrice = calculateTotal();

    // Agregar total sin el símbolo "₡"
    doc.text(
      `Total: ${totalPrice.toLocaleString("es-CR")}`,
      10,
      yPosition + 10
    );

    // Agregar leyenda al pie de página
    doc.setFontSize(10);
    doc.text("Cotización realizada desde ABCupon 2024", 100, yPosition + 30, {
      align: "center",
    });

    // Descargar el PDF
    doc.save(`Cotizacion-${subproductData.name}.pdf`);
  };

  const generatePDF1 = () => {
    const input = componentRef.current;

    // Guardar los estilos originales
    const originalWidth = input.style.width;
    const originalFontSize = input.style.fontSize;
    const originalTransform = input.style.transform;

    // Seleccionar el elemento de categoría y guardar su estilo original
    const categoriaElement = input.querySelector(".categoria-upper");
    const originalMarginTop = categoriaElement.style.marginTop;
    const originalMarginBottom = categoriaElement.style.marginBottom;

    // Establecer los nuevos márgenes
    categoriaElement.style.marginTop = "5px";
    categoriaElement.style.marginBottom = "5px";

    // Seleccionar todos los elementos con la clase .clasificado1 y eliminar la clase temporalmente
    const clasificado1Elements = input.querySelectorAll(".clasificado1");
    clasificado1Elements.forEach((element) => element.classList.remove("clasificado1"));

    // Establecer un tamaño fijo como si fuera una pantalla de escritorio (1920px)
    input.style.width = "1920px";
    input.style.fontSize = "100%"; // Ajustar el tamaño de fuente para el PDF
    input.style.transform = "scale(1)"; // Asegurar que no haya transformaciones activas

    // Usar html2canvas con una escala alta para asegurar buena calidad
    html2canvas(input, {
        useCORS: true,
        scale: 2, // Aumentar la escala para mejorar la calidad de la captura
    })
        .then((canvas) => {
            const imgData = canvas.toDataURL("image/png"); // Convertir el canvas a imagen
            const pdf = new jsPDF("p", "mm", "a4");

            // Ajustar el ancho y altura de la imagen para que se ajuste mejor a la página A4
            const imgWidth = 190; // Ajustar el ancho de la imagen en mm
            const pageHeight = 295; // Altura de la página A4 en mm
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            let position = 0;

            // Añadir la imagen al PDF
            pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            // Añadir páginas adicionales si el contenido excede una página
            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            // Añadir pie de página
            const footerText = "Clasificados económicos realizado en ABCupon.com";
            pdf.setFontSize(10); // Ajustar el tamaño de fuente
            const footerWidth =
                (pdf.getStringUnitWidth(footerText) * pdf.internal.getFontSize()) /
                pdf.internal.scaleFactor; // Ancho del texto en unidades PDF
            const footerX = (pdf.internal.pageSize.getWidth() - footerWidth) / 2; // Centrar el texto
            const footerY = pdf.internal.pageSize.getHeight() - 10; // Posición Y del pie de página

            pdf.text(footerText, footerX, footerY); // Agregar pie de página

            // Guardar el PDF
            pdf.save(`Clasificados-${subproductData.name}.pdf`);
        })
        .catch((error) => {
            console.error("Error generando el PDF: ", error);
        })
        .finally(() => {
            // Restaurar los estilos originales
            input.style.width = originalWidth;
            input.style.fontSize = originalFontSize;
            input.style.transform = originalTransform;

            // Restaurar los márgenes originales
            categoriaElement.style.marginTop = originalMarginTop;
            categoriaElement.style.marginBottom = originalMarginBottom;

            // Volver a agregar la clase .clasificado1 después de generar el PDF
            clasificado1Elements.forEach((element) => element.classList.add("clasificado1"));
        });
};




  const generatePDF2 = () => {
    const input = componentRef1.current;

    // Guardar los estilos originales
    const originalStyles = {
      width: input.style.width,
      fontSize: input.style.fontSize,
      letterSpacing: input.style.letterSpacing,
      wordSpacing: input.style.wordSpacing,
      lineHeight: input.style.lineHeight,
      padding: [],
      borders: [],
      buttonStyles: [],
    };

    // Ocultar todos los TextSlider
    const textSliders = input.querySelectorAll(".text-slider-class");
    textSliders.forEach((textSlider) => {
      textSlider.style.display = "none";
    });

    // Establecer un tamaño fijo como si fuera una pantalla de escritorio (1920px)
    input.style.width = "1920px";
    input.style.fontSize = "80%";
    input.style.letterSpacing = "10px";
    input.style.wordSpacing = "1px";

    // Seleccionar todos los elementos que se van a modificar
    const h2Elements = input.querySelectorAll("h2");
    const h3Elements = input.querySelectorAll("h3");
    const h4Elements = input.querySelectorAll("h4");
    const tdElements = input.querySelectorAll("td");
    const buttonElements = input.querySelectorAll("button");

    // Ajustar y guardar los estilos de los botones
    buttonElements.forEach((element, index) => {
      originalStyles.buttonStyles[index] = {
        marginTop: element.style.marginTop,
        lineHeight: element.style.lineHeight,
        letterSpacing: element.style.letterSpacing,
        wordSpacing: element.style.wordSpacing,
      };

      element.style.marginTop = "40px";
      element.style.lineHeight = "3";
      element.style.letterSpacing = "15px";
      element.style.wordSpacing = "5px";
    });

    // Ajustar h2, h3, h4 y td
    h2Elements.forEach((element) => {
      element.style.marginTop = "10px";
      element.style.lineHeight = "3";
    });

    h3Elements.forEach((element) => {
      element.style.marginTop = "-30px";
      element.style.lineHeight = "5";
    });

    h4Elements.forEach((element) => {
      element.style.marginTop = "-35px";
      element.style.lineHeight = "2";
      element.style.marginBottom = "10px";
    });

    tdElements.forEach((element, index) => {
      originalStyles.padding[index] = element.style.padding;
      originalStyles.borders[index] = element.style.borderBottom;
      element.style.lineHeight = "2.7";
      element.style.borderBottom = "none";
    });

    // Usar html2canvas
    html2canvas(input, {
      useCORS: true,
      scale: 2,
    })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");

        const imgWidth = 190; // Ancho de la imagen en mm
        const pageHeight = 295; // Altura de la página A4
        const imgHeight = (canvas.height * imgWidth) / canvas.width + 50; // Incrementar la altura en 50px
        let heightLeft = imgHeight;
        let position = 0;

        // Añadir la imagen al PDF
        pdf.addImage(
          imgData,
          "PNG",
          (pdf.internal.pageSize.getWidth() - imgWidth) / 2,
          position,
          imgWidth,
          imgHeight
        ); // Centrar la imagen
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(
            imgData,
            "PNG",
            (pdf.internal.pageSize.getWidth() - imgWidth) / 2,
            position,
            imgWidth,
            imgHeight
          ); // Centrar la imagen
          heightLeft -= pageHeight;
        }

        // Añadir pie de página
        const footerText = "Catálogo digital realizado en ABCupon.com";
        pdf.setFontSize(10); // Ajustar el tamaño de fuente
        const footerWidth =
          (pdf.getStringUnitWidth(footerText) * pdf.internal.getFontSize()) /
          pdf.internal.scaleFactor; // Ancho del texto en unidades PDF
        const footerX = (pdf.internal.pageSize.getWidth() - footerWidth) / 2; // Centrar el texto
        const footerY = pdf.internal.pageSize.getHeight() - 10; // Posición Y del pie de página

        pdf.text(footerText, footerX, footerY); //

        // Guardar el PDF
        pdf.save(`Productos-${subproductData.name}.pdf`);
      })
      .catch((error) => {
        console.error("Error generating PDF: ", error);
      })
      .finally(() => {
        // Restaurar los estilos originales
        input.style.width = originalStyles.width;
        input.style.fontSize = originalStyles.fontSize;
        input.style.letterSpacing = originalStyles.letterSpacing;
        input.style.wordSpacing = originalStyles.wordSpacing;

        h2Elements.forEach((element) => {
          element.style.marginTop = "0";
          element.style.lineHeight = originalStyles.lineHeight;
        });

        h3Elements.forEach((element) => {
          element.style.marginTop = "0";
          element.style.lineHeight = originalStyles.lineHeight;
        });

        h4Elements.forEach((element) => {
          element.style.lineHeight = originalStyles.lineHeight;
          element.style.marginBottom = "0";
        });

        tdElements.forEach((element, index) => {
          element.style.lineHeight = "normal";
          element.style.padding = originalStyles.padding[index];
          element.style.borderBottom = originalStyles.borders[index];
        });

        buttonElements.forEach((element, index) => {
          element.style.marginTop =
            originalStyles.buttonStyles[index].marginTop;
          element.style.lineHeight =
            originalStyles.buttonStyles[index].lineHeight;
          element.style.letterSpacing =
            originalStyles.buttonStyles[index].letterSpacing;
          element.style.wordSpacing =
            originalStyles.buttonStyles[index].wordSpacing;
        });

        textSliders.forEach((textSlider) => {
          textSlider.style.display = "block";
        });
      });
  };

  const generatePDF3 = async () => {
    const input1 = componentRef.current; // Referencia para el primer componente
    const input2 = componentRef1.current; // Referencia para el segundo componente

    // Seleccionar el elemento de categoría y guardar su estilo original
    const categoriaElement = input1.querySelector(".categoria-upper");
    const originalMarginTop = categoriaElement.style.marginTop;
    const originalMarginBottom = categoriaElement.style.marginBottom;

    // Establecer los nuevos márgenes
    categoriaElement.style.marginTop = "5px";
    categoriaElement.style.marginBottom = "5px";

    // Seleccionar todos los elementos con la clase .clasificado1 y eliminar la clase temporalmente
    const clasificado1Elements = input1.querySelectorAll(".clasificado1");
    clasificado1Elements.forEach((element) => element.classList.remove("clasificado1"));

    // Estilos originales de input1
    const originalStyles1 = {
      width: input1.style.width,
      fontSize: input1.style.fontSize,
      transform: input1.style.transform,
    };

    // Aplicar estilos a input1 (Componente 1)
    input1.style.width = "1920px";
    input1.style.fontSize = "100%";
    input1.style.transform = "scale(1)";

    // Generar imagen de input1
    const canvas1 = await html2canvas(input1, {
      useCORS: true,
      scale: 2,
    });
    const imgData1 = canvas1.toDataURL("image/jpeg", 0.7);

    // Restaurar estilos originales de input1
    Object.keys(originalStyles1).forEach((style) => {
      input1.style[style] = originalStyles1[style];
    });

    // Estilos originales de input2 (Componente 2)
    const originalStyles2 = {
      width: input2.style.width,
      fontSize: input2.style.fontSize,
      letterSpacing: input2.style.letterSpacing,
      wordSpacing: input2.style.wordSpacing,
      lineHeight: input2.style.lineHeight,
      padding: [],
      borders: [],
      buttonStyles: [],
      h2Styles: [],
      h3Styles: [],
      h4Styles: [],
    };

    // Ocultar todos los TextSlider
    const textSliders = input2.querySelectorAll(".text-slider-class");
    textSliders.forEach((textSlider) => {
      textSlider.style.display = "none";
    });

    // Aplicar estilos a input2 (Componente 2)
    input2.style.width = "1920px";
    input2.style.fontSize = "80%";
    input2.style.letterSpacing = "10px";
    input2.style.wordSpacing = "1px";

    // Ajustar elementos h2, h3, h4, td, y botones en input2
    const h2Elements = input2.querySelectorAll("h2");
    const h3Elements = input2.querySelectorAll("h3");
    const h4Elements = input2.querySelectorAll("h4");
    const tdElements = input2.querySelectorAll("td");
    const buttonElements = input2.querySelectorAll("button");

    h2Elements.forEach((element, index) => {
      originalStyles2.h2Styles[index] = {
        marginTop: element.style.marginTop,
        lineHeight: element.style.lineHeight,
      };
      element.style.marginTop = "10px";
      element.style.lineHeight = "3";
    });

    h3Elements.forEach((element, index) => {
      originalStyles2.h3Styles[index] = {
        marginTop: element.style.marginTop,
        lineHeight: element.style.lineHeight,
      };
      element.style.marginTop = "-30px";
      element.style.lineHeight = "5";
    });

    h4Elements.forEach((element, index) => {
      originalStyles2.h4Styles[index] = {
        marginTop: element.style.marginTop,
        lineHeight: element.style.lineHeight,
        marginBottom: element.style.marginBottom,
      };
      element.style.marginTop = "-35px";
      element.style.lineHeight = "2";
      element.style.marginBottom = "10px";
    });

    tdElements.forEach((element, index) => {
      originalStyles2.padding[index] = element.style.padding;
      originalStyles2.borders[index] = element.style.borderBottom;
      element.style.lineHeight = "2.7";
      element.style.borderBottom = "none";
    });

    buttonElements.forEach((element, index) => {
      originalStyles2.buttonStyles[index] = {
        marginTop: element.style.marginTop,
        lineHeight: element.style.lineHeight,
        letterSpacing: element.style.letterSpacing,
        wordSpacing: element.style.wordSpacing,
      };

      element.style.marginTop = "40px";
      element.style.lineHeight = "3";
      element.style.letterSpacing = "15px";
      element.style.wordSpacing = "5px";
    });

    // Generar imagen de input2
    const canvas2 = await html2canvas(input2, {
      useCORS: true,
      scale: 2,
    });
    const imgData2 = canvas2.toDataURL("image/jpeg", 0.7);

    // Crear el PDF y añadir ambas imágenes
    const pdf = new jsPDF("p", "mm", "a4");

    const imgWidth = 190; // Ajuste del ancho en mm
    const pageHeight = 295; // Altura de la página A4 en mm
    const pageWidth = 210; // Ancho de la página A4 en mm
    const marginLeft = pageWidth * 0.05; // 5% de margen izquierdo
    const marginTop = pageHeight * 0.05; // 5% de margen superior solo para input2

    const footerText = "Catalogo generado por ABCupon"; // Pie de página
    const footerFontSize = 8; // Tamaño del pie de página más pequeño
    const footerPositionY = pageHeight - 10; // Posición del pie de página desde la parte inferior
    let pageNumber = 1; // Contador de páginas

    // Agregar imágenes estáticas a las primeras páginas en el orden deseado
    const imagePaths = [
      Portada,        // Página 1
      Quienessomos,   // Página 2
  ];

  for (const path of imagePaths) {
    const img = new Image();
    img.src = path;
  
    await new Promise((resolve) => {
      img.onload = () => {
        const imgHeight = (img.height * imgWidth) / img.width;
        pdf.addImage(img.src, "PNG", marginLeft, marginTop, imgWidth - marginLeft, imgHeight);
        // Asegúrate de que el tamaño y la posición del pie de página sean consistentes
        pdf.setFontSize(footerFontSize); // Establecer el tamaño de la fuente
        pdf.text(footerText, pageWidth / 2, footerPositionY, { align: "center" });
        pdf.text(`Página ${pageNumber}`, pageWidth - 20, footerPositionY, { align: "right" }); // Número de página
        pageNumber++;
        resolve();
      };
    });
  
    pdf.addPage(); // Agregar una nueva página para la siguiente imagen
  }

    
    // Agregar la primera imagen al PDF
    const imgHeight1 = (canvas1.height * imgWidth) / canvas1.width;
    let position = 0; // Sin margen superior para input1
    pdf.addImage(
      imgData1,
      "PNG",
      marginLeft,
      position,
      imgWidth - marginLeft,
      imgHeight1
    );

    // Agregar el pie de página a la primera página
    pdf.setFontSize(footerFontSize);
    pdf.text(footerText, pageWidth / 2, footerPositionY, { align: "center" });
    pdf.text(`Página ${pageNumber}`, pageWidth - 20, footerPositionY); // Número de página en la esquina derecha
    pageNumber++;

    // Agregar páginas adicionales si la primera imagen es mayor a una página
    let heightLeft = imgHeight1 - pageHeight;
    while (heightLeft > 0) {
      position = heightLeft - imgHeight1;
      pdf.addPage();
      pdf.addImage(
        imgData1,
        "PNG",
        marginLeft,
        position,
        imgWidth - marginLeft,
        imgHeight1
      );
      pdf.text(footerText, pageWidth / 2, footerPositionY, { align: "center" });
      pdf.text(`Página ${pageNumber}`, pageWidth - 20, footerPositionY); // Número de página
      heightLeft -= pageHeight;
      pageNumber++;
    }

    // Agregar la segunda imagen al PDF
    const imgHeight2 = (canvas2.height * imgWidth) / canvas2.width;
    heightLeft = imgHeight2;
    pdf.addPage();
    pdf.addImage(
      imgData2,
      "PNG",
      marginLeft,
      marginTop,
      imgWidth - marginLeft,
      imgHeight2
    );

    // Agregar el pie de página a la segunda imagen
    pdf.text(footerText, pageWidth / 2, footerPositionY, { align: "center" });
    pdf.text(`Página ${pageNumber}`, pageWidth - 20, footerPositionY); // Número de página en la esquina derecha

    // Agregar una nueva página para la contraportada
    const ContraportadaImage = new Image();
    ContraportadaImage.src = Contraportada;

    await new Promise((resolve) => {
        ContraportadaImage.onload = () => {
            const imgHeight = (ContraportadaImage.height * imgWidth) / ContraportadaImage.width;
            pdf.addPage();
            pdf.addImage(ContraportadaImage.src, "PNG", marginLeft, marginTop, imgWidth - marginLeft, imgHeight);
            pdf.text(footerText, pageWidth / 2, footerPositionY, { align: "center" });
            pdf.text(`Página ${pageNumber}`, pageWidth - 20, footerPositionY); // Número de página
            pageNumber++;
            resolve();
        };
    });
    // Guardar el PDF
    pdf.save(`Catalogo de ${subproductData.name}.pdf`);

    // Restaurar estilos originales de input2
    Object.keys(originalStyles2).forEach((style) => {
      input2.style[style] = originalStyles2[style];
    });

    // Restaurar los TextSlider en input2
    textSliders.forEach((textSlider) => {
      textSlider.style.display = "block";
    });

    // Restaurar estilos en botones, h2, h3, h4, y td
    h2Elements.forEach((element, index) => {
      element.style.marginTop = originalStyles2.h2Styles[index].marginTop;
      element.style.lineHeight = originalStyles2.h2Styles[index].lineHeight;
    });

    h3Elements.forEach((element, index) => {
      element.style.marginTop = originalStyles2.h3Styles[index].marginTop;
      element.style.lineHeight = originalStyles2.h3Styles[index].lineHeight;
    });

    h4Elements.forEach((element, index) => {
      element.style.marginTop = originalStyles2.h4Styles[index].marginTop;
      element.style.lineHeight = originalStyles2.h4Styles[index].lineHeight;
      element.style.marginBottom = originalStyles2.h4Styles[index].marginBottom;
    });

    tdElements.forEach((element, index) => {
      element.style.lineHeight = "normal";
      element.style.padding = originalStyles2.padding[index];
      element.style.borderBottom = originalStyles2.borders[index];
    });

    buttonElements.forEach((element, index) => {
      element.style.marginTop = originalStyles2.buttonStyles[index].marginTop;
      element.style.lineHeight = originalStyles2.buttonStyles[index].lineHeight;
      element.style.letterSpacing =
        originalStyles2.buttonStyles[index].letterSpacing;
      element.style.wordSpacing =
        originalStyles2.buttonStyles[index].wordSpacing;
    });
    // Restaurar los márgenes originales de la categoría
    categoriaElement.style.marginTop = originalMarginTop;
    categoriaElement.style.marginBottom = originalMarginBottom;
 // Volver a añadir la clase .clasificado1 a los elementos
 clasificado1Elements.forEach((element) => element.classList.add("clasificado1"));

  };

  return (
    <div>
      <br />
      <br />
      <br />
      <br />
      {subproductData && (
        <div>
          <div
            style={{
              border: "2px solid #ccc",
              borderRadius: "10px",
              padding: "20px",
              boxShadow: "5px 5px 10px #888888",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
              marginBottom: "20px",
            }}
          >
            {subproductData.logo ? (
              <img
                src={subproductData.logo}
                alt="Logo del subproducto"
                style={{
                  maxWidth: "50%", // Máximo 50% del ancho de la pantalla
                  maxHeight: "80vh", // Máximo 80% de la altura de la pantalla
                  border: "2px solid red", // Borde rojo
                  boxShadow: "0 0 10px black", // Sombreado negro
                  borderRadius: "10px", // Esquinas redondeadas
                }}
              />
            ) : (
              <img
                src={Publicidad}
                alt="Imagen de Publicidad"
                style={{
                  maxWidth: "50%", // Máximo 50% del ancho de la pantalla
                  maxHeight: "80vh", // Máximo 80% de la altura de la pantalla
                  border: "2px solid red", // Borde rojo
                  boxShadow: "0 0 10px black", // Sombreado negro
                  borderRadius: "10px", // Esquinas redondeadas
                }}
              />
            )}

            <h1
              style={{
                fontSize: "3em",
                color: "red",
                textShadow: "2px 2px 4px #000",
                fontWeight: "bold",
                marginBottom: "10px",
                marginTop: "10px",
                textAlign: "left",
              }}
            >
              {subproductData.name}
            </h1>
            <h3
              style={{
                fontSize: "2em",
                color: "red",
                textShadow: "2px 2px 2px #000",
                fontWeight: "bold",
                marginTop: "0px",
                marginBottom: "20px",
                textAlign: "left",
              }}
            >
              {subproductData.comercial_activity}
            </h3>
            <button
              onClick={generatePDF3}
              style={{
                backgroundColor: "white",
                color: "black",
                borderRadius: "5px",
                border: "none",
                cursor: "pointer",
                borderColor: "black",
                borderWidth: "0.4px",
                borderStyle: "solid",
                fontSize: "1.8em",
                zIndex: "1",
                marginBottom: "2%",
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "green";
                e.target.style.color = "white";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "white";
                e.target.style.color = "black";
              }}
            >
              Descargar Catalogo Digital
            </button>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                padding: "2rem",
                backgroundColor: "#f4f4f4", // Fondo suave para destacar el contenido
                borderRadius: "12px", // Bordes redondeados para un look moderno
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Sombra suave para elevar el contenedor
                maxWidth: isMobile ? "100%" : "80%", // Ancho máximo del contenedor
                margin: "0 auto", // Centra el contenido horizontalmente en la página
              }}
            >
              <p
                style={{
                  fontSize: "1.8em",
                  marginBottom: "1rem",
                  color: "#333",
                }}
              >
                <strong>Correo:</strong>{" "}
                {isMobile && subproductData.email.length > 50
                  ? subproductData.email
                      .match(/.{1,50}/g)
                      .map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))
                  : subproductData.email}
              </p>

              <p
                style={{
                  fontSize: "1.8em",
                  marginBottom: "1rem",
                  color: "#333",
                }}
              >
                <strong>Teléfono:</strong> {subproductData.phone}
              </p>

              <div
                style={{
                  fontSize: "1.8em",
                  marginBottom: "1rem",
                  color: "#333",
                  textAlign: "justify",
                }}
              >
                <strong>Descripción:</strong>
                <ul
                  style={{
                    paddingLeft: "1.5em",
                    marginTop: "0.5rem",
                    listStyleType: "disc",
                    lineHeight: "1.6",
                  }}
                >
                  {subproductData.description ? (
                    subproductData.description
                      .split(
                        /(?<!\w+\.\w{2,})(?<=\.)(?= )|(?<=\w+@\w+\.\w{2,})\./
                      )
                      .map(
                        (sentence, index) =>
                          sentence.trim() && (
                            <li key={index} style={{ marginBottom: "0.5rem" }}>
                              {sentence.trim() + ""}
                            </li>
                          )
                      )
                  ) : (
                    <li>No hay descripción disponible.</li> // O cualquier otro mensaje que desees mostrar
                  )}
                </ul>
              </div>

              <div
                style={{
                  fontSize: "1.8em",
                  marginBottom: "1rem",
                  color: "#333",
                  marginRight: "32%",
                }}
              >
                <p>
                  <strong>Contacto:</strong> {subproductData.contact_name}
                </p>
              </div>
              <p
                style={{
                  fontSize: "1.8em",
                  marginBottom: "1rem",
                  color: "#333",
                }}
              >
                <strong>País:</strong> {subproductData.country}
              </p>

              <p
                style={{
                  fontSize: "1.8em",
                  marginBottom: "1rem",
                  color: "#333",
                  marginRight: "24%",
                }}
              >
                <strong>Método de Pago:</strong> {subproductData.pay_method}
              </p>

              <p
                style={{
                  fontSize: isMobile ? "1.0em" : "1.8em",
                  cursor: "pointer",
                  marginRight: "40%",
                  color: "#007bff", // Color del enlace
                  textDecoration: "underline", // Subrayado para el enlace
                  wordBreak: "break-word", // Permite que las palabras se dividan
                  maxWidth: isMobile ? "90%" : "100%", // Limita el ancho en móviles
                  whiteSpace: "normal", // Permite que el texto use múltiples líneas
                }}
                onClick={openURL}
              >
                <strong>URL: </strong>
                {subproductData.url}
              </p>

              {/* Si deseas agregar productos, puedes habilitar esta sección */}
              {/* <p style={{ fontSize: "1.8em", marginBottom: "1rem", color: "#333" }}>
    <strong>Productos:</strong> {subproductData.product_names}
  </p> */}
            </div>

            <h2
              style={{
                fontSize: "2em",
                color: "red",
                textShadow: "2px 2px 2px #000",
                fontWeight: "bold",
                marginTop: "10px",
                marginBottom: "20px",
                textAlign: "center",
              }}
            >
              Servicios Ofrecidos
            </h2>

            <input
              type="text"
              value={searchTerm}
              onChange={handleInputChange}
              placeholder="Escriba el nombre del servicio que esta interesado"
              style={{
                backgroundColor: "white",
                color: "black",
                fontWeight: "bold",
                border: "1px solid black",
                padding: "10px 20px",
                cursor: "pointer",
                width: "50%",
                transition: "background-color 0.3s, color 0.3s",
                // boxShadow: "0 0 5px red",
                borderRadius: "10px",
                marginBottom: "2%",
              }}
            />

            <button
              onClick={handleSearch}
              style={{
                backgroundColor: "white",
                color: "black",
                fontWeight: "bold",
                border: "1px solid black",
                padding: "10px 20px",
                cursor: "pointer",
                width: "50vh",
                transition: "background-color 0.3s, color 0.3s",
                // boxShadow: "0 0 5px red",
                borderRadius: "10px",
                marginBottom: "2%",
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "green";
                e.target.style.color = "white";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "white";
                e.target.style.color = "black";
              }}
            >
              Presione para buscar
            </button>

            <button
              onClick={generatePDF}
              style={{
                backgroundColor: "white",
                color: "black",
                borderRadius: "5px",
                border: "none",
                cursor: "pointer",
                borderColor: "black",
                borderWidth: "0.4px",
                borderStyle: "solid",
                fontSize: "1.8em",
                marginBottom: "2%",
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "green";
                e.target.style.color = "white";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "white";
                e.target.style.color = "black";
              }}
            >
              Descargar Cotización de servicios ofrecidos en PDF
            </button>

            <div
              style={{
                width: "80%",
                height: "75vh", // 80% de la altura de la pantalla
                margin: "0 auto",
                backgroundColor: "white",
                border: "2px solid black",
                borderRadius: "10px",
                boxShadow: "5px 5px 10px #888888",
                padding: "20px",
                overflowY: "auto", // Scroll vertical activado,
              }}
            >
              <table style={{ width: "100%" }}>
                <thead
                  style={{
                    background: "#ddd",
                    color: "white",
                    fontWeight: "bold",
                    borderBottom: "2px solid black",
                  }}
                >
                  <tr>
                    <th
                      style={{
                        padding: "10px",
                        borderRight: "2px solid black",
                        color: "black",
                      }}
                    >
                      Cantidad
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        borderRight: "2px solid black",
                        color: "black",
                      }}
                    >
                      Nombre
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        borderRight: "2px solid black",
                        color: "black",
                      }}
                    >
                      Descripción
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        borderRight: "2px solid black",
                        color: "black",
                      }}
                    >
                      Precio Unitario
                    </th>
                    <th style={{ padding: "10px", color: "black" }}>
                      Subtotal
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredServices.length > 0 ? (
                    filteredServices.map((service) => (
                      <tr
                        key={service.id}
                        style={{ borderBottom: "1px solid green" }}
                      >
                        <td
                          style={{
                            padding: "10px",
                            borderRight: "2px solid black",
                          }}
                        >
                          <div style={{ position: "relative", zIndex: 2 }}>
                            <input
                              type="number"
                              value={quantities[service.id] || 0}
                              onChange={(e) => {
                                const newQuantity = parseInt(
                                  e.target.value,
                                  10
                                );
                                if (newQuantity >= 0) {
                                  const newQuantities = {
                                    ...quantities,
                                    [service.id]: newQuantity,
                                  };
                                  setQuantities(newQuantities);
                                }
                              }}
                              style={{ width: "50px" }}
                            />
                          </div>
                        </td>
                        <td style={{ padding: "10px", fontWeight: "bold" }}>
                          {service.name}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            borderRight: "2px solid black",
                          }}
                        >
                          {service.description}
                        </td>
                        <td
                          style={{
                            padding: "10px",
                            borderRight: "2px solid black",
                          }}
                        >
                          ₡{service.price}
                        </td>
                        <td style={{ padding: "10px" }}>
                          ₡{calculateSubtotal(service.price, service.id)}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="5"
                        style={{ textAlign: "center", padding: "20px" }}
                      >
                        <h3>No hay servicios disponibles.</h3>
                        <p>Mostrando datos predeterminados.</p>
                      </td>
                    </tr>
                  )}

                  {/* Aquí se muestra la sección de combos si no hay servicios */}
                  {services.length === 0 && subCombos.length > 0 && (
                    <tr>
                      <td colSpan="5">
                        <div
                          style={{
                            margin: "20px 0",
                            backgroundColor: "#f9f9f9",
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                            padding: "10px",
                          }}
                        >
                          <h4>Combos Disponibles:</h4>
                          <table style={{ width: "100%" }}>
                            <thead
                              style={{
                                background: "#000000",
                                color: "white",
                                fontWeight: "bold",
                                borderBottom: "2px solid black",
                              }}
                            >
                              <tr>
                                <th style={{ color: "black", padding: "10px" }}>
                                  Cantidad
                                </th>
                                <th
                                  style={{
                                    color: "black",
                                    padding: "10px",
                                    borderRight: "2px solid black",
                                  }}
                                >
                                  Nombre
                                </th>
                                <th
                                  style={{
                                    color: "black",
                                    padding: "10px",
                                    borderRight: "2px solid black",
                                  }}
                                >
                                  Descripción
                                </th>
                                <th
                                  style={{
                                    color: "black",
                                    padding: "10px",
                                    borderRight: "2px solid black",
                                  }}
                                >
                                  Servicios
                                </th>
                                <th
                                  style={{
                                    color: "black",
                                    padding: "10px",
                                    borderRight: "2px solid black",
                                  }}
                                >
                                  Precio
                                </th>
                                <th style={{ color: "black", padding: "10px" }}>
                                  Subtotal
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {subCombos.map((combo, index) => (
                                <tr
                                  key={index}
                                  style={{ borderBottom: "1px solid green" }}
                                >
                                  <td style={{ padding: "10px" }}>
                                    <input
                                      type="number"
                                      value={comboQuantities[combo.id] || 0}
                                      onChange={(e) => {
                                        const newQuantity = parseInt(
                                          e.target.value,
                                          10
                                        );
                                        if (
                                          !isNaN(newQuantity) &&
                                          newQuantity >= 0
                                        ) {
                                          const newQuantities = {
                                            ...comboQuantities,
                                            [combo.id]: newQuantity,
                                          };
                                          setComboQuantities(newQuantities);
                                        }
                                      }}
                                      style={{ width: "50px" }}
                                    />
                                  </td>
                                  <td
                                    style={{
                                      padding: "10px",
                                      borderRight: "2px solid black",
                                    }}
                                  >
                                    {combo.name}
                                  </td>
                                  <td
                                    style={{
                                      padding: "10px",
                                      borderRight: "2px solid black",
                                    }}
                                  >
                                    {combo.description}
                                  </td>
                                  <td
                                    style={{
                                      padding: "10px",
                                      borderRight: "2px solid black",
                                    }}
                                  >
                                    <ul>
                                      {combo.services.map(
                                        (service, serviceIndex) => (
                                          <li key={serviceIndex}>
                                            <strong
                                              style={{
                                                color: "green",
                                                fontSize: "1em",
                                              }}
                                            >
                                              *
                                            </strong>{" "}
                                            {service.name} = ₡{service.price}
                                            <br />
                                          </li>
                                        )
                                      )}
                                    </ul>
                                    <p
                                      style={{
                                        color: "black",
                                        marginTop: "10px",
                                      }}
                                    >
                                      <strong>Total Servicios:</strong> ₡
                                      {combo.services
                                        .reduce(
                                          (total, service) =>
                                            total + parseFloat(service.price),
                                          0
                                        )
                                        .toLocaleString("es-CR")}
                                    </p>
                                  </td>
                                  <td
                                    style={{
                                      padding: "10px",
                                      borderRight: "2px solid black",
                                    }}
                                  >
                                    ₡{combo.price}
                                  </td>
                                  <td style={{ padding: "10px" }}>
                                    {calculateComboSubtotal(combo)}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                            <tfoot>
                              <tr>
                                <td
                                  colSpan="5"
                                  style={{
                                    textAlign: "right",
                                    fontWeight: "bold",
                                    borderTop: "2px solid black",
                                  }}
                                >
                                  Total
                                </td>
                                <td
                                  style={{
                                    padding: "10px",
                                    borderTop: "2px solid black",
                                  }}
                                >
                                  ₡{calculateTotalCombos()}
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td
                      colSpan="4"
                      style={{
                        textAlign: "right",
                        fontWeight: "bold",
                        borderTop: "2px solid black",
                      }}
                    >
                      Total
                    </td>
                    <td
                      style={{ padding: "10px", borderTop: "2px solid black" }}
                    >
                      ₡{calculateTotal()}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>

            <h2
              style={{
                fontSize: "2em",
                color: "red",
                textShadow: "2px 2px 2px #000",
                fontWeight: "bold",
                marginTop: "20px",
                marginBottom: "20px",
                textAlign: "center",
              }}
            >
              Combos Disponibles
            </h2>

            <div
              style={{
                width: "80%",
                height: "75vh", // 80% de la altura de la pantalla
                margin: "0 auto",
                backgroundColor: "white",
                border: "2px solid black",
                borderRadius: "10px",
                boxShadow: "5px 5px 10px #888888",
                padding: "20px",
                overflowY: "auto", // Scroll vertical activado,
              }}
            >
              <table style={{ width: "100%" }}>
                <thead
                  style={{
                    background: "#ddd",
                    color: "white",
                    fontWeight: "bold",
                    borderBottom: "2px solid black",
                  }}
                >
                  <tr>
                    <th
                      style={{
                        color: "black",
                        padding: "10px",
                        borderRight: "2px solid black",
                      }}
                    >
                      Cantidad
                    </th>
                    <th
                      style={{
                        color: "black",
                        padding: "10px",
                        borderRight: "2px solid black",
                      }}
                    >
                      Nombre
                    </th>
                    <th
                      style={{
                        color: "black",
                        padding: "10px",
                        borderRight: "2px solid black",
                      }}
                    >
                      Descripción
                    </th>
                    <th
                      style={{
                        color: "black",
                        padding: "10px",
                        borderRight: "2px solid black",
                      }}
                    >
                      Servicios
                    </th>
                    <th
                      style={{
                        color: "black",
                        padding: "10px",
                        borderRight: "2px solid black",
                      }}
                    >
                      Precio
                    </th>
                    <th style={{ color: "black", padding: "10px" }}>
                      Subtotal
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {subCombos.map((combo) => (
                    <tr
                      key={combo.id}
                      style={{ borderBottom: "1px solid green" }}
                    >
                      <td style={{ padding: "10px" }}>
                        <input
                          type="number"
                          value={comboQuantities[combo.id] || 0}
                          onChange={(e) => {
                            const newQuantity = parseInt(e.target.value, 10);
                            if (!isNaN(newQuantity) && newQuantity >= 0) {
                              const newQuantities = {
                                ...comboQuantities,
                                [combo.id]: newQuantity,
                              };
                              setComboQuantities(newQuantities);
                            }
                          }}
                          style={{ width: "50px" }}
                        />
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          borderRight: "2px solid black",
                        }}
                      >
                        {combo.name}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          borderRight: "2px solid black",
                        }}
                      >
                        {combo.description}
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          borderRight: "2px solid black",
                        }}
                      >
                        <ul>
                          {combo.services ? (
                            combo.services.map((service, serviceIndex) => (
                              <li key={serviceIndex}>
                                <strong
                                  style={{ color: "green", fontSize: "1em" }}
                                >
                                  *
                                </strong>{" "}
                                {service.name} = ₡{service.price}
                              </li>
                            ))
                          ) : (
                            <li>No hay servicios disponibles</li>
                          )}
                        </ul>
                        <p style={{ marginTop: "10px" }}>
                          <strong>Total Servicios:</strong> ₡
                          {combo.services
                            ? combo.services
                                .reduce(
                                  (total, service) =>
                                    total + parseFloat(service.price),
                                  0
                                )
                                .toLocaleString("es-CR")
                            : "0"}
                        </p>
                      </td>
                      <td
                        style={{
                          padding: "10px",
                          borderRight: "2px solid black",
                        }}
                      >
                        ₡{combo.price}
                      </td>
                      <td style={{ padding: "10px" }}>
                        ₡{calculateComboSubtotal(combo)}
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td
                      colSpan="5"
                      style={{
                        textAlign: "right",
                        fontWeight: "bold",
                        borderTop: "2px solid black",
                      }}
                    >
                      Total
                    </td>
                    <td
                      style={{ padding: "10px", borderTop: "2px solid black" }}
                    >
                      ₡{calculateTotalCombos()}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          {subproductData.file && (
            <div
              style={{
                border: "2px solid #ccc",
                borderRadius: "10px",
                padding: "20px",
                boxShadow: "5px 5px 10px #888888",
                marginBottom: "20px",
                display: "flex",
                flexDirection: isMobile ? "column" : "row", // Cambio de dirección en dispositivos móviles
              }}
            >
              <div
                style={{
                  flex: isMobile ? "none" : "50%",
                  marginBottom: isMobile ? "20px" : "0",
                }}
              >
                {" "}
                {/* Estilo para dispositivos móviles */}
                {subproductData.file.toLowerCase().endsWith(".pdf") ? (
                  <iframe
                    src={`https://docs.google.com/viewer?url=${subproductData.file}&embedded=true`}
                    width="100%"
                    height="400"
                    title="Archivo PDF"
                  ></iframe>
                ) : subproductData.file
                    .toLowerCase()
                    .match(/\.(jpeg|jpg|gif|png)$/) ? (
                  <img
                    src={subproductData.file}
                    alt="Imagen del archivo"
                    style={{ width: "100%" }}
                  />
                ) : subproductData.file
                    .toLowerCase()
                    .match(/\.(mp4|avi|mkv)$/) ? (
                  <video width="100%" height="400" controls>
                    <source src={subproductData.file} type="video/mp4" />
                    Tu navegador no soporta el elemento de video.
                  </video>
                ) : subproductData.file
                    .toLowerCase()
                    .match(/\.(doc|docx|xls|xlsx)$/) ? (
                  <iframe
                    src={`https://view.officeapps.live.com/op/embed.aspx?src=${subproductData.file}`}
                    width="100%"
                    height="400"
                  ></iframe>
                ) : (
                  <p>No se puede mostrar el archivo</p>
                )}
              </div>
              <div style={{ flex: "50%" }}>
                {subproductData.addressmap && (
                  <div>
                    <iframe
                      src={subproductData.addressmap}
                      width="100%"
                      height="400"
                      title="Ubicación en Mapa"
                    ></iframe>
                  </div>
                )}
              </div>
            </div>
          )}
          <div style={{ textAlign: "center" }}>
            <button
              style={{
                backgroundColor: "green",
                color: "white",
                fontSize: "3.5em",
                padding: "10px 20px",
                border: "none",
                cursor: "pointer",
              }}
              onClick={openWhatsAppModal}
            >
              <i className="fab fa-whatsapp" style={{ marginRight: "5px" }}></i>{" "}
              Cotizar por WhatsApp
            </button>
          </div>
          <br />
          <br />
          {/* <button
            onClick={generatePDF1}
            style={{
              backgroundColor: "white",
              color: "black",
              borderRadius: "5px",
              border: "none",
              cursor: "pointer",
              borderColor: "black",
              borderWidth: "0.4px",
              borderStyle: "solid",
              fontSize: "1.8em",
              marginBottom: "2%",
            }}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = "green";
              e.target.style.color = "white";
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = "white";
              e.target.style.color = "black";
            }}
          >
            Descargar clasificados
          </button> */}
          <div ref={componentRef}>
            <Avisoseconomicos
              email={subproductData.email}
              name={subproductData.name}
            />
          </div>
          {/* <button
            onClick={generatePDF2}
            style={{
              backgroundColor: "white",
              color: "black",
              borderRadius: "5px",
              border: "none",
              cursor: "pointer",
              borderColor: "black",
              borderWidth: "0.4px",
              borderStyle: "solid",
              fontSize: "1.8em",
              marginBottom: "2%",
            }}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = "green";
              e.target.style.color = "white";
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = "white";
              e.target.style.color = "black";
            }}
          >
            Descargar catalogo de productos
          </button> */}

          <div ref={componentRef1}>
            <HomeScreen
              allowedProduct={subproductData.email}
              name={subproductData.name}
            />
          </div>
        </div>
      )}

      <Modal
        isOpen={showWhatsAppModal}
        onRequestClose={closeWhatsAppModal}
        contentLabel="Enviar mensaje por WhatsApp"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            borderRadius: "10px",
            padding: "20px",
            boxShadow: "5px 5px 10px #888888",
            width: "80%", // Ancho del modal
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        <span
          className="whatsapp-modal-close"
          onClick={closeWhatsAppModal}
          style={{
            color: "red", // Color de la X
            fontSize: "3em", // Tamaño de la X
            position: "absolute",
            top: "10px",
            right: "10px", // Alineación a la derecha
            cursor: "pointer",
          }}
        >
          &times;
        </span>
        <h2>Enviar mensaje por WhatsApp</h2>
        <div style={{ marginBottom: "10px" }}>
          <label>Nombre:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{
              border: "2px solid red",
              boxShadow: "0 0 5px black",
              width: "100%", // Ancho del input
              padding: "5px",
            }}
          />
        </div>
        <div style={{ marginBottom: "10px" }}>
          <label>Apellido:</label>
          <input
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            style={{
              border: "2px solid red",
              boxShadow: "0 0 5px black",
              width: "100%",
              padding: "5px",
            }}
          />
        </div>
        <div style={{ marginBottom: "10px" }}>
          <label>Correo:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{
              border: "2px solid red",
              boxShadow: "0 0 5px black",
              width: "100%",
              padding: "5px",
            }}
          />
        </div>
        <div style={{ marginBottom: "10px" }}>
          <label>Consulta:</label>
          <textarea
            value={consulta}
            onChange={(e) => setConsulta(e.target.value)}
            style={{
              border: "2px solid red",
              boxShadow: "0 0 5px black",
              width: "100%",
              padding: "5px",
              minHeight: "100px", // Altura mínima del textarea
            }}
          />
        </div>
        <button
          style={{
            backgroundColor: "green",
            color: "white",
            fontSize: "1.5em",
            padding: "10px 20px",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            marginTop: "10px",
          }}
          onClick={sendWhatsAppMessage}
        >
          Enviar
        </button>
      </Modal>
    </div>
  );
};

export default SubproductDetails;
