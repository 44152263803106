import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const ClasificadoDetail = ({ clasificado, onCloseModal }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  
  // Estado para manejar el zoom
  const [isZoomed, setIsZoomed] = useState(false);
  const [zoomStyle, setZoomStyle] = useState({});

  const handleMouseMove = (e) => {
    const img = e.currentTarget;
    const { left, top, width, height } = img.getBoundingClientRect();
  
    // Calcular las posiciones para el zoom
    const x = e.clientX - left;
    const y = e.clientY - top;
  
    // Cambia el zoomFactor y el tamaño del área de zoom según si es móvil
    const zoomFactor = isMobile ? 6 : 2; // Doble de zoom en móviles
    const zoomWidth = width * zoomFactor;
    const zoomHeight = height * zoomFactor;
  
    // Ajustar el estilo del zoom
    setZoomStyle({
      backgroundImage: `url(${clasificado.content})`,
      backgroundSize: `${zoomWidth}px ${zoomHeight}px`,
      backgroundPosition: `${-x * zoomFactor}px ${-y * zoomFactor}px`,
      backgroundRepeat: 'no-repeat', // Asegúrate de que no se repita
      display: "block",
      position: "absolute",
      width: "400px", // Ancho del área de zoom
      height: "400px", // Alto del área de zoom
      border: "2px solid red", // Borde rojo para el área de zoom
      borderRadius: "10px", // Bordes redondeados
      zIndex: 10, // Asegurarse de que esté por encima de otros elementos
      pointerEvents: "none", // Para que no interfiera con el ratón
    });
  };
  
  

  return (
    <div
      className="modal"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        className="modal-content"
        style={{
          maxWidth: "90%", // Máximo ancho del 80% de la pantalla
          maxHeight: "80vh", // Máximo 80% de la altura de la pantalla
          overflowX: "auto", // Agregar scroll horizontal si es necesario
          overflowY: "auto", // Agregar scroll vertical si es necesario
          borderRadius: "10px", // Bordes redondeados
          margin: "2% 2% 2% 2%", // Margen
          boxShadow: "0 0 10px 0px rgba(255, 68, 68, 0.75)", // Sombreado rojo
          padding: "20px", // Espaciado interno
          display: "flex", // Usar flex para el diseño
          flexDirection: "row", // Dirección de flex
        }}
      >
        {clasificado.content && clasificado.content_type === "clasificado_imagen" ? (
          <>
            <div
              style={{
                flex: "1",
                marginRight: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative", // Posición relativa para el área de zoom
              }}
            >
              <img
                src={clasificado.content}
                alt={clasificado.title}
                style={{ width: isMobile ? "100%" : "100%", height: isMobile ? "80%" : "95%", borderRadius: "10px" }}
                onMouseEnter={() => setIsZoomed(true)}
                onMouseLeave={() => {
                  setIsZoomed(false);
                  setZoomStyle({});
                }}
                onMouseMove={handleMouseMove} // Manejar movimiento del ratón
              />
              {isZoomed && (
                <div style={{ ...zoomStyle, position: "absolute", top: 0, left: 0 }} />
              )}
            </div>

            <div
              style={{
                flex: "1",
                border: "2px solid red",
                boxShadow: "2px 2px 4px black",
                padding: "10px",
                borderRadius: "10px",
              }}
            >
              <h2
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        textShadow: "2px 2px 4px #000",
                      }}
                    >
                      {clasificado.title}
                    </h2>
              <table style={{ width: "100%" }}>
                <tbody>

                  <tr>
                    <td>Descripción:</td>
                    <td>{clasificado.description}</td>
                  </tr>
                  <tr>
                    <td>Tipo de contenido:</td>
                    <td>{clasificado.content_type}</td>
                  </tr>
                  <tr>
                    <td>Teléfono:</td>
                    <td>{clasificado.phone_number}</td>
                  </tr>
                  <tr>
                    <td>Whatsapp:</td>
                    <td>{clasificado.whatsapp}</td>
                  </tr>
                  <tr>
                    <td>Página Web:</td>
                    <td>
                      {clasificado.url && (
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            window.open(clasificado.url, "_blank");
                          }}
                          style={{
                            color: "black",
                            fontWeight: "bold",
                            textShadow: "2px 2px 4px #ff4444",
                          }}
                        >
                          {clasificado.url}
                        </a>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
              <button
                onClick={onCloseModal}
                style={{
                  marginTop: "10px",
                  width: "100%",
                }}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = 'green';
                  e.target.style.color = 'white';
                }} 
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = 'red';
                  e.target.style.color = 'white';
                }}
              >
                Cerrar
              </button>
            </div>
          </>
        ) : (
          <div
            style={{
              width: "100%",
              border: "2px solid red",
              boxShadow: "2px 2px 4px black",
              padding: "10px",
              borderRadius: "10px",
            }}
          >
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <h2
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      textShadow: "2px 2px 4px #000",
                    }}
                  >
                    {clasificado.title}
                  </h2>
                </tr>
                <tr>
                  <td>Descripción:</td>
                  <td>{clasificado.description}</td>
                </tr>
                <tr>
                  <td>Tipo de contenido:</td>
                  <td>{clasificado.content_type}</td>
                </tr>
                <tr>
                  <td>Teléfono:</td>
                  <td>{clasificado.phone_number}</td>
                </tr>
                <tr>
                  <td>Whatsapp:</td>
                  <td>{clasificado.whatsapp}</td>
                </tr>
                <tr>
                  <td>Página Web:</td>
                  <td>
                    {clasificado.url && (
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(clasificado.url, "_blank");
                        }}
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          textShadow: "2px 2px 4px #ff4444",
                        }}
                      >
                        {clasificado.url}
                      </a>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
            <button
              onClick={onCloseModal}
              style={{
                marginTop: "10px",
                width: "100%",
              }}
            >
              Cerrar
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ClasificadoDetail;
