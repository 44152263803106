import React, { useState, useEffect } from "react";
import { Table, Button, Modal } from "react-bootstrap";
import JobDataService from "../../services/employee";
import CompanydetailModal from "./job_modal/company_detail";
import CompanyModal from "./job_modal/company_modal";

const Companies = ({ token, currentUser }) => {
  const [companies, setCompanies] = useState([]);
  const [currentCompany, setCurrentCompany] = useState(null);
  const [showCompanyDetailModal, setShowCompanyDetailModal] = useState(false);
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const [showCompanyModal1, setShowCompanyModal1] = useState(false);
  const [newCompany, setNewCompany] = useState({
    name: "",
    logo: null,
    website: "",
    description: "",
    contact_email: "",
    phone_number: "",
    address: "",
    industry: "",
    established_date: null,
  });
  useEffect(() => {
    loadCompanies();
  }, [currentUser]);

  const loadCompanies = () => {
    JobDataService.getAllCompanies(token)
      .then((response) => {
        const filteredCompanies = response.data.filter(
          (company) => company.user === currentUser.id
        );
        setCompanies(filteredCompanies);
      })
      .catch((error) => console.error(error));
  };

 const handleSaveCompany = (companyData) => {
    if (companyData.id) {
      // Edit company
      JobDataService.updateCompany(companyData.id, companyData, token)
        .then(() => {
          loadCompanies();
        })
        .catch((error) => console.error(error));
    } else {
      // Create new company
      console.log("datos de compania", companyData);
      const formData = new FormData();
      formData.append("name", companyData.name);
      formData.append("logo", companyData.logo); // Asegúrate de que esto sea un archivo
      formData.append("website", companyData.website);
      formData.append("description", companyData.description);
      formData.append("contact_email", companyData.contact_email);
      formData.append("phone_number", companyData.phone_number);
      formData.append("address", companyData.address);
      formData.append("industry", companyData.industry);
      formData.append("established_date", companyData.established_date);

      for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
      }
      JobDataService.createCompany(formData, token)
        .then(() => {
          setShowCompanyModal(false);
          loadCompanies();
          // Resetea el estado del nuevo objeto
          setNewCompany({
            name: "",
            logo: null,
            website: "",
            description: "",
            contact_email: "",
            phone_number: "",
            address: "",
            industry: "",
            established_date: null,
          });
        })
        .catch((error) => console.error("Error al crear empresa", error));
    }
    setShowCompanyModal(false);
  };

  const handleShowCompanyDetails = (company) => {
    setCurrentCompany(company); // Establecer la empresa actual
    setShowCompanyModal1(true); // Mostrar el modal
  };
  const handleDeleteCompany = (id) => {
    JobDataService.deleteCompany(id, token)
      .then(() => loadCompanies())
      .catch((error) => console.error(error));
  };
  

  return (
    <div>
        {/* Botón para agregar Empresa */}
        <Button variant="primary" onClick={() => setShowCompanyModal(true)}>
        Agregar Empresa
      </Button>

      {/* Tabla de Empresas */}
      <h2>Empresas</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th style={{ color: "blue" }}>ID</th>
            <th style={{ color: "blue" }}>Nombre</th>
            <th style={{ color: "blue" }}>Descripción</th>
            <th style={{ color: "blue" }}>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {companies.map((company) => (
            <tr key={company.id}>
              <td>{company.id}</td>
              <td>
                <span
                  onClick={() => handleShowCompanyDetails(company)} // Muestra los detalles de la empresa
                  style={{
                    textDecoration: "underline", // Subrayado para simular un enlace
                    cursor: "pointer", // Cambia el cursor para indicar que es interactivo
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.textDecoration = "underline")
                  } // Agrega subrayado al pasar el mouse
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.textDecoration = "none")
                  } // Quita subrayado al salir
                >
                  {company.name}
                </span>
              </td>

              <td>{company.description}</td>
              <td>
                <Button
                  variant="danger"
                  onClick={() => handleDeleteCompany(company.id)}
                >
                  Eliminar
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <CompanydetailModal
        show={showCompanyModal1}
        handleClose={() => setShowCompanyModal1(false)}
        company={currentCompany}
        handleSave={() => {}} // Puedes ajustar esto si necesitas una función para guardar
      />
      <CompanyModal
        show={showCompanyModal}
        handleClose={() => setShowCompanyModal(false)}
        company={currentCompany}
        handleSave={handleSaveCompany}
      />
    </div>
  );
};

export default Companies;
