import React, { useState } from "react";
import { Modal, Button } from "bootstrap";
import { MdZoomIn, MdZoomOut } from 'react-icons/md';
import PDFViewer from "pdf-viewer-reactjs";

const PdfViewer1 = ({ pdf, onCancel, visible }) => {
    const [scale, setScale] = useState(1);
    const [page, setPage] = useState(1);
    const [numPages, setNumPages] = useState(null);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const onSetScale = (type) => {
        let newScale = type ? scale + 0.1 : scale - 0.1;

        if (newScale > 2) {
            newScale = 2;
        } else if (newScale < 0.1) {
            newScale = 0.1;
        }

        setScale(newScale);
    };

    const onPageChange = (type) => {
        let newPage = type ? page + 1 : page - 1;

        if (newPage > numPages) {
            newPage = 1;
        } else if (newPage < 1) {
            newPage = numPages;
        }

        setPage(newPage);
    };

    const zoomStyle = {
        marginLeft: 10,
        cursor: 'pointer'
    };

    const footer = (
        <div className="footer">
            <Button onClick={() => onPageChange(0)} disabled={page <= 1}>Previous</Button>
            <span style={{ margin: '0 10px' }}>Page {page} of {numPages}</span>
            <span>{Math.round(scale * 100)}%</span>
            <Button onClick={() => onPageChange(1)} disabled={page >= numPages}>Next</Button>
            <MdZoomOut style={zoomStyle} onClick={() => onSetScale(false)} />
            <MdZoomIn style={zoomStyle} onClick={() => onSetScale(true)} />
        </div>
    );

    return (
        <Modal
            maskClosable={false}
            onCancel={onCancel}
            visible={visible}
            width={"50%"}
            bodyStyle={{ height: 600, overflowY: 'auto' }}
            style={{ top: 20 }}
            footer={footer}
        >
            <div className="pdfWrapper" style={{ height: '100%', overflow: 'hidden' }}>
                <PDFViewer
                    document={{ url: pdf }}
                    css={{ height: '100%', width: '100%' }}
                    scale={scale}
                    onLoadSuccess={onDocumentLoadSuccess}
                    onLoadError={(error) => console.error('PDF loading error:', error)}
                    page={page} // Prop para especificar la página actual
                />
            </div>
        </Modal>
    );
};

export default PdfViewer1;
