import React from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import Category from '../../json/category.json';

const ModalProducto3 = ({
  showModal,
  handleCloseModal,
  newProductData,
  setNewProductData,
  handleImageChange,
  handleCreateOrEditProduct,
}) => {
  
  const generateCategoryOptions = (categories) => {
    return categories.map((category) => (
      <option key={category.nombre} value={category.nombre}>
        {category.nombre}
      </option>
    ));
  };

  const generateSubcategoryOptions = (category) => {
    const selectedCategory = Category.categorias.find((cat) => cat.nombre === category);
    return selectedCategory ? selectedCategory.subcategorias.map((subcategory) => (
      <option key={subcategory.nombre} value={subcategory.nombre}>
        {subcategory.nombre}
      </option>
    )) : [];
  };

  const generateSubSubcategoryOptions = (category, subcategory) => {
    const selectedCategory = Category.categorias.find((cat) => cat.nombre === category);
    const selectedSubcategory = selectedCategory?.subcategorias.find((subcat) => subcat.nombre === subcategory);
    return selectedSubcategory ? selectedSubcategory.subsubcategorias.map((subsubcat) => (
      <option key={subsubcat} value={subsubcat}>
        {subsubcat}
      </option>
    )) : [];
  };

  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      {/* <Modal.Header closeButton> */}
        <Modal.Title>{newProductData?.id ? 'Editar Producto' : 'Crear Producto'}</Modal.Title>
      {/* </Modal.Header> */}
      <Modal.Body>
        <Form>
          <Form.Group controlId="name">
            <Form.Label>Nombre del Producto</Form.Label>
            <Form.Control
              type="text"
              value={newProductData.name || ''}
              onChange={(e) => setNewProductData({ ...newProductData, name: e.target.value })}
            />
          </Form.Group>

          <Form.Group controlId="category">
            <Form.Label>Categoría</Form.Label>
            <Form.Control
              as="select"
              value={newProductData.category || ''}
              onChange={(e) => {
                const selectedCategory = e.target.value;
                setNewProductData({
                  ...newProductData,
                  category: selectedCategory,
                  subcategory: '', // Reiniciar subcategoría
                  subsubcategory: '' // Reiniciar sub-subcategoría
                });
              }}
            >
              <option value="">Seleccionar Categoría</option>
              {generateCategoryOptions(Category.categorias)}
            </Form.Control>
          </Form.Group>

          {newProductData.category && (
            <Form.Group controlId="subcategory">
              <Form.Label>Subcategoría</Form.Label>
              <Form.Control
                as="select"
                value={newProductData.subcategory || ''}
                onChange={(e) => {
                  const selectedSubcategory = e.target.value;
                  setNewProductData({
                    ...newProductData,
                    subcategory: selectedSubcategory,
                    subsubcategory: '' // Reiniciar sub-subcategoría
                  });
                }}
              >
                <option value="">Seleccionar Subcategoría</option>
                {generateSubcategoryOptions(newProductData.category)}
              </Form.Control>
            </Form.Group>
          )}

          {newProductData.subcategory && (
            <Form.Group controlId="subsubcategory">
              <Form.Label>Sub-Subcategoría</Form.Label>
              <Form.Control
                as="select"
                value={newProductData.subsubcategory || ''}
                onChange={(e) => setNewProductData({ 
                  ...newProductData, 
                  subsubcategory: e.target.value 
                })}
              >
                <option value="">Seleccionar Sub-Subcategoría</option>
                {generateSubSubcategoryOptions(newProductData.category, newProductData.subcategory)}
              </Form.Control>
            </Form.Group>
          )}

          <Form.Group controlId="description">
            <Form.Label>Descripción</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={newProductData.description || ''}
              onChange={(e) => setNewProductData({ ...newProductData, description: e.target.value })}
            />
          </Form.Group>

          <Form.Group controlId="price">
            <Form.Label>Precio</Form.Label>
            <Form.Control
              type="number"
              value={newProductData.price || ''}
              onChange={(e) => setNewProductData({ ...newProductData, price: e.target.value })}
            />
          </Form.Group>

          <Form.Group controlId="discount">
            <Form.Label>Descuento</Form.Label>
            <Form.Control
              type="number"
              value={newProductData.discount || ''}
              onChange={(e) => setNewProductData({ ...newProductData, discount: e.target.value })}
            />
          </Form.Group>

          <Form.Group controlId="countInStock">
            <Form.Label>Stock</Form.Label>
            <Form.Control
              type="number"
              value={newProductData.countInStock || ''}
              onChange={(e) => setNewProductData({ ...newProductData, countInStock: e.target.value })}
            />
          </Form.Group>

          <Form.Group controlId="image">
            <Form.Label>Imagen</Form.Label>
            <Form.Control
              type="file"
              onChange={handleImageChange}
            />
          </Form.Group>

          <Button variant="primary" onClick={handleCreateOrEditProduct}>
            {newProductData?.id ? 'Actualizar Producto' : 'Crear Producto'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalProducto3;
