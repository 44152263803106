import React, { useState, useEffect } from 'react'; 
import './SearchTable.css'; // Para agregar estilos personalizados
import DistributorDataService from "../../../services/products";
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

const SearchTable = ({ onSearchChange }) => {
  // Estados para cada input
  const [name, setName] = useState('');
  const [category, setCategory] = useState('');
  const [ads, setAds] = useState('');
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate(); 

  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
  const isTablet = useMediaQuery({ query: '(min-width: 481px) and (max-width: 768px)' });
  const isSmallDesktop = useMediaQuery({ query: '(min-width: 769px) and (max-width: 1024px)' });
  const isLargeDesktop = useMediaQuery({ query: '(min-width: 1025px)' });

  // Almacenar el estado de búsqueda
  const [searchParams, setSearchParams] = useState({
    name: '',
    category: '',
    ads: ''
  });

  const fetchCategories = () => {
    DistributorDataService.getAllSubProduct()
      .then((response) => {
        const uniqueCategories = [...new Set(response.data.map(distributor => distributor.comercial_activity))];
        setCategories(uniqueCategories);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const handleSearch = () => {
    // Envía los parámetros de búsqueda al padre
    const combinedSearchValue = {
      name: searchParams.name,
      category: searchParams.category,
      ads: searchParams.ads
    };

    // Invoca el método onSearchChange con el formato deseado
    if (combinedSearchValue.ads) {
      onSearchChange(`ae_${combinedSearchValue.ads}`); // Si hay anuncios, prepend 'ae_'
    } else {
      onSearchChange(combinedSearchValue.name || combinedSearchValue.category || ''); // Envía lo que haya
    }
  };

  // Efecto para cargar categorías al montar el componente
  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <div className={`table-container ${isMobile ? 'mobile' : isTablet ? 'tablet' : isSmallDesktop ? 'small-desktop' : 'large-desktop'}`}>
      <table className="search-table">
        <thead>
          <tr>
            <th colSpan="3" className="search-header">
              <i className="fas fa-search search-icon"></i>
              <span className="header-text">
                Escriba el producto o servicio que solicita aquí: 
              </span>
              <i className="fas fa-search search-mirror"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>0.1-Buscar por nombre</td>
            <td>0.2-Buscar por categoría</td>
          </tr>
          <tr>
            <td>
              <input
                type="text"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  setSearchParams(prev => ({ ...prev, name: e.target.value })); // Almacena el valor en searchParams
                }}
                placeholder="Buscar nombre"
                className="input-search"
              />
            </td>
            <td>
              <select
                value={category}
                onChange={(e) => {
                  setCategory(e.target.value);
                  setSearchParams(prev => ({ ...prev, category: e.target.value })); // Almacena el valor en searchParams
                }}
                className="input-select"
              >
                <option value="">Selecciona una categoría</option>
                {categories.map((cat, index) => (
                  <option key={index} value={cat}>{cat}</option>
                ))}
              </select>
            </td>
          </tr>
          <tr>
            <td>0.3-Buscar en Avisos Económicos</td>
            <td>0.4-Contáctenos ABc</td>
          </tr>
          <tr>
            <td>
              <input
                type="text"
                value={ads}
                onChange={(e) => {
                  setAds(e.target.value);
                  setSearchParams(prev => ({ ...prev, ads: e.target.value })); // Almacena el valor en searchParams
                }}
                placeholder="Buscar avisos"
                className="input-search"
              />
            </td>
            <td>
              <button 
                onClick={() => navigate('/contacto')} 
                className="contact-button"
              >
                Servicio al Cliente 
              </button>
            </td>
          </tr>
          <tr>
          <td colSpan="2" style={{ textAlign: 'center' }}>
              <button 
                className="search-button"
                style={{
                  backgroundColor: 'black',
                  color: 'white',
                  fontWeight: "bold",
                  border: '1px solid black',
                  padding: '10px 20px',
                  cursor: 'pointer',
                  width: '50vh',
                  transition: 'background-color 0.3s, color 0.3s',
                  borderRadius: '10px',
                }} 
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = 'green';
                  e.target.style.color = 'white';
                }} 
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = 'black';
                  e.target.style.color = 'white';
                }}
                onClick={handleSearch} // Asegúrate de que no se ejecute inmediatamente
              >
               INICIAR BUSQUEDA
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default SearchTable;
