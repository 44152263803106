import React, { useState, useEffect } from 'react';
import TourismDataService from "../../../../services/Tourism";
import './style.css';

const PropertyModal = ({ isOpen, onClose, property, token, amenities }) => {
  console.log("amenidades", amenities);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [pricePerNight, setPricePerNight] = useState('');
  const [bedrooms, setBedrooms] = useState('');
  const [bathrooms, setBathrooms] = useState('');
  const [location, setLocation] = useState('');
  const [canton, setCanton] = useState('');
  const [province, setProvince] = useState('');
  const [country, setCountry] = useState('');
  const [selectedAmenities, setSelectedAmenities] = useState([]);

  useEffect(() => {
    if (property) {
      setSelectedAmenities(property.amenities || []);
    } else {
      setSelectedAmenities([]);
    }
  }, [property]);

  useEffect(() => {
    if (property) {
      setTitle(property.title);
      setDescription(property.description);
      setPricePerNight(property.price_per_night);
      setBedrooms(property.bedrooms);
      setBathrooms(property.bathrooms);
      setLocation(property.location);
      setCanton(property.canton);
      setProvince(property.province);
      setCountry(property.country);
    } else {
      setTitle('');
      setDescription('');
      setPricePerNight('');
      setBedrooms('');
      setBathrooms('');
      setLocation('');
      setCanton('');
      setProvince('');
      setCountry('');
    }
  }, [property]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('price_per_night', pricePerNight);
    formData.append('bedrooms', bedrooms);
    formData.append('bathrooms', bathrooms);
    formData.append('amenities', selectedAmenities);
    formData.append('location', location);
    formData.append('canton', canton);
    formData.append('province', province);
    formData.append('country', country);
    
    // Enviar datos
    TourismDataService.createJobPosting(formData, token)
      .then(response => {
        console.log("Property created successfully:", response);
        onClose(); // Cerrar modal después de enviar
      })
      .catch((error) => console.error("Error al crear la propiedad", error));
  };

  const handleAmenitiesChange = (amenity) => {
    setSelectedAmenities(prev =>
      prev.includes(amenity)
        ? prev.filter(a => a !== amenity)
        : [...prev, amenity]
    );
  };
  
  if (!isOpen) return null;

  return (
    <div className="modal">
      <h2>{property ? "Edit Property" : "Create Property"}</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <label className="form-label">Title:</label>
          <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} required />
        </div>
        <div className="form-row">
          <label className="form-label">Description:</label>
          <textarea value={description} onChange={(e) => setDescription(e.target.value)} required />
        </div>
        <div className="form-row">
          <label className="form-label">Price per Night:</label>
          <input type="number" value={pricePerNight} onChange={(e) => setPricePerNight(e.target.value)} required />
        </div>
        <div className="form-row">
          <label className="form-label">Bedrooms:</label>
          <input type="number" value={bedrooms} onChange={(e) => setBedrooms(e.target.value)} required />
        </div>
        <div className="form-row">
          <label className="form-label">Bathrooms:</label>
          <input type="number" value={bathrooms} onChange={(e) => setBathrooms(e.target.value)} required />
        </div>
        <label>
          Amenities:
          <div className="amenities-table">
            {amenities.map((amenity, index) => (
              <div className="amenity-item" key={amenity.id}>
                <input
                  type="checkbox"
                  checked={selectedAmenities.includes(amenity.name)}
                  onChange={() => handleAmenitiesChange(amenity.name)}
                />
                {amenity.name}
              </div>
            ))}
          </div>
        </label>
        <div className="form-row">
          <label className="form-label">Location:</label>
          <input type="text" value={location} onChange={(e) => setLocation(e.target.value)} required />
        </div>
        <div className="form-row">
          <label className="form-label">Canton:</label>
          <input type="text" value={canton} onChange={(e) => setCanton(e.target.value)} required />
        </div>
        <div className="form-row">
          <label className="form-label">Province:</label>
          <input type="text" value={province} onChange={(e) => setProvince(e.target.value)} required />
        </div>
        <div className="form-row">
          <label className="form-label">Country:</label>
          <input type="text" value={country} onChange={(e) => setCountry(e.target.value)} required />
        </div>
        <button type="submit">{property ? "Update" : "Create"}</button>
        <button type="button" onClick={onClose}>Close</button>
      </form>
    </div>
  );
};

export default PropertyModal;
