import { Table, Button, Modal, Form, Toast, Tabs, Tab } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import TodoDataService from "../../services/todos";
import Categories from "../json/category.json"
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import "./ContactsInfo.css";

const ContactsInfo = () => {
  const [leads, setLeads] = useState([]);
  const [selectedLead, setSelectedLead] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const handleCategoryChange = (e) => {
    const selected = e.target.value;
    setSelectedCategory(selected);
    handleNewLeadInputChange({
      target: {
        name: "brand_category",
        value: selected,
      },
    });
  };
  const [editedLead, setEditedLead] = useState({
    // Campos originales
    description: "",
    email: "",
    name: "",
    number: "",
    priority: "",
    status: "",
    company_address: "",
    // Campos adicionales, ordenados alfabéticamente
    brand_category: "",
    brand_description: "",
    brand_differentiation: "",
    brand_necessity: "",
    brand_perception_keywords: "",
    brand_personality: "",
    brand_slogan_or_motto: "",
    brand_style_preference: "",
    brand_values: "",
    brand_virtues: "",
    business_experience_duration: "",
    business_type: "",
    colors: "",
    commercial_information_details: "",
    company_logo: null, // Puede ser null o un objeto según cómo manejes las imágenes
    company_name: "",
    company_website_or_social_media: "",
    contact_person_name: "",
    contact_person_phone: "",
    contact_person_position: "",
    contact_reason: "",
    current_business_goals: "",
    main_competitors: "",
    opening_hours_location_maps: "",
    payment_information: "",
    payment_method: "",
    target_age_range: "",
    target_gender: "",
    target_interests: "",
    target_lifecycle_stage: "",
    target_socioeconomic_level: "",
  });

  const [activeTab, setActiveTab] = useState("client");
  const [tabData, setTabData] = useState(editedLead); // Estado para manejar los datos según la pestaña

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    switch (tab) {
      case "client":
        setTabData({
          name: editedLead.name,
          email: editedLead.email,
          description: editedLead.description,
          number: editedLead.number,
          company_address: editedLead.company_address,
          brand_category: editedLead.brand_category,
          brand_description: editedLead.brand_description,
          brand_differentiation: editedLead.brand_differentiation,
        });
        break;
      case "vendor":
        setTabData({
          contact_person_name: editedLead.contact_person_name,
          contact_person_phone: editedLead.contact_person_phone,
        });
        break;
      case "graphic_designer":
        setTabData({
          color_palette: editedLead.color_palette,
          logo_use: editedLead.logo_use,
        });
        break;
      case "marketing_designer":
        setTabData({
          current_business_goals: editedLead.current_business_goals,
          main_competitors: editedLead.main_competitors,
        });
        break;
      default:
        break;
    }
  };

  const handleTabChangeCreate = (tab) => {
    setActiveTab(tab);
    switch (tab) {
      case "client":
        setTabData({
          name: newLead.name,
          email: newLead.email,
          description: newLead.description,
          number: newLead.number,
          company_address: newLead.company_address,
          brand_category: newLead.brand_category,
          brand_description: newLead.brand_description,
          brand_differentiation: newLead.brand_differentiation,
        });
        break;
      case "vendor":
        setTabData({
          contact_person_name: newLead.contact_person_name,
          contact_person_phone: newLead.contact_person_phone,
        });
        break;
      case "graphic_designer":
        setTabData({
          color_palette: newLead.color_palette,
          logo_use: newLead.logo_use,
        });
        break;
      case "marketing_designer":
        setTabData({
          current_business_goals: newLead.current_business_goals,
          main_competitors: newLead.main_competitors,
        });
        break;
      default:
        break;
    }
  };

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [newLead, setNewLead] = useState({
    // Campos requeridos
    name: "",
    email: "",
    description: "",
    number: "",
    priority: "",
    status: "nuevo", // Valor por defecto
    company_address: "",
    // Campos adicionales
    comments: [],
    comercial_activity: "",
    brand_category: "",
    brand_description: "",
    brand_differentiation: "",
    brand_necessity: "",
    brand_perception_keywords: "",
    brand_personality: "",
    brand_slogan_or_motto: "",
    brand_style_preference: "",
    brand_values: "",
    brand_virtues: "",
    business_experience_duration: "",
    business_type: "",
    colors: "",
    commercial_information_details: "",
    company_logo: null, // Puede ser null o un objeto según cómo manejes las imágenes
    company_name: "",
    company_website_or_social_media: "",
    contact_person_name: "",
    contact_person_phone: "",
    contact_person_position: "",
    contact_reason: "",
    current_business_goals: "",
    main_competitors: "",
    opening_hours_location_maps: "",
    payment_information: "",
    payment_method: "",
    target_age_range: "",
    target_gender: "",
    target_interests: "",
    target_lifecycle_stage: "",
    target_socioeconomic_level: "",
  });

  const [errorToast, setErrorToast] = useState(null);
  const [showCommentsModal, setShowCommentsModal] = useState(false);
  const [selectedLeadComments, setSelectedLeadComments] = useState([]);
  const [selectedLeadId, setSelectedLeadId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [leadsPerPage, setLeadsPerPage] = useState(4);
  const [currentPage, setCurrentPage] = useState(1);
  const [showDeleteToast, setShowDeleteToast] = useState(false);
  const [deleteToastMessage, setDeleteToastMessage] = useState("");
  const token = useSelector((state) => state.authentication.token);
  const user = useSelector((state) => state.authentication.user);
  const [currentUser, setCurrentUser] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [profile_picture, setProfile_picture] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [showModal1, setShowModal1] = useState(false);
const [modalLead, setModalLead] = useState(null);

const handleLeadClick = (lead) => {
  setModalLead(lead);
  setShowModal1(true);
};


  useEffect(() => {
    const currentUserData = localStorage.getItem("currentUser");
    if (currentUserData) {
      try {
        const parsedData = JSON.parse(currentUserData);
        setCurrentUser(parsedData);
      } catch (error) {
        console.error("Error parsing currentUser data:", error);
      }
    }
  }, []);

  useEffect(() => {
    const storedUsername = localStorage.getItem("user");
    if (storedUsername) {
      loadLeads();
    }
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [leadsPerPage]);

  const loadLeads = () => {
    TodoDataService.getAllLeads(localStorage.getItem("token"))
      .then((response) => {
        const updatedLeads = response.data.map((lead) => {
          const lastComment =
            lead && lead.comments && lead.comments.length > 0
              ? lead.comments[lead.comments.length - 1]
              : null;

          return {
            ...lead,
            lastComment,
          };
        });

        setLeads(updatedLeads);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleDelete = (id) => {
    TodoDataService.deleteLead(id, localStorage.getItem("token"))
      .then((response) => {
        loadLeads();
      })
      .catch((error) => {
        console.error(error);
      });
    // Configurar el mensaje de Toast y mostrar el Toast
    setDeleteToastMessage("Lead eliminado exitosamente.");
    setShowDeleteToast(false);

    // Restablecer otros estados según sea necesario
    setSelectedLead(null);
  };

  const handleEdit = (lead) => {
    setSelectedLead(lead);
    setShowModal(true);
    setEditedLead({
      name: lead.name || "",
      email: lead.email || "",
      description: lead.description || "",
      number: lead.number || "",
      priority: lead.priority || "",
      status: lead.status || "nuevo", // Valor por defecto
      comercial_activity: lead.comercial_activity|| "",
      company_address: lead.company_address,
      // Campos adicionales
      brand_category: lead.brand_category || "",
      brand_description: lead.brand_description || "",
      brand_differentiation: lead.brand_differentiation || "",
      brand_necessity: lead.brand_necessity || "",
      brand_perception_keywords: lead.brand_perception_keywords || "",
      brand_personality: lead.brand_personality || "",
      brand_slogan_or_motto: lead.brand_slogan_or_motto || "",
      brand_style_preference: lead.brand_style_preference || "",
      brand_values: lead.brand_values || "",
      brand_virtues: lead.brand_virtues || "",
      business_experience_duration: lead.business_experience_duration || "",
      business_type: lead.business_type || "",
      colors: lead.colors || "",
      commercial_information_details: lead.commercial_information_details || "",
      company_logo: lead.company_logo || null, // Puede ser null o un objeto según cómo manejes las imágenes
      company_name: lead.company_name || "",
      company_website_or_social_media:
        lead.company_website_or_social_media || "",
      contact_person_name: lead.contact_person_name || "",
      contact_person_phone: lead.contact_person_phone || "",
      contact_person_position: lead.contact_person_position || "",
      contact_reason: lead.contact_reason || "",
      current_business_goals: lead.current_business_goals || "",
      main_competitors: lead.main_competitors || "",
      opening_hours_location_maps: lead.opening_hours_location_maps || "",
      payment_information: lead.payment_information || "",
      payment_method: lead.payment_method || "",
      target_age_range: lead.target_age_range || "",
      target_gender: lead.target_gender || "",
      target_interests: lead.target_interests || "",
      target_lifecycle_stage: lead.target_lifecycle_stage || "",
      target_socioeconomic_level: lead.target_socioeconomic_level || "",
      comment: "", // Puedes inicializarlo con un valor predeterminado si es necesario
    });

    setIsEditMode(true); // Activa el modo de edición
  };

  const handleSave = () => {
    console.log(
      editedLead.name, "nombre",
      editedLead.email, "email",
      editedLead.description, "descripcion",
      editedLead.priority, "prioridad",
      editedLead.status, "status",
      editedLead.number, "numero"
    );
  
    // Validar campos obligatorios
    const requiredFields = [
      editedLead.name,
      editedLead.email,
      editedLead.description,
      editedLead.priority,
      editedLead.status,
      editedLead.number,
    ];
  
    // Validar campos obligatorios uno por uno
    if (!editedLead.name) {
      alert("Por favor ingrese el nombre.");
      return;
    }
    if (!editedLead.email) {
      alert("Por favor ingrese el email.");
      return;
    }
    if (!editedLead.description) {
      alert("Por favor ingrese la descripción.");
      return;
    }
    if (!editedLead.priority) {
      alert("Por favor ingrese la prioridad.");
      return;
    }
    if (!editedLead.status) {
      alert("Por favor ingrese el estado.");
      return;
    }
    if (!editedLead.number) {
      alert("Por favor ingrese el número.");
      return;
    }
  
    // Validar el formato del email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(editedLead.email)) {
      console.log("Formato de correo inválido.");
      return;
    }
  
    // Crear un nuevo objeto FormData para manejar los archivos e información del lead
    const formData = new FormData();
  
    // Agregar cada campo del editedLead al FormData
    for (const key in editedLead) {
      if (editedLead.hasOwnProperty(key)) {
        formData.append(key, editedLead[key]); // Agrega los datos editados directamente
      }
    }
  
    // Agregar la imagen si existe
    if (editedLead.company_logo) {
      formData.append("company_logo", editedLead.company_logo);
    }
  
    console.log("FormData antes de enviar:", Array.from(formData.entries())); // Para depurar
  
    // Enviar datos actualizados al servidor usando FormData
    TodoDataService.updateLead(selectedLead.id, formData, localStorage.getItem("token"))
      .then((response) => {
        if (editedLead.comment) {
          const commentData = {
            lead: selectedLead.id,
            comment: editedLead.comment,
          };
          return TodoDataService.createCommentlead(commentData, localStorage.getItem("token"));
        } else {
          return Promise.resolve();
        }
      })
      .then(() => {
        setShowModal(false);
        loadLeads();
      })
      .catch((error) => {
        console.error("Error al actualizar lead:", error);
        const errorMessage = error.response?.data?.error || "Ocurrió un error al actualizar el lead.";
        showErrorToast(errorMessage);
      });
  };
  

  const handleCreateComment = (lead) => {
    // Inicializar el nuevo comentario
    const newComment = {
      lead: lead.id,
      comment: "", // El comentario se dejará vacío al inicio
    };
  
    // Mostrar un formulario modal para que el usuario escriba su comentario
    const comment = prompt("Escribe tu comentario:");
  
    // Si el comentario no está vacío
    if (comment && comment.trim() !== "") {
      newComment.comment = comment;
  
      // Llamada al servicio para crear el comentario
      TodoDataService.createCommentlead(newComment, localStorage.getItem("token"))
        .then(() => {
          // Después de crear el comentario, recargar los leads y comentarios
          loadLeads(); // Asegúrate de que `loadLeads` también recarga los comentarios
          console.log("Comentario creado exitosamente.");
        })
        .catch((error) => {
          console.error(error);
          console.log("Error al crear el comentario.");
        });
    } else {
      showErrorToast("El comentario no puede estar vacío.");
    }
  };
  

  const handleInputChange = (event) => {
    const { name, value, files } = event.target;
  
    // Verificar si el campo es un archivo
    if (name === "company_logo") {
      setEditedLead((prevState) => ({
        ...prevState,
        [name]: files[0], // Almacena el archivo
      }));
    } else {
      setEditedLead((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedLead(null);
    setEditedLead({
      name: "",
      email: "",
      description: "",
      priority: "",
      status: "",
      number: "",
      comment: "",
    });
  };

  const handleCreateLead = () => {
    // console.log("se presiono el boton de crear")
    setShowCreateModal(true);
  };

  const handleNewLeadInputChange = (event) => {
    const { name, value, files } = event.target;
  
    // Verificar si el campo es un archivo
    if (name === "company_logo") {
      setNewLead((prevState) => ({
        ...prevState,
        [name]: files[0], // Almacena el archivo
      }));
    } else {
      setNewLead((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  
  const handleSaveNewLead = () => {
  console.log(newLead.name, "nombre", newLead.email, "email",newLead.description, "descripcion", newLead.priority, "prioridad", newLead.status, "status", newLead.number, "numero")

    // Validar campos obligatorios
    const requiredFields = [
      newLead.name,
      newLead.email,
      newLead.description,
      newLead.priority,
      newLead.status,
      newLead.number,
      
    ];
  
    // Validar campos obligatorios uno por uno
  if (!newLead.name) {
    alert("Por favor ingrese el nombre.");
    return;
  }
  if (!newLead.email) {
    alert("Por favor ingrese el email.");
    return;
  }
  if (!newLead.description) {
    alert("Por favor ingrese la descripción.");
    return;
  }
  if (!newLead.priority) {
    alert("Por favor ingrese la prioridad.");
    return;
  }
  if (!newLead.status) {
    alert("Por favor ingrese el estado.");
    return;
  }
  if (!newLead.number) {
    alert("Por favor ingrese el número.");
    return;
  }
  
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(newLead.email)) {
      console.log("Formato de correo inválido.")
      // showErrorToast("Formato de correo inválido.");
      return;
    }
  
     // Crear un nuevo objeto FormData
  const formData = new FormData();

  // Agregar cada campo del leadData al FormData
  for (const key in newLead) {
    if (newLead.hasOwnProperty(key)) {
      formData.append(key, newLead[key]); // Agrega los datos del lead directamente
    }
  }

  // Agregar la imagen si existe
  if (newLead.company_logo) {
    formData.append("company_logo", newLead.company_logo);
  }

  console.log("FormData antes de enviar:", Array.from(formData.entries())); // Para depurar

  // Enviar datos al servidor usando FormData
  TodoDataService.createLead(formData, localStorage.getItem("token"))
      .then((response) => {
        setShowCreateModal(false);
        // Reiniciar el estado de newLead
        setNewLead({
          name: "",
          email: "",
          description: "",
          number: "",
          priority: "",
          status: "nuevo", // Valor por defecto
          company_address: "",
          brand_category: "",
          brand_description: "",
          brand_differentiation: "",
          brand_necessity: "",
          brand_perception_keywords: "",
          brand_personality: "",
          brand_slogan_or_motto: "",
          brand_style_preference: "",
          brand_values: "",
          brand_virtues: "",
          business_experience_duration: "",
          business_type: "",
          colors: "",
          commercial_information_details: "",
          company_logo: null,
          company_name: "",
          company_website_or_social_media: "",
          contact_person_name: "",
          contact_person_phone: "",
          contact_person_position: "",
          contact_reason: "",
          current_business_goals: "",
          main_competitors: "",
          opening_hours_location_maps: "",
          payment_information: "",
          payment_method: "",
          target_age_range: "",
          target_gender: "",
          target_interests: "",
          target_lifecycle_stage: "",
          target_socioeconomic_level: "",
        });
  
        loadLeads();
      })
      .catch((error) => {
        console.error("Error al crear lead:", error);
        const errorMessage = error.response?.data?.error || "Ocurrió un error al crear el lead.";
        showErrorToast(errorMessage);
      });
  };
  

  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
    setNewLead({
      // Campos originales
      name: "",
      email: "",
      description: "",
      number: "",
      priority: "",
      status: "", // Valor por defecto

      // Campos adicionales
      brand_category: "",
      brand_description: "",
      brand_differentiation: "",
      brand_necessity: "",
      brand_perception_keywords: "",
      brand_personality: "",
      brand_slogan_or_motto: "",
      brand_style_preference: "",
      brand_values: "",
      brand_virtues: "",
      business_experience_duration: "",
      business_type: "",
      colors: "",
      commercial_information_details: "",
      company_logo: null, // Puede ser null o un objeto según cómo manejes las imágenes
      company_name: "",
      company_website_or_social_media: "",
      contact_person_name: "",
      contact_person_phone: "",
      contact_person_position: "",
      contact_reason: "",
      current_business_goals: "",
      main_competitors: "",
      opening_hours_location_maps: "",
      payment_information: "",
      payment_method: "",
      target_age_range: "",
      target_gender: "",
      target_interests: "",
      target_lifecycle_stage: "",
      target_socioeconomic_level: "",
    });
  };

  const showErrorToast = (message) => {
    setErrorToast(message);
    setTimeout(() => {
      setErrorToast(null);
    }, 5000);
  };


  const handleDownloadCSV = async () => { 
  try {
    // Filtra las claves para eliminar "lastComment" y agregar "comments"
    const leadKeys = Object.keys(leads[0]).filter(key => key !== "lastComment").concat("comments");

    const csvData = await convertToCSV(leads, leadKeys);

    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "clientes.csv";
    a.click();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error al descargar el CSV:", error);
  }
};

const convertToCSV = async (data, fields) => {
  const columns = fields;
  const header = columns.join(";");

  const rows = [];

  for (const lead of data) {
    // Obtén los comentarios para cada lead
    const commentsResponse = await TodoDataService.getLeadComments(lead.id, localStorage.getItem("token"));
    
    // Crea una fila para el lead
    const row = columns.map((column) => {
      if (column === "comments") {
        // Guardar el texto de los comentarios en una variable
        const commentsText = commentsResponse.data
          .map(commentObj => commentObj.comment)
          .join(", ");
        console.log("Comentarios:", commentsText); // Para ver los comentarios en la consola
        return commentsText; // Retornar el texto de los comentarios
      } else if (typeof lead[column] === "object" && lead[column] !== null) {
        // Para otros campos que son objetos
        return JSON.stringify(lead[column]);
      } else {
        // Para otros tipos de datos
        return lead[column] || ""; // Manejar valores null o undefined
      }
    });

    rows.push(row.join(";")); // Añadir la fila al array de filas
  }

  return header + "\n" + rows.join("\n");
};

const handleImageChange = (event) => {
  const file = event.target.files[0];
  setProfile_picture(file); // Guardar la imagen seleccionada en el estado
  setImagePreview(URL.createObjectURL(file)); // Previsualizar la imagen
};



  const handleLastCommentClick = (lead) => {
    TodoDataService.getLeadComments(lead.id, localStorage.getItem("token"))
      .then((response) => {
        setSelectedLeadComments(response.data);
        setSelectedLeadId(lead.id);
        setShowCommentsModal(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleDeleteComment = (commentId) => {
    if (!selectedLeadId) {
      console.error("No lead selected.");
      return;
    }

    TodoDataService.deleteCommentContact(
      selectedLeadId,
      commentId,
      localStorage.getItem("token")
    )
      .then((response) => {
        setSelectedLeadComments((comments) =>
          comments.filter((comment) => comment.id !== commentId)
        );
      })
      .catch((error) => {
        console.error(error);
        showErrorToast("Se produjo un error al eliminar el comentario..");
      });
  };

  const handleDeleteConfirmation = (lead) => {
    setSelectedLead(lead);
    setDeleteToastMessage(
      `¿Estás seguro de que quieres eliminar? ${lead.name}?`
    );
    setShowDeleteToast(true);
  };

  const handleDeleteConfirmationClose = () => {
    setShowDeleteToast(false);
  };

  const filteredLeads = leads.filter(
    (lead) =>
      lead.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      lead.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastLead = currentPage * leadsPerPage;
  const indexOfFirstLead = indexOfLastLead - leadsPerPage;
  const currentLeads = filteredLeads.slice(indexOfFirstLead, indexOfLastLead);

  const totalPages = Math.ceil(filteredLeads.length / leadsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleLeadsPerPageChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setLeadsPerPage(value);
  };

  const [columnSort, setColumnSort] = useState({
    column: "",
    direction: "asc", // 'asc' or 'desc'
  });

  const handleSort = (columnName) => {
    setColumnSort((prevSort) => {
      if (prevSort.column === columnName) {
        // Cambiar la dirección si ya está ordenada por esa columna
        return {
          ...prevSort,
          direction: prevSort.direction === "asc" ? "desc" : "asc",
        };
      } else {
        // Ordenar por una nueva columna en orden ascendente
        return {
          column: columnName,
          direction: "asc",
        };
      }
    });
  };

  const sortedLeads = [...filteredLeads].sort((a, b) => {
    const columnA = (a[columnSort.column] || "").toLowerCase();
    const columnB = (b[columnSort.column] || "").toLowerCase();

    if (columnSort.direction === "asc") {
      return columnA.localeCompare(columnB);
    } else {
      return columnB.localeCompare(columnA);
    }
  });

  const downloadPDF = () => {
    setTimeout(() => {
        const input = document.getElementById('modal-content');

        if (input) {
            // Cambiar el tamaño de fuente de los elementos <p> y <h5> a un tamaño más pequeño
            const paragraphs = input.getElementsByTagName('p');
            const headings = input.getElementsByTagName('h5');

            // Ajustar tamaño de los párrafos
            for (let p of paragraphs) {
                p.style.fontSize = '10px'; // Ajusta el tamaño según sea necesario
            }

            // Ajustar tamaño de los encabezados h5
            for (let h5 of headings) {
                h5.style.fontSize = '12px'; // Ajusta el tamaño según sea necesario
            }

            // Establecer el estilo del contenedor para asegurar que el contenido se visualice correctamente
            input.style.width = '100%'; // Asegúrate de que el ancho sea 100%
            input.style.overflow = 'visible'; // Permite que el contenido se expanda

            // Usar html2canvas para capturar el contenido del modal
            html2canvas(input, {
                scale: 2, // Aumenta la escala para mejorar la calidad
                useCORS: true, // Permite imágenes de otros dominios
                scrollX: 0, // Evita que se corte en el eje X
                scrollY: -window.scrollY, // Evita que se corte en el eje Y
            }).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'mm', 'a4'); // Crea un PDF en formato A4
                const imgWidth = pdf.internal.pageSize.getWidth() - 20; // Ancho del PDF menos margen
                const pageHeight = pdf.internal.pageSize.getHeight();
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                let heightLeft = imgHeight;

                // Calcular la escala si es necesario
                const scale = imgHeight > pageHeight ? pageHeight / imgHeight : 1;
                const adjustedWidth = imgWidth * scale;
                const adjustedHeight = imgHeight * scale;

                // Añadir la imagen al PDF
                pdf.addImage(imgData, 'PNG', 10, 10, adjustedWidth, adjustedHeight);
                heightLeft -= pageHeight;

                // Añadir páginas adicionales si es necesario
                while (heightLeft >= 0) {
                    pdf.addPage();
                    pdf.addImage(imgData, 'PNG', 10, heightLeft - adjustedHeight, adjustedWidth, adjustedHeight);
                    heightLeft -= pageHeight;
                }

                // Guardar el PDF
                pdf.save('modal_lead_details.pdf');

                // Descargar la imagen como archivo adicional si existe
                if (modalLead.company_logo) {
                    const logoImg = new Image();
                    logoImg.crossOrigin = 'Anonymous'; // Asegúrate de que la imagen se cargue de manera cruzada
                    logoImg.src = modalLead.company_logo;

                    logoImg.onload = () => {
                        const canvasLogo = document.createElement('canvas');
                        const ctx = canvasLogo.getContext('2d');
                        canvasLogo.width = logoImg.width;
                        canvasLogo.height = logoImg.height;
                        ctx.drawImage(logoImg, 0, 0);

                        // Convierte el canvas a un Data URL y descarga la imagen
                        const logoData = canvasLogo.toDataURL('image/jpeg');
                        const logoLink = document.createElement('a');
                        logoLink.href = logoData;
                        logoLink.download = `${modalLead.company_name}_logo.jpg`;
                        document.body.appendChild(logoLink);
                        logoLink.click();
                        document.body.removeChild(logoLink);
                    };
                }

                // Restablecer el tamaño de fuente original después de la captura
                for (let p of paragraphs) {
                    p.style.fontSize = ''; // Restablece a su tamaño original
                }

                for (let h5 of headings) {
                    h5.style.fontSize = ''; // Restablece a su tamaño original
                }

                // Restablecer estilo del contenedor
                input.style.width = ''; // Restablece el ancho original
                input.style.overflow = ''; // Restablece el overflow original
            });
        } else {
            console.error('El elemento con id "modal-content" no se encontró.');
        }
    }, 500);
};

  
  
  
  
  
  return (
    <div style={{ width: "100%" }}>
<div style={{ display: 'flex', width: '100%', marginTop: '13%' }}>
      <h1>Clientes</h1>

      <div
        className="header-buttons"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "50%",
          marginLeft: "50%",
          marginTop: "-3%",
        }}
      >
        {currentUser && currentUser.staff_status === "administrator" && (
          <>
            <Button
              variant="primary"
              onClick={handleCreateLead}
              style={{
                color: "blue",
                backgroundColor: "white",
                border: "2px solid black",
                boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                transition: "background-color 0.3s, border-color 0.3s",
              }}
            >
              Nuevo cliente
            </Button>
            <Button
              variant="primary"
              onClick={handleDownloadCSV}
              style={{
                color: "blue",
                backgroundColor: "white",
                border: "2px solid black",
                boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                transition: "background-color 0.3s, border-color 0.3s",
              }}
            >
              Descargar
            </Button>
          </>
        )}
        {currentUser && currentUser.staff_status === "sales" && (
          <>
            <Button
              variant="primary"
              onClick={handleCreateLead}
              style={{
                color: "blue",
                backgroundColor: "white",
                border: "2px solid black",
                boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                transition: "background-color 0.3s, border-color 0.3s",
              }}
            >
              Nuevo cliente
            </Button>
          </>
        )}
      </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <Form.Group controlId="searchTerm"  style={{ display: 'flex', alignItems: 'center' }}>
          <Form.Control
            type="text"
            placeholder="Buscar por nombre o correo electrónico"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{
              borderRadius: "5px",
              boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
              transition: "box-shadow 0.3s",
              outline: "none",
              padding: "6px",
              width: "30%",
            }}
            onFocus={(e) => {
              e.target.style.boxShadow = "0 0 6px rgba(0, 0, 255, 0.5)";
            }}
            onBlur={(e) => {
              e.target.style.boxShadow = "2px 2px 4px rgba(0, 0, 0, 0.5)";
            }}
          />
        </Form.Group>
        <Form.Group controlId="leadsPerPage" style={{ marginBottom: "10px" }}>
          <Form.Label style={{ display: 'flex', alignItems: 'center' }}>Clientes por pagina:</Form.Label>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Form.Control
              as="input"
              type="number"
              min="1"
              value={leadsPerPage}
              onChange={handleLeadsPerPageChange}
              style={{
                borderRadius: "5px",
                boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                transition: "box-shadow 0.3s",
                outline: "none",
                padding: "6px",
                marginLeft: "5px",
                marginRight: "5px",
                width: "50px",
                textAlign: "center",
              }}
              onFocus={(e) => {
                e.target.style.boxShadow = "0 0 6px rgba(0, 0, 255, 0.5)";
              }}
              onBlur={(e) => {
                e.target.style.boxShadow = "2px 2px 4px rgba(0, 0, 0, 0.5)";
              }}
            />
          </div>
        </Form.Group>
      </div>

      <Table
        striped
        bordered
        hover
        className="leads-table"
        style={{ width: "100%" }}
      >
        <thead>
          <tr>
            <th onClick={() => handleSort("name")} style={{ color: 'blue' }}>Nombre del Vendedor</th>
            <th onClick={() => handleSort("name")} style={{ color: 'blue' }}>Nombre de la Empresa</th>
            <th onClick={() => handleSort("email")} style={{ color: 'blue' }}>Correo electronico</th>
            <th style={{ color: 'blue' }}>Numero</th>
            <th style={{ color: 'blue' }}>Descripción</th>
            <th onClick={() => handleSort("priority")} style={{ color: 'blue' }}>Prioridad</th>
            <th onClick={() => handleSort("status")} style={{ color: 'blue' }}>Estado</th>
            <th style={{ color: 'blue' }}>Último comentario</th>
            <th style={{ color: 'blue' }}>Comportamiento</th>
          </tr>
        </thead>
        <tbody>
          {currentLeads.map((lead) => (
            <React.Fragment key={lead.id}>
              <tr>
                <td>
                  <span
                    href="#"
                    style={{
                      display: "inline-block",
                      padding: "6px 12px",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "1.42857143",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                      verticalAlign: "middle",
                      msTouchAction: "manipulation",
                      touchAction: "manipulation",
                      cursor: "pointer",
                      userSelect: "none",
                      backgroundImage: "none",
                      // border: '1px solid transparent',
                      borderRadius: "4px",
                      textDecoration: "none",
                      color: "#333",
                      backgroundColor: "#fff",
                      borderColor: "#ccc",
                    }}
                    onClick={() => {
                      handleLeadClick(lead); // Cambia esta línea
                      setIsEditMode(false);
                    }}
                  >
                    {lead.contact_person_name}
                  </span>
                </td>
                <td>{lead.name}</td>

                <td>{lead.email}</td>
                <td>{lead.number}</td>
                <td>{lead.description}</td>
                <td>{lead.priority}</td>
                <td>{lead.status}</td>
                <td>
                {lead.lastComment ? (
                <div>
                  <Button
                    variant="link"
                    onClick={() => handleLastCommentClick(lead)}
                  >
                    Ver
                  </Button>
                  <Button
                    variant="link"
                    onClick={() => handleCreateComment(lead)}
                  >
                    Crear comentario
                  </Button>
                  </div>
                  
                ) : (
                  <Button
                    variant="link"
                    onClick={() => handleCreateComment(lead)}
                  >
                    Crear comentario
                  </Button>
                )}
              </td>
                <td>
                  {currentUser &&
                    currentUser.staff_status === "administrator" && (
                      <>
                        <Button
                          variant="danger"
                          onClick={() => handleDeleteConfirmation(lead)}
                          className="action-button"
                        >
                          Eliminar
                        </Button>
                        <Button
                          variant="primary"
                          onClick={() => handleEdit(lead)}
                          className="action-button"
                        >
                          Editar
                        </Button>
                      </>
                    )}
                  {currentUser && currentUser.staff_status === "sales" && (
                    <>
                      <Button
                        variant="primary"
                        onClick={() => handleEdit(lead)}
                        className="action-button"
                      >
                        Editar
                      </Button>
                    </>
                  )}
                </td>
              </tr>
              {selectedLead === lead && selectedLeadComments.length > 0 && (
                <tr>
                  <td colSpan="8" style={{ padding: "0" }}>
                    <Table bordered hover className="comments-table">
                      <thead>
                        <tr>
                          <th>Comentario</th>
                          <th>Usuario</th>
                          <th>Accion</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedLeadComments.map((comment) => (
                          <tr key={comment.id}>
                            <td>{comment.comment}</td>
                            <td>{comment.user}</td>
                            <td>
                              <Button
                                variant="danger"
                                onClick={() => handleDeleteComment(comment.id)}
                                className="action-button"
                              >
                                Eliminar
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </Table>

      <div>
        {totalPages > 1 && (
          <div
            className="pagination-container"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="primary"
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
              style={{ marginRight: "5px" }}
            >
              Anterior
            </Button>
            <div className="page-info" style={{ margin: "0 5px" }}>
              Pagina {currentPage}
            </div>
            <Button
              variant="primary"
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
              style={{ marginLeft: "5px" }}
            >
              Siguiente
            </Button>
          </div>
        )}
      </div>

      {showModal1 && modalLead && (
  <Modal show={showModal1} onHide={() => setShowModal1(false)} size="lg" style={{overflowY:"auto"}}>
      <h2>Detalles del Cliente Potencial</h2>
    <Modal.Body style={{overflowY:"auto", color:"black"}}id="modal-content"> 
      <div style={{ display: "flex", alignItems: "center", marginBottom: "20px", height:"80%" }}>
        
        <div >
          <h5>{modalLead.company_name}</h5>
          <p><strong style={{ fontSize: '1.2em' }}>Nombre de Contacto:</strong> {modalLead.contact_person_name}</p>
          <p><strong style={{ fontSize: '1.2em' }}>Email:</strong> {modalLead.email}</p>
          <p><strong style={{ fontSize: '1.2em' }}>Número de Teléfono:</strong> {modalLead.contact_person_phone}</p>
          <p><strong style={{ fontSize: '1.2em' }}>Posición:</strong> {modalLead.contact_person_position}</p>
          <p><strong style={{ fontSize: '1.2em' }}>Dirección:</strong> {modalLead.company_address}</p>
          <p><strong style={{ fontSize: '1.2em' }}>Descripción:</strong> {modalLead.description}</p>
          <p><strong style={{ fontSize: '1.2em' }}>Prioridad:</strong> {modalLead.priority}</p>
          <p><strong style={{ fontSize: '1.2em' }}>Estado:</strong> {modalLead.status}</p>
          <p><strong style={{ fontSize: '1.2em' }}>Razón de Contacto:</strong> {modalLead.contact_reason}</p>
          <p><strong style={{ fontSize: '1.2em' }}>Objetivos Comerciales Actuales:</strong> {modalLead.current_business_goals}</p>
          <p><strong style={{ fontSize: '1.2em' }}>Competidores Principales:</strong> {modalLead.main_competitors}</p>
          <p><strong style={{ fontSize: '1.2em' }}>Horarios de Apertura:</strong> {modalLead.opening_hours_location_maps}</p>
          <p><strong style={{ fontSize: '1.2em' }}>Método de Pago:</strong> {modalLead.payment_method}</p>
          <p><strong style={{ fontSize: '1.2em' }}>Intereses del Público Objetivo:</strong> {modalLead.target_interests}</p>
          <p><strong style={{ fontSize: '1.2em' }}>Nivel Socioeconómico Objetivo:</strong> {modalLead.target_socioeconomic_level}</p>

          {/* Agrega más campos según lo que necesites mostrar */}
        </div>
        {modalLead.company_logo && (
          <img
            src={modalLead.company_logo}
            alt={`${modalLead.company_name} Logo`}
            style={{
              width: "100px",
              height: "100px",
              objectFit: "contain",
              borderRadius: "5px",
              marginRight: "20px"
            }}
          />
        )}
      </div>

      <h5 style={{fontSize:"2.2em"}}>Información de Marca</h5>
      <p><strong style={{ fontSize: '1.2em' }}>Categoría de Marca:</strong> {modalLead.brand_category}</p>
      <p><strong style={{ fontSize: '1.2em' }}>Descripción de Marca:</strong> {modalLead.brand_description}</p>
      <p><strong style={{ fontSize: '1.2em' }}>Diferenciación de Marca:</strong> {modalLead.brand_differentiation}</p>
      <p><strong style={{ fontSize: '1.2em' }}>Necesidad de Marca:</strong> {modalLead.brand_necessity}</p>
      <p><strong style={{ fontSize: '1.2em' }}>Palabras Clave de Percepción de Marca:</strong> {modalLead.brand_perception_keywords}</p>
      <p><strong style={{ fontSize: '1.2em' }}>Personalidad de Marca:</strong> {modalLead.brand_personality}</p>
      <p><strong style={{ fontSize: '1.2em' }}>Slogan o Lema de Marca:</strong> {modalLead.brand_slogan_or_motto}</p>
      <p><strong style={{ fontSize: '1.2em' }}>Preferencia de Estilo de Marca:</strong> {modalLead.brand_style_preference}</p>
      <p><strong style={{ fontSize: '1.2em' }}>Valores de Marca:</strong> {modalLead.brand_values}</p>
      <p><strong style={{ fontSize: '1.2em' }}>Virtudes de Marca:</strong> {modalLead.brand_virtues}</p>

      <h5 style={{fontSize:"2.2em"}}>Información Comercial</h5>
      <p><strong style={{ fontSize: '1.2em' }}>Duración de Experiencia Empresarial:</strong> {modalLead.business_experience_duration}</p>
      <p><strong style={{ fontSize: '1.2em' }}>Tipo de Negocio:</strong> {modalLead.business_type}</p>
      <p><strong style={{ fontSize: '1.2em' }}>Colores de Marca:</strong> {modalLead.colors}</p>
      <p style={{marginBottom:"5%"}}><strong style={{ fontSize: '1.2em' }}>Detalles de Información Comercial:</strong> {modalLead.commercial_information_details}</p>
        <br/>

    </Modal.Body>
    <Modal.Footer>
    <Button variant="primary" onClick={downloadPDF}>
              Descargar PDF
            </Button>
      <Button variant="secondary" onClick={() => setShowModal1(false)}>
        Cerrar
      </Button>
    </Modal.Footer>
  </Modal>
)}



      {/* Modal de creación (Create New Lead) */}
      <Modal
        show={showCreateModal}
        onHide={handleCloseCreateModal}
        centered
        className="modal-above-table"
      >

          <Modal.Title>Crear nuevo cliente potencial</Modal.Title>

        <Modal.Body>
          <div style={{ maxHeight: "calc(100vh - 410px)", overflowY: "auto" }}>
            <Tabs
              activeKey={activeTab}
              onSelect={handleTabChangeCreate}
              className="mb-3 custom-tabs"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Tab
                eventKey="client"
                title={<span style={{ color: "black" }}>Cliente</span>}
                className="custom-tab"
              >
                {activeTab === "client" && (
                  <>
                    <Form.Group controlId="name">
                      <Form.Label>Nombre de la Empresa</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        value={newLead.name || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="company_logo">
                    <Form.Label>Logo de la Empresa</Form.Label>
                      <input
                        type="file"
                        name="company_logo"
                        accept="image/*"
                        onChange={handleNewLeadInputChange}
                        className="file-input"
                      />
                  </Form.Group>
                    <Form.Group controlId="email">
                      <Form.Label>Correo Electrónico</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={newLead.email || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="company_description">
                      <Form.Label>Descripción Breve de la Empresa</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="description"
                        value={newLead.description || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="number">
                      <Form.Label>Número de Contacto</Form.Label>
                      <Form.Control
                        type="text"
                        name="number"
                        value={newLead.number || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="comercial_activity">
                      <Form.Label>Actividad comercial</Form.Label>
                      <Form.Control
                        type="text"
                        name="comercial_activity"
                        value={newLead.comercial_activity || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="newPriority">
          <Form.Label>Prioridad</Form.Label>
          <Form.Control
            as="select"
            name="priority"
            value={newLead.priority}
            onChange={handleNewLeadInputChange}
          >
            <option value="">Seleccionar prioridad</option>
            <option value="bajo">Baja</option>
            <option value="medio">Medio</option>
            <option value="alto">Alta</option>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="newStatus">
          <Form.Label>Status</Form.Label>
          <Form.Control
            as="select"
            name="status"
            value={newLead.status}
            onChange={handleNewLeadInputChange}
          >
            <option value="">Seleccionar estado</option>
            <option value="nuevo">Nuevo</option>
            <option value="contactado">Contactado</option>
            <option value="ganado">Cliente Ganado</option>
          </Form.Control>
        </Form.Group>
                    <Form.Group controlId="company_address">
                      <Form.Label>Dirección de la Empresa</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="company_address"
                        value={newLead.company_address || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    {/* <Form.Group controlId="brand_category">
                      <Form.Label>Categoría de Marca</Form.Label>
                      <Form.Control
                        type="text"
                        name="brand_category"
                        value={newLead.brand_category || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group> */}
                    <Form.Group controlId="brand_category">
                      <Form.Label>Categoría de Marca</Form.Label>
                      {/* Menú desplegable para categorías principales */}
                      <Form.Control as="select" value={newLead.brand_category} onChange={handleCategoryChange}>
                        <option value="">Selecciona una categoría</option>
                        {Categories.categorias.map((categoria, index) => (
                          <option key={index} value={categoria.nombre}>
                            {categoria.nombre}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>

                    <Form.Group controlId="brand_description">
                      <Form.Label>Descripción Detallada de la Marca</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="brand_description"
                        value={newLead.brand_description || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="brand_differentiation">
                      <Form.Label>Diferenciación de la Marca</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="brand_differentiation"
                        value={newLead.brand_differentiation || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    {/* Nuevos campos del cliente */}
                    <Form.Group controlId="brand_necessity">
                      <Form.Label>Necesidad que Satisface la Marca</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="brand_necessity"
                        value={newLead.brand_necessity || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="brand_perception_keywords">
                      <Form.Label>
                        Palabras Clave de Percepción de la Marca
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        name="brand_perception_keywords"
                        value={newLead.brand_perception_keywords || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="brand_personality">
                      <Form.Label>Personalidad de la Marca</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="brand_personality"
                        value={newLead.brand_personality || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="brand_slogan_or_motto">
                      <Form.Label>Eslogan o Motto de la Marca</Form.Label>
                      <Form.Control
                        type="text"
                        name="brand_slogan_or_motto"
                        value={newLead.brand_slogan_or_motto || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="brand_style_preference">
                      <Form.Label>Preferencia de Estilo de la Marca</Form.Label>
                      <Form.Control
                        type="text"
                        name="brand_style_preference"
                        value={newLead.brand_style_preference || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="brand_values">
                      <Form.Label>Valores de la Marca</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="brand_values"
                        value={newLead.brand_values || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="brand_virtues">
                      <Form.Label>Virtudes de la Marca</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="brand_virtues"
                        value={newLead.brand_virtues || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="business_experience_duration">
                      <Form.Label>Duración de Experiencia Comercial</Form.Label>
                      <Form.Control
                        type="text"
                        name="business_experience_duration"
                        value={newLead.business_experience_duration || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="business_type">
                      <Form.Label>Tipo de Negocio</Form.Label>
                      <Form.Control
                        type="text"
                        name="business_type"
                        value={newLead.business_type || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="colors">
                      <Form.Label>Colores Asociados a la Marca</Form.Label>
                      <Form.Control
                        type="text"
                        name="colors"
                        value={newLead.colors || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="commercial_information_details">
                      <Form.Label>
                        Detalles de la Información Comercial
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        name="commercial_information_details"
                        value={newLead.commercial_information_details || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>

                    <Form.Group controlId="opening_hours_location_maps">
                      <Form.Label>
                        Horario de Atención
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        name="opening_hours_location_maps"
                        value={newLead.opening_hours_location_maps || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="payment_method">
                      <Form.Label>
                        Metodos de pago
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        name="payment_method"
                        value={newLead.payment_method || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="payment_information">
                      <Form.Label>
                        Numero de cuenta o numero Sinpe
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        name="payment_information"
                        value={newLead.payment_information || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    
                    


                    <Form.Group controlId="company_website_or_social_media">
                      <Form.Label>
                        Sitio Web o Redes Sociales de la Empresa
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="company_website_or_social_media"
                        value={newLead.company_website_or_social_media || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                  </>
                )}
              </Tab>

              <Tab
                eventKey="vendor"
                title={<span style={{ color: "black" }}>Vendedor</span>}
                className="custom-tab"
              >
                {activeTab === "vendor" && (
                  <>
                    <Form.Group controlId="contact_person_name">
                      <Form.Label>Nombre de la Persona de Contacto</Form.Label>
                      <Form.Control
                        type="text"
                        name="contact_person_name"
                        value={newLead.contact_person_name || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="contact_person_phone">
                      <Form.Label>
                        Teléfono de la Persona de Contacto
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="contact_person_phone"
                        value={newLead.contact_person_phone || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="contact_reason">
                      <Form.Label>Motivo de Contacto</Form.Label>
                      <Form.Control
                        type="email"
                        name="contact_reason"
                        value={newLead.contact_reason || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="contact_person_position">
                      <Form.Label>Rol del Vendedor</Form.Label>
                      <Form.Control
                        type="text"
                        name="contact_person_position"
                        value={newLead.contact_person_position || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                  </>
                )}
              </Tab>

              <Tab
                eventKey="marketing_designer"
                title={
                  <span style={{ color: "black" }}>Estratega de Marketing</span>
                }
                className="custom-tab"
              >
                {activeTab === "marketing_designer" && (
                  <>
                    <Form.Group controlId="current_business_goals">
                      <Form.Label>Metas Actuales del Negocio</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="current_business_goals"
                        value={newLead.current_business_goals || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="main_competitors">
                      <Form.Label>Principales Competidores</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="main_competitors"
                        value={newLead.main_competitors || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="target_age_range">
                      <Form.Label>Edad de Público Objetivo</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="target_age_range"
                        value={newLead.target_age_range || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="target_gender">
                      <Form.Label>Genero para campañas</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="target_gender"
                        value={newLead.target_gender || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="target_socioeconomic_level">
                      <Form.Label>Nivel socioeconomico para campañás</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="target_socioeconomic_level"
                        value={newLead.target_socioeconomic_level || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="target_interests">
                      <Form.Label>
                        Objetivos Específicos de la Campaña
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        name="target_interests"
                        value={newLead.target_interests || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="target_lifecycle_stage">
                      <Form.Label>
                        Fecha de finalización de campaña
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        name="target_lifecycle_stage"
                        value={newLead.target_lifecycle_stage || ""}
                        onChange={handleNewLeadInputChange}
                      />
                    </Form.Group>
                  </>
                )}
              </Tab>
            </Tabs>
          </div>
          <Button variant="primary" onClick={handleSaveNewLead}>
            Crear Cliente Potencial
          </Button>
        </Modal.Body>
      </Modal>

      <Modal
        show={showCommentsModal}
        onHide={() => setShowCommentsModal(false)}
        centered
      >
       
          <Modal.Title>Comentarios</Modal.Title>
        
        <Modal.Body>
          {selectedLeadComments.length > 0 ? (
            <Table>
              <thead>
                <tr>
                  <th style={{color:"black"}}>Comentario</th>
                  <th style={{color:"black"}}>Usuario</th>
                  <th style={{color:"black"}}>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {selectedLeadComments.map((comment) => (
                  <tr key={comment.id}>
                    <td>{comment.comment}</td>
                    <td>{comment.user}</td>
                    <td>
                      <Button
                        variant="danger"
                        onClick={() => handleDeleteComment(comment.id)}
                        className="action-button"
                      >
                        Eliminar
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No hay comentarios disponibles.</p>
            
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowCommentsModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal de edición (Edit Lead) */}
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        centered
        className="modal-above-table"
      >
          <Modal.Title>Editar Cliente</Modal.Title>
        <Modal.Body>
          <div style={{ maxHeight: "calc(100vh - 410px)", overflowY: "auto" }}>
            <Tabs
              activeKey={activeTab}
              onSelect={handleTabChange}
              className="mb-3 custom-tabs"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Tab
                eventKey="client"
                title={<span style={{ color: "black" }}>Cliente</span>}
                className="custom-tab"
              >
                {activeTab === "client" && (
                  <>
                    <Form.Group controlId="name">
                      <Form.Label>Nombre de la Empresa</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        value={editedLead.name || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="email">
                      <Form.Label>Correo Electrónico</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={editedLead.email || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="company_description">
                      <Form.Label>Descripción Breve de la Empresa</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="description"
                        value={editedLead.description || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="company_logo">
                    <Form.Label>Logo de la Empresa</Form.Label>
                      <input
                        type="file"
                        name="company_logo"
                        accept="image/*"
                        onChange={handleInputChange}
                        className="file-input"
                      />
                  </Form.Group>
                    <Form.Group controlId="number">
                      <Form.Label>Número de Contacto</Form.Label>
                      <Form.Control
                        type="text"
                        name="number"
                        value={editedLead.number || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="company_address">
                      <Form.Label>Dirección de la Empresa</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="company_address"
                        value={editedLead.company_address || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="brand_category">
                      <Form.Label>Categoría de Marca</Form.Label>
                      <Form.Control
                        type="text"
                        name="brand_category"
                        value={editedLead.brand_category || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="brand_description">
                      <Form.Label>Descripción Detallada de la Marca</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="brand_description"
                        value={editedLead.brand_description || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="brand_differentiation">
                      <Form.Label>Diferenciación de la Marca</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="brand_differentiation"
                        value={editedLead.brand_differentiation || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    {/* Nuevos campos del cliente */}
                    <Form.Group controlId="brand_necessity">
                      <Form.Label>Necesidad que Satisface la Marca</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="brand_necessity"
                        value={editedLead.brand_necessity || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="brand_perception_keywords">
                      <Form.Label>
                        Palabras Clave de Percepción de la Marca
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        name="brand_perception_keywords"
                        value={editedLead.brand_perception_keywords || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="brand_personality">
                      <Form.Label>Personalidad de la Marca</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="brand_personality"
                        value={editedLead.brand_personality || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="brand_slogan_or_motto">
                      <Form.Label>Eslogan o Motto de la Marca</Form.Label>
                      <Form.Control
                        type="text"
                        name="brand_slogan_or_motto"
                        value={editedLead.brand_slogan_or_motto || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="brand_style_preference">
                      <Form.Label>Preferencia de Estilo de la Marca</Form.Label>
                      <Form.Control
                        type="text"
                        name="brand_style_preference"
                        value={editedLead.brand_style_preference || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="brand_values">
                      <Form.Label>Valores de la Marca</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="brand_values"
                        value={editedLead.brand_values || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="brand_virtues">
                      <Form.Label>Virtudes de la Marca</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="brand_virtues"
                        value={editedLead.brand_virtues || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="business_experience_duration">
                      <Form.Label>Duración de Experiencia Comercial</Form.Label>
                      <Form.Control
                        type="text"
                        name="business_experience_duration"
                        value={editedLead.business_experience_duration || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="business_type">
                      <Form.Label>Tipo de Negocio</Form.Label>
                      <Form.Control
                        type="text"
                        name="business_type"
                        value={editedLead.business_type || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="colors">
                      <Form.Label>Colores Asociados a la Marca</Form.Label>
                      <Form.Control
                        type="text"
                        name="colors"
                        value={editedLead.colors || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="commercial_information_details">
                      <Form.Label>
                        Detalles de la Información Comercial
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        name="commercial_information_details"
                        value={editedLead.commercial_information_details || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="company_website_or_social_media">
                      <Form.Label>
                        Sitio Web o Redes Sociales de la Empresa
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="company_website_or_social_media"
                        value={editedLead.company_website_or_social_media || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </>
                )}
              </Tab>

              <Tab
                eventKey="vendor"
                title={<span style={{ color: "black" }}>Vendedor</span>}
                className="custom-tab"
              >
                {activeTab === "vendor" && (
                  <>
                    <Form.Group controlId="contact_person_name">
                      <Form.Label>Nombre de la Persona de Contacto</Form.Label>
                      <Form.Control
                        type="text"
                        name="contact_person_name"
                        value={editedLead.contact_person_name || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="contact_person_phone">
                      <Form.Label>
                        Teléfono de la Persona de Contacto
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="contact_person_phone"
                        value={editedLead.contact_person_phone || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="contact_reason">
                      <Form.Label>Correo Electrónico del Vendedor</Form.Label>
                      <Form.Control
                        type="email"
                        name="contact_reason"
                        value={editedLead.contact_reason || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="contact_person_position">
                      <Form.Label>Rol del Vendedor</Form.Label>
                      <Form.Control
                        type="text"
                        name="contact_person_position"
                        value={editedLead.contact_person_position || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </>
                )}
              </Tab>

            

              <Tab
                eventKey="marketing_designer"
                title={
                  <span style={{ color: "black" }}>Estratega de Marketing</span>
                }
                className="custom-tab"
              >
                {activeTab === "marketing_designer" && (
                  <>
                    <Form.Group controlId="current_business_goals">
                      <Form.Label>Metas Actuales del Negocio</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="current_business_goals"
                        value={editedLead.current_business_goals || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="main_competitors">
                      <Form.Label>Principales Competidores</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="main_competitors"
                        value={editedLead.main_competitors || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="target_age_range">
                      <Form.Label>Edad de Público Objetivo</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="target_age_range"
                        value={editedLead.target_age_range || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="target_gender">
                      <Form.Label>Genero para campañas</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="target_gender"
                        value={editedLead.target_gender || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="target_socioeconomic_level">
                      <Form.Label>Nivel socioeconomico para campañás</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="target_socioeconomic_level"
                        value={editedLead.target_socioeconomic_level || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="target_interests">
                      <Form.Label>
                        Objetivos Específicos de la Campaña
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        name="target_interests"
                        value={editedLead.target_interests || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                    <Form.Group controlId="target_lifecycle_stage">
                      <Form.Label>
                        Fecha de finalización de campaña
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        name="target_lifecycle_stage"
                        value={editedLead.target_lifecycle_stage || ""}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </>
                )}
              </Tab>
            </Tabs>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseModal}>
            Cerrar
          </Button>
          {isEditMode && selectedLead ? (
            <Button variant="primary" onClick={handleSave}>
              Guardar cambios
            </Button>
          ) : null}
        </Modal.Footer>
      </Modal>

      {/* Toast de error */}
      {errorToast && (
        <Toast
          show={errorToast}
          onClose={() => setErrorToast(null)}
          style={{
            position: "fixed",
            top: "10px",
            right: "10px",
            minWidth: "200px",
          }}
        >
          <Toast.Header>
            <strong className="mr-auto">Error</strong>
          </Toast.Header>
          <Toast.Body>{errorToast}</Toast.Body>
        </Toast>
      )}

      {/* Toast de confirmación de eliminación */}
      <Toast
        show={showDeleteToast}
        onClose={handleDeleteConfirmationClose}
        style={{
          position: "fixed",
          top: "50%",
          right: "50%",
          background: "white",
          borderColor:"red",
        }}
      >

          <strong className="mr-auto" style={{fontSize:"2.3em"}}>Confirmar eliminación</strong>

        <Toast.Body>
          {deleteToastMessage}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "10px",
            }}
          >
            <Button
              variant="secondary"
              onClick={handleDeleteConfirmationClose}
              style={{ marginRight: "5px" }}
            >
              Cancelar
            </Button>
            <Button
              variant="danger"
              onClick={() => handleDelete(selectedLead.id)}
            >
              Eliminar
            </Button>
          </div>
        </Toast.Body>
      </Toast>
    </div>
  );
};

export default ContactsInfo;
