import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap'

const JobCategoryModal = ({ show, handleClose, jobCategory, handleSave }) => {
    const [name, setName] = useState(jobCategory ? jobCategory.name : '');

    const handleSubmit = (e) => {
        e.preventDefault();
        handleSave({ id: jobCategory ? jobCategory.id : null, name });
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{jobCategory ? 'Editar Categoría de Trabajo' : 'Crear Categoría de Trabajo'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formJobCategoryName">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control 
                            type="text" 
                            value={name} 
                            onChange={(e) => setName(e.target.value)} 
                            required 
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit">Guardar</Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default JobCategoryModal;
