
import React, { useState, useEffect } from 'react';

const TextSlider = ({ relatedForms }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [displayItems, setDisplayItems] = useState([]);

  useEffect(() => {
    // Crea un arreglo de textos combinando field.label y response.value
    const items = [];
    if (relatedForms.length > 0) {
      relatedForms.forEach((form) => {
        form.fields.forEach((field) => {
          // Excluye 'Kilometraje', 'Comentario adicional', 'Placa' y 'Dirección maps'
          if (!['Comentario adicional:', 'Placa: (Ingresar número completo)', 'Dirección maps' ].includes(field.label)) {
            const response = form.responses.find((response) => response.form_field === field._id);
            let value = response ? response.value : 'Sin respuesta';
  
            // Verifica si el campo tiene "(m2)" en el label para agregar " m2" al valor
            if (field.label.includes('(m2)') && response) {
              value = `${value} m²`;
            }
  
            // Si el campo es "Cilindrada", agrega " cm³" al valor
            if (field.label === 'Cilindrada' && response) {
              value = `${value} cm³`;
            }
  
            // Agrega el texto formateado al arreglo de items
            items.push(`${field.label}: ${value}`);
          }
        });
      });
    }
    setDisplayItems(items);
  }, [relatedForms]);
  

  // Cambia el índice cada 3 segundos
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % displayItems.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [displayItems.length]);

  return (
    <div style={styles.slider}>
      {displayItems.length > 0 && <h2 style={styles.text}>{displayItems[currentIndex]}</h2>}
    </div>
  );
};

// Estilos para el slider
const styles = {
  slider: {
    display: 'flex',
    height: '40px',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#e3f0ff',
    transition: 'opacity 0.5s ease-in-out',
    overflow: 'hidden',
  },
  text: {
    fontSize: '16px',  // Ajusta el tamaño de fuente según sea necesario
    lineHeight: '40px', // Centra el texto verticalmente
    color: 'black',
    margin: 0, // Elimina el margen para evitar desbordamiento
    whiteSpace: 'nowrap', // Evita que el texto se divida en múltiples líneas
    overflow: 'hidden', // Oculta el texto que se desborda
    textOverflow: 'ellipsis', // Agrega puntos suspensivos al final si es necesario
    fontWeight: 'bold',
  },
};

export default TextSlider;
