import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Boton from "./iframe";
import HomeScreen from "../ecommerce/Homescreen";
import Footer from "../../components/navigation/Footer";
import { useMediaQuery } from "react-responsive";
import logo from "./imagenes/logo.jpg";
import image1 from "./imagenes/1.jpg";
import image2 from "./imagenes/2.jpg";
import image3 from "./imagenes/3.jpg";
import image4 from "./imagenes/4.jpg";
import image5 from "./imagenes/5.jpg";
import image6 from "./imagenes/6.jpg";
import image7 from "./imagenes/7.jpg";
import image8 from "./imagenes/8.jpg";
import image9 from "./imagenes/9.jpg";
import image10 from "./imagenes/10.jpg";
import image11 from "./imagenes/11.jpg";
import image12 from "./imagenes/12.jpg";
import image13 from "./imagenes/13.jpg";
import image14 from "./imagenes/14.jpg";
import image15 from "./imagenes/15.jpg";
import image16 from "./imagenes/16.jpg";
import image17 from "./imagenes/17.jpg";
import image18 from "./imagenes/18.jpg";
import image19 from "./imagenes/19.jpg";
import image20 from "./imagenes/20.jpg";
import image21 from "./imagenes/21.jpg";
import image22 from "./imagenes/22.jpg";
import image23 from "./imagenes/23.jpg";
import image24 from "./imagenes/24.jpg";

const Tienda = () => {
  const [allowedCategories, setAllowedCategories] = useState([
    "1. Autos y Accesorios AB",
    "2. Bolsa de Empleo AB",
    "3. Casas, Lotes y Boncre",
    "4. Clinica Salud y Estetica",
    "5. Comunicación, Tecnología y Energía",
    "6. Construcción, Diseño y Supervisión",
    "7. Cupones de Descuento de Inversión e Intercambio",
    "8. Centros de Educación y Universidades",
    "9. Entretenimiento, Diversión y Restaurantes",
    "10. Ferretería y Depósito",
    "11. Hogar, Tienda, Electrónica y Supermercados",
    "12. Planes de Inversión, Portafolio Inmobiliario",
    "13. Legal y Notariado",
    "14. Librería AB",
    "15. Catálogo, Ofertas y Subastas",
    "16. Noticias AB",
    "17. Póliza y Seguros AB",
    "18. Préstamos Privados Sobre Propiedades",
    "19. Productos y Servicios Cooperativos",
    "20. Combos de Publicidad y Páginas Web",
    "21. Fundación Eslabonescr.com",
    "22. Esencial Costa Rica: Hoteles y Turismo",
    "23. Transporte y Mensajería",
    "24. Directorio Comercial C.R",
  ]);
  const allowedCategories_Abcupon = [
    "1. Autos y Accesorios AB",
    "2. Bolsa de Empleo AB",
    "3. Casas, Lotes y Boncre",
    "4. Clinica Salud y Estetica",
    "5. Comunicación, Tecnología y Energía",
    "6. Construcción, Diseño y Supervisión",
    "7. Cupones de Descuento de Inversión e Intercambio",
    "8. Centros de Educación y Universidades",
    "9. Entretenimiento, Diversión y Restaurantes",
    "10. Ferretería y Depósito",
    "11. Hogar, Tienda, Electrónica y Supermercados",
    "12. Planes de Inversión, Portafolio Inmobiliario",
    "13. Legal y Notariado",
    "14. Librería AB",
    "15. Catálogo, Ofertas y Subastas",
    "16. Noticias AB",
    "17. Póliza y Seguros AB",
    "18. Préstamos Privados Sobre Propiedades",
    "19. Productos y Servicios Cooperativos",
    "20. Combos de Publicidad y Páginas Web",
    "21. Fundación Eslabonescr.com",
    "22. Esencial Costa Rica: Hoteles y Turismo",
    "23. Transporte y Mensajería",
    "24. Directorio Comercial C.R",
  ];
  const allowedCategories_Autos = [
    "1. Autos y Accesorios AB",
  ];
  const allowedCategories_Casas = [
    "3. Casas, Lotes y Boncre",
  ];
  const allowedCategories_Salud = [
    "4. Clinica Salud y Estetica",
  ];
  const allowedCategories_Comunicacion = [
    "5. Comunicación, Tecnología y Energía",
  ];
  const allowedCategories_Construccion = [
    "6. Construcción, Diseño y Supervisión",
  ];
  const allowedCategories_Cupones = [
    "7. Cupones de Descuento de Inversión e Intercambio",
  ];
  const allowedCategories_Centros_Educativos = [
   "8. Centros de Educación y Universidades",
  ];
  const allowedCategories_Entretenimiento = [
    "9. Entretenimiento, Diversión y Restaurantes",
  ];
  const allowedCategories_Ferreteria = [
    "10. Ferretería y Depósito",
  ];
  const allowedCategories_Hogar = [
    "11. Hogar, Tienda, Electrónica y Supermercados",
  ];
  const allowedCategories_Planes = [
    "12. Planes de Inversión, Portafolio Inmobiliario",
  ];
  const allowedCategories_Legal = [
    "13. Legal y Notariado",
  ];
  const allowedCategories_Libreria = ["14. Librería AB",];
  const allowedCategories_Ofertas = ["15. Catálogo, Ofertas y Subastas",];
  const allowedCategories_Poliza = [
    "17. Póliza y Seguros AB",
  ];
  const allowedCategories_Prestamo = [
    "18. Préstamos Privados Sobre Propiedades",
  ];
  const allowedCategories_Producto = [
    "19. Productos y Servicios Cooperativos",
  ];
  const allowedCategories_Publicidad = [
    "20. Combos de Publicidad y Páginas Web",
  ];
  const allowedCategories_Fundaciones = [
    "21. Fundación Eslabonescr.com",
  ];
  const allowedCategories_Hoteles = [
    "22. Esencial Costa Rica: Hoteles y Turismo",
  ];
  const allowedCategories_Transporte = [
    "23. Transporte y Mensajería",
  ];
  const images = [
    { src: image1, name: "allowedCategories_Autos" },
    { src: image2, name: "bolsadeempleo" },
    { src: image3, name: "allowedCategories_Casas" },
    { src: image4, name: "allowedCategories_Salud" },
    { src: image5, name: "allowedCategories_Comunicacion" },
    { src: image6, name: "allowedCategories_Construccion" },
    { src: image7, name: "allowedCategories_Cupones" },
    { src: image8, name: "allowedCategories_Centros_Educativos" },
    { src: image9, name: "allowedCategories_Entretenimiento" },
    { src: image10, name: "allowedCategories_Ferreteria" },
    { src: image11, name: "allowedCategories_Hogar" },
    { src: image12, name: "allowedCategories_Planes" },
    { src: image13, name: "allowedCategories_Legal" },
    { src: image14, name: "allowedCategories_Libreria" },
    { src: image15, name: "allowedCategories_Ofertas" },
    { src: image16, name: "https://www.noticias.abcupon.com/" },
    { src: image17, name: "allowedCategories_Poliza" },
    { src: image18, name: "allowedCategories_Prestamo" },
    { src: image19, name: "allowedCategories_Producto" },
    { src: image20, name: "allowedCategories_Publicidad" },
    { src: image21, name: "allowedCategories_Fundaciones" },
    { src: image22, name: "allowedCategories_Hoteles" },
    { src: image23, name: "allowedCategories_Transporte" },
    { src: image24, name: "directorio_comercial" },
  ];

  const [inputValue, setInputValue] = useState("");
  const [botones, setBotones] = useState([]);
  const [selectedImageName, setSelectedImageName] = useState("");
  const intervalIdRef = useRef();
  const [isAutoMode, setIsAutoMode] = useState(true);
  const [autoModeTimeout, setAutoModeTimeout] = useState(null);
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleImageClick = (image) => {
    console.log("Nombre de la imagen:", image.name);

    switch (image.name) {
      case "allowedCategories_Abcupon":
        setAllowedCategories(allowedCategories_Abcupon);
        break;
      case "allowedCategories_Autos":
        setAllowedCategories(allowedCategories_Autos);
        break;
      case "allowedCategories_Casas":
        setAllowedCategories(allowedCategories_Casas);
        break;
      case "allowedCategories_Salud":
        setAllowedCategories(allowedCategories_Salud);
        break;
      case "allowedCategories_Comunicacion":
        setAllowedCategories(allowedCategories_Comunicacion);
        break;
      case "allowedCategories_Construccion":
        setAllowedCategories(allowedCategories_Construccion);
        break;
      case "allowedCategories_Cupones":
        setAllowedCategories(allowedCategories_Cupones);
        break;
      case "allowedCategories_Centros_Educativos":
        setAllowedCategories(allowedCategories_Centros_Educativos);
        break;
      case "allowedCategories_Entretenimiento":
        setAllowedCategories(allowedCategories_Entretenimiento);
        break;
      case "allowedCategories_Ferreteria":
        setAllowedCategories(allowedCategories_Ferreteria);
        break;
      case "allowedCategories_Hogar":
        setAllowedCategories(allowedCategories_Hogar);
        break;
      case "allowedCategories_Planes":
        setAllowedCategories(allowedCategories_Planes);
        break;
      case "allowedCategories_Legal":
        setAllowedCategories(allowedCategories_Legal);
        break;
      case "allowedCategories_Libreria":
        setAllowedCategories(allowedCategories_Libreria);
        break;
      case "allowedCategories_Ofertas":
        setAllowedCategories(allowedCategories_Ofertas);
        break;
      case "allowedCategories_Poliza":
        setAllowedCategories(allowedCategories_Poliza);
        break;
      case "allowedCategories_Prestamo":
        setAllowedCategories(allowedCategories_Prestamo);
        break;
      case "allowedCategories_Producto":
        setAllowedCategories(allowedCategories_Producto);
        break;
      case "allowedCategories_Publicidad":
        setAllowedCategories(allowedCategories_Publicidad);
        break;
      case "allowedCategories_Fundaciones":
        setAllowedCategories(allowedCategories_Fundaciones);
        break;
      case "allowedCategories_Hoteles":
        setAllowedCategories(allowedCategories_Hoteles);
        break;
      case "allowedCategories_Transporte":
        setAllowedCategories(allowedCategories_Transporte);
        break;
      case "https://www.noticias.abcupon.com/":
        // Abre una nueva pestaña con el sitio web noticias.abcupon.com
        window.open("https://www.noticias.abcupon.com/", "_blank");
        break;
      case "bolsadeempleo":
        window.location.href = "#/bolsadeempleo";
        break;
      case "directorio_comercial":
        window.location.href = "#/directorio_comercial";
        break;
      default:
        // Si el nombre de la imagen no coincide con ninguno de los casos anteriores, no hagas nada
        break;
    }
  };

  const filteredBotones = botones.filter((boton) =>
    boton.label.toLowerCase().includes(inputValue.toLowerCase())
  );

  // Hook useEffect para manejar el tamaño de la ventana
  useEffect(() => {
    const handleWindowResize = () => {
      const windowWidth = window.innerWidth;
      const marginTop = windowWidth <= 768 ? "20vh" : "15vh"; // Cambio de valores en dispositivos móviles
      const marginBottom = windowWidth <= 768 ? "10vh" : "0"; // Cambio de valores en dispositivos móviles
      document.documentElement.style.setProperty("--marginTop", marginTop);
      document.documentElement.style.setProperty(
        "--marginBottom",
        marginBottom
      );
    };

    // Agregar el listener para el cambio de tamaño de ventana
    window.addEventListener("resize", handleWindowResize);

    // Llamada inicial para establecer el valor correcto
    handleWindowResize();

    // Limpieza del listener al desmontar el componente
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const [currentImages, setCurrentImages] = useState(images);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Obtener la primera imagen y moverla al final del array
      const updatedImages = [...currentImages];
      const firstImage = updatedImages.shift();
      updatedImages.push(firstImage);

      // Actualizar el estado con el nuevo orden de imágenes
      setCurrentImages(updatedImages);
    }, 3000); // Cambia el valor del intervalo según tus preferencias

    // Limpiar el intervalo al desmontar el componente
    return () => clearInterval(intervalId);
  }, [currentImages]);

  const handlePrevClick = () => {
    setIsAutoMode(false); // Cambia al modo manual al presionar el botón
    clearTimeout(autoModeTimeout);
    const updatedIndex = (currentIndex - 1 + images.length) % images.length;
    setCurrentIndex(updatedIndex);
    updateImagesOrder(updatedIndex);
    restartAutoSlider();
  };

  const handleNextClick = () => {
    setIsAutoMode(false); // Cambia al modo manual al presionar el botón
    clearTimeout(autoModeTimeout);
    const updatedIndex = (currentIndex + 1) % images.length;
    setCurrentIndex(updatedIndex);
    updateImagesOrder(updatedIndex);
    restartAutoSlider();
  };

  const updateImagesOrder = (index) => {
    // Actualizar el orden de las imágenes basado en el índice actual
    const updatedImages = [...images.slice(index), ...images.slice(0, index)];
    setCurrentImages(updatedImages);
  };
  const restartAutoSlider = () => {
    // Reiniciar el intervalo para cambiar automáticamente las imágenes después de 30 segundos de inactividad
    clearTimeout(autoModeTimeout);
    setAutoModeTimeout(
      setTimeout(() => {
        setIsAutoMode(true); // Vuelve al modo automático
        handleNextClick(); // Avanza automáticamente al siguiente después de 30 segundos
      }, 30000)
    );
  };

  useEffect(() => {
    // Intervalo para cambiar automáticamente las imágenes
    const intervalId = setInterval(() => {
      if (isAutoMode) {
        handleNextClick();
      }
    }, 3000);

    // Limpiar el intervalo al desmontar el componente
    return () => clearInterval(intervalId);
  }, [isAutoMode]);

  return (
    <div style={{backgroundColor:"black"}}>
    <div style={{ marginTop: isMobile ? "20%":"var(--marginTop)", marginLeft:"4%", marginRight:"4%", backgroundColor: "black", }} >
      <br />
      {/* Imágenes en la parte superior ocupando la mitad de la pantalla */}
      <div
  style={{
    display: "flex",
    justifyContent: "left",
    height: isMobile ? "75vh" : "10vh",
    // marginBottom: "var(--marginBottom)",
    overflowX: "auto",
  }}
>
  <img
    onClick={() =>
      handleImageClick({ name: "allowedCategories_Abcupon" })
    }
    src={logo}
    alt="Logo"
    loading="lazy"
    style={{
      flex: "0 0 auto",
      maxWidth: "50%",
      maxHeight: "100%",
      objectFit: "cover",
    }}
  />
  <div style={{ display: "flex", flexWrap: "nowrap", overflow: "hidden" }}>
    {currentImages.slice(0, isMobile ? 2 : 8).map((image, index) => (
      <img
        onClick={() => handleImageClick(image)}
        key={index}
        src={image.src}
        loading="lazy"
        alt={`Imagen ${index + 1}`}
        style={{
          flex: "0 0 auto",
          maxWidth: "50%",
          maxHeight: "100%",
          objectFit: "cover",
        }}
      />
    ))}
  </div>
</div>


      <br />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "var(--marginBottom)",
          marginTop: isMobile ? "-30%" : "0",
        }}
      >
        {!isMobile && (
          <>
            <button
              onClick={handlePrevClick}
              style={{
                backgroundColor: "white",
                color: "black",
                fontWeight: "bold",
                padding: "8px",
                borderRadius: "5px",
                border: "none",
                cursor: "pointer",
                borderColor: "black",
                borderWidth: "0.4px",
                borderStyle: "solid",
              }}
            >
              Anterior
            </button>
            <button
              onClick={handleNextClick}
              style={{
                backgroundColor: "black",
                color: "white",
                fontWeight: "bold",
                padding: "8px",
                borderRadius: "5px",
                border: "none",
                cursor: "pointer",
                borderColor: "black",
                borderWidth: "0.4px",
                borderStyle: "solid",
              }}
            >
              Siguiente
            </button>
          </>
        )}
      </div>

      <br />
      {/* <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        placeholder="Buscar pagina..."
        style={{
          marginRight: "10px",
          padding: "8px",
          borderRadius: "5px",
          border: "1px solid red",
        }}
      /> */}
      <br />
      <br />
      {filteredBotones.map((boton) => (
        <Boton key={boton.label} url={boton.url} label={boton.label} />
      ))}

      <div>
        {/* Renderizar el componente HomeScreen debajo de las imágenes */}
        <HomeScreen allowedCategories={allowedCategories} />
        <Footer />
      </div>
    </div>
    </div>
  );
};

export default Tienda;
