import React, { useEffect, useState, useRef } from "react";
import TodoDataService from "../../services/todos";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "./profile.css";

const Profile = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [workExperience, setWorkExperience] = useState([]);
  const [skills, setSkills] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedExperience, setSelectedExperience] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false); // Estado para el modal
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [isExperienceModalOpen, setIsExperienceModalOpen] = useState(false);
  const [editedUser, setEditedUser] = useState({}); // Estado para los datos editados
  const [profile_picture, setProfile_picture] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [userList, setUserList] = useState([]);
  const token = useSelector((state) => state.authentication.token);
  const componentRef = useRef();

  useEffect(() => {
    const storedUser = localStorage.getItem("currentUser");
    console.log("currentUSer", currentUser)
    if (storedUser) {
      const user = JSON.parse(storedUser);
      setCurrentUser(user);
      setEditedUser({
        ...user,
        user_summary: user.user_summary || "", // Asignamos un valor por defecto si está vacío o undefined
      });
      fetchWorkExperience(user.id);
      fetchSkills(user.id);
    }
  }, []);

  const fetchUser = (userId) => {
    if (token) {
      TodoDataService.getUserDetails(userId, token)
        .then((response) => {
          const updatedUser = response.data;
          setCurrentUser(updatedUser); // Actualiza currentUser
          setUserList(updatedUser);
          console.log("usuarios current", response.data);
          localStorage.setItem("currentUser", JSON.stringify(userList));
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const fetchWorkExperience = async (userId) => {
    try {
      const response = await TodoDataService.getAllWorkExperiences(token);
      setWorkExperience(response.data);
    } catch (error) {
      console.error("Error fetching work experience:", error);
    }
  };

  const fetchSkills = async (userId) => {
    try {
      const response = await TodoDataService.getAllSkills(token);
      setSkills(response.data);
    } catch (error) {
      console.error("Error fetching skills:", error);
    }
  };

  const handleSkillChange = (event) => {
    const skillId = event.target.value;
    console.log("skillId", skillId);
    if (event.target.checked) {
      setSelectedSkills([...selectedSkills, skillId]);
    } else {
      setSelectedSkills(selectedSkills.filter((id) => id !== skillId));
    }
  };

  const handleExperienceChange = (event) => {
    const experienceId = event.target.value;
    console.log("experienceId", experienceId);
    if (event.target.checked) {
      setSelectedExperience([...selectedExperience, experienceId]);
    } else {
      setSelectedExperience(
        selectedExperience.filter((id) => id !== experienceId)
      );
    }
  };

  const handleEditUserChange = (event) => {
    const { name, value } = event.target;
    const formattedValue = name === "openwork" ? value === "yes" : value;
    setEditedUser({ ...editedUser, [name]: formattedValue  });
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setProfile_picture(file); // Guardar la imagen seleccionada en el estado
    setImagePreview(URL.createObjectURL(file)); // Previsualizar la imagen
  };

  const handleEditUser = () => {
    // Formatear la fecha antes de enviarla al servidor
    const formattedDateOfBirth = moment(editedUser.date_of_birth).format(
      "YYYY-MM-DD"
    );

    // Validar y formatear el número de teléfono
    const formattedPhoneNumber = editedUser.phone_number.replace(/\D/g, "");

    // Validar el nombre de la empresa
    const formattedCompany = editedUser.company.trim();


    // Validar y formatear el resumen del usuario
    const formattedUserSummary = editedUser.user_summary
      ? editedUser.user_summary.trim()
      : "";

    // Crear un nuevo objeto con los campos que se van a enviar
    const updatedUser = {
      first_name: editedUser.first_name,
      last_name: editedUser.last_name,
      email: editedUser.email,
      staff_status: editedUser.staff_status,
      address: editedUser.address,
      phone_number: formattedPhoneNumber,
      company: formattedCompany,
      openwork: editedUser.openwork,
      bio: formattedUserSummary,
      date_of_birth: formattedDateOfBirth,
    };

    console.log("Datos editados:", updatedUser);

    const formData = new FormData();

    // Agregar cada campo del usuario al FormData
    for (const key in updatedUser) {
      if (updatedUser.hasOwnProperty(key)) {
        formData.append(key, updatedUser[key]); // Agrega los datos del usuario directamente
      }
    }

    // Agregar la imagen si existe
    if (profile_picture) {
      formData.append("profile_picture", profile_picture);
    }

    console.log("FormData antes de enviar:", Array.from(formData.entries()));

    // Llamada al servicio para actualizar el usuario
    TodoDataService.updateUser(currentUser.id, formData, token)
      .then(() => {
        const newUser = { ...currentUser, ...updatedUser };
        if (Object.values(updatedUser).some((value) => value !== "")) {
          localStorage.setItem("currentUser", JSON.stringify(newUser));
          setCurrentUser(newUser);
        }
        setCurrentUser(newUser);
        setIsModalOpen(false);
        setImagePreview(null);
        fetchUser(currentUser.id);
      })
      .catch((error) => {
        console.error("Error al actualizar el usuario:", error);
      });
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const toggleSkillModal = () => {
    setIsModalOpen1(!isModalOpen1);
  };

  const toggleExperienceModal = () => {
    setIsExperienceModalOpen(!isExperienceModalOpen);
  };

  const handleCreateSkill = async (event) => {
    event.preventDefault();
    const skillData = {
      user: currentUser.id,
      name: event.target.skillName.value,
    };
    console.log("Datos de skill enviados:", skillData);
    
    try {
      await TodoDataService.createSkill(skillData, token); // Enviar skillData al servidor
      fetchSkills(); // Actualizar la lista de habilidades
      toggleSkillModal(); // Cerrar el modal
    } catch (error) {
      console.error("Error al crear skill:", error);
    }
  };

  const handleCreateExperience = async (event) => {
    event.preventDefault();
    
    const experienceData = {
      user: currentUser.id,
      job_title: event.target.experienceTitle.value,
      company_name: event.target.experienceCompany.value,
      start_date: event.target.startDate.value,
      end_date: event.target.endDate.value,
      responsibilities: event.target.responsability.value,
    };
    console.log("Datos de experiencia enviados:", experienceData);

    try {
      await TodoDataService.createWorkExperience(experienceData, token); // Enviar experienceData al servidor
      fetchWorkExperience(currentUser.id); // Actualizar la lista de experiencias
      toggleExperienceModal(); // Cerrar el modal
    } catch (error) {
      console.error("Error al crear experiencia laboral:", error);
    }
  };
  
  const handleDeleteSkill = async (id) => {
    const confirmDelete = window.confirm(
      "¿Estás seguro de que deseas eliminar esta habilidad?"
    );
    if (confirmDelete) {
      try {
        await TodoDataService.deleteSkill(id, token);
        setSkills(skills.filter((skill) => skill.id !== id)); // Actualiza el estado para reflejar la eliminación
      } catch (error) {
        console.error("Error al eliminar la habilidad:", error);
      }
    }
  };

  const handleDeleteExperience = async (id) => {
    console.log("id",id)
    const confirmDelete = window.confirm(
      "¿Estás seguro de que deseas eliminar esta experiencia laboral?"
    );
    if (confirmDelete) {
      try {
        await TodoDataService.deleteWorkExperience(id, token);
        setWorkExperience(
          workExperience.filter((experience) => experience.id !== id)
        ); // Actualiza el estado para reflejar la eliminación
      } catch (error) {
        console.error("Error al eliminar la experiencia laboral:", error);
      }
    }
  };

  const downloadPDF = async () => {
    const input = componentRef.current;
  
    // Ocultar los botones
    const buttons = input.querySelectorAll("button");
    buttons.forEach((button) => {
      button.style.display = "none";
    });
  
    // Cambiar el tamaño del texto en las tablas
    const tables = input.querySelectorAll("table");
    tables.forEach((table) => {
      const headers = table.querySelectorAll("th");
      const cells = table.querySelectorAll("td");
  
      headers.forEach((header) => {
        header.style.fontSize = "1.2em"; // Duplicar el tamaño del texto
      });
      cells.forEach((cell) => {
        cell.style.fontSize = "1.2em"; // Duplicar el tamaño del texto
      });
    });
  
    // Convertir el contenido del perfil a un canvas
    const canvas = await html2canvas(input, {
      scale: 2, // Ajustar la escala para mayor resolución
      useCORS: true, // Permitir el uso de imágenes externas
    });
  
    const imgData = canvas.toDataURL("image/png");
  
    const pdf = new jsPDF("p", "mm", "a4");
    const imgWidth = 210;
    const pageHeight = 297;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;
    let position = 0;
  
    pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;
  
    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }
  
    // Restaurar los estilos originales
    tables.forEach((table) => {
      const headers = table.querySelectorAll("th");
      const cells = table.querySelectorAll("td");
  
      headers.forEach((header) => {
        header.style.fontSize = ""; // Restaurar el tamaño original
      });
      cells.forEach((cell) => {
        cell.style.fontSize = ""; // Restaurar el tamaño original
      });
    });
  
    // Restaurar los botones
    buttons.forEach((button) => {
      button.style.display = "inline-block"; // O el valor original que tenían
    });
  
    pdf.save("perfil.pdf");
  };
  
  // Estilo para la imagen del usuario y otros estilos
  const profileImageStyle = {
    width: "30%",
    height: "auto",
    borderRadius: "15px",
    border: "3px solid red",
    boxShadow: "0 0 10px black",
    marginBottom: "5px",
    marginLeft: "8%",
  };

  const containerStyle = {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  };

  const columnStyle = {
    width: "50%",
    padding: "10px",
  };

  const tableStyle = {
    width: "100%",
    border: "1px solid #ccc",
    color: "black",
    borderCollapse: "collapse",
    marginTop: "10px",
  };

  const smallTableStyle = {
    width: "100%",
    border: "1px solid #ccc",
    borderCollapse: "collapse",
    marginTop: "10px",
  };

  const buttonStyle = {
    marginTop: "10px",
    padding: "10px 15px",
    backgroundColor: "#007BFF",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  };

 
  return (
    <div style={{ marginTop: "7%" }}>
      <div ref={componentRef} style={{ padding: "20px", backgroundColor: "#f5f5f5" }}>
      {currentUser && (
        <h2>
          Bienvenido, {currentUser.first_name} {currentUser.last_name}
        </h2>
      )}
      <button style={{ marginTop: "10px", padding: "10px 15px", backgroundColor: "#007BFF", color: "white", border: "none", borderRadius: "5px", cursor: "pointer" }} onClick={downloadPDF}>
                  Descargar componente como PDF
                </button>
      <div style={containerStyle}>
        <div style={columnStyle}>
        {currentUser ? (
            <>
              {currentUser.profile_picture && (
                <img
                  src={currentUser.profile_picture}
                  alt="Perfil"
                  style={profileImageStyle}
                />
              )}
              <button style={buttonStyle} onClick={toggleModal}>
                Editar datos del perfil
              </button>
            </>
          ) : (
            <p>Cargando imagen de perfil...</p>
          )}
          {currentUser ? (
            <table style={tableStyle}>
              <tbody>
                <tr>
                  <td
                    className="font-semibold"
                    style={{ paddingRight: "20px" }}
                  >
                    Nombre:
                  </td>
                  <td>{currentUser.first_name}</td>
                </tr>
                <tr>
                  <td
                    className="font-semibold"
                    style={{ paddingRight: "20px" }}
                  >
                    Apellido:
                  </td>
                  <td>{currentUser.last_name}</td>
                </tr>
                <tr>
                  <td
                    className="font-semibold"
                    style={{ paddingRight: "20px" }}
                  >
                    Correo electrónico:
                  </td>
                  <td>{currentUser.email}</td>
                </tr>
                <tr>
                  <td
                    className="font-semibold"
                    style={{ paddingRight: "20px" }}
                  >
                    Tipo de Usuario:
                  </td>
                  <td>{currentUser.staff_status}</td>
                </tr>
                <tr>
                  <td
                    className="font-semibold"
                    style={{ paddingRight: "20px" }}
                  >
                    Buscando trabajo:
                  </td>
                  <td>{currentUser.openwork ? 'Sí' : 'No'}</td>
                </tr>
                <tr>
                  <td
                    className="font-semibold"
                    style={{ paddingRight: "20px" }}
                  >
                    Resumen de Usuario:
                  </td>
                  <td>{currentUser.bio}</td>
                </tr>
                <tr>
                  <td
                    className="font-semibold"
                    style={{ paddingRight: "20px" }}
                  >
                    Fecha de cumpleaños:
                  </td>
                  <td>{currentUser.date_of_birth}</td>
                </tr>
                <tr>
                  <td
                    className="font-semibold"
                    style={{ paddingRight: "20px" }}
                  >
                    Dirección:
                  </td>
                  <td>{currentUser.address}</td>
                </tr>
                <tr>
                  <td
                    className="font-semibold"
                    style={{ paddingRight: "20px" }}
                  >
                    País:
                  </td>
                  <td>{currentUser.country}</td>
                </tr>
                <tr>
                  <td
                    className="font-semibold"
                    style={{ paddingRight: "20px" }}
                  >
                    Número de teléfono:
                  </td>
                  <td>{currentUser.phone_number}</td>
                </tr>
                <tr>
                  <td
                    className="font-semibold"
                    style={{ paddingRight: "20px" }}
                  >
                    Empresa:
                  </td>
                  <td>{currentUser.company}</td>
                </tr>
              </tbody>
            </table>
          ) : (
            <p>Cargando información del usuario...</p>
          )}
        </div>

        <div style={columnStyle}>
          {currentUser && (
            <>
              <h2>Experiencia Laboral</h2>
              <table style={tableStyle}>
                <thead>
                  <tr>
                  <th
                      style={{
                        padding: "10px",
                        borderBottom: "1px solid #ccc",
                        color: "black",
                      }}
                    >
                      
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        borderBottom: "1px solid #ccc",
                        color: "black",
                      }}
                    >
                      Título del Trabajo
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        borderBottom: "1px solid #ccc",
                        color: "black",
                      }}
                    >
                      Compañía
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        borderBottom: "1px solid #ccc",
                        color: "black",
                      }}
                    >
                      Fecha de Inicio
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        borderBottom: "1px solid #ccc",
                        color: "black",
                      }}
                    >
                      Fecha de Fin
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        borderBottom: "1px solid #ccc",
                        color: "black",
                      }}
                    >
                      Responsabilidades
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        borderBottom: "1px solid #ccc",
                        color: "black",
                      }}
                    >
                      Acción
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {workExperience.length > 0 ? (
                    workExperience.map((experience) => (
                      <tr key={experience.id}>
                        <td>
                          <input
                            type="checkbox"
                            value={experience.id}
                            onChange={handleExperienceChange}
                          />
                           </td>
                           <td>
                          {experience.job_title}
                        </td>
                        <td>{experience.company_name}</td>
                        <td>{experience.start_date}</td>
                        <td>{experience.end_date}</td>
                        <td>{experience.responsibilities}</td>
                        <td>
                          <button
                            onClick={() => handleDeleteExperience(experience.id)}
                          >
                            Eliminar
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5">
                        No hay experiencia laboral disponible.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </>
          )}
          {/* <button style={buttonStyle}>Editar Experiencias</button> */}
          <button onClick={toggleExperienceModal}>Crear Experiencia </button>

          <h2>Profesiones</h2>
          <table style={smallTableStyle}>
            <thead>
              <tr>
              <th
                  style={{
                    padding: "10px",
                    borderBottom: "1px solid #ccc",
                    color: "black",
                  }}
                >
                  
                </th>
                <th
                  style={{
                    padding: "10px",
                    borderBottom: "1px solid #ccc",
                    color: "black",
                  }}
                >
                  Habilidad
                </th>
                <th
                  style={{
                    padding: "10px",
                    borderBottom: "1px solid #ccc",
                    color: "black",
                  }}
                >
                  Acción
                </th>
              </tr>
            </thead>
            <tbody>
              {skills.length > 0 ? (
                skills.map((skill) => (
                  <tr key={skill.id}>
                    <td>
                      <input
                        type="checkbox"
                        value={skill.id}
                        onChange={handleSkillChange}
                      />
                    </td>
                    <td>
                      {skill.name}
                    </td>
                    <td>
                      <button onClick={() => handleDeleteSkill(skill.id)}>
                        Eliminar
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>No se encontraron habilidades.</td>
                </tr>
              )}
            </tbody>
          </table>
          {/* <button style={buttonStyle}>Editar habilidades</button> */}
          <button onClick={toggleSkillModal}>Crear Profesión</button>
        </div>
      </div>

      {isModalOpen && (
        <div className="modal">
          <div
            className="modal-content"
            style={{ overflowY: "auto", width: "50%" }}
          >
            <span className="close" onClick={toggleModal}>
              &times;
            </span>
            <h2>Editar Perfil</h2>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleEditUser();
              }}
              className="edit-profile-form"
            >
              <div className="form-group">
                <label>Nombre:</label>
                <input
                  type="text"
                  name="first_name"
                  value={editedUser.first_name}
                  onChange={handleEditUserChange}
                  required
                  className="form-input"
                />
              </div>
              <div className="form-group">
                <label>Apellido:</label>
                <input
                  type="text"
                  name="last_name"
                  value={editedUser.last_name}
                  onChange={handleEditUserChange}
                  required
                  className="form-input"
                />
              </div>
              <div className="form-group">
                <label>Dirección:</label>
                <input
                  type="text"
                  name="address"
                  value={editedUser.address}
                  onChange={handleEditUserChange}
                  className="form-input"
                />
              </div>
              <div className="form-group">
                <label>Fecha de cumpleaños:</label>
                <input
                  type="date"
                  name="date_of_birth"
                  value={editedUser.date_of_birth}
                  onChange={handleEditUserChange}
                  className="form-input"
                />
              </div>
              <div className="form-group">
                <label>Número de teléfono:</label>
                <input
                  type="text"
                  name="phone_number"
                  value={editedUser.phone_number}
                  onChange={handleEditUserChange}
                  className="form-input"
                />
              </div>
              <div className="form-group">
                <label>Empresa:</label>
                <input
                  type="text"
                  name="company"
                  value={editedUser.company}
                  onChange={handleEditUserChange}
                  className="form-input"
                />
              </div>
              <div className="form-group">
  <label>Buscando trabajo?</label>
  <select
    name="openwork"
    value={editedUser.openwork ? "yes" : "no"}  // Usamos "yes" y "no" para mostrar las opciones
    onChange={handleEditUserChange}
    className="form-input"
  >
    <option value="yes">Sí</option>
    <option value="no">No</option>
  </select>
</div>

              <div className="form-group">
                <label>
                  Imagen de perfil:
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    className="file-input"
                  />
                </label>
                {imagePreview && (
                  <img
                    src={imagePreview}
                    alt="Previsualización"
                    className="image-preview"
                  />
                )}
              </div>
              <div className="form-group">
                <label>Resumen de Usuario:</label>
                <textarea
                  name="user_summary"
                  value={editedUser.user_summary}
                  onChange={handleEditUserChange}
                  rows="4"
                  className="form-textarea"
                />
              </div>

              <button type="submit" className="submit-button">
                Enviar
              </button>
            </form>
          </div>
        </div>
      )}

      {/* Modal para agregar Skill */}
      {isModalOpen1 && (
  <div className="modal">
    <div className="modal-content" style={{ overflowY: "auto", width: "50%", padding: "20px", borderRadius: "8px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", backgroundColor: "#ffffff" }}>
      <span className="close" onClick={toggleSkillModal} style={{ cursor: "pointer", fontSize: "24px", color: "#888", float: "right" }}>
        &times;
      </span>
      <h2 style={{ textAlign: "center", color: "#333" }}>Ingresar profesión que puede desarrollar</h2>
      <form onSubmit={handleCreateSkill} className="edit-profile-form" style={{ display: "flex", flexDirection: "column", marginTop: "20px" }}>
        <label style={{ marginBottom: "10px", color: "#555", fontWeight: "bold" }}>
          Nombre de la Profesión:
          <input 
            type="text" 
            name="skillName" 
            required 
            style={{ padding: "10px", marginTop: "5px", borderRadius: "5px", border: "1px solid #ccc", fontSize: "16px", width: "100%" }} 
          />
        </label>
        <button 
          type="submit" 
          className="submit-button" 
          
        >
          Guardar profesión
        </button>
        {/* <button type="button" onClick={toggleSkillModal}>Cerrar</button> */}
      </form>
    </div>
  </div>
)}


      {/* Modal para agregar Experiencia */}
      {isExperienceModalOpen && (
  <div className="modal">
    <div className="modal-content">
      <span className="close" onClick={toggleExperienceModal}>&times;</span>
      <h2>Crear Experiencia Laboral</h2>
      <form onSubmit={handleCreateExperience} className="edit-profile-form">
        <div className="form-group">
          <label>Título del Trabajo:</label>
          <input type="text" name="experienceTitle" className="form-input" required />
        </div>
        <div className="form-group">
          <label>Compañía:</label>
          <input type="text" name="experienceCompany" className="form-input" required />
        </div>
        <div className="form-group">
          <label>Responsabilidades:</label>
          <textarea name="responsability" className="form-textarea" rows="4" required></textarea>
        </div>
        <div className="form-group">
          <label>Fecha de Inicio:</label>
          <input type="date" name="startDate" className="form-input" required />
        </div>
        <div className="form-group">
          <label>Fecha de Fin:</label>
          <input type="date" name="endDate" className="form-input" />
        </div>
        <button type="submit" className="submit-button">Guardar Experiencia</button>
      </form>
    </div>
  </div>
)}
</div>
    </div>
  );
};

export default Profile;
