import React, { useEffect, useState } from "react"; 
import ShippingDataService from "../../../services/shipping";
import TodoDataService from "../../../services/todos";
import { useSelector } from 'react-redux';

const FormDetailsModalByName = ({ formName, onClose, form_idToView }) => {
  const [formProduct, setFormProduct] = useState(null);
  const token = useSelector(state => state.authentication.token);
  const [formProducts, setFormProducts] = useState([]);
  const [users, setUsers] = useState([]);
  const [products, setProducts] = useState([]);
  const [responses, setResponses] = useState({});
  const [selectedFields, setSelectedFields] = useState({}); // Inicializado como objeto vacío

  const productoId = form_idToView;

  useEffect(() => {
    fetchFormByName(formName);
  }, [formName]);

  const fetchFormByName = async (name) => {
    try {
      const response = await ShippingDataService.getAllForms();
        const foundForm = response.data.find(
          (form) => form.product.some((productId) => productId === name) // Verifica si 10 está en el array de productos
        );

        setFormProduct(foundForm || null); // Establece el formulario encontrado o null
        console.log("foundForm", foundForm);
        console.log("foundname", name);
        if (foundForm) {
          const initialResponses = {};
          
          foundForm.fields.forEach(field => {
            // Busca la respuesta por field._id y productId
            const responseValue = getResponseForField(foundForm.responses, field._id, name);
            
            // Si no hay respuesta para el productId dado, asigna un valor vacío
            initialResponses[field._id] = responseValue !== undefined ? responseValue : '';
          });
    
          console.log("foundForm.responses", foundForm.responses);
          console.log("initialResponses", initialResponses);
          setResponses(initialResponses || null);
        }
    } catch (error) {
      console.error("Error fetching form by name:", error);
    }
  };

  useEffect(() => {
    fetchFormProducts();
    fetchUsers();
    fetchProducts();
  }, []);

  const fetchFormProducts = () => {
    ShippingDataService.getAllForms()
      .then((response) => {
        setFormProducts(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchUsers = () => {
    TodoDataService.getUserList(token)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchProducts = () => {
    ShippingDataService.getAllProducts()
      .then((response) => {
        setProducts(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getUserNameById = (userId) => {
    const user = users.find((user) => user.id === userId);
    return user ? `${user.first_name} ${user.last_name}` : "Desconocido";
  };

  const getProductNameById = (productId) => {
    const product = products.find((product) => product._id === productId);
    return product ? product.name : "Producto no disponible";
  };

  const getResponseForField = (responses, fieldId, productId) => {
    const response = responses.find(
      (response) => response.form_field === fieldId && response.product === productId
    );
  
    // Si se encuentra, retorna el valor; si no, retorna undefined
    return response ? response.value : undefined;
  };

  const handleCheckboxChange = (fieldId) => {
    setSelectedFields((prev) => {
      const updatedFields = { ...prev };
      if (updatedFields[fieldId]) {
        // Checkbox deseleccionado
        console.log(`Checkbox deseleccionado para el campo: ${fieldId}, responseId: ${updatedFields[fieldId]}`);
        delete updatedFields[fieldId]; // Elimina el response._id
      } else {
        // Checkbox seleccionado
        const responseItem = formProduct.responses.find(resp => resp.form_field === fieldId);
        if (responseItem) {
          updatedFields[fieldId] = responseItem._id; // Guarda el response._id
          console.log(` responseId: ${responseItem._id}`);
          handleDeleteSelected(responseItem._id)
        } else {
          console.log(`No se encontró responseId para el campo: ${fieldId}`);
        }
      }
      return updatedFields;
    });
  };
  

  const handleDeleteSelected = async (selectedField) => {
    if (!selectedField) return;
    try {
      await ShippingDataService.deleteFormResponse(selectedField, token);
      console.log(`Campo con ID: ${selectedField} eliminado`);

      // Volver a cargar los datos después de eliminar el campo
      fetchFormByName(formName);
      
      // Actualiza selectedFields para eliminar el campo eliminado
      setSelectedFields((prev) => {
        const updatedFields = { ...prev };
        delete updatedFields[selectedField]; // Elimina el campo del estado
        return updatedFields;
      });

    } catch (error) {
      console.error('Error eliminando el campo del formulario:', error);
    }
  };

  const handleSubmitResponse = async () => {
    const responseDataBase = {
      form: formProduct._id,
      product: productoId,
      user: formProduct.user,
    };

    const responsesArray = Object.entries(responses).map(([fieldId, value]) => ({
      form_field: fieldId,
      value: value,
    }));

    console.log("datos completos", responseDataBase);
    
    try {
      for (const response of responsesArray) {
        const { value } = response;
        if (value) {
          const responseData = {
            ...responseDataBase,
            responses: response,
          };
          await ShippingDataService.createFormResponse(responseData, token);
          console.log("Respuesta enviada:", responseData);
          await new Promise((resolve) => setTimeout(resolve, 500));
        } else {
          console.log(`No se enviará la respuesta para el campo ${response.form_field} porque el valor está vacío.`);
        }
      }
    } catch (error) {
      console.error("Error al enviar respuestas:", error);
    }
  };
  
  const handleInputChange = (e, fieldId) => {
    const newValue = e.target.value;
    setResponses((prevResponses) => ({
      ...prevResponses,
      [fieldId]: newValue,
    }));
  };
  
  return (
    <div>
      <div className="modal">
        <div className="modal-content" style={{ overflowY: 'auto', width: "70%" }}>
          <button onClick={onClose} className="close-button">X</button>
          <div style={{ marginBottom: "4%", fontWeight: "bold" }}>
            <h1>Producto seleccionado</h1>
            <h2>{getProductNameById(productoId)}</h2>
          </div>
          {formProduct ? (
            <div>
              <h3 style={{ fontWeight: "bold" }}>Formulario: {formProduct.name}</h3>
              <p>ID: {formProduct._id}</p>
              <p>Fecha de creación: {new Date(formProduct.created_at).toLocaleString()}</p>
              <p>Usuario: {getUserNameById(formProduct.user)}</p>
              <p>Productos: 
  {formProduct.product.map((productId, index) => (
    <span key={productId}>
      {getProductNameById(productId)}
      {index < formProduct.product.length - 1 ? ', ' : ''}
    </span>
  ))}
</p>

              <h3>Campos:</h3>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <table style={{ width: "100%", borderCollapse: "collapse", marginBottom: '20px' }}>
  <thead>
    <tr>
      <th style={{ border: '1px solid #ccc', padding: '8px', textAlign: 'center' }}>Checkbox</th>
      <th style={{ border: '1px solid #ccc', padding: '8px', textAlign: 'center' }}>Label</th>
      <th style={{ border: '1px solid #ccc', padding: '8px', textAlign: 'center' }}>Response</th>
    </tr>
  </thead>
  <tbody>
    {formProduct.fields.map((field, index) => (
      <tr key={index}>
        <td style={{ border: '1px solid #ccc', padding: '8px', textAlign: 'center' }}>
          <input
            type="checkbox"
            checked={!!selectedFields[field._id]} // Convertir a booleano para que sea false si no está definido
            onChange={() => handleCheckboxChange(field._id)} // Maneja el cambio en el checkbox
            style={{ width: "20px" }}
          />
        </td>
        <td style={{ border: '1px solid #ccc', padding: '8px' }}>{field.label}:</td>
        <td style={{ border: '1px solid #ccc', padding: '8px' }}>
          <input
            value={responses[field._id] || ""}
            onChange={(e) => handleInputChange(e, field._id)}
            style={{ width: '100%', padding: '5px', borderRadius: '4px', border: '1px solid #ccc' }} // Estilo del input
          />
        </td>
      </tr>
    ))}
  </tbody>
</table>

              </div>
              <button onClick={handleSubmitResponse}>Enviar Respuesta</button>
            </div>
          ) : (
            <p>No se encontró el formulario.</p>
          )}
        </div>
      </div>
      <button onClick={onClose}>Cerrar</button>
    </div>
  );
}

export default FormDetailsModalByName;
