import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import {
  FaShoppingCart,
  FaMapMarkerAlt,
  FaSearch,
  FaStar,
} from "react-icons/fa";
import ShippingDataService from "../../services/shipping";
import TodoDataService from "../../services/todos";
import { useSelector } from "react-redux";
import Footer from "../../components/navigation/Footer";
import CurrentUserContext from "../../components/backend/CurrentUserContext";
import { useMediaQuery } from "react-responsive";
import TextSlider from "./TextSlider";

function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export { useInterval };

// Componente principal
function HomeScreen(props) {
  // Estado para productos
  const [products, setProducts] = useState([]);
  const [userList, setUserList] = useState([]);
  const [storedData, setStoredData] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [selectedSubSubcategory, setSelectedSubSubcategory] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [minStars, setMinStars] = useState(0);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(8000000000);
  const [inputValue, setInputValue] = useState("");
  const [inputMinValue, setInputMinValue] = useState(""); // Valor para el input
  const [modalProduct, setModalProduct] = useState(null);
  const [reviews, setReviews] = useState([]);
  // const [orders, setOrders] = useState([]);
  const [orderItems, setOrderItems] = useState([]);
  const [shippingAddresses, setShippingAddresses] = useState([]);
  const [users, setUsers] = useState([]);
  const [productreviews, setProductReviews] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [cartItems, setCartItems] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const subtotal = cartItems.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  // Lógica de paginación
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = productreviews.slice(indexOfFirstItem, indexOfLastItem);
  // const currentOrders = orders.slice(indexOfFirstItem, indexOfLastItem);
  const [isCartModalOpen, setIsCartModalOpen] = useState(false);
  const [isMapModalOpen, setIsMapModalOpen] = useState(false);
  const { allowedCategories } = props;
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 1024px)" });
  const isLargeScreen = useMediaQuery({ query: "(min-width: 1025px)" });
  const token = useSelector((state) => state.authentication.token);
  const user = useSelector((state) => state.authentication.user);

  const flexBasis = isMobile ? "100%" : isTablet ? "50%" : "20%";
  const margin = isMobile ? "5px" : "10px";
  const containerRef = useRef(null);
  const [isZoomed, setIsZoomed] = useState(false);
  const [zoomPosition, setZoomPosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    const imgElement = e.target;
    const rect = imgElement.getBoundingClientRect();
    const x = e.clientX - rect.left; // Posición X del cursor dentro de la imagen
    const y = e.clientY - rect.top; // Posición Y del cursor dentro de la imagen

    // Relación de zoom entre la imagen ampliada y la original
    const fact = 800 / 400;
    const opp = 100;

    // Calcular las posiciones para el recorte (clip)
    const x1 = -opp + x * fact;
    const y1 = -opp + y * fact;
    const x2 = opp + x * fact;
    const y2 = opp + y * fact;

    // Actualizar la posición del zoom
    setZoomPosition({
      x1,
      y1,
      x2,
      y2,
      left: x * (1 - fact),
      top: y * (1 - fact),
    });

    setIsZoomed(true);
  };

  const handleMouseLeave = () => {
    setIsZoomed(false);
  };

  // Funciones de apertura y cierre de modales
  const openCartModal = () => {
    setIsCartModalOpen(true);

    // Obtiene los productos del localStorage y actualiza el estado del carrito
    const storedCartItems = localStorage.getItem("cartItems");
    if (storedCartItems) {
      setCartItems(JSON.parse(storedCartItems));
    }
  };

  const closeCartModal = () => {
    setIsCartModalOpen(false);
  };

  const openMapModal = () => {
    setIsMapModalOpen(true);
  };

  const closeMapModal = () => {
    setIsMapModalOpen(false);
  };

  // Función de paginación
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Estado y efecto para usuarios
  const [currentPageProducts, setCurrentPageProducts] = useState(1);
  const productsPerPage = 10;

  useEffect(() => {
    const storedUser = localStorage.getItem("currentUser");
    if (storedUser) {
      setCurrentUser(JSON.parse(storedUser));
    }
  }, []);

  useEffect(() => {
    fetchProducts();
    fetchReviews();
    // fetchOrders();
    fetchOrderItems();
    fetchShippingAddresses();
    // fetchUsers();
    fetchCartItems();
  }, [token]);
  useEffect(() => {
    fetchProducts();
    fetchReviews();
    // fetchOrders();
    fetchOrderItems();
    fetchShippingAddresses();
    // fetchUsers();
    fetchCartItems();
  }, []);

  const fetchCartItems = () => {
    const storedCartItems = localStorage.getItem("cartItems");
    if (storedCartItems) {
      setCartItems(JSON.parse(storedCartItems));
    }
  };

  useEffect(() => {
    const storedCountry = localStorage.getItem("selectedCountry");
    console.log("pais del navbar", storedCountry);
    if (storedCountry) {
      setSelectedCountry(storedCountry);
    }
  }, []);

  const updateSelectedCountryFromLocalStorage = () => {
    const storedCountry = localStorage.getItem("selectedCountry");
    if (storedCountry) {
      setSelectedCountry(storedCountry);
    }
  };

  // Actualiza el estado inicial desde el localStorage
  useEffect(() => {
    updateSelectedCountryFromLocalStorage();
  }, []);

  // Utiliza useInterval para verificar el localStorage cada 4 segundos
  useInterval(() => {
    updateSelectedCountryFromLocalStorage();
    fetchProducts(); // Asegúrate de que esta función recargue los datos según los nuevos valores
  }, 1000);

  const fetchReviews = () => {
    ShippingDataService.getAllReviews()
      .then((response) => {
        setReviews(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // const fetchOrders = () => {
  //   ShippingDataService.getAllOrders()
  //     .then((response) => {
  //       setOrders(response.data);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  const fetchOrderItems = () => {
    ShippingDataService.getAllOrderItems()
      .then((response) => {
        setOrderItems(response.data);
      })
      .catch((error) => {
        console.error("Error fetching order items:", error);
      });
  };

  const fetchShippingAddresses = () => {
    ShippingDataService.getAllShippingAddresses()
      .then((response) => {
        setShippingAddresses(response.data);
      })
      .catch((error) => {
        console.error("Error fetching shipping addresses:", error);
      });
  };

  // useEffect(() => {
  //   fetchProducts();
  // }, [selectedCategory, searchText, minStars, minPrice, maxPrice]);

  const fetchProducts = () => {
    const productsKey = "products";

    // Llama a la API para obtener los productos
    ShippingDataService.getAllProducts()
      .then((response) => {
        // Obtiene los productos de la respuesta
        const fetchedProducts = response.data;

        // Verifica si los productos están en localStorage
        const storedProducts = localStorage.getItem(productsKey);

        // Si existen, eliminarlos de localStorage
        if (storedProducts) {
          localStorage.removeItem(productsKey);
        }

        // Guarda los productos obtenidos en localStorage
        localStorage.setItem(productsKey, JSON.stringify(fetchedProducts));

        // Actualiza el estado de los productos
        setProducts(fetchedProducts);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Lógica de categorías únicas
  const uniqueCategories = [
    ...new Set(products.map((product) => product.category)),
  ];
  const [productForms, setProductForms] = useState({});

  // Función para filtrar productos
  const filterProducts = () => {
    return products.filter((product) => {
      const nameMatch = product.name
        .toLowerCase()
        .includes(searchText.toLowerCase());
      const categoryMatch =
        !selectedCategory || product.category === selectedCategory;

      // Cambia la lógica aquí para que solo filtre por subcategoría si está seleccionada
      const subcategoryMatch =
        !selectedSubcategory || product.subcategory === selectedSubcategory;
      const subsubcategoryMatch =
        !selectedSubSubcategory ||
        product.subsubcategory === selectedSubSubcategory;

      const isAllowedCategory = allowedCategories.includes(product.category);
      // console.log("isAllowedCategory", isAllowedCategory)
      const starsMatch = product.rating >= minStars;
      const numericMinPrice = parseFloat(minPrice) || 0;
      const numericMaxPrice = parseFloat(maxPrice) || 0;
      const priceMatch =
        product.price >= numericMinPrice && product.price <= numericMaxPrice;
      console.log(numericMaxPrice);
      return (
        nameMatch &&
        categoryMatch &&
        subcategoryMatch &&
        subsubcategoryMatch &&
        isAllowedCategory &&
        starsMatch &&
        priceMatch
      );
    });
  };

  const filteredProducts = filterProducts();

  // Lógica de paginación para productos
  const indexOfLastProduct = currentPageProducts * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );
  const paginateProducts = (pageNumber) => {
    setCurrentPageProducts(pageNumber);

    setTimeout(() => {
      containerRef.current.scrollIntoView({ behavior: "smooth" });
    }, 400);
  };
  const openImageModal = (productId, productName) => {
    // Llamar al método para obtener las imágenes de ProductImage
    ShippingDataService.getProductImages(productId)
      .then((response) => {
        console.log(
          "Datos de las imágenes obtenidos con éxito:",
          response.data
        );

        // Filtrar las imágenes que tienen el mismo productId
        const filteredImages = response.data.filter(
          (image) => image.product === productId
        );

        setSelectedImages(filteredImages); // Establecer las imágenes filtradas en el estado
        setSelectedProductName(productName); // Establecer el nombre del producto
        setSelectedProductId(productId); // Establecer el nombre del producto
        setCurrentImageIndex(0); // Inicializar el índice de imagen actual en 0
        setIsImageModalOpen(true);
        setIsImageExpanded(false); // Inicializar la imagen como no expandida
      })
      .catch((error) => {
        console.error("Error al obtener las imágenes:", error);
      });
  };

  // Función para mostrar la imagen anterior
  const showPreviousImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };

  // Función para mostrar la imagen siguiente
  const showNextImage = () => {
    if (currentImageIndex < selectedImages.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
    }
  };

  // Función para alternar la expansión de la imagen
  const toggleImageExpansion = () => {
    setIsImageExpanded(!isImageExpanded);
  };

  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedProductName, setSelectedProductName] = useState("");
  const [selectedProductId, setSelectedProductId] = useState("");
  const [currentImageIndex, setCurrentImageIndex] = useState(0); // Índice de la imagen actual
  const [isImageExpanded, setIsImageExpanded] = useState(false); // Estado para la imagen expandida
  const [formNameToView, setFormNameToView] = useState(null);
  const [form_idToView, setForm_idToView] = useState(null);
  const [isNameModalOpen, setIsNameModalOpen] = useState(false);

  useEffect(() => {
    if (formNameToView) {
      fetchFormByName(formNameToView);
      // fetchFormProducts();
      // fetchUsers();
      // fetchProducts1();
    }
  }, [formNameToView]);

  // Funciones de apertura y cierre del modal de producto
  const openModal = (product) => {
    console.log("product", product);
    setModalProduct(product);
    setForm_idToView(product._id);
    setFormNameToView(product._id);
    setIsNameModalOpen(true);
  };

  const [formProducts, setFormProducts] = useState([]);
  const [formProduct, setFormProduct] = useState(null);
  const [responses, setResponses] = useState({});

  const fetchFormByName = async (name) => {
    try {
      const response = await ShippingDataService.getAllForms();
      console.log("formularios", response.data);
      const foundForm = response.data.find(
        (form) => form.product.some((productId) => productId === name) // Verifica si 10 está en el array de productos
      );

      setFormProduct(foundForm || null); // Establece el formulario encontrado o null
      console.log("foundForm", foundForm); // Muestra el formulario encontrado
      console.log("searched product ID", 10); // Muestra el ID del producto que estás buscando

      if (foundForm) {
        const initialResponses = {};
        // Aquí no inicializamos selectedFields, se queda vacío

        foundForm.fields.forEach((field) => {
          // Busca la respuesta por field._id y productId
          const responseValue = getResponseForField(
            foundForm.responses,
            field._id,
            name
          );

          // Si no hay respuesta para el productId dado, asigna un valor vacío
          initialResponses[field._id] =
            responseValue !== undefined ? responseValue : "";
        });

        setResponses(initialResponses);
        // selectedFields no se establece aquí
      }
    } catch (error) {
      console.error("Error fetching form by name:", error);
    }
  };
  const fetchFormProducts = async () => {
    const formProductsKey = "formProducts";

    // Verifica si los productos de formulario están en localStorage
    const storedFormProducts = localStorage.getItem(formProductsKey);

    if (storedFormProducts) {
      // Si existen, eliminarlos de localStorage
      localStorage.removeItem(formProductsKey);
    }

    try {
      // Llama a la API para obtener los productos de formulario
      const response = await ShippingDataService.getAllForms();
      console.log("formularios", response.data);
      // Guarda los productos obtenidos en localStorage
      localStorage.setItem(formProductsKey, JSON.stringify(response.data));
      // Actualiza el estado de los productos de formulario
      setFormProducts(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchFormProducts(); // Llama a la función para obtener todos los formularios
  }, []);
  const fetchUsers = () => {
    const usersKey = "users";

    // Verifica si los usuarios están en localStorage
    const storedUsers = localStorage.getItem(usersKey);

    if (storedUsers) {
      // Si existen, eliminarlos de localStorage
      localStorage.removeItem(usersKey);
    }

    // Llama a la API para obtener los usuarios
    TodoDataService.getUserList(token)
      .then((response) => {
        // Guarda los usuarios obtenidos en localStorage
        localStorage.setItem(usersKey, JSON.stringify(response.data));

        // Actualiza el estado de los usuarios
        setUsers(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const closeModal = () => {
    setModalProduct(null);
    fetchProducts();
    fetchReviews();
    // fetchOrders();
    fetchOrderItems();
    fetchShippingAddresses();
    // fetchUsers();
    fetchCartItems();
  };

  const getUserNameById = (userId) => {
    const user = users.find((user) => user.id === userId);
    return user ? `${user.first_name} ${user.last_name}` : "Desconocido";
  };

  const getProductNameById = (productId) => {
    const product = products.find((product) => product._id === productId);
    return product ? product.name : "Producto no disponible";
  };

  const getResponseForField = (responses, fieldId, productId) => {
    const response = responses.find(
      (response) =>
        response.form_field === fieldId && response.product === productId
    );

    // Si se encuentra, retorna el valor; si no, retorna undefined
    return response ? response.value : undefined;
  };

  // Función para obtener el nombre de usuario
  const getUserDisplayName = (userId) => {
    console.log("User ID in getUserDisplayName:", userId); // Debugging log
    const user = users.find((u) => u.id === userId);
    return user
      ? `${user.first_name} ${user.last_name}`
      : "Usuario Desconocido";
  };

  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    margin: "20px 0",
  };

  const thStyle = {
    background: "#f2f2f2",
    padding: "10px",
    textAlign: "left",
    borderBottom: "1px solid #ddd",
  };

  const tdStyle = {
    padding: "10px",
    textAlign: "left",
    borderBottom: "1px solid #ddd",
  };

  const getUserList = () => {
    TodoDataService.getUserList(token)
      .then((response) => {
        // Assuming the API response includes the user's first_name and last_name
        const modifiedData = response.data.map((user) => ({
          ...user,
          full_name: `${user.first_name} ${user.last_name}`,
        }));

        setUserList(modifiedData);
        setStoredData(modifiedData);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // Tamaño máximo para el nombre y descripción
  const MAX_NAME_LENGTH = 25;
  const MAX_DESCRIPTION_LENGTH = 50;

  // Tamaño máximo del contenedor para cada producto
  const MAX_CONTAINER_WIDTH = 220;

  // Porcentaje adicional para el contenedor principal
  const ADDITIONAL_WIDTH_PERCENTAGE = 10;

  // Calcula el ancho final del contenedor principal
  const CONTAINER_WIDTH =
    MAX_CONTAINER_WIDTH * (1 + ADDITIONAL_WIDTH_PERCENTAGE / 100);

  const handleAddToCart = (product) => {
    // Crea una copia del carrito actual
    const updatedCart = [...cartItems];

    // Verifica si el producto ya está en el carrito
    const existingItem = updatedCart.find((item) => item._id === product._id);

    if (existingItem) {
      // Si el producto ya está en el carrito, incrementa la cantidad
      existingItem.quantity += 1;
    } else {
      // Si el producto no está en el carrito, agrégalo con cantidad 1
      updatedCart.push({ ...product, quantity: 1 });
    }

    // Actualiza el estado del carrito y guarda en el localStorage
    setCartItems(updatedCart);
    localStorage.setItem("cartItems", JSON.stringify(updatedCart));

    // Muestra un mensaje de éxito o realiza otras acciones necesarias
    alert(`Producto "${product.name}" agregado al carrito.`);
  };

  const handleQuantityChange = (itemId, newQuantity) => {
    // Crea una copia del carrito actual
    const updatedCart = cartItems.map((item) =>
      item._id === itemId ? { ...item, quantity: newQuantity } : item
    );

    // Actualiza el estado del carrito y guarda en el localStorage
    setCartItems(updatedCart);
    localStorage.setItem("cartItems", JSON.stringify(updatedCart));
  };

  const handleRemoveFromCart = (itemId) => {
    // Filtra los productos para mantener solo aquellos que no coincidan con el ID
    const updatedCart = cartItems.filter((item) => item._id !== itemId);

    // Actualiza el estado del carrito y guarda en el localStorage
    setCartItems(updatedCart);
    localStorage.setItem("cartItems", JSON.stringify(updatedCart));
  };
  const handleClearCart = () => {
    // Limpia el estado del carrito y el localStorage
    setCartItems([]);
    localStorage.removeItem("cartItems");
  };

  const categoriesWithoutShipping = ["Casas Nuevas", "Casas Alquiler"];

  const handleSendOrder = () => {
    if (currentUser === null) {
      // El usuario no ha iniciado sesión
      alert("Necesita iniciar sesión para realizar un pedido");
    } else {
      // Obtén los detalles del usuario actual
      const { first_name, last_name, phone } = currentUser || {};

      // Filtra los productos para excluir las categorías sin envío
      const filteredCartItems = cartItems.filter(
        (item) => !categoriesWithoutShipping.includes(item.category)
      );

      // Calcula el subtotal
      const subtotal = filteredCartItems.reduce(
        (total, item) => total + item.price * item.quantity,
        0
      );

      // Construye el mensaje con los detalles del carrito y usuario
      let message = `Hola, soy ${first_name} ${last_name}. Mi pedido es el siguiente:\n\n`;

      // Agrega el detalle de cada producto con cantidad
      const orderDetails = filteredCartItems
        .map(
          (item) =>
            `${item.name} (${item.category}, ${item.brand}) x${
              item.quantity
            } - ₡${item.price * item.quantity}`
        )
        .join("\n");

      // Verifica si algún producto tiene categoría sin envío
      const hasCategoryWithoutShipping = cartItems.some((item) =>
        categoriesWithoutShipping.includes(item.category)
      );

      if (!hasCategoryWithoutShipping) {
        // Agrega el detalle de envío solo si no hay productos de categorías sin envío
        const shippingCost = 3500; // Costo fijo de envío por Correos de Costa Rica
        const totalAmount = correosShippingRemoved ? subtotal : subtotal + 3500;

        message += `${orderDetails}\n\nSubtotal: ₡${subtotal}\nEnvío (Correos de Costa Rica): ₡${shippingCost}\nTotal: ₡${totalAmount}`;
      } else {
        // Agrega el detalle de la orden sin envío
        message += `${orderDetails}\n\nSubtotal: ₡${subtotal}\nTotal: ₡${subtotal}`;
      }

      // Construye el enlace de WhatsApp
      const whatsappLink = `https://api.whatsapp.com/send?phone=+50687886767&text=${encodeURIComponent(
        message
      )}`;

      // Abre la aplicación de WhatsApp en una nueva ventana o pestaña
      window.open(whatsappLink, "_blank");
    }
  };

  const [correosShippingRemoved, setCorreosShippingRemoved] = useState(false);

  const [correosShippingCost, setCorreosShippingCost] = useState(3500);

  const formatPrice = (value) => {
    // Formatea el valor como un número con separador de miles
    return new Intl.NumberFormat("en-US").format(value);
  };

  const handleInputChange = (event) => {
    const value = event.target.value;

    // Si el input está vacío, actualizar el estado del input
    if (value === "") {
      setInputValue("");
      return;
    }

    // Extraer solo los números y actualizar el estado del input
    const numericValue = value.replace(/[^0-9]/g, "");
    setInputValue(numericValue);
  };

  const handleBlur = () => {
    // Al perder el foco, actualizamos maxPrice con el valor numérico
    if (inputValue !== "") {
      setMaxPrice(parseInt(inputValue, 10)); // Convertimos a número
    } else {
      setMaxPrice(8000000000); // Establecer maxPrice al valor inicial si el input está vacío
    }
  };

  const handleMinInputChange = (event) => {
    const value = event.target.value;

    // Si el input está vacío, actualizar el estado del input
    if (value === "") {
      setInputMinValue("");
      return;
    }

    // Extraer solo los números y actualizar el estado del input
    const numericValue = value.replace(/[^0-9]/g, "");
    setInputMinValue(numericValue);
  };

  const handleMinBlur = () => {
    // Al perder el foco, actualizamos minPrice con el valor numérico
    if (inputMinValue !== "") {
      setMinPrice(parseInt(inputMinValue, 10)); // Convertimos a número
    } else {
      setMinPrice(0); // Establecer minPrice a 0 si el input está vacío
    }
  };

  const [fontSize, setFontSize] = useState("1.5rem"); // Tamaño inicial de la fuente
  const textRef = useRef(null); // Referencia para el texto

  useEffect(() => {
    const adjustFontSize = () => {
      if (textRef.current) {
        const containerWidth = textRef.current.parentElement.offsetWidth; // Ancho del contenedor
        let newSize = parseFloat(fontSize); // Tamaño de fuente actual

        // Ajusta el tamaño de la fuente para que el texto quepa
        while (textRef.current.scrollWidth > containerWidth && newSize > 0) {
          newSize -= 0.1; // Reduce el tamaño de la fuente
          setFontSize(`${newSize}rem`);
        }

        // Aumenta el tamaño si es muy pequeño (opcional)
        while (textRef.current.scrollWidth < containerWidth && newSize < 5) {
          newSize += 0.1; // Aumenta el tamaño de la fuente
          setFontSize(`${newSize}rem`);
        }
      }
    };

    adjustFontSize(); // Llama a la función para ajustar el tamaño al cargar el componente

    window.addEventListener("resize", adjustFontSize); // Ajusta el tamaño en cambios de tamaño de ventana
    return () => {
      window.removeEventListener("resize", adjustFontSize); // Limpia el evento al desmontar
    };
  }, [fontSize]);
  const uniqueSubSubcategories = [
    ...new Set(
      products
        .filter((product) => product.subcategory === selectedSubcategory)
        .flatMap((product) => product.subsubcategory.split(","))
    ),
  ];

  return (
    <div style={{ zoom: 0.6 }}>
      <div
        style={{
          backgroundColor: "red",
          color: "white",
          padding: "10px",
          textAlign: "center",
          maxWidth: "100%",
          marginTop: isMobile ? "10%" : "",
          marginRight: "2%",
        }}
        ref={containerRef}
      >
        <h2
          style={{
            backgroundColor: "white",
            color: "black",
            padding: "5px 10px", // Espacio alrededor del texto
            textAlign: "center",
            display: "inline-block", // Asegura que el fondo no ocupe más espacio que el texto
            maxWidth: "100%",
            border: "2px solid black", // Contorno azul
            borderRadius: "8px", // Bordes redondeados
            marginBottom: "10px",
            fontSize: "2.3em",
          }}
        >
          11. Botonera de tienda, presionar para ir a la categoria deseada
        </h2>
        {/* Categoria */}
        <div style={{ overflowX: "auto" }}>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <button
              key="Todas"
              style={{
                marginRight: "10px", // Espaciado entre botones
                backgroundColor:
                  selectedCategory === null ? "rgb(0, 255, 0)" : "white", // Cambia el color de fondo si "Ver Todos" está seleccionado
                fontSize: "1.8em",
                color: "black",
                fontWeight: "bold",
                borderColor: "red",
                borderColor: "black", // Color del borde
                borderWidth: "0.4px", // Grosor del borde en píxeles
                borderStyle: "solid",
              }}
              onClick={() => setSelectedCategory(null)} // Limpiar la selección
            >
              Todas las Categorías
            </button>
            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                flexWrap: "nowrap", // Evitar que se vayan a la siguiente línea
                overflowX: "auto", // Habilitar desplazamiento horizontal
                whiteSpace: "nowrap", // No permitir que los botones se dividan en varias líneas
              }}
            >
              {uniqueCategories
                .filter((category) => allowedCategories.includes(category))
                .map((category, index, array) => {
                  // Si solo hay una categoría en el array filtrado, seleccionarla automáticamente
                  if (array.length === 1 && selectedCategory !== array[0]) {
                    setSelectedCategory(array[0]);
                    setSelectedSubSubcategory(null); // Reinicia subsubcategory
                    setSelectedSubcategory(null);
                  }

                  return (
                    <button
                      key={category}
                      onClick={() => {
                        setSelectedCategory(category); // Selecciona la categoría
                        setSelectedSubSubcategory(null); // Reinicia subsubcategory
                        setSelectedSubcategory(null);
                      }}
                      style={{
                        marginRight: "10px", // Espaciado entre botones
                        backgroundColor:
                          selectedCategory === category
                            ? "rgb(0, 255, 0)"
                            : "white", // Cambia el color de fondo según la selección
                        fontSize: "1.2em",
                        color: "black",
                        fontWeight: "bold",
                        borderColor: "black", // Color del borde
                        borderWidth: "0.4px", // Grosor del borde en píxeles
                        borderStyle: "solid", // Estilo del borde
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor =
                          "rgb(0, 255, 0)";
                        e.currentTarget.style.color = "black";
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor =
                          selectedCategory === category
                            ? "rgb(0, 255, 0)"
                            : "white"; // Mantiene el color de fondo si está seleccionado
                        e.currentTarget.style.color = "black";
                      }}
                    >
                      {category}
                    </button>
                  );
                })}
            </div>
          </div>
        </div>
        {/* Subcategoria */}
        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column", // Alinear el contenedor principal en columna
              overflowX: "auto",
            }}
          >
            {selectedCategory && (
              <div
                style={{
                  display: "flex",
                  // flexDirection: "column", // Cambia a columna para alinear verticalmente
                  alignItems: "flex-start", // Alinear los elementos al inicio
                }}
              >
                <button
                  key="Todas"
                  style={{
                    backgroundColor:
                      selectedSubcategory === null ? "rgb(0, 255, 0)" : "white", // Cambia el color de fondo si "Ver Todos" está seleccionado
                    fontSize: "1.8em",
                    color: "black",
                    borderColor: "black", // Color del borde
                    fontWeight: "bold",
                    borderWidth: "0.4px", // Grosor del borde en píxeles
                    borderStyle: "solid", // Estilo del borde
                    marginBottom: "10px", // Espacio entre el botón "Todas" y las subcategorías
                  }}
                  onClick={() => {
                    setSelectedSubcategory(null);
                    setSelectedSubSubcategory(null);
                  }}
                >
                  Todas las Subcategorías
                </button>

                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap", // Permite que los elementos se envuelvan en varias filas
                    justifyContent: "flex-start", // Alinea los botones al inicio de la fila
                    gap: "10px", // Espaciado entre botones
                  }}
                >
                  {products
                    .filter((product) => product.category === selectedCategory)
                    .reduce((acc, product) => {
                      // Agregar subcategorías únicas al acumulador
                      product.subcategory.split(",").forEach((subcategory) => {
                        const trimmedSubcategory = subcategory.trim();
                        if (!acc.includes(trimmedSubcategory)) {
                          acc.push(trimmedSubcategory);
                        }
                      });
                      return acc;
                    }, [])
                    // Ordenar las subcategorías alfabéticamente
                    .sort((a, b) => a.localeCompare(b))
                    .map((subcategory) => (
                      <button
                        key={subcategory}
                        style={{
                          backgroundColor:
                            selectedSubcategory === subcategory
                              ? "rgb(0, 255, 0)"
                              : "white", // Cambia el color de fondo según la selección
                          fontSize: "1.2em",
                          color: "black",
                          fontWeight: "bold",
                          borderColor: "black", // Color del borde
                          borderWidth: "0.4px", // Grosor del borde en píxeles
                          borderStyle: "solid", // Estilo del borde
                          flex: "0 0 calc(120px - 10px)", // Ancho para 6 botones por fila (100% / 6)
                          boxSizing: "border-box", // Asegura que el padding y el borde se incluyan en el ancho
                        }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.backgroundColor =
                            "rgb(0, 255, 0)";
                          e.currentTarget.style.color = "black";
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.backgroundColor =
                            selectedSubcategory === subcategory
                              ? "rgb(0, 255, 0)"
                              : "white"; // Mantiene el color de fondo si está seleccionado
                          e.currentTarget.style.color = "black";
                        }}
                        onClick={() => {
                          setSelectedSubcategory(subcategory);
                          setSelectedSubSubcategory(null);
                        }}
                      >
                        {subcategory}
                      </button>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Visualización de subsubcategorías */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            overflowX: "auto",
            whiteSpace: "nowrap",
            maxWidth: "100%", // Asegúrate de que el contenedor no exceda el ancho de la pantalla
          }}
        >
          <div
            style={{
              display: "flex",
              maxWidth: "100%",
              flexDirection: "column", // Alinear el contenedor principal en columna
            }}
          >
            {selectedSubcategory && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row", // Alinear los elementos en fila
                  alignItems: "center", // Alinear verticalmente los botones
                  overflowX: "auto", // Habilitar desplazamiento horizontal si hay muchas subcategorías
                  whiteSpace: "nowrap", // Evitar que los botones se rompan en varias líneas
                }}
              >
                <button
                  key="Todas"
                  style={{
                    backgroundColor:
                      selectedSubSubcategory === null
                        ? "rgb(0, 255, 0)"
                        : "white",
                    fontSize: "1.8em",
                    color: "black",
                    borderColor: "black", // Color del borde
                    fontWeight: "bold",
                    borderWidth: "0.4px", // Grosor del borde en píxeles
                    borderStyle: "solid",
                  }}
                  onClick={() => setSelectedSubSubcategory(null)}
                >
                  Todas las Subsubcategorías
                </button>

                <div
                  style={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    flexWrap: "nowrap", // Evitar que se vayan a la siguiente línea
                    overflowX: "auto", // Habilitar desplazamiento horizontal
                    whiteSpace: "nowrap", // No permitir que los botones se dividan en varias líneas
                    justifyContent: "flex-start", // Alinear elementos a la izquierda
                    alignItems: "flex-start", // Alinear elementos al inicio del eje transversal
                  }}
                >
                  {uniqueSubSubcategories
                    .sort((a, b) => a.localeCompare(b)) // Ordenar las subsubcategorías alfabéticamente
                    .map((subsubcategory) => (
                      <button
                        key={subsubcategory}
                        style={{
                          marginRight: "10px",
                          backgroundColor:
                            selectedSubSubcategory === subsubcategory
                              ? "rgb(0, 255, 0)"
                              : "white",
                          fontSize: "1.2em",
                          color: "black",
                          fontWeight: "bold",
                          borderColor: "black",
                          borderWidth: "0.4px",
                          borderStyle: "solid",
                        }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.backgroundColor =
                            "rgb(0, 255, 0)";
                          e.currentTarget.style.color = "black";
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.backgroundColor =
                            selectedSubSubcategory === subsubcategory
                              ? "rgb(0, 255, 0)"
                              : "white";
                          e.currentTarget.style.color = "black";
                        }}
                        onClick={() =>
                          setSelectedSubSubcategory(subsubcategory)
                        }
                      >
                        {subsubcategory}
                      </button>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            marginTop: "20px",
            padding: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "10px",
          }}
        >
          <div style={{ marginRight: "20px" }}>
            <div style={{ marginRight: "35px" }}>
              <h3 style={{ fontSize: "1.2em", fontWeight: "bold" }}>
                Filtrar por Nombre
              </h3>
            </div>
            <div style={{ display: "flex" }}>
              <input
                type="text"
                placeholder="Buscar por nombre"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                style={{
                  marginRight: "10px",
                  padding: "8px",
                  borderRadius: "5px",
                  border: "1px solid red",
                  fontSize: "1.2em",
                  fontWeight: "bold",
                  color: "black",
                  // zIndex:2,
                }}
              />
              <FaSearch size={20} style={{ alignSelf: "center" }} />
            </div>
          </div>

          <div>
            <div style={{ marginRight: "35px" }}>
              <h3 style={{ fontSize: "1.2em", fontWeight: "bold" }}>
                Filtrar por Precio
              </h3>
            </div>
            <div style={{ display: "flex" }}>
              <input
                type="text"
                placeholder="Precio mínimo" // Placeholder que se muestra cuando no hay valor
                value={
                  inputMinValue === "" ? "" : `₡${formatPrice(inputMinValue)}`
                }
                onChange={handleMinInputChange}
                onBlur={handleMinBlur} // Actualiza minPrice al perder el foco
                style={{
                  marginRight: "10px",
                  padding: "8px",
                  borderRadius: "5px",
                  color: "black",
                  border: "1px solid red",
                  fontSize: "1.2em",
                  fontWeight: "bold",
                }}
              />
              <input
                type="text"
                placeholder="Precio máximo" // Placeholder que se muestra cuando no hay valor
                value={inputValue === "" ? "" : `₡${formatPrice(inputValue)}`} // Muestra el precio formateado si hay valor
                onChange={handleInputChange}
                onBlur={handleBlur} // Actualiza maxPrice al perder el foco
                style={{
                  fontSize: "1.2em",
                  marginRight: "10px",
                  padding: "8px",
                  color: "black",
                  borderRadius: "5px",
                  border: "1px solid red",
                  fontWeight: "bold",
                }}
              />
            </div>
          </div>

          <style>
            {`
      @media (max-width: 600px) {
        div {
          flex-direction: column;
        }
      }
    `}
          </style>
        </div>
      </div>
      <h1
        style={{
          fontSize: "2.6em",
          fontWeight: "bold",
        }}
      >
        Productos
      </h1>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))", // Ajusta la cantidad de columnas automáticamente
          gap: "10px", // Espaciado entre productos
          marginRight: "2%",
        }}
      >
        {currentProducts.map((product) => {
          // Truncar el nombre y la descripción si es necesario
          const truncatedName =
            product.name.length > MAX_NAME_LENGTH
              ? `${product.name.substring(0, MAX_NAME_LENGTH)}...`
              : product.name;

          const truncatedDescription =
            product.description.length > MAX_DESCRIPTION_LENGTH
              ? `${product.description.substring(0, MAX_DESCRIPTION_LENGTH)}...`
              : product.description;

          const relatedForms = formProducts.filter((form) =>
            form.product.some((productId) => productId === product._id)
          );

          return (
            <div
              key={product._id}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                border: "1px solid #ddd",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                padding: "2px",
                background: "white",
                height: "auto", // Cambiado a auto para adaptarse al contenido
                boxSizing: "border-box",
              }}
            >
              {/* Mostrar formularios relacionados */}
              {relatedForms.length > 0 && (
                <div
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    padding: "10px",
                    textAlign: "center",
                    width: "100%",
                    height: "60px",
                    marginBottom: "-5%",
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    border: "1px solid black",
                    marginBottom: "4%",
                  }}
                >
                  <div>
                    <div style={{ marginTop: "4%" }}>
                      {relatedForms.map((form) => (
                        <div key={form._id}>
                          {/* Verifica los campos "Marca" y "Título" */}
                          {form.fields.map((field) => {
                            if (
                              field.label === "Marca" ||
                              field.label === "Título"
                            ) {
                              // Busca la respuesta correspondiente al campo actual y verifica el productId
                              const response = form.responses.find(
                                (response) =>
                                  response.form_field === field._id &&
                                  response.product === product._id // Filtrar por productId
                              );

                              return (
                                <h2
                                  key={field._id}
                                  style={{
                                    color: "white",
                                    margin: "10px 0",
                                    fontSize: "1.6em",
                                  }}
                                >
                                  {response ? response.value : "Sin respuesta"}{" "}
                                  {/* Valor del campo correspondiente */}
                                </h2>
                              );
                            }
                            return null; // No renderiza nada si el campo no coincide
                          })}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              <div>
                {product.image && (
                  <img
                    src={product.image}
                    alt="Product Image"
                    loading="lazy"
                    style={{
                      width: "440px", // Ancho fijo
                      height: "150px", // Altura fija
                      borderRadius: "5px",
                      marginBottom: "10px",
                      objectFit: "cover", // Ajuste de la imagen dentro de las dimensiones
                    }}
                  />
                )}
              </div>

              <strong
                style={{
                  marginBottom: "10px",
                  fontSize: "1.6em",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                {truncatedName}
              </strong>

              <table
                style={{
                  width: "100%",
                  borderCollapse: "collapse",
                  marginBottom: "10px",
                }}
              >
                <tbody>
                  {/* Información del producto */}
                  <tr>
                    <td
                      style={{
                        textAlign: "left",
                        padding: "8px",
                        color: "black",
                        borderBottom: "1px solid #ddd",
                        fontWeight: "bold",
                        fontSize: isMobile ? "1.4em" : "1.4em",
                      }}
                    >
                      Calificación:
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        padding: "8px",
                        borderBottom: "1px solid #ddd",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "1.4em",
                        }}
                      >
                        {Array.from({ length: product.rating }, (_, index) => (
                          <FaStar
                            key={index}
                            color="gold"
                            style={{
                              marginRight: "3px",
                              fontWeight: "bold",
                            }}
                          />
                        ))}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        textAlign: "left",
                        padding: "8px",
                        color: "black",
                        borderBottom: "1px solid #ddd",
                        fontWeight: "bold",
                        fontSize: isMobile ? "1.4em" : "1.4em",
                      }}
                    >
                      Visitas:
                    </td>
                    <td
                      style={{
                        textAlign: "right",
                        padding: "8px",
                        borderBottom: "1px solid #ddd",
                        color: "black",
                        fontSize: isMobile ? "1.4em" : "1.4em",
                      }}
                    >
                      {product.numReviews} Visitas
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        textAlign: "left",
                        padding: "8px",
                        color: "black",
                        borderBottom: "1px solid #ddd",
                        fontWeight: "bold",
                        fontSize: isMobile ? "1.4em" : "1.4em",
                      }}
                    >
                      Precio (Colones):
                    </td>
                    <td
                      style={{
                        textAlign: "right",
                        padding: "8px",
                        borderBottom: "1px solid #ddd",
                        color: "black",
                        fontSize: isMobile ? "1.4em" : "1.4em",
                      }}
                    >
                      ₡{formatPrice(product.price)}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        textAlign: "left",
                        padding: "8px",
                        color: "black",
                        borderBottom: "1px solid #ddd",
                        fontWeight: "bold",
                        fontSize: isMobile ? "1.4em" : "1.4em",
                      }}
                    >
                      Precio (Dólares):
                    </td>
                    <td
                      style={{
                        textAlign: "right",
                        padding: "8px",
                        borderBottom: "1px solid #ddd",
                        color: "black",
                        fontSize: isMobile ? "1.4em" : "1.4em",
                      }}
                    >
                      <span style={{ color: "green" }}>
                        (${(product.price / 520).toFixed(2)})
                      </span>
                    </td>
                  </tr>

                  {/* Mostrar propiedades relacionadas solo si hay datos */}
                  {relatedForms.length > 0 &&
                    relatedForms.map((form) => (
                      <React.Fragment key={form._id}>
                        {form.fields.map((field) => {
                          // Verifica si el campo es uno de los que queremos mostrar
                          if (
                            [
                              "Construcción (m2)",
                              "Habitaciones",
                              "Baños",
                              "Espacio de cochera",
                              "Lote (m2)",
                              "Fecha de creación",
                              "Transmisión",
                              "Número de pasajeros",
                              "Año",
                              "Combustible",
                              "Cilindrada",
                            ].includes(field.label)
                          ) {
                            // Busca la respuesta correspondiente al campo actual
                            const response = form.responses.find(
                              (response) =>
                                response.form_field === field._id &&
                                response.product === product._id
                            );
                            const displayValue = response
                              ? response.value
                              : "Sin respuesta";

                            // Verifica si el campo tiene "(m2)" en el label para agregar " m2" al valor
                            let formattedValue = field.label.includes("(m2)")
                              ? `${displayValue} m2`
                              : displayValue;
                            // Si el campo es "Cilindrada", agrega " cm³" al valor
                            if (field.label === "Cilindrada" && response) {
                              formattedValue = `${displayValue} cm³`;
                            }

                            return (
                              <tr key={field._id}>
                                <td
                                  style={{
                                    textAlign: "left",
                                    padding: "8px",
                                    color: "black",
                                    borderBottom: "1px solid #ddd",
                                    fontWeight: "bold",
                                    fontSize: isMobile ? "1.4em" : "1.4em",
                                  }}
                                >
                                  <strong>{field.label}:</strong>
                                </td>
                                <td
                                  style={{
                                    textAlign: "right",
                                    padding: "8px",
                                    color: "black",
                                    fontSize: isMobile ? "1.4em" : "1.4em",
                                    borderBottom: "1px solid #ddd",
                                  }}
                                >
                                  {formattedValue}
                                </td>
                              </tr>
                            );
                          }
                          return null; // No renderiza nada si el campo no coincide
                        })}
                      </React.Fragment>
                    ))}
                </tbody>
              </table>

              {/* Mostrar formularios relacionados */}
              {relatedForms.length > 0 && (
                <div style={{ width: "100%", overflow: "hidden" }}>
                  <div style={{ marginTop: "4%", overflow: "hidden" }}>
                    {(() => {
                      // Filtrar solo los formularios que tienen respuestas para el product._id
                      const filteredRelatedForms = relatedForms
                        .map((form) => {
                          // Filtrar las respuestas para el product._id
                          const filteredResponses = form.responses.filter(
                            (response) => response.product === product._id
                          );

                          // Solo devolver el formulario si tiene respuestas filtradas
                          if (filteredResponses.length > 0) {
                            return {
                              ...form,
                              responses: filteredResponses,
                            };
                          }
                          return null; // No devolver formularios sin respuestas
                        })
                        .filter((form) => form !== null); // Eliminar los formularios nulos

                      // Renderizar el TextSlider solo si hay formularios filtrados
                      return filteredRelatedForms.length > 0 ? (
                        <TextSlider relatedForms={filteredRelatedForms} />
                      ) : (
                        <p>
                          No hay propiedades relacionadas para este producto.
                        </p>
                      );
                    })()}
                  </div>
                </div>
              )}

              <div>
                <button
                  onClick={() => openModal(product)}
                  style={{
                    backgroundColor: "white",
                    color: "black",
                    borderRadius: "5px",
                    border: "none",
                    cursor: "pointer",
                    borderColor: "black",
                    borderWidth: "0.4px",
                    borderStyle: "solid",
                    fontSize: "1.8em",
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = "green";
                    e.target.style.color = "white";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = "white";
                    e.target.style.color = "black";
                  }}
                >
                  Ver Más
                </button>
              </div>
            </div>
          );
        })}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "10px",
          flexDirection: "row", // Mantener en fila
          gap: "10px",
          marginBottom: "10px",
        }}
      >
        <button
          onClick={() => paginateProducts(currentPageProducts - 1)}
          disabled={currentPageProducts === 1}
          style={{
            backgroundColor: "white",
            color: "black",
            fontWeight: "bold",
            border: "1px solid black",
            padding: "10px 10px",
            cursor: "pointer",
            // width: '50vh',
            transition: "background-color 0.3s, color 0.3s",
            borderRadius: "10px",
          }}
          onMouseEnter={(e) => {
            e.target.style.backgroundColor = "green";
            e.target.style.color = "white";
          }}
          onMouseLeave={(e) => {
            e.target.style.backgroundColor = "white";
            e.target.style.color = "black";
          }}
        >
          Anterior
        </button>

        <p
          style={{
            backgroundColor: "#28a745", // Verde
            color: "white", // Texto blanco para contraste
            fontWeight: "bold",
            border: "1px solid #218838", // Bordes más oscuros
            padding: "10px 10px",
            cursor: "pointer",
            transition: "background-color 0.3s, color 0.3s",
            borderRadius: "10px",
          }}
        >
          Página {currentPageProducts}
        </p>

        <button
          onClick={() => paginateProducts(currentPageProducts + 1)}
          disabled={indexOfLastProduct >= filteredProducts.length}
          style={{
            backgroundColor: "black",
            color: "white",
            fontWeight: "bold",
            border: "1px solid black",
            padding: "10px 10px",
            cursor: "pointer",
            // width: '50vh',
            transition: "background-color 0.3s, color 0.3s",
            borderRadius: "10px",
          }}
          onMouseEnter={(e) => {
            e.target.style.backgroundColor = "green";
            e.target.style.color = "white";
          }}
          onMouseLeave={(e) => {
            e.target.style.backgroundColor = "black";
            e.target.style.color = "white";
          }}
        >
          Siguiente
        </button>
      </div>
      <div
        style={{
          position: "fixed",
          bottom: "10px",
          left: "10px",
          display: "flex",
          justifyContent: "space-between",
          width: "20%",
        }}
      >
        <div
          style={{
            backgroundColor: "blue",
            color: "white",
            padding: "10px",
            borderRadius: isMobile ? "" : "50%",
            cursor: "pointer",
          }}
          onClick={openCartModal}
        >
          <FaShoppingCart size={30} />
        </div>
      </div>

      {/* Modal Producto */}
      {modalProduct && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflowY: "auto",
            zIndex: 99,
          }}
        >
          <div
            style={{
              backgroundColor: "#f5e1c0", // Color base envejecido
              background: `radial-gradient(circle at 30% 20%, rgba(255,255,255,1) 0%, rgba(245, 237, 225,1) 25%, rgba(250, 207, 145,1) 100%)`, // Degradado envejecido más opaco
              padding: "20px",
              borderRadius: "10px",
              width: "90%",
              maxWidth: "100%",
              margin: "7% auto",
              position: "relative",
              overflow: "auto",
              height: "90%",
              border: "1px solid #d4b483", // Borde más oscuro para simular el desgaste
              boxShadow: `
          inset 0 0 5px 3px rgba(0,0,0,0.5), /* Sombras internas suaves para arrugas generales */
          inset -5px -5px 10px rgba(0,0,0,0.08), /* Sombras para simular profundidad */
          inset 5px 5px 15px rgba(0,0,0,0.08) /* Más sombras internas */
        `,
              filter: "grayscale(0.1)", // Suavizar colores
              position: "relative",
            }}
          >
            {/* Título centrado */}
            <div
              style={{
                textAlign: "center",
                marginBottom: "20px",
              }}
            >
              <h2>{modalProduct.name}</h2>
              <button
                onClick={closeModal}
                style={{
                  background: "red",
                  color: "white",
                  padding: "5px",
                  borderRadius: "50%",
                  border: "none",
                  cursor: "pointer",
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  zIndex: 99,
                }}
              >
                X
              </button>
            </div>

            <div
              style={{
                display: isMobile ? "block" : "flex", // Cambiar a block en móviles
                justifyContent: isMobile ? "center" : "space-between", // Centrar en móviles
                alignItems: "flex-start",
              }}
            >
              {/* Imagen en el 50% izquierdo con borde y sombra */}
              <div
                style={{
                  width: isMobile ? "100%" : "50%", // 100% en móviles
                  paddingRight: isMobile ? "0" : "10px", // Sin padding en móviles
                }}
              >
                {modalProduct.image && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <img
                      src={modalProduct.image}
                      alt="Product Image"
                      loading="lazy"
                      style={{
                        width: isMobile ? "100%" : "40%", // 100% en móviles
                        height: "auto",
                        border: "2px solid red",
                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
                      }}
                    />
                  </div>
                )}

                {/* Botón para ver imágenes adicionales */}
                <button
                  onClick={() =>
                    openImageModal(modalProduct._id, modalProduct.name)
                  }
                  style={{
                    backgroundColor: "white",
                    color: "black",
                    fontWeight: "bold",
                    fontSize: isMobile ? "1.2em" : "1.6em",
                    border: "1px solid black",
                    padding: "10px 10px",
                    cursor: "pointer",
                    width: "100%",
                    transition: "background-color 0.3s, color 0.3s",
                    borderRadius: "10px",
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = "green";
                    e.target.style.color = "white";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = "white";
                    e.target.style.color = "black";
                  }}
                >
                  Ver imágenes adicionales
                </button>

                <div style={{ marginTop: "4%" }}>
                  <table
                    style={{
                      border: "1px solid #ccc",
                      padding: "8px",
                      color: "black",
                      borderBottom: "1px solid #ddd",
                      fontWeight: "bold",
                      fontSize: isMobile ? "1.2em" : "1.6em",
                      wordWrap: "break-word", // Permitir que el texto se divida en varias líneas
                    }}
                  >
                    <tbody>
                      <tr>
                        <td>Estrellas:</td>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "row",
                            }}
                          >
                            {Array.from(
                              { length: modalProduct.rating },
                              (_, index) => (
                                <FaStar
                                  key={index}
                                  color="gold"
                                  style={{ marginRight: "3px" }}
                                />
                              )
                            )}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Vistas:</td>
                        <td>{modalProduct.numReviews}</td>
                      </tr>
                      <tr>
                        <td>Precio:</td>
                        <td>
                          ₡{formatPrice(modalProduct.price)}
                          <span style={{ color: "green", marginLeft: "10px" }}>
                            (${(modalProduct.price / 520).toFixed(2)})
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td>Descripción:</td>
                        <td style={{ textAlign: "left" }}>
                          {modalProduct.description
                            .split("..")
                            .map((item, index) => (
                              <p key={index}>{item.replace(/: /g, ":")}</p>
                            ))}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              {/* Tabla en el 50% derecho */}
              <div
                style={{
                  width: isMobile ? "100%" : "50%", // 100% en móviles
                  paddingLeft: isMobile ? "0" : "10px", // Sin padding en móviles
                }}
              >
                {formProduct ? (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <table
                        style={{
                          width: "100%",
                          borderCollapse: "collapse",
                          marginBottom: "20px",
                          fontSize: isMobile ? "1.2em" : "1.4em", // Ajusta el tamaño de la fuente si lo prefieres más pequeño
                          tableLayout: "fixed", // Mantener el tamaño de la tabla estático
                          lineHeight: 1.5,
                        }}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                border: "1px solid #ccc",
                                backgroundColor: "rgb(0, 255, 0)",
                                color: "black",
                                padding: "8px",
                                textAlign: "center",
                                width: "50%", // Asegura que las columnas se dividan equitativamente
                                fontSize: isMobile ? "1.2em" : "1.4em",
                              }}
                            >
                              Características
                            </th>
                            <th
                              style={{
                                border: "1px solid #ccc",
                                backgroundColor: "rgb(0, 255, 0)",
                                color: "black",
                                padding: "8px",
                                textAlign: "center",
                                fontSize: isMobile ? "1.2em" : "1.4em",
                                width: "50%", // Mantener un tamaño fijo para la columna
                              }}
                            >
                              {modalProduct.name}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {formProduct.fields.map((field, index) => (
                            <tr key={index}>
                              <td
                                style={{
                                  border: "1px solid #ccc",
                                  padding: "8px",
                                  color: "black",
                                  borderBottom: "1px solid #ddd",
                                  fontWeight: "bold",
                                  fontSize: isMobile ? "1.4em" : "1.4em",
                                  wordWrap: "break-word", // Permitir que el texto se divida en varias líneas
                                }}
                              >
                                {field.label}:
                              </td>
                              <td
                                style={{
                                  border: "1px solid #ccc",
                                  padding: "8px",
                                  color: "black",
                                  borderBottom: "1px solid #ddd",
                                  fontWeight: "bold",
                                  fontSize: isMobile ? "1.4em" : "1.4em",
                                  wordWrap: "break-word", // Manejar contenido largo en varias líneas
                                }}
                              >
                                {field.label === "Precio (¢)" ? (
                                  <span
                                    style={{
                                      display: "inline-block",
                                      wordWrap: "break-word", // Adaptar el contenido largo
                                    }}
                                  >
                                    {/* Asegúrate de que product.price esté definido en el contexto adecuado */}
                                    ₡{formatPrice(responses[field._id])} ( $
                                    {(
                                      (responses[field._id] || 0) / 520
                                    ).toFixed(2)}
                                    )
                                  </span>
                                ) : field.label === "Dirección maps" ? (
                                  <iframe
                                    src={responses[field._id]}
                                    width="100%"
                                    height="200px"
                                    style={{
                                      border: 0,
                                    }}
                                    allowFullScreen=""
                                    loading="lazy"
                                  ></iframe>
                                ) : (
                                  <span
                                    style={{
                                      width: "100%",
                                      padding: "5px",
                                      display: "inline-block",
                                      wordWrap: "break-word", // Adaptar el contenido largo
                                    }}
                                  >
                                    {responses[field._id] || ""}
                                  </span>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <p
                      style={{
                        border: "1px solid #ccc",
                        padding: "8px",
                        color: "black",
                        borderBottom: "1px solid #ddd",
                        fontWeight: "bold",
                        fontSize: isMobile ? "1.4em" : "1.4em",
                        wordWrap: "break-word", // Permitir que el texto se divida en varias líneas
                      }}
                    >
                      Fecha de creación:{" "}
                      {new Date(formProduct.created_at).toLocaleString()}
                    </p>
                    <p
                      style={{
                        border: "1px solid #ccc",
                        padding: "8px",
                        color: "black",
                        borderBottom: "1px solid #ddd",
                        fontWeight: "bold",
                        fontSize: isMobile ? "1.4em" : "1.4em",
                        wordWrap: "break-word", // Permitir que el texto se divida en varias líneas
                      }}
                    >
                      Vendedor: {getUserNameById(formProduct.user)}
                    </p>
                    {/* <button onClick={handleSubmitResponse}>Enviar Respuesta</button> */}
                  </div>
                ) : (
                  <p>No se encontró el formulario.</p>
                )}

                {/* Línea separadora */}
                <hr style={{ width: "100%", margin: "20px 0" }} />

                {/* Botón de agregar al carrito */}
                <button
                  onClick={() => handleAddToCart(modalProduct)}
                  style={{
                    backgroundColor: "white",
                    color: "black",
                    fontWeight: "bold",
                    fontSize: isMobile ? "1.8em" : "2.0em",
                    border: "1px solid black",
                    padding: "10px 10px",
                    cursor: "pointer",
                    width: "100%",
                    transition: "background-color 0.3s, color 0.3s",
                    borderRadius: "10px",
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = "green";
                    e.target.style.color = "white";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = "white";
                    e.target.style.color = "black";
                  }}
                >
                  Agregar a mi pedido
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Modal de imágenes adicionales */}
      <Modal
        show={isImageModalOpen}
        onHide={() => setIsImageModalOpen(false)}
        size="lg"
        style={{ overflowX: "auto", overflowY: "auto" }}
      >
        <Modal.Title>
          <span style={{ fontSize: "100%", fontWeight: "bold" }}>
            {selectedProductName}
          </span>
        </Modal.Title>
        <Modal.Body
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: isMobile ? "120vh" : "80vh", // 70% de la altura de la pantalla
            backgroundColor: "white", // Color de fondo del cuadro
            position: "relative", // Necesario para el botón de cierre
            overflow: "auto", // Asegúrate de que se pueda desplazar si el contenido es demasiado grande
            margin: "auto", // Centrar el modal en la pantalla
            borderRadius: "8px", // Opcional: bordes redondeados
          }}
        >
          <div
            className="text-center"
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            {selectedImages.length > 0 && (
              <div
                style={{
                  position: "relative",
                  width: isMobile ? "100%" : "400px",
                }}
              >
                {/* Imagen pequeña (normal) */}
                <img
                  src={selectedImages[currentImageIndex].image}
                  alt="Selected Product"
                  loading="lazy"
                  className={`img-fluid ${isZoomed ? "zoom-active" : ""}`}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={handleMouseLeave}
                  style={{
                    width: isMobile ? "100%" : "400px",
                    height: isMobile ? "auto" : "400px",
                    cursor: "zoom-in",
                    position: "relative",
                    objectFit: "cover",
                    zIndex: "1",
                  }}
                />

                {/* Imagen ampliada (zoom) */}
                {isZoomed && (
                  <img
                    src={selectedImages[currentImageIndex].image}
                    alt="Zoomed Product"
                    loading="lazy"
                    style={{
                      position: "absolute",
                      width: "800px",
                      height: "800px",
                      top: `${zoomPosition.top}px`,
                      left: `${zoomPosition.left}px`,
                      clip: `rect(${zoomPosition.y1}px, ${zoomPosition.x2}px, ${zoomPosition.y2}px, ${zoomPosition.x1}px)`,
                      zIndex: "999",
                      pointerEvents: "none", // Evita interacción accidental con la imagen ampliada
                    }}
                  />
                )}
              </div>
            )}
          </div>

          <hr />
          <div className="text-left">
            {selectedImages.length > 0 && (
              <p style={{ fontSize: "200%", fontWeight: "bold" }}>
                Descripcion: {selectedImages[currentImageIndex].comment}
              </p>
            )}
          </div>
          <hr />
          <div
            className="row"
            style={{ width: "100%", justifyContent: "center", display: "flex" }}
          >
            <div className="col-md-2">
              {/* Botón "Imagen anterior" */}
              <button
                onClick={showPreviousImage}
                style={{
                  backgroundColor: "white",
                  color: "black",
                  fontWeight: "bold",
                  border: "1px solid black",
                  padding: "10px 10px",
                  cursor: "pointer",
                  // width: '50vh',
                  transition: "background-color 0.3s, color 0.3s",
                  borderRadius: "10px",
                }}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = "green";
                  e.target.style.color = "white";
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = "white";
                  e.target.style.color = "black";
                }}
                disabled={currentImageIndex === 0}
              >
                Imagen anterior
              </button>
            </div>

            <div className="col-md-2">
              {/* Botón "Imagen siguiente" */}
              <button
                onClick={showNextImage}
                style={{
                  backgroundColor: "black",
                  color: "white",
                  fontWeight: "bold",
                  border: "1px solid black",
                  padding: "10px 10px",
                  cursor: "pointer",
                  // width: '50vh',
                  transition: "background-color 0.3s, color 0.3s",
                  borderRadius: "10px",
                }}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = "green";
                  e.target.style.color = "white";
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = "black";
                  e.target.style.color = "white";
                }}
                disabled={currentImageIndex === selectedImages.length - 1}
              >
                Imagen siguiente
              </button>
            </div>
          </div>

          <hr />
          {/* Botón de cerrar el modal */}
          <div className="text-center">
            <button
              onClick={() => setIsImageModalOpen(false)}
              style={{
                background: "red",
                color: "white",
                padding: "5px",
                borderRadius: "50%",
                border: "none",
                cursor: "pointer",
                position: "absolute",
                top: "0vh",
                right: "4px",
                zIndex: 99999,
              }}
            >
              X
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {/* Modal del Carrito */}
      {isCartModalOpen && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflowY: "auto", // Habilita el desplazamiento vertical
          }}
        >
          <div
            style={{
              background: "white",
              padding: "20px",
              borderRadius: "10px",
              width: "90%",
              maxWidth: "90%",
              maxHeight: "80%",
              margin: "7% auto",
              position: "relative",
              overflow: "auto",
            }}
          >
            {/* Contenido del Carrito */}
            <h2 onClick={closeCartModal}> Carrito de Compras</h2>
            {/* Agrega aquí la lógica para mostrar los productos en el carrito */}
            <button
              onClick={closeCartModal}
              style={{
                background: "red",
                color: "white",
                padding: "8px",
                borderRadius: "5px",
                border: "none",
                cursor: "pointer",
                position: "absolute",
                top: "40px",
                right: "4px",
                zIndex: 9999, // Asegura que esté en la parte superior
                fontSize: "20px", // Aumenta el tamaño para dispositivos móviles
              }}
            >
              X
            </button>
            <br />
            <table style={tableStyle}>
              <thead>
                <tr>
                  <th style={thStyle}>Producto</th>
                  <th style={thStyle}>Categoría</th>
                  <th style={thStyle}>Marca</th>
                  <th style={thStyle}>Precio</th>
                  <th style={thStyle}>Cantidad</th>
                  <th style={thStyle}>Subtotal</th>
                  <th style={thStyle}>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {cartItems.map((item) => (
                  <tr key={item._id}>
                    <td style={tdStyle}>{item.name}</td>
                    <td style={tdStyle}>{item.category}</td>
                    <td style={tdStyle}>{item.brand}</td>
                    <td style={tdStyle}>₡{item.price}</td>
                    <td style={tdStyle}>
                      <input
                        type="number"
                        value={item.quantity}
                        onChange={(e) =>
                          handleQuantityChange(
                            item._id,
                            parseInt(e.target.value, 10)
                          )
                        }
                        style={{ width: "50px" }}
                      />
                    </td>
                    <td style={tdStyle}>₡{item.price * item.quantity}</td>
                    <td style={tdStyle}>
                      <button onClick={() => handleRemoveFromCart(item._id)}>
                        Eliminar
                      </button>
                    </td>
                  </tr>
                ))}
                {/* Información de envío y total */}
                <tr>
                  <td colSpan="5" style={tdStyle}>
                    Envío por Correos de Costa Rica
                  </td>
                  <td style={tdStyle}>₡{correosShippingCost}</td>
                  <td style={tdStyle}>
                    {correosShippingRemoved ? (
                      <button
                        onClick={() => {
                          setCorreosShippingRemoved(false);
                          setCorreosShippingCost(3500);
                        }}
                      >
                        Restaurar
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          setCorreosShippingRemoved(true);
                          setCorreosShippingCost(0);
                        }}
                      >
                        Eliminar
                      </button>
                    )}
                  </td>
                </tr>
                <tr>
                  <td colSpan="5" style={tdStyle}>
                    Total
                  </td>
                  <td style={tdStyle}>
                    ₡{correosShippingRemoved ? subtotal : subtotal + 3500}
                  </td>
                </tr>
              </tbody>
            </table>
            <button
              onClick={handleClearCart}
              style={{
                backgroundColor: "red",
                color: "white",
                padding: "10px",
                borderRadius: "5px",
                border: "none",
                cursor: "pointer",
                marginTop: "10px",
              }}
            >
              Vaciar Carrito
            </button>
            <button
              onClick={handleSendOrder}
              style={{
                backgroundColor: "green",
                color: "white",
                padding: "10px",
                borderRadius: "5px",
                border: "none",
                cursor: "pointer",
                marginTop: "10px",
              }}
            >
              Enviar Pedido por WhatsApp
            </button>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <button
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
                style={{
                  backgroundColor: "white",
                  color: "black",
                  fontWeight: "bold",
                  border: "1px solid black",
                  padding: "10px 10px",
                  cursor: "pointer",
                  // width: '50vh',
                  transition: "background-color 0.3s, color 0.3s",
                  borderRadius: "10px",
                }}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = "green";
                  e.target.style.color = "white";
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = "white";
                  e.target.style.color = "black";
                }}
              >
                Anterior
              </button>
              <p
                style={{
                  backgroundColor: "#28a745", // Verde
                  color: "white", // Texto blanco para contraste
                  fontWeight: "bold",
                  border: "1px solid #218838", // Bordes más oscuros
                  padding: "10px 10px",
                  cursor: "pointer",
                  transition: "background-color 0.3s, color 0.3s",
                  borderRadius: "10px",
                }}
              >
                Página {currentPage}
              </p>
              <button
                onClick={() => paginate(currentPage + 1)}
                // disabled={indexOfLastItem >= orders.length}
                style={{
                  backgroundColor: "black",
                  color: "white",
                  fontWeight: "bold",
                  border: "1px solid black",
                  padding: "10px 10px",
                  cursor: "pointer",
                  // width: '50vh',
                  transition: "background-color 0.3s, color 0.3s",
                  borderRadius: "10px",
                }}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = "green";
                  e.target.style.color = "white";
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = "black";
                  e.target.style.color = "white";
                }}
              >
                Siguiente
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default HomeScreen;
