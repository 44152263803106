import React, { useEffect } from 'react';
import ProximamenteImage from '../../assets/proximamente.jpg'; // Importa la imagen desde la carpeta assets

const Noticias = () => {
  // Estilos para el contenedor principal
  const containerStyle = {
    width: '100%',
    height: '135vh',
    marginTop: '8%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
  };

  // Estilos para el botón
  const buttonStyle = {
    margin: '16px',
    padding: '10px',
    backgroundColor: '#FF0000',
    color: '#ffffff',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(255, 0, 0, 0.5)',
    cursor: 'pointer',
  };

  // Función para redirigir al usuario al sitio de noticias
  const redirectToNoticias = () => {
    window.location.href = 'https://abcupon.com/';
  };

  // Estilos para la imagen de 'Próximamente'
  const imageContainerStyle = {
    marginTop: '20%',
    width: '60%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'visible',
  };

  const imageStyle = {
    width: '100%',
    height: 'auto',
    animation: 'spin 6s ease-in-out infinite', // 6 segundos para la animación completa
    transformOrigin: 'center',
  };

  // Definición de la animación para rotación con pausa
  useEffect(() => {
    const styleSheet = document.styleSheets[0];
    const keyframes = `
      @keyframes spin {
        0% { transform: rotate(0deg); }
        40% { transform: rotate(360deg); }
        100% { transform: rotate(360deg); } /* Mantiene la rotación final */
      }
    `;
    styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
  }, []);

  return (
    <div style={containerStyle}>
      {/* Contenedor de la imagen de 'Próximamente' */}
      <div style={imageContainerStyle}>
        <img
          src={ProximamenteImage}
          alt="Próximamente"
          style={imageStyle} // Aplica el estilo definido para la imagen
        />
      </div>
 {/* Iframe para mostrar el contenido de noticias */}
      {/* <iframe
        src="https://www.noticias.abcupon.com/"
        title="Noticias ABCupon"
        style={iframeStyle}
        allowFullScreen
      ></iframe> */}
      {/* Botón para redirigir al sitio de noticias */}
      <button style={buttonStyle} onClick={redirectToNoticias}>
        Ir a Noticias de ABCupon
      </button>
    </div>
  );
};

export default Noticias;
